import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class ImpressumPage extends Component {
  render() {
    return (
      <div id="not_found" className="page">
        <Container>
					<h5><b>Impressum</b></h5>
          <Row>
            <Col>
              <p>Angaben gemäß § 5 TMG:<br/>Hellofarmer UG<br/>10787 Berlin<br/>Deutschland</p>
              <p>Vertreten durch:<br/>Matija Corkovic</p>
              <p>Kontakt:<br/>E-Mail: info@hellofarmer.de</p>
              <p>Handelsregister:<br/>Amtsgericht Charlottenburg (Berlin) HRB 231619 B</p>
              <p>Umsatzsteuer-ID:<br/>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br/>DEXXXXXXXXX</p>
            </Col>
          </Row>
				</Container>
      </div>
    );
  }
}

export default ImpressumPage;