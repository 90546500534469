import React, { Component } from "react";
import { Container, Button } from "reactstrap";

import history from "../../../../helper/history";

// import { Link } from "react-router-dom";
import { ReactComponent as OrderSuccessIcon } from "../../../../assets/orderSuccessIcon.svg"

class MembershipSuccessPage extends Component {
  render() {
    return (
      <div className="page">
        <Container className="order-success-page">
					<div>
						<OrderSuccessIcon className="mt-5" />
						<h4 className="page-title mb-5">Willkommen bei Hellofarmer Premium</h4>
						<Button onClick={() => history.push("/market")}>ZUM MARKT</Button>
            <p className="greyed-out my-2">oder</p>
            <Button onClick={() => history.push("/checkout")}>ZUM CHECKOUT</Button>
					</div>
        </Container>
      </div>
    );
  }
}

export default MembershipSuccessPage;