import gql from "graphql-tag";
// Register user
export const REGISTER = gql`
  mutation register(
    $name: String!
    $email: String!
    $telephone: String!
    $password: String!
    $language: String!
    $region: String!
    $city: String!
    $userType: Int!
    $sendMarketingMaterial: Boolean!
    $address: Delivery_addressInput
  ) {
    register(
      name: $name
      email: $email
      telephone: $telephone
      password: $password
      language: $language
      region: $region
      city: $city
      userType: $userType
      sendMarketingMaterial: $sendMarketingMaterial
      address: $address
    ) {
      token
      user {
        name
        email
        telephone
        role
        region
        language
        profile_picture
        feed {
          ... on FeedItem {
            id
            creator
            receiver
            type
            sourceId
            creation_date
          }
        }
        delivery_address
        notification_preferences {
          order_updates
          item_request_updates
          email_notifications
          sms_notifications
          browser_notifications
        }
      }
    }
  }
`;
