import {
  CREATE_MARKET_LIST,
  CREATE_MARKET_LIST_FROM_CART,
  CREATE_MARKET_LIST_FROM_ORDER,
  DELETE_MARKET_LIST,
  ADD_ITEM_TO_MARKETLIST,
  REMOVE_ITEM_FROM_MARKETLIST,
  GET_ALL_MARKET_LISTS,
  GET_SINGLE_MARKET_LIST,
  CHANGE_MARKETLIST_NAME,
  MARKETLISTS_LOADING
} from "../actions/types";

const initialState = {
  marketlists: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MARKETLISTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CREATE_MARKET_LIST:
      return {
        ...state,
        marketlists: [...state.marketlists, action.payload],
        loading: false
      };
    case CREATE_MARKET_LIST_FROM_CART:
      return {
        ...state,
        marketlists: [...state.marketlists, action.payload],
        loading: false
      };
    case CREATE_MARKET_LIST_FROM_ORDER:
      return {
        ...state,
        marketlists: [...state.marketlists, action.payload],
        loading: false
      };
    case DELETE_MARKET_LIST:
      return {
        ...state,
        marketlists: action.payload,
        loading: false
      };
    case ADD_ITEM_TO_MARKETLIST:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case REMOVE_ITEM_FROM_MARKETLIST:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CHANGE_MARKETLIST_NAME:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case GET_ALL_MARKET_LISTS:
      return {
        ...state,
        marketlists: action.payload,
        loading: false
      };
    case GET_SINGLE_MARKET_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    default:
      return state;
  }
}
