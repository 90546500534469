import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  Row,
  Col,
	Table
} from "reactstrap";
import Toggle from "react-toggle";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import history from "../../../helper/history";
import { getCoupons } from "../../../actions/couponActions";
import { adminGetAllOrders } from "../../../actions/orderActions";
import AdminCreateCouponModal from "../pages/coupons/AdminCreateCouponModal";
import { toggleCouponStatus } from "../../../actions/couponActions";
// import OrdersList from "./OrdersList";

// import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

import Avatar from "@material-ui/core/Avatar";

class AdminCouponsTab extends Component {
  state = {
    name: "",
    email: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

	componentDidMount() {
		this.props.getCoupons();
		this.props.adminGetAllOrders();
	} 

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

	getShortOrderId = orderId => {
    var shortOrderId = ""
    shortOrderId = orderId.substring(0,6) + "..."
    return shortOrderId 
  }

  deliveryDateField = delivery_date => {
    if (delivery_date){
      if (delivery_date.charAt(4) == "-") {
        var shortOrderDate = delivery_date.split("T")[0]
        var splitOrderDate = shortOrderDate.split("-")
        const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
        return date
      } else {
        var splitDeliveryDate = delivery_date.split(' ')
        var day = splitDeliveryDate[2];
        var month = splitDeliveryDate[1];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months.indexOf(month);
        month += 1;
        var year = splitDeliveryDate[3];
        const date = day + '.' + ('0' + month).slice(-2) + '.' + year
        return date
      }
    }
  }

  orderDateField = orderDate => {
    if (orderDate){
      var shortOrderDate = orderDate.split("T")[0]
      var splitOrderDate = shortOrderDate.split("-")
      const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
      return date
    }
  }

  statusField = status => {
		if (status === false){
			return <span className="order-status-red">Inactive</span>;
		} else if (status === true){
			return <span className="order-status-green">Active</span>;
		}
  };

	toggleCouponStatus = (couponId) => {
    this.props.toggleCouponStatus(couponId);
    Swal.fire({
      text: "Änderungen wurden gespeichert",
      icon: "success",
      confirmButtonColor: "#C25982",
    	confirmButtonText: "Ok"
    });
  };

  render() {
    const { user } = this.props.auth;
		const { all_orders } = this.props.order;
		const { coupons } = this.props.coupon;
		const orderedCoupons = [...coupons].reverse();
    const { loading } = this.props.coupon;
		const orderedOrders = [...all_orders].reverse()

    return (
      <LoadingOverlay
        className="rounded-edges"
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <Container className="admin-tab my-4 p-0 orders-tab">
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    Coupons ({coupons.length})
										<AdminCreateCouponModal />
                  </h5>
                </Col>
              </Row>
							<div className="project-list">
        				<Table>
          				<thead>
           	 				<tr className="greyed-out">
              				<th className="pl-4">Code</th>
              				<th className="hide-on-mobile">Value off</th>
              				<th className="hide-on-mobile">Pct off</th>
											<th className="hide-on-mobile">Redeemers</th>
              				<th className="pr-4">Active</th>
            				</tr>
          				</thead>
          			<tbody>
            			{orderedCoupons.length ? (
             				orderedCoupons.map(
                  ({ id, code, value_off, pct_off, redeemers, is_active }) => {
                    return (
											<tr
                        className="dark-grey"
                        // onClick={() => history.push(`/admin/coupons/${id}`)}
                      >
                        <td className="pl-4">{code}</td>
												<td className="hide-on-mobile">{value_off ? `${this.props.t('currencySymbol')}${value_off}` : null}</td>
												<td className="hide-on-mobile">{pct_off ? `${pct_off}%` : null}</td>
												<td className="hide-on-mobile">{redeemers.length}</td>
												<td className="pr-4">
													<button
                            className="overview-button float-left p-0"
                            onClick={() => this.toggleCouponStatus(id)}
                          >
                            <Toggle
                              checked={is_active}
                              icons={false}
                            />
                          </button>	
												</td>
                      </tr>
                      // <tr
                      //   className="orders-list-item dark-grey"
                      //   onClick={() => history.push(`/admin/orders/${id}`)}
                      // >
                      //   <td className="pl-4">{order_no}</td>
                      //   <td>{delivery_address?.name}</td>
                      //   {/* <td>{supplier ? supplier.name : "None"}</td> */}
                      //   <td>{this.orderDateField(date)}</td>
                      //   <td className="hide-on-mobile">{items.length} Artikel</td>
                      //   <td>{total_price.toFixed(2)}€</td>
                      //   <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                      //   <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      // </tr>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    There are currently no orders...
                  </p>
                )}
          			</tbody>
        			</Table>
      			</div>
            </Card>
          </Col>
        </Row>
      </Container>
      </LoadingOverlay>
    );
  }
}

AdminCouponsTab.propTypes = {
  user: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	coupon: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
	order: state.order,
	coupon: state.coupon
});

export default withTranslation()(connect(
  mapStateToProps,
  { getCoupons, adminGetAllOrders, toggleCouponStatus }
)(withRouter(AdminCouponsTab)));