import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import supplierReducer from "./supplierReducer";
import itemReducer from "./itemReducer";
import item_requestReducer from "./item_requestReducer";
import deliveryaddressReducer from "./deliveryaddressReducer";
import orderReducer from "./orderReducer";
import marketListReducer from "./marketListReducer";
import cartReducer from "./cartReducer";
import feedReducer from "./feedReducer";
import newsReducer from "./newsReducer";
import membershipReducer from "./membershipReducer";
import couponReducer from "./couponReducer";

export default combineReducers({
  supplier: supplierReducer,
  item: itemReducer,
  item_request: item_requestReducer,
  delivery_address: deliveryaddressReducer,
  order: orderReducer,
  marketlist: marketListReducer,
  cart: cartReducer,
  feed: feedReducer,
  error: errorReducer,
  auth: authReducer,
  news: newsReducer,
  membership: membershipReducer,
  coupon: couponReducer
});
