import React, { Component } from "react";
// import history from "../../helper/history";
import { Container, Row, Col } from "reactstrap";
import AppNavbar from "../navigation/navbar/AppNavbar";
import DashboardPage from "./main_pages/dashboard/DashboardPage";
import HelpPage from "./main_pages/HelpPage";
import FeedbackPage from "./main_pages/FeedbackPage";
import SettingsPage from "./main_pages/user/accountSettings/SettingsPage";
import NotFoundPage from "./main_pages/not_found_page";
import MarketPage from "./main_pages/market/MarketPage";
import SingleItemPage from "./main_pages/market/SingleItemPage";
import MarketlistsPage from './main_pages/marketlists/MarketlistsPage';
import SingleMarketListPage from "./main_pages/market/SingleMarketListPage";
import CheckoutPage from "./main_pages/market/CheckoutPage";
import OrdersPage from "./main_pages/orders/OrdersPage";
import AnalyticsPage from "./main_pages/analytics/AnalyticsPage";
import FarmersPage from "./main_pages/suppliers/FarmersPage";
import SingleFarmerPage from "./main_pages/suppliers/SingleFarmerPage";
import SingleSupplierPage from "./main_pages/suppliers/SingleSupplierPage";
import SingleOrderPage from "./main_pages/orders/SingleOrderPage";
import AgbPage from "./main_pages/AgbPage";
import DatenschutzPage from "./main_pages/DatenschutzPage";
import ImpressumPage from "./main_pages/ImpressumPage";
import NewsItemPage from "./main_pages/NewsItemPage";
import OrderSuccessPage from "./main_pages/OrderSuccessPage";
import MembershipsPage from "./main_pages/membership/MembershipsPage";
import MembershipSuccessPage from "./main_pages/MembershipSuccessPage";
import AdminPage from "../../admin/AdminPage";
import AdminSingleUserPage from "../../admin/pages/AdminSingleUserPage";
import AdminSingleFarmerPage from "../../admin/pages/farmers/AdminSingleFarmerPage";
import AdminSingleItemPage from "../../admin/pages/items/AdminSingleItemPage";
import FarmerPage from "../../farmer/FarmerPage";
// import ConfirmOrderPage from "../../global/ConfirmOrderPage";

/* eslint-disable-next-line */
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

class MainContent extends Component {
  render() {
    return (
      <div
        className={`main-content ${
          this.props.sidebarOpen ? "sidebarOpen" : "sidebarClosed"
        }`}
      >
        <AppNavbar toggleSidebar={this.props.toggleSidebar} />
        {this.props.isAdmin ?
          <Switch>
          <Redirect from="/login" to="/market" />
          <Redirect from="/register" to="/market" />
          {/* <Route
            exact
            path="/"
            // render={props => <DashboardPage {...props} testProp={"TestProp"} />}
            component={DashboardPage}
          /> */}
          <Redirect exact from="/" to="/market" />
          <Route exact path="/admin" component={AdminPage}/>
          <Route path="/admin/orders/:id" component={SingleOrderPage} />
          <Route path="/admin/users/:id" component={AdminSingleUserPage} />
          <Route path="/admin/farmers/:id" component={AdminSingleFarmerPage} />
          <Route path="/admin/items/:id" component={AdminSingleItemPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/feedback" component={FeedbackPage} />
          <Route exact path ="/agb" component={AgbPage}/>
          <Route exact path ="/datenschutz" component={DatenschutzPage}/>
          <Route exact path ="/impressum" component={ImpressumPage}/>
          <Route exact path="/market" component={MarketPage} />
          <Route exact path="/marketlists" component={MarketlistsPage} />
          <Route
            path="/market/singlemarketlist"
            component={SingleMarketListPage}
          />
          <Route path="/marketlists/:id" component={SingleMarketListPage} />
          {/* <Route path="/market/:id" component={SingleMarketListPage} /> */}
          <Route path="/market/items/:id" component={SingleItemPage} />
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/items/singleitem" component={SingleItemPage} />
          <Route path="/items/:id" component={SingleItemPage} />
          <Route exact path="/orders" component={OrdersPage} />
          <Route path="/orders/singleorder" component={SingleOrderPage} />
          <Route path="/orders/:id" component={SingleOrderPage} />
          <Route path="/analytics" component={AnalyticsPage} />
          {/* <Route exact path="/suppliers" component={SuppliersPage} /> */}
          <Route exact path="/farmers" component={FarmersPage} />
          <Route exact path="/farmers/:id" component={SingleFarmerPage} />
          <Route
            path="/suppliers/singlesupplier"
            component={SingleSupplierPage}
          />
          <Route path="/suppliers/:id" component={SingleSupplierPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/news/:id" component={NewsItemPage} />
          <Route path="/order-success" component={OrderSuccessPage} />
          <Route path="/membership" component={MembershipsPage} />
          <Route path="/membership-success" component={MembershipSuccessPage} />
          {/* <Route path="/confirm_order/:id" component={ConfirmOrderPage} /> */}
          <Route path="/*" component={NotFoundPage} />
          </Switch> :

          this.props.isGuest ? 
            <Switch>
              <Redirect from="/login" to="/market" />
              <Redirect from="/register" to="/market" />
              <Route
                exact
                path="/"
                // render={props => <DashboardPage {...props} testProp={"TestProp"} />}
                component={MarketPage}
              />
              <Route exact path="/help" component={HelpPage} />
              <Route exact path="/feedback" component={FeedbackPage} />
              <Route exact path ="/agb" component={AgbPage}/>
              <Route exact path ="/datenschutz" component={DatenschutzPage}/>
              <Route exact path ="/impressum" component={ImpressumPage}/>
              <Route exact path="/market" component={MarketPage} />
              <Route path="/market/items/:id" component={SingleItemPage} />
              <Route exact path="/farmers" component={FarmersPage} />
              <Route exact path="/orders" component={OrdersPage} />
              <Route exact path="/farmers/:id" component={SingleFarmerPage} />
              <Route path="/news/:id" component={NewsItemPage} />
              <Route path="/*" component={NotFoundPage} />
            </Switch> :
            <Switch>
              <Redirect from="/login" to="/market" />
              <Redirect from="/register" to="/market" />
              <Route
                exact
                path="/"
                // render={props => <DashboardPage {...props} testProp={"TestProp"} />}
                component={MarketPage}
              />
              <Route exact path="/farmer" component={FarmerPage}/>
              <Route exact path="/help" component={HelpPage} />
              <Route exact path="/feedback" component={FeedbackPage} />
              <Route exact path ="/agb" component={AgbPage}/>
              <Route exact path ="/datenschutz" component={DatenschutzPage}/>
              <Route exact path ="/impressum" component={ImpressumPage}/>
              <Route exact path="/market" component={MarketPage} />
              <Route exact path="/marketlists" component={MarketlistsPage} />
              <Route
                path="/market/singlemarketlist"
                component={SingleMarketListPage}
              />
              <Route path="/marketlists/:id" component={SingleMarketListPage} />
              {/* <Route path="/market/:id" component={SingleMarketListPage} /> */}
              <Route path="/market/items/:id" component={SingleItemPage} />
              <Route path="/checkout" component={CheckoutPage} />
              <Route path="/items/singleitem" component={SingleItemPage} />
              <Route path="/items/:id" component={SingleItemPage} />
              <Route exact path="/orders" component={OrdersPage} />
              <Route path="/orders/singleorder" component={SingleOrderPage} />
              <Route path="/orders/:id" component={SingleOrderPage} />
              <Route path="/analytics" component={AnalyticsPage} />
              {/* <Route exact path="/suppliers" component={SuppliersPage} /> */}
              <Route exact path="/farmers" component={FarmersPage} />
              <Route exact path="/farmers/:id" component={SingleFarmerPage} />
              <Route
                path="/suppliers/singlesupplier"
                component={SingleSupplierPage}
              />
              <Route path="/suppliers/:id" component={SingleSupplierPage} />
              <Route path="/settings" component={SettingsPage} />
              <Route path="/news/:id" component={NewsItemPage} />
              <Route path="/order-success" component={OrderSuccessPage} />
              <Route path="/membership" component={MembershipsPage} />
              <Route path="/membership-success" component={MembershipSuccessPage} />
              {/* <Route path="/confirm_order/:id" component={ConfirmOrderPage} /> */}
              <Route path="/*" component={NotFoundPage} />
            </Switch>
            }
        
        <hr></hr>
        <Container>
        <Row className="mb-3">
          <Col>
            <NavLink className="footer-link" to="/datenschutz">
              Datenschutz
            </NavLink>
          </Col>
          <Col>
            <NavLink className="footer-link" to="/impressum">
              Impressum
            </NavLink>
          </Col>
          <Col>
            <NavLink className="footer-link" to="/agb">
              AGB
            </NavLink>
          </Col>
        </Row>
        </Container>
      </div>
    );
  }
}

export default MainContent;
