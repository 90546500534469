import React, { Component } from "react";
import { Container } from "reactstrap";

class DatenschutzPage extends Component {
  render() {
    return (
      <div id="not_found" className="page">
        <Container>
					<h5><b>Datenschutz</b></h5>
				</Container>
      </div>
    );
  }
}

export default DatenschutzPage;