import gql from "graphql-tag";
// Invite supplier
export const CREATE_MARKET_LIST_MUTATION = gql`
  mutation createMarketlist($marketlist: MarketlistInput!) {
    createMarketlist(marketlist: $marketlist) {
      id
      name
    }
  }
`;
