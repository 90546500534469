import React, { Component, Fragment } from "react";

import { withTranslation } from 'react-i18next';

import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import history from "../../helper/history";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

import { logout } from '../../actions/authActions'

class Logout extends Component {
  logoutUser = () => {
    Swal.fire({
      text:
        "Möchten Sie sich wirklich von Ihrem Konto abmelden?",
      imageUrl: 'https://res.cloudinary.com/dqzo9yjly/image/upload/v1637207966/icons/cd7oyf8mtpkobfx8emtg.svg',
      imageWidth: 97,
      imageHeight: 97,
      imageAlt: 'Custom image',
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ausloggen"
    }).then(result => {
      if (result.value) {
        // console.log("TEST")
        this.props.logout();
        history.push("/login");
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Button id="btn-logout" onClick={this.logoutUser}>
          <Row>
            {/* <Col className='hide-on-mobile' sm={1}>
              <FontAwesomeIcon className="dark-grey" icon={faSignOutAlt} />
            </Col> */}
            <Col className="dark-grey">
              <FontAwesomeIcon className="hide-on-desktop" icon={faSignOutAlt} style={{marginRight: '0.5rem', stroke: '#909090'}} />
              {this.props.t('logOut')}
            </Col>
          </Row>
        </Button>
      </Fragment>
    );
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  
});

export default withTranslation()(connect(
  mapStateToProps,
  { logout }
)(Logout));
