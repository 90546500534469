import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { Image } from "cloudinary-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { SRLWrapper } from "simple-react-lightbox";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../../helper/history";

import { getSingleSupplier } from "../../../../../actions/supplierActions";

class SingleFarmerPage extends Component {

	componentDidMount() {
    const supplierId = this.props.match.params.id;
    this.props.getSingleSupplier(supplierId);
  }

	farmerArticle = (article, article_count) => {
		return (
			<a target="_blank" href={article}>Artikel {article_count}</a>
		)
	}

	farmerStockItem = (stock_item) => {
		return (
			<Button>{stock_item.item_name}</Button>
		)
	}

	farmerGalleryItem = (gallery_item) => {
		return(
			<Image
      	publicId={gallery_item}
      	className="farmer-gallery-item"
    	/>
		)
	}

	farmerCertification = (certification) => {
		if (certification == "green"){
			return (
				<Image
          publicId={"https://res.cloudinary.com/dqzo9yjly/image/upload/v1637268813/farmer_certifications/akzuk009rmosphm7ht8h.png"}
        	className="farmer-certification"
        />
			)
		} else if (certification == "blue"){
			return (
				<Image
          publicId={"https://res.cloudinary.com/dqzo9yjly/image/upload/v1637268813/farmer_certifications/rbe0i71t9fz2tp51z0b8.png"}
        	className="farmer-certification"
        />
			)
		} else if (certification == "pink"){
			return (
				<Image
          publicId={"https://res.cloudinary.com/dqzo9yjly/image/upload/v1637268813/farmer_certifications/i6q2gl5sa9gvh3myv6qd.png"}
        	className="farmer-certification"
        />
			)
		}
	}

  render() {
		// const farmer = {
		// 	id: "1",
    //   name: "Malte Voigts",
    //   farm_name: "Spargelhof Kremmen",
    //   year_founded: "2007",
    //   location: "Berlin, Kremmen",
    //   short_description: "Malte Voigts lenkt und leitet die Geschicke des Spargelhof Kremmen als Betriebsleiter seit bereits seit dessen Gründung. Der leidenschaftliche und äußerst charismatische Landwirt liebt was er tut und isst selbst am liebsten das, was er gemeinsam mit seinem Team auf dem fruchtbaren Boden erzeugt. ... mehr" 
		// }
		const farmer = this.props.supplier;
		const { loading } = this.props.supplier;
		var article_count = 0;

		const containerStyle = {
			width: '391px',
			height: '220px'
		};

    return (
      <div id="single-farmer-page" className="page">
        <Container className="mt-4">
				<Row className="mb-3">
            <Col>
						<FontAwesomeIcon
            	onClick={() => history.goBack()}
            	icon={faChevronLeft}
            	className="back-button float-left mr-3"
          	/>
              <h4 className="page-title">{farmer.type}</h4>
            </Col>
					</Row>
				<LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
        >
					<Row>
						<Col sm={7}>
							<Row>
								<Col sm={5}>
									<Image
              			publicId={farmer.farmer_picture}
              			// width="50%"
              			// height="50%"
             		 		className="farmer-image hide-on-mobile"
            			/>
									{/* <img className="farmer-image hide-on-mobile" src={sampleFarmerImage} alt=""/> */}
								</Col>
								<Col>
									<div className="hide-on-mobile">
									<h3>{farmer.name}</h3>
									<Row className="farmer-data">
										<Col>
											<Row>
												<Col sm={4}>
													<p><b>Name</b></p>
												</Col>
												<Col>
													<p>{farmer.farm_name}</p>	
												</Col>
											</Row>
											<Row>
												<Col sm={4}>
													<p><b>Gegründet</b></p>
												</Col>
												<Col>
													<p>{farmer.year_founded}</p>	
												</Col>
											</Row>
											<Row>
												<Col sm={4}>
													<p><b>Ort</b></p>
												</Col>
												<Col>
													<p>{farmer.location}</p>	
												</Col>
											</Row>
										</Col>
									</Row>
									{farmer.certifications?.length ? (
									<div className="">
										<p><b>Zertifikat</b></p>
										{
											farmer.certifications.map(certification => {
												return this.farmerCertification(certification)
											})
										}
									</div>
									): null
									}
										</div>
										<Card id="single-farmer-page-farmer-card">
          						<div className="center p-3">
												<Image
              						publicId={farmer.farmer_picture}
              						// width="50%"
              						// height="50%"
             		 					className="farmer-image Card"
            						/>
          						</div>
          						{/* <CardImg top width="100%" src={tomatoes} alt="Card image cap" /> */}
          						<CardBody>
            						<p className="farmer-card-title">{farmer.name}</p>
            						<div className="px-3 pb-3">
              						<p className="mb-0"><b>Name</b></p>
              						<p className="mb-0 greyed-out">{farmer.farm_name}</p>
              						<p className="mb-0"><b>Gegründet</b></p>
              						<p className="mb-0 greyed-out">{farmer.year_founded}</p>
              						<p className="mb-0"><b>Ort</b></p>
              						<p className="mb-0 greyed-out">{farmer.location}</p>
													{farmer.certifications?.length ? (
														<div className="">
															<p className="mb-2"><b>Zertifikat</b></p>
															{
																farmer.certifications.map(certification => {
																return this.farmerCertification(certification)
															})
															}
														</div>
														): null
													}
            						</div>
          						</CardBody>
        						</Card>
								</Col>
							</Row>
						</Col>
						<Col>
							{/* <Image
                publicId={farmer.location_image}
              	className="farmer-map"
              /> */}
							<LoadScript
        				googleMapsApiKey="AIzaSyBXkKkaIO6BAmYzYhbNazHZ7ZpX4rEFqYM"
      				>
        				<GoogleMap
          				mapContainerStyle={containerStyle}
          				center={farmer.location_coordinates}
          				zoom={10}
        				>
          				<Marker
      							position={farmer.location_coordinates}
    							/>
         				 	<></>
        				</GoogleMap>
      				</LoadScript>
							{/* <img className="farmer-map" src={sampleFarmerLocation} alt=""/> */}
						</Col>
					</Row>
					<Row className="mt-4">
						<Col className="mobile-full-width-col">
							<h5>Über den Farmer</h5>
							<p className="dark-grey">
								{farmer.bio}
							</p>
								{farmer.articles?.length ? (
									<div className="farmer-articles">
										<p className="dark-grey">Auch bekannt aus der Presse: </p>
										<p>
											{farmer.articles.map(article => {
												article_count += 1
												return this.farmerArticle(article, article_count)
											})}
										</p>
									</div>
								): null
								}
							<h5>Angebot</h5>
							<div className="farmer-item-list">
								{farmer.stock?.length ? (
									farmer.stock.map(stock_item => {
										return this.farmerStockItem(stock_item)
									})
								): null
								}
							</div>
							{/* <div className="farmer-item-list"><Button>Bio-Äpfel</Button><Button>Bio-Kartoffel</Button><Button>Bio-Milch</Button><Button>Bio-Kopfsalat</Button><Button>Bio-Eier</Button><Button>Bio-Putenfleisch</Button></div> */}
						</Col>
						<Col>
							<h5>Galerie</h5>
							<SRLWrapper>
							{farmer.gallery?.length ? (
								farmer.gallery.map(gallery_item => {
									return this.farmerGalleryItem(gallery_item)
								})
							): null
							}
							</SRLWrapper>
							{/* <img className="farmer-gallery-item" src={farmerGallery1} alt=""/>
							<img className="farmer-gallery-item" src={farmerGallery2} alt=""/> */}
						</Col>
					</Row>
					</LoadingOverlay>
				</Container>
      </div>
    );
  }
}

SingleFarmerPage.propTypes = {
  getSingleSupplier: PropTypes.func.isRequired,
  supplier: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier
});

export default withTranslation()(connect(
  mapStateToProps,
  { getSingleSupplier }
)(withRouter(SingleFarmerPage)));