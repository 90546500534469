import gql from "graphql-tag";
// Invite supplier
export const CREATE_ORDER_MUTATION = gql`
  mutation createOrder($cartId: String!, $paymentMethodId: String, $deliveryDate: String, $deliveryWindow: String, $deliveryInstructions: String, $deliveryAddress: Delivery_addressInput, $couponId: String) {
    createOrder(cartId: $cartId, paymentMethodId: $paymentMethodId, deliveryDate: $deliveryDate, deliveryWindow: $deliveryWindow, deliveryInstructions: $deliveryInstructions, deliveryAddress: $deliveryAddress, couponId: $couponId) {
      id
      order_no
      date
      price
      seven_pct_tax
      total_price
      status
      items {
        ... on Cartitem {
          item
          item_name
          quantity
          price
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
