import gql from "graphql-tag";
export const CHANGE_ORDER_STATUS_MUTATION = gql`
  mutation changeOrderStatus($orderId: String!, $status: Int!) {
    changeOrderStatus(orderId: $orderId, status: $status) {
      id
      order_no
      description
      price
      seven_pct_tax
      total_price
      date
      delivery_date
      delivery_window
      discount
      delivery_address {
        name
        street_address
        street_address
        zip_code
        city
        country
      }
      status
      supplier {
        ... on Supplier {
          id
          name
          avatar
        }
      }
      items {
        ... on Cartitem {
          item
          item_name
          quantity
          quantity_accepted
          quantity_delivered
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;