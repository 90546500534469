import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Card,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Container,
  Alert
} from "reactstrap";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoTitle from "../../assets/NewHelloFarmerLogo.svg";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/PasswordIcon.svg";

import history from "../../helper/history";

class LoginPage extends Component {
  state = {
    modal: false,
    email: "",
    password: "",
    msg: null
  };

  static propTypes = {
    loading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loginUser = e => {

    const { email, password } = this.state;

    const user = {
      email: email.toLowerCase(),
      password
    };

    this.props.login(user);
  };

  loginAsGuest = () => {
    const user = {
      email: "guest@hellofarmer.de",
      password: "HellofarmerGuest2022!"
    };

    this.props.login(user);
  }

  render() {
    const { loading } = this.props;
    
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <div id='authPage' className="login auth-page">
        <Container className="login-page center">
          <div className="mb-4">
            <a href="https://www.hellofarmer.de/">
              <img src={logoTitle} alt="Logo" />
            </a>
          </div>

          <div className="panel">
            <h2 className="center">{this.props.t('signIn')}</h2>

            <Form className="p-4 custom-form">
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PasswordIcon className="input-icon"/>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={this.props.t('password')}
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <a className="forgot-pw-link" href="/forgot-password">
                  {this.props.t('forgotYourPassword')}
                </a>
                <Button
                  onClick={() => this.loginUser()}
                  className="authButtonPrimary"
                  style={{marginTop: '1rem', fontSize: 14}}
                  block
                >
                  {this.props.t('signIn').toUpperCase()}
                </Button>
                {/* <a className="forgot-pw-link float-right" href="/register">
                  Registrieren
                </a> */}
                {/* <div id='authOrContainer' style={{marginTop: '0.5rem'}}>
						    	<hr style={{width: '40%', borderTop: '1px solid #C25982'}} />
							    <p style={{width: '20%', color: '#C25982', fontSize: 16, marginBottom: 0}}>or</p>
							    <hr style={{width: '40%', borderTop: '1px solid #C25982'}} />
						    </div> */}
                {/* <Button
                    className="authButtonPrimary"
                    onClick={() => null}
                    style={{fontSize: 14}}
                  >
                    Login with Google
                  </Button> */}
                <p id='nochKeinKontoText'>
                  {this.props.t('dontHaveAnAccountYet')}
                </p>
                <a href='/register'>
                  <Button
                    className="authButtonPrimary"
                    onClick={() => null}
                    style={{fontSize: 14}}
                  >
                    {this.props.t('register').toUpperCase()}
                  </Button>
                </a>
                <Button
                  onClick={() => this.loginAsGuest()}
                  className="authButtonAlt"
                  style={{fontSize: 14}}
                >
                  {this.props.t('continueAsGuest').toUpperCase()}
                </Button>
              </FormGroup>
            </Form>
            {/* <a className="signup-link" href="/register">
              <CardFooter>
                <span className="greyed-out">Sie haben noch kein Konto? </span>
                <span className="underline">Registrieren</span>
              </CardFooter>
            </a> */}
          </div>
        </Container>
      </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default withTranslation()(connect(
  mapStateToProps,
  { login, clearErrors }
)(LoginPage));
