export const CREATE_COUPON = "CREATE_COUPON";
export const TOGGLE_COUPON_STATUS = "TOGGLE_COUPON_STATUS";
export const GET_COUPONS = "GET_COUPONS";
export const COUPONS_LOADING = "COUPONS_LOADING";
export const UPDATE_FARMER_ITEM_QUANTITY = "UPDATE_FARMER_ITEM_QUANTITY";
export const REFER_A_FARMER = "REFER_A_FARMER";
export const CREATE_ITEM = "CREATE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const CREATE_FARMER = "CREATE_FARMER";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const ADMIN_GET_ALL_USERS = "ADMIN_GET_ALL_USERS";
export const ADMIN_GET_ALL_ORDERS = "ADMIN_GET_ALL_ORDERS";
export const MARKETLISTS_LOADING = "MARKETLISTS_LOADING";
export const FARMERS_LOADING = "FARMERS_LOADING";
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ITEMS_LOADING = "ITEMS_LOADING";
export const NEWS_LOADING = "NEWS_LOADING";
export const FEED_LOADING = "FEED_LOADING";
export const ORDER_LOADING = "ORDER_LOADING";
export const CREATE_MEMBERSHIP = "CREATE_MEMBERSHIP";
export const CREATE_MEMBERSHIP_SUCCESS = "CREATE_MEMBERSHIP_SUCCESS";
export const CREATE_MEMBERSHIP_FAIL = "CREATE_MEMBERSHIP_FAIL";
export const GET_MEMBERSHIP = "GET_MEMBERSHIP";
export const GET_NEWS = "GET_NEWS";
export const GET_SINGLE_NEWS_ITEM = "GET_SINGLE_NEWS_ITEM";
export const SEND_CONTACT_FORM = "SEND_CONTACT_FORM";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const ADD_ITEM = "ADD_ITEM";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_MARKET_LIST = "CREATE_MARKET_LIST";
export const CREATE_MARKET_LIST_FROM_CART = "CREATE_MARKET_LIST_FROM_CART";
export const CREATE_MARKET_LIST_FROM_ORDER = "CREATE_MARKET_LIST_FROM_ORDER";
export const DELETE_MARKET_LIST = "DELETE_MARKET_LIST";
export const ADD_ITEM_TO_MARKETLIST = "ADD_ITEM_TO_MARKETLIST";
export const REMOVE_ITEM_FROM_MARKETLIST = "REMOVE_ITEM_FROM_MARKETLIST";
export const CHANGE_MARKETLIST_NAME = "CHANGE_MARKETLIST_NAME";
export const INVITE_SUPPLIER = "INVITE_SUPPLIER";
export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const GET_ALL_ITEM_REQUESTS = "GET_ALL_ITEM_REQUESTS";
export const CREATE_ITEM_REQUEST = "CREATE_ITEM_REQUEST";
export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const ADD_DELIVERY_ADDRESS = "ADD_DELIVERY_ADDRESS";
export const CHANGE_DELIVERY_ADDRESS = "CHANGE_DELIVERY_ADDRESS";
export const DELETE_DELIVERY_ADDRESS = "DELETE_DELIVERY_ADDRESS";
export const GET_DELIVERY_ADDRESSES = "GET_DELIVERY_ADDRESSES";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_ALL_MARKET_LISTS = "GET_ALL_MARKET_LISTS";
export const GET_SINGLE_MARKET_LIST = "GET_SINGLE_MARKET_LIST";
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const GET_SINGLE_SUPPLIER = "GET_SINGLE_SUPPLIER";
export const GET_CART = "GET_CART";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const CHANGE_CART_ITEM_QUANTITY = "CHANGE_CART_ITEM_QUANTITY";
export const EMPTY_CART = "EMPTY_CART";
export const GET_USER_FEED = "GET_USER_FEED";
export const CHANGE_USER_LANGUAGE = "CHANGE_USER_LANGUAGE"
export const CHANGE_USER_NAME = "CHANGE_USER_NAME"
export const CHANGE_USER_EMAIL = "CHANGE_USER_EMAIL"
export const CHANGE_USER_PHONE = "CHANGE_USER_PHONE"
export const CHANGE_USER_TAX_NUMBER = "CHANGE_USER_TAX_NUMBER"
export const CHANGE_USER_TAX_ID = "CHANGE_USER_TAX_ID"
export const CHANGE_USER_PROFILE_PICTURE = "CHANGE_USER_PROFILE_PICTURE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE = "TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE";
export const TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE = "TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE";
export const TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE = "TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE";
export const TOGGLE_ORDER_UPDATES_PREFERENCE = "TOGGLE_ORDER_UPDATES_PREFERENCE";
export const TOGGLE_SMS_NOTIFICATIONS_PREFERENCE = "TOGGLE_SMS_NOTIFICATIONS_PREFERENCE";
export const SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL";
export const RESET_FORGOTTEN_PASSWORD = "RESET_FORGOTTEN_PASSWORD";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";