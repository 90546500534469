import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  Row,
  Col,
  Alert
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { changePassword } from "../../../../../../../actions/userActions";
import { changeUserName } from "../../../../../../../actions/userActions";
import { changeUserEmail } from "../../../../../../../actions/userActions";
import { changeUserPhone } from "../../../../../../../actions/userActions";
import { changeUserTaxNumber } from "../../../../../../../actions/userActions";
import { changeUserTaxId } from "../../../../../../../actions/userActions";
import { clearErrors } from "../../../../../../../actions/errorActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import UploadProfilePictureModal from "./UploadProfilePictureModal";
import Avatar from "@material-ui/core/Avatar";

class ProfileTab extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    tax_number: "",
    tax_id: "",
    current_password: "",
    new_password: "",
    new_password_confirmed: "",
    msg: null,
    password_change_success: "none"
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPasswordChange = e => {
    e.preventDefault();
    const { current_password, new_password, new_password_confirmed } = this.state;
    if (new_password == new_password_confirmed){
      Swal.fire({
        title: "Passwort ändern",
        text: "Sind Sie sicher, dass Sie Ihr Passwort ändern möchten?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#C25982",
        cancelButtonColor: "#C25982",
        cancelButtonText: "Nein",
        confirmButtonText: "Ja"
      }).then(result => {
        if (result.value) {
          this.props.changePassword({ current_password, new_password });
          this.setState({ password_change_success: this.props.auth.password_change_successfull });
        }
      });
    }
    this.forceUpdate();
  };

  onNameChange = e => {
    e.preventDefault();
    const { name } = this.state;
    Swal.fire({
      title: "Name ändern",
      text: "Sind Sie sicher, dass Sie Ihren Namen ändern möchten",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserName(name);
      }
    });
  }

  onEmailChange = e => {
    e.preventDefault();
    const { email } = this.state;
    Swal.fire({
      title: "E-Mail ändern",
      text: "Sind Sie sicher, dass Sie Ihre E-Mail ändern möchten",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserEmail(email);
      }
    });
  }

  onPhoneChange = e => {
    e.preventDefault();
    const { phone } = this.state;
    Swal.fire({
      title: "Telefonnummer ändern",
      text: "Sind Sie sicher, dass Sie Ihre Telefonnummer ändern möchten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserPhone(phone);
      }
    });
  }

  onTaxNumberChange = e => {
    e.preventDefault();
    const { tax_number } = this.state;
    Swal.fire({
      title: "SteuerNr. ändern",
      text: "Sind Sie sicher, dass Sie Ihre Steuernummer ändern möchten",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserTaxNumber(tax_number);
      }
    });
  }

  onTaxIdChange = e => {
    e.preventDefault();
    const { tax_id } = this.state;
    Swal.fire({
      title: "USt.-IDnr. ändern",
      text: "Sind Sie sicher, dass Sie Ihre USt.-IDnr. ändern möchten",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserTaxId(tax_id);
      }
    });
  }

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  passwordChangeStatusMsg = () => {
    var statusMsg = {}
    switch (this.state.password_change_success) {
      case "none":
        statusMsg = null
        // return (
        //   null
        // );
        break;
      case null:
        statusMsg = <Alert color="danger">FAILURE</Alert>
        // return (
        //   <Alert color="danger">FAILURE</Alert>
        // );    
        break;
      case true:
        statusMsg = <Alert color="success">SUCCESS</Alert>
        // return (
        //   <Alert color="success">SUCCESS</Alert>
        // ); 
        break;
    }
    return statusMsg
  }

  isPasswordChangeValid = () => {
    return this.state.current_password == "" || this.state.new_password == "" || this.state.new_password_confirmed == "";
  }

  render() {
    const { user } = this.props.auth;
    return (
      <Container className="settings-profile-tab p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              {/* <p>
                <strong>Profil bearbeiten</strong>
              </p> */}
              <h5>
                <b>Profil bearbeiten</b>
              </h5>
              <Row>
                <Col sm={2}>
                  {/* {user.avatar ? (
                    <Avatar src="../../assets/DefaultProfilePic.svg" />
                  ) : (
                    <Avatar
                      className="avatar"
                      src="../../assets/DefaultProfilePic.svg"
                    />
                  )} */}
                  <UploadProfilePictureModal user={user}/>
                </Col>
                <Col>
                  <Form className="custom-form">
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Row>
                        <Col sm={7}>
                          <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder={user.name}
                          className="mb-3"
                          onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!this.state.name} className="btn-dark btn-save-user-field" onClick={this.onNameChange}>
                            Speichern
                          </Button>
                        </Col>
                      </Row>
                      <Label for="email">E-Mail</Label>
                      <Row>
                        <Col sm={7}>
                          <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder={user.email}
                          className="mb-3"
                          onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!this.state.email} className="btn-dark btn-save-user-field" onClick={this.onEmailChange}>
                            Speichern
                          </Button>
                        </Col>
                      </Row>
                      <Label for="email">Telefon</Label>
                      <Row>
                        <Col sm={7}>
                          <Input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder={user.telephone}
                          className="mb-3"
                          onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!this.state.phone} className="btn-dark btn-save-user-field" onClick={this.onPhoneChange}>
                            Speichern
                          </Button>
                        </Col>
                      </Row>
                      <Label for="email">SteuerNr.</Label>
                      <Row>
                        <Col sm={7}>
                          <Input
                          type="text"
                          name="tax_number"
                          id="tax_number"
                          placeholder={user.tax_number}
                          className="mb-3"
                          onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!this.state.tax_number} className="btn-dark btn-save-user-field" onClick={this.onTaxNumberChange}>
                            Speichern
                          </Button>
                        </Col>
                      </Row>
                      <Label for="email">USt.-IDnr.</Label>
                      <Row>
                        <Col sm={7}>
                          <Input
                          type="text"
                          name="tax_id"
                          id="tax_id"
                          placeholder={user.tax_id}
                          className="mb-3"
                          onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!this.state.tax_id} className="btn-dark btn-save-user-field" onClick={this.onTaxIdChange}>
                            Speichern
                          </Button>
                        </Col>
                      </Row>
                      {/* <Button
                        className="custom-btn"
                        color="dark"
                        style={{ marginTop: "2rem" }}
                        block
                      >
                        Profil aktualisieren
                      </Button> */}
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Card>
            <Card>
              <h5>
                <b>Passwort ändern</b>
              </h5>
              {/* <p>
                <strong>Passwort ändern</strong>
              </p> */}
              <Form
                className="custom-form"
              >
                <FormGroup>
                  {this.passwordChangeStatusMsg()}
                  <Label for="password">Aktuelles Passwort</Label>
                  <Input
                    type="password"
                    name="current_password"
                    id="current_password"
                    placeholder="Passwort"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <hr />
                  <Label for="password">Neues Passwort</Label>
                  <Input
                    type="password"
                    name="new_password"
                    id="new_password"
                    placeholder="Neues Passwort"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Label for="password">Neues Passwort bestätigen</Label>
                  <Input
                    type="password"
                    name="new_password_confirmed"
                    id="new_password_confirmed"
                    placeholder="Neues Passwort bestätigen"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Button
                    className="custom-btn"
                    color="dark"
                    style={{ marginTop: "2rem" }}
                    onClick={this.onPasswordChange}
                    block
                    disabled={this.isPasswordChangeValid()}
                  >
                    Passwort aktualisieren
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProfileTab.propTypes = {
  changePassword: PropTypes.func.isRequired,
  changeUserName: PropTypes.func.isRequired, changeUserEmail: PropTypes.func.isRequired, changeUserPhone: PropTypes.func.isRequired, changeUserTaxNumber: PropTypes.func.isRequired, changeUserTaxId: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  error: state.error
});

export default connect(
  mapStateToProps,
  { changePassword, changeUserName, changeUserEmail, changeUserPhone, changeUserTaxNumber, changeUserTaxId, clearErrors }
)(withRouter(ProfileTab));
