import gql from "graphql-tag";
// Login user

export const REMOVE_ITEM_FROM_CART_MUTATION = gql`
  mutation removeItemFromCart($cartitemId: String!) {
    removeItemFromCart(cartitemId: $cartitemId) {
      id
      items {
        ... on Cartitem {
          id
          item
          item_name
          image
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
