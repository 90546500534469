import React, { Component } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  Label
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import history from "../../../../../helper/history";

import { getAllItems } from "../../../../../actions/itemActions";
import { addItemToCart } from "../../../../../actions/cartActions";
import { addItemToMarketlist } from "../../../../../actions/marketListActions";
import { logout } from "../../../../../actions/authActions";

import ItemSupplierList from "../suppliers/ItemSupplierList";
import ItemNutritionalFactsModal from "./ItemNutritionalFactsModal";

import { ReactComponent as ItemCardCartIconSvg } from "../../../../../assets/ItemCardCartIcon.svg";
import { ReactComponent as PlusIconSvg } from "../../../../../assets/PlusIcon.svg";
import { ReactComponent as MinusIconSvg } from "../../../../../assets/MinusIcon.svg";
// import tomatoes from "../../../../../assets/tomatoes.png";

import Avatar from "@material-ui/core/Avatar";

import Swal from "sweetalert2";

import { Image } from "cloudinary-react";

class Item extends Component {
  constructor(props) {
    super(props);

    this.toggleVendor = this.toggleVendor.bind(this);
    this.state = {
      selectedVendor: "0",
      selectedVendorName:"0",
      selectedVendorUnit:"0",
      selectedVendorPrice: 0,
      selectedVendorPrice7Pct: 0,
      selectedVendorStock: 0,
      selectedQuantity: 1
    };
  }

  toggleVendor(vendor, vendorName, price, price7Pct, vendorUnit, vendorStock) {
    if (this.state.selectedVendor !== vendor) {
      this.setState({ selectedVendor: vendor });
      this.setState({ selectedVendorName: vendorName });
      this.setState({ selectedVendorUnit: vendorUnit });
      this.setState({ selectedVendorPrice: price });
      this.setState({ selectedVendorPrice7Pct: price7Pct });
      this.setState({ selectedVendorStock: vendorStock });
    }
  }

  state = {
    modal: false,
    name: "",
    email: ""
  };

  componentDidMount() {
    this.props.getAllItems();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addToCart = (item, quantity, price, supplier, supplierName, itemIsInCart) => {
    console.log(itemIsInCart.toString())
    const newCartitem = {
      image: item.image,
      item: item.id,
      item_name: item.name,
      quantity: this.state.selectedQuantity,
      price: this.state.selectedVendorPrice,
      price_7_pct: this.state.selectedVendorPrice7Pct,
      supplier: this.state.selectedVendor,
      supplier_name: this.state.selectedVendorName,
      unit: this.state.selectedVendorUnit,
      item_is_in_cart: itemIsInCart
    };

    this.props.addItemToCart(newCartitem);

    this.toggle();
    Swal.fire({
      // title: "Artikel abgelegt",
      text: "Artikel in den Warenkorb abgelegt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  addToMarketlist = (item, marketlist_id, quantity, price, supplier) => {
    const newCartitem = {
      image: item.image,
      item: item.id,
      item_name: item.name,
      quantity: quantity,
      price: this.state.selectedVendorPrice,
      price_7_pct: this.state.selectedVendorPrice7Pct,
      supplier: this.state.selectedVendor,
      supplier_name: this.state.selectedVendorName,
      unit: this.state.selectedVendorUnit
    };
    this.props.addItemToMarketlist(marketlist_id, newCartitem);
    this.toggle();
    Swal.fire({
      // title: "Artikel abgelegt",
      text: "Artikel zur Einkaufszettel abgelegt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  // handleQuantityChange = e => {
  //   const targetValue = e.target.value;
  //   this.setState({ selectedQuantity: parseInt(targetValue) });
  // };

  handleQuantityChange = (plusOrMinus) => {
    if(plusOrMinus == "+") {
      this.setState({ selectedQuantity: this.state.selectedQuantity + 1 });
    } else if (plusOrMinus = "-") {
      if (this.state.selectedQuantity != 1){
        this.setState({ selectedQuantity: this.state.selectedQuantity - 1 });
      }
    }
  }

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  getNumOfItemsFound = item => {
    const numOfItemsFound = item.suppliers.length;
    if (numOfItemsFound < 1) {
      return "Out of stock	";
    } else if (numOfItemsFound == 1) {
      return numOfItemsFound + " item found";
    } else if (numOfItemsFound > 1) {
      return numOfItemsFound + " items found";
    }
  };

  getItemAvgPrice = item => {
    var count = 0;
    var total_price = 0;
    var unit = null;
    item.suppliers.forEach(supplier => {
      for (var i = 0, iLen = supplier.stock.length; i < iLen; i++) {
        if (supplier.stock[i].item == item.id) {
          count += 1;
          total_price += supplier.stock[i].price_per_unit_net;
          unit = supplier.stock[i].unit;
        }
      }
    });
    var itemAvgPrice = total_price;
    itemAvgPrice = itemAvgPrice.toFixed(2)
    // const itemAvgPriceString = itemAvgPrice + `€${unit}`
    
    return (
      <p className='itemCardPrice'>
        {itemAvgPrice + this.props.t('currencySymbol')}{unit}
      </p>
      // <div className="item-price">
      //   <span>{itemAvgPrice + "€"}</span><br/>{unit}
      // </div>
    );
  };

  getModalTitle = page => {
    if (page == "Marketlist") {
      return "Zur Einkaufszettel hinzufügen";
    } else {
      return "Zum Warenkorb hinzufügen";
    }
  };

  getSubmitButton = (isGuest, page, cartItems) => {
    if (page == "Marketlist") {
      return (
        <Button
          className="float-right btn-modal"
          color="dark"
          style={{ marginTop: "2rem" }}
          block
          onClick={!isGuest ? () =>
            this.addToMarketlist(
              this.props.itemObject,
              this.props.marketlist_id,
              this.state.selectedQuantity,
              this.state.selectedVendorPrice,
              this.state.selectedVendor,
              this.state.selectedVendorName
            ) : this.alertForGuest
          }
        >
          Anlegen
        </Button>
      );
    } else {
      return (
        <Button
          className="float-right btn-modal"
          color="dark"
          style={{ marginTop: "2rem" }}
          block
          onClick={!isGuest ? () => {
            if (
              this.state.selectedVendor == "0" ||
              this.state.selectedQuantity == 0
            ) {
              Swal.fire({
                text: "Bitte fülle alle Felder aus",
                icon: "warning",
                confirmButtonColor: "#C25982",
                confirmButtonText: "Ok"
              });
            } else if (this.state.selectedQuantity > this.state.selectedVendorStock){
              Swal.fire({
                text: `Entschuldigung! Die Höchstmenge beträgt ${this.state.selectedVendorStock}`,
                icon: "warning",
                confirmButtonColor: "#C25982",
                confirmButtonText: "Ok"
              });
            } else if (cartItems.some(e => e.item === this.props.itemObject.id)){
              this.addToCart(
                this.props.itemObject,
                this.state.selectedQuantity,
                this.state.selectedVendorPrice,
                this.state.selectedVendor,
                this.state.selectedVendorName,
                true
              );
            } else {
              this.addToCart(
                this.props.itemObject,
                this.state.selectedQuantity,
                this.state.selectedVendorPrice,
                this.state.selectedVendor,
                this.state.selectedVendorName,
                false
              );
            }
          } : this.alertForGuest}
        >
          In den Warenkorb
        </Button>
      );
    }
  };

  isItemInCart = (itemId, cartItems) => {
    const itemIsInCart = cartItems.some(obj => obj.item === itemId);
    if (itemIsInCart) {
      return true
    } else return false
  }

  onCardClicked = (itemIsInCart) => {
    // if (itemIsInCart) {
    //   Swal.fire({
    //     text: "Artikel befindet sich bereits in deinen Warenkorb",
    //     icon: "warning",
    //     confirmButtonColor: "#C25982",
    //     confirmButtonText: "Ok"
    //   });
    // } else {
      this.toggle()
    // }
  }

  alertForGuest = () => {
    Swal.fire({
      title: "Bitte registriere Dich um zu bestellen",
      icon: "warning",
      iconColor:"#C25982",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Jetzt registrieren"
    }).then(result => {
      if (result.value) {
        this.props.logout();
        history.push("/register");
      }
    });
  }

  getItemRegionText = (region) => {
    const splitRegion = region.split(" ")
    if (splitRegion.length > 1) {
      return splitRegion[1]
    } else {
      return region
    }
  }

  render() {
    const { user } = this.props.auth;
    const items = this.props.item.items;
    const aItems = this.props.items;
    const cart = this.props.cart;
    const isGuest = this.props.isGuest;
    // console.log(cart.items.some(e => e.id === this.props.itemObject.id));

    return (
        <Card onClick={
          // () => this.onCardClicked(this.isItemInCart(this.props.itemObject.id, cart?.items))
          () => history.push(`market/items/${this.props.itemObject.id}`)
        } className={classnames(
          {
            active: this.isItemInCart(this.props.itemObject.id, cart?.items)
          }
          )}>
          <Image
            publicId={this.props.itemObject.image}
            width="100%"
            height="auto"
            className="Card image cap"
          />
          {/* <CardImg top width="100%" src={tomatoes} alt="Card image cap" /> */}
          <CardBody>            
            {this.getItemAvgPrice(this.props.itemObject)}
            <p className='itemCardName'>
              {this.props.itemObject.name}
            </p>
            <p className='itemCardRegion'>
              {this.getItemRegionText(this.props.itemObject.region)}
            </p>
            {/* <CardTitle>{this.props.itemObject.name}</CardTitle>
            <CardSubtitle>
              <Row>
                <Col className="item-region">
                  {this.getItemRegionText(this.props.itemObject.region)}
                </Col>
                <Col>
                </Col>
              </Row>
            </CardSubtitle>
            <CardText>
              <Row>
                <Col>
                  {this.getItemAvgPrice(this.props.itemObject)}
                </Col>
                <Col className="item-card-cart-icon-col">
                  <ItemCardCartIconSvg className="float-right"/>
                </Col>
              </Row>
            </CardText> */}
          </CardBody>
        </Card>
    );
  }
}

Item.propTypes = {
  getAllItems: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  item: state.item
});

export default connect(
  mapStateToProps,
  { logout, getAllItems, addItemToCart, addItemToMarketlist }
)(withRouter(Item));
