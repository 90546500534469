import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { getAllSuppliers } from "../../../../actions/supplierActions";
import { createItem } from "../../../../actions/itemActions"
import classnames from "classnames";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

class AdminCreateItemModal extends Component {
  state = {
    modal: false,
    name: "",
    nutritional_facts: [],
    categories: [],
		tax_percentage: "",
		selectedSupplier: "",
		imageUrl: null,
    imageAlt: null
  };

	componentDidMount() {
    this.props.getAllSuppliers();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

	handleImageUpload = () => {
    const { files } = document.querySelector('input[type="file"]');
    const formData = new FormData();
    formData.append("file", files[0]);
    
    formData.append("upload_preset", "csirufyq");
    const options = {
      method: "POST",
      body: formData
    };

    return fetch(
      "https://api.Cloudinary.com/v1_1/dqzo9yjly/image/upload",
      options
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          imageUrl: res.secure_url,
          imageAlt: `An image of ${res.original_filename}`
        });
        // handleProfilePictureChange(res.secure_url)
      })
      .catch(err => console.log(err));
  };

  onSubmit = e => {
    e.preventDefault();
    console.log(this.state.nutritional_facts)
    const newItem = {
      name: this.state.name,
			region: this.state.region,
			description: this.state.description,
			image: this.state.imageUrl,
			categories: this.state.categories,
			supplier: this.state.selectedSupplier,
			unit: "/" + this.state.unit,
			price_per_unit: parseFloat(this.state.price_per_unit),
			tax_pct: parseInt(this.state.tax_percentage),
			amount: parseInt(this.state.quantity),
      nutritional_facts: this.state.nutritional_facts,
      item_cost: parseFloat(this.state.item_cost)
    };
    
    this.props.createItem(newItem);
    
    this.toggle();
    Swal.fire({
      title: "Artikel erstellt",
      text: "Die Artikel wurde erstellt.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

	setTaxPercentage = tax_percentage => {
		if(this.state.tax_percentage != tax_percentage){
			this.setState({
				tax_percentage: tax_percentage
			});
		}
	}

	handleSupplierChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedSupplier: targetValue });
  };

  addCategory = () => {
    var updatedCategories = this.state.categories
    updatedCategories.push(this.state.category)
    this.setState({ categories: updatedCategories, category: "" });
  }

  removeCategory = (category_to_remove) => {
    var updatedCategories = this.state.categories
    updatedCategories = updatedCategories.filter(function(category) {
      return category !== category_to_remove
    })
    this.setState({ categories: updatedCategories });
  }

  addNutritionalFact = () => {
    var updatedNutritionalFacts = this.state.nutritional_facts
    updatedNutritionalFacts.push(this.state.nutritional_fact)
    this.setState({ nutritional_facts: updatedNutritionalFacts, nutritional_fact: "" });
  }

  removeNutritionalFact = (nutritional_fact_to_remove) => {

    var updatedNutritionalFacts = this.state.nutritional_facts
    updatedNutritionalFacts = updatedNutritionalFacts.filter(function(nutritional_fact) {
      return nutritional_fact !== nutritional_fact_to_remove
    })
    this.setState({ nutritional_facts: updatedNutritionalFacts });
  }

  render() {
		const farmers = this.props.supplier.suppliers;

    return (
      <div className="hinzufügen-modal">
				<span className="add-btn float-right" onClick={this.toggle}>
					<a>
            + Create Item
					</a>
        </span>
        <Modal
					id="admin-create-item-modal"
          className="add-market-list-modal"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Item hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        className="mb-2"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="region"
                        id="region"
                        className="mb-2"
                        placeholder="Region eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        className="mb-2"
                        placeholder="Description eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										{/* <div className="linediv">
                      <Input
                        type="text"
                        name="category"
                        id="category"
                        className="mb-2"
                        placeholder="Category eingeben"
                        onChange={this.onChange}
                      />
                    </div> */}
                    <Row>
                      <Col>
                        <Input
                          type="text"
                          name="category"
                          id="category"
                          value={this.state.category}
                          className="full-width my-2"
                          placeholder="Kategorie eingeben"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col sm={2}>
                        <Button className="full-width mt-2" onClick={() => this.addCategory()}>Add</Button>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        {this.state.categories.map(category => {
                          return (
                            <p className="admin-create-item-nutritional-fact">{category}<span className="greyed-out cursor-pointer" onClick={() => this.removeCategory(category)}> x</span></p>  
                          )
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          type="text"
                          name="nutritional_fact"
                          id="nutritional_fact"
                          value={this.state.nutritional_fact}
                          className="full-width mb-2"
                          placeholder="Nährwert eingeben"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col sm={2}>
                        <Button className="full-width" onClick={() => this.addNutritionalFact()}>Add</Button>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        {this.state.nutritional_facts.map(nutritional_fact => {
                          return (
                            <p className="admin-create-item-nutritional-fact">{nutritional_fact}<span className="greyed-out cursor-pointer" onClick={() => this.removeNutritionalFact(nutritional_fact)}> x</span></p>  
                          )
                        })}
                      </Col>
                    </Row>
										<div className="linediv">
                      {/* <Input
                        type="text"
                        name="farmer_picture"
                        id="farmer_picture"
                        className="mb-2"
                        placeholder="Farmer picture URL eingeben"
                        onChange={this.onChange}
                      /> */}
                      <input type="file" id="file" className="inputfile" onChange={this.handleImageUpload}/>
                      <label for="file">Foto auswählen (312x312)</label>
                    </div>
										<div className="linediv">
										<Input
                			type="select"
                			name="select"
											className="mb-2"
                			id="supplierSelect"
                			onChange={this.handleSupplierChange}
              			>
                			{farmers.map(farmer => {
												return (
													<option value={farmer.id}>
            								{farmer.name}
          								</option>
												)
											})}
             		 		</Input>
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="unit"
                        id="unit"
                        className="mb-2"
                        placeholder="Unit eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Input
                        type="text"
                        name="item_cost"
                        id="item_cost"
                        className="mb-2"
                        placeholder="Artikelpreis eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="price_per_unit"
                        id="price_per_unit"
                        className="mb-2"
                        placeholder="Price per unit eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<h5 className="mt-3">Tax percentage</h5>
										<Row className="mb-2">
											<Col>
												<Button onClick={() => this.setTaxPercentage("7")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.tax_percentage === "7"
                  				}
                				)}>7</Button>
											</Col>
											<Col>
												<Button onClick={() => this.setTaxPercentage("19")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.tax_percentage === "19"
                  				}
                				)}>19</Button>
											</Col>
										</Row>
										<div className="linediv">
                      <Input
                        type="text"
                        name="quantity"
                        id="quantity"
                        className="mb-2"
                        placeholder="Quantity eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AdminCreateItemModal.propTypes = {
  user: PropTypes.object.isRequired,
	supplier: PropTypes.object.isRequired,
	createItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	supplier: state.supplier
});

export default connect(
  mapStateToProps,
  { getAllSuppliers, createItem }
)(AdminCreateItemModal);