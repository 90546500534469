import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Container,
  Badge,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem,
  CardDeck,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardImgOverlay
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCircle,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";
import { Image } from "cloudinary-react";

import supplier from "../../../../../assets/supplier.png";
import sameDayOrderBanner from "../../../../../assets/SameDayOrderBanner.png";
import NewsItem1 from "../../../../../assets/NewsItem1.png";
import NewsItem2 from "../../../../../assets/NewsItem2.png";

import { Link } from "react-router-dom";

import { getNews } from "../../../../../actions/newsActions";
// import { getUserFeed } from "../../../../../actions/feedActions";
import { getAllOrders } from "../../../../../actions/orderActions";
import { getMembership } from "../../../../../actions/membershipActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SubscribeToMembershipModal from "../membership/SubscribeToMembershipModal"

class DashboardPage extends Component {
  componentDidMount() {
    this.props.getNews()
    this.props.getAllOrders();
    this.props.getMembership();
  }
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }
  endOfWeek(date) {
    let lastday = date.getDate() - (date.getDay() - 1) + 4;
    return new Date(date.setDate(lastday));
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  calculateTotalBudgets = projects => {
    var totalBudgets = 0;
    projects.forEach(project => {
      totalBudgets += project.budget;
    });
    return totalBudgets;
  };

  // calculateTotalExpenses = projects => {
  //   var totalExpenses = 0;
  //   projects.forEach(project => {
  //     project.invoices.forEach(invoice => {
  //       totalExpenses += invoice.price
  //     })
  //   })
  //   return totalExpenses;
  // }

  aufgabenModuleItem = assignment => {
    return (
      <tr>
        <td>{assignment[0]}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  postfachModuleItem = notification => {
    return (
      <ListGroupItem>
        <p>{/* Notification */}</p>
      </ListGroupItem>
    );
  };

  getDaysAgo = feedItemDate => {
    const millisecondsDiff = Date.now() - +new Date(feedItemDate);
    const daysDiff = millisecondsDiff / (60 * 60 * 24 * 1000);
    const days = Math.round(daysDiff);
    return days;
  };

  feedItem = item => {
    switch (item.type) {
      case "ORDER_CREATED":
        return (
          <Link
            className="market-list-item"
            exact
            to={`/orders/${item.sourceId}`}
            // {`/myProject/${id}`}
          >
            <ListGroupItem className="justify-content-between">
              <Row>
                <Col sm={2}>
                  {item.receiverAvatar ? (
                    <Image
                    publicId={`suppliers/${item.receiverAvatar}`}
                    className="notifications-modal-supplier-avatar"
                  />
                  ) : (
                    <Avatar src="../../assets/DefaultProfilePic.svg" />
                  )}
                </Col>
                <Col className="center-vertically">
                  <span className="dashboard-feed-item-text">
                    <b>Sie</b> haben eine Bestellung mit <b>{item.receiver}</b>{" "}
                    aufgegeben
                  </span>
                </Col>
                {/* <span> €</span>
                  <span>0 Aufträge</span> */}
                <Col sm={2} className="center-vertically">
                  {/* <span>{+new Date(item.creation_date)}</span>
                  <span>{Date.now()}</span> */}
                  <span>Vor {this.getDaysAgo(item.creation_date)} Tagen</span>
                </Col>
              </Row>
            </ListGroupItem>
          </Link>
        );
      default:
        break;
    }
  };

  getHeadliner = (orderedNews) => {
    var headliner = null;

    orderedNews.map(newsItem => {
      if(newsItem.type == "headliner"){
        headliner = newsItem
      }
    })

    return headliner;
  }

  headlinerItem = (headliner) => {
    return (
      <Row className="headliners">
        <Col className="headliner">
          <Link
            exact
            to={`/membership`}
          >
            <Image
              publicId={headliner.hero_image}
							width="100%"
              className="hide-on-mobile"
            />
            <div>
              <h5 className="hide-on-mobile news-item-header">{headliner.headline}</h5>
              <p className="hide-on-mobile news-item-text">{headliner.main_text}</p>
            </div>
            <Image
              publicId={headliner.hero_image}
							width="100%"
              className="hide-on-desktop"
            />
            <h5 className="hide-on-desktop">{headliner.headline}</h5>
            <p className="hide-on-desktop news-item-text">{headliner.main_text}</p>
          </Link>
        </Col>
      </Row>
    )
  }

  newsItem = (newsItem) => {
    var linkTo = "";
    if (newsItem.farmer_link !== ""){
      linkTo = `/farmers/${newsItem.farmer_link}`
    } else if (newsItem.item_link !== ""){
      linkTo = "/market"
    } else {
      linkTo = `/news/${newsItem.id}`
    }

    return (
      <Col className="feed-item-col">
        <Link
          className="feed-item"
          exact
          to={linkTo}
        >
          <Row className="feed-item mb-4">
            <Col className="">
              <Image
                publicId={newsItem.hero_image}
                // width="100%"
                // height="100%"
                className="Card image cap"
              />
            </Col>
            <Col className="feed-item-text-col">
              <h5>{newsItem.headline}</h5>
              <p className="dark-grey news-item-text">{newsItem.main_text}</p>
            </Col>
          </Row>
        </Link>
      </Col>
    )
  }

  render() {
    const { user } = this.props.auth;
    // const { feed } = this.props.feed;
    // const orderedFeed = [...feed].reverse();

    const { news } = this.props.news;
    const { loading } = this.props.news;
    const headliner = this.getHeadliner(news);
    const newsItems = [...news].reverse();

    const projects = [];
    const testProp = this.props.testProp;

    const membership = this.props.membership

    return (
      <div className="dashboard page">
        <LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
        >
        <Container>
          {/* {!membership.type ? 
            <Row className="dashboard-news mt-4">
            <Col>
              <h5 className="mb-3 dashboard-subtitle">News</h5>
              <Row className="headliners">
                <Col className="headliner">
                  <SubscribeToMembershipModal source={"dashboard"}/>
                </Col>
              </Row>
                {headliner ? (
                  this.headlinerItem(headliner)
                ) : (
                  <div className="center">
                    <p className="greyed-out">
                      Es gibt keine Nachrichten
                    </p>
                  </div>
                )}
            </Col>
            </Row> : null
          } */}
          <Row className="dashboard-feed mt-4">
            <Col>
              <h5 className="mb-3 dashboard-subtitle">Feed</h5>
              <Row>
                {news.length ? (
                  newsItems.map(newsItem => {
                    if (newsItem.type != "headliner"){
                      return this.newsItem(newsItem);
                    }
                  })
                ) : (
                  <div className="center">
                    <p className="greyed-out">
                      Es gibt keine Nachrichten
                    </p>
                  </div>
                )}
                {/* <Col className="feed-item-col">
                  <Link
                    className="feed-item"
                    exact
                    to={`/news/3`}
                  >
                  <Row className="feed-item mb-4">
                    <Col className="">
                      <Image
                        publicId={"feed/feed1"}
                        // width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col className="feed-item-text-col">
                      <h5>Rudolf Manger neue Bauernbiografie</h5>
                      <p className="dark-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </Col>
                  </Row>
                  </Link>
                </Col>
                <Col className="feed-item-col">
                  <Link
                    className="feed-item"
                    exact
                    to={`/news/4`}
                  >
                  <Row className="mb-4">
                    <Col>
                      <Image
                        publicId={"feed/feed2"}
                        // width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col className="feed-item-text-col">
                      <h5>Farm X Marmelade besser als Weltraum</h5>
                      <p className="dark-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </Col>
                  </Row>
                  </Link>
                </Col> */}
              </Row>
              {/* <Row>
                <Col className="feed-item-col">
                  <Link
                    className="feed-item"
                    exact
                    to={`/news/5`}
                  >
                  <Row className="mb-4">
                    <Col>
                      <Image
                        publicId={"feed/feed3"}
                        // width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col className="feed-item-text-col">
                      <h5>20% Rabatt am Mittwoch bis 14 Uhr</h5>
                      <p className="dark-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </Col>
                  </Row>
                  </Link>
                </Col>
                <Col className="feed-item-col">
                  <Link
                    className="feed-item"
                    exact
                    to={`/news/6`}
                  >
                  <Row className="mb-4">
                    <Col>
                      <Image
                        publicId={"feed/feed4"}
                        // width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col className="feed-item-text-col">
                      <h5>Neue Gewürze sind auf Farm X angekommen</h5>
                      <p className="dark-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </Col>
                  </Row>
                  </Link>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// export default connect(
//   mapStateToProps,
//   null
// )(DashboardPage);

DashboardPage.propTypes = {
  order: PropTypes.object.isRequired,
  // feed: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  order: state.order,
  // feed: state.feed,
  news: state.news,
  dashboard: state.dashboard,
  membership: state.membership
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllOrders, getNews, getMembership }
)(DashboardPage));
