import React, { Component } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Badge,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { getAllMarketlists } from "../../../../../actions/marketListActions";
import { deleteMarketlist } from "../../../../../actions/marketListActions";

import AddMarketListButton from "./AddMarketListButton";

class MarketListsTab extends Component {
  componentDidMount() {
    this.props.getAllMarketlists();
  }

  deleteMarketlist = (e, marketlistId) => {
    e.preventDefault();
    this.props.deleteMarketlist(marketlistId);
    // this.props.removeItemFromCart(cartitemId);
  };

  render() {
    const marketlists = this.props.marketlist.marketlists;
    const isGuest=this.props.isGuest

    return (
      <Container className="my-4 p-0">
        <Row>
          <Col>
            <ListGroup>
              <AddMarketListButton isGuest={isGuest}/>
              {marketlists.length ? (
                marketlists.map(marketlist => {
                return (
                  <Link
                    className="market-list-item"
                    exact
                    to={`/marketlists/${marketlist?.id}`}
                  >
                    <ListGroupItem className="justify-content-between">
                      <Badge className="mr-4">{marketlist?.items?.length}</Badge>
                      <span>{marketlist?.name}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={(e) => this.deleteMarketlist(e, marketlist.id)}
                        className="delete-marketlist-btn float-right"
                      />
                    </ListGroupItem>
                  </Link>
                );
              })
              ) : (
                <p className="greyed-out">
                  Sie haben zur Zeit keine Einkaufszettel...
                </p>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

MarketListsTab.propTypes = {
  project: PropTypes.object.isRequired,
  marketlist: PropTypes.object.isRequired,
  deleteMarketlist: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  project: state.project,
  marketlist: state.marketlist
});

export default connect(
  mapStateToProps,
  { getAllMarketlists, deleteMarketlist }
)(withRouter(MarketListsTab));
