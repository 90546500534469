import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  Row,
  Col,
	Table
} from "reactstrap";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import history from "../../../helper/history";
// import OrdersList from "./OrdersList";

// import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

import Avatar from "@material-ui/core/Avatar";

class FarmerItemsTab extends Component {
  state = {
    name: "",
    email: "",
		items: []
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

	componentDidMount() {
		
	} 

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

	getShortOrderId = orderId => {
    var shortOrderId = ""
    shortOrderId = orderId.substring(0,6) + "..."
    return shortOrderId 
  }

  deliveryDateField = delivery_date => {
    if (delivery_date){
      if (delivery_date.charAt(4) == "-") {
        var shortOrderDate = delivery_date.split("T")[0]
        var splitOrderDate = shortOrderDate.split("-")
        const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
        return date
      } else {
        var splitDeliveryDate = delivery_date.split(' ')
        var day = splitDeliveryDate[2];
        var month = splitDeliveryDate[1];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months.indexOf(month);
        month += 1;
        var year = splitDeliveryDate[3];
        const date = day + '.' + ('0' + month).slice(-2) + '.' + year
        return date
      }
    }
  }

  orderDateField = orderDate => {
    if (orderDate){
      var shortOrderDate = orderDate.split("T")[0]
      var splitOrderDate = shortOrderDate.split("-")
      const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
      return date
    }
  }

  statusField = status => {
    switch (status) {
      case 0:
        return <span className="order-status-yellow">Verarbeitung</span>;
      case 1:
        return <span className="order-status-red">Abgesagt</span>;
      case 2:
        return <span>Auslieferung</span>;
      case 3:
        return <span>Unzustellbar</span>;
      case 4:
        return <span className="order-status-green">Bestätigt</span>;
    }
  };

	updateItemQuantity = (value, item_id) => {
		var newItems = this.state.items;
		if (newItems.filter(item => item.item_id === item_id).length > 0) {
			for(var i = 0; i < newItems.length; i++) {
				if (newItems[i].item_id == item_id) {
					newItems[i].quantity = value;
				}
			}
		} else {
			newItems.push(
				{
					item_id: item_id,
					quantity: value
				}
			);
			this.setState({items: newItems});
		}
	}

	saveItemQuantity = (item_id) => {
		var newItems = this.state.items;
		const item = newItems.find(item => item.item_id === item_id)

		console.log(item)
		
	}

	saveItemQuantityDisabled = (item_id, quantity) => {
		var newItems = this.state.items;
		if (newItems.filter(item => item.item_id === item_id).length > 0) {
			return false
		} else return true
	}

  render() {
    const { user } = this.props.auth;
		const items = this.props.items
    const { loading } = this.props.item;

    return (
      <LoadingOverlay
          className="rounded-edges"
          active={loading}
          spinner
          text={this.props.t('loading')}
        >
      <Container className="admin-tab my-4 p-0 orders-tab">
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    Items ({items?.length})
                  </h5>
                </Col>
              </Row>
							<div className="project-list">
        				<Table>
          				<thead>
           	 				<tr className="greyed-out">
              				<th className="pl-4">Name</th>
              				<th>Unit</th>
              				<th className="pr-4">Quantity</th>
            				</tr>
          				</thead>
          			<tbody>
            			{items?.length ? (
             				items.map(
                  ({ item, item_name, unit, amount }) => {
                    return (
                      <tr
                        className="orders-list-item dark-grey"
                        // onClick={() => history.push(`/admin/items/${id}`)}
                      >
                        <td className="pl-4">{item_name}</td>
                        <td>{unit}</td>
                        <td className="pr-4 farmer-item-quantity">
													<Row>
														<Col>
															<Input
																min="0"
      													type="number"
																placeholder={amount}
																onChange={(e) => {this.updateItemQuantity(e.target.value, item)}}
    														/>
														</Col>
														<Col>
															<Button disabled={this.saveItemQuantityDisabled(item, amount)} className="full-width" onClick={() => this.saveItemQuantity(item)}>
																Speichern
															</Button>
														</Col>
													</Row>
												</td>
                      </tr>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    There are currently no items...
                  </p>
                )}
          			</tbody>
        			</Table>
      			</div>
            </Card>
          </Col>
        </Row>
      </Container>
      </LoadingOverlay>
    );
  }
}

FarmerItemsTab.propTypes = {
  user: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
	item: state.item,
});

export default withTranslation()(connect(
  mapStateToProps,
  { }
)(withRouter(FarmerItemsTab)));