import React, { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';

import { CardElement, useElements, useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js"
import {loadStripe} from '@stripe/stripe-js';

import {
  Form, Button, Alert, Input
} from "reactstrap";

import { FormGroup } from "@material-ui/core";
// import { addPayment_method } from "../../../../../actions/paymentActions";
import { createOrder } from "../../../../../actions/orderActions";
import { createMembership } from "../../../../../actions/membershipActions";

import { useDispatch } from "react-redux";


const CARD_OPTIONS = {
	hidePostalCode: true,
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#979797",
			color: "#495057",
			fontWeight: 400,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "1.13rem",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#979797" },
			"::placeholder": { color: "#979797" }
		},
		invalid: {
			iconColor: "#ff635f",
			color: "#ff635f"
		}
	}
}

export default function PaymentForm({transactionType, cart, deliveryPreferences, cartTotal, isActive, couponId}){
  const {t} = useTranslation('translations');

	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const [billingDetails, setBillingDetails] = useState({ 
		name: ""
  	});
	const [errorMessage, setErrorMessage] = useState({
		message: ""
	});
	const [paymentRequest, setPaymentRequest] = useState(null);

	const componentDidMount = () => {
		fetchData()
	}
	useEffect(componentDidMount, [])

	async function fetchData() {
		const stripe = await loadStripe('pk_live_51IphCGJGsykiXePyjEFemqyirNiRZF4FJ6ijTGMvx13GUnZQZTXc2W6WFoYKALipkkHRBzwfUHzTySWDmpJ9q7uw00EOZ5O4in');
		if (stripe && cartTotal) {
			console.log('Create stripe: ', cartTotal)
			const pr = stripe.paymentRequest({
				country: 'DE',
				currency: 'eur',
				total: {
					label: 'HelloFarmer Bestellung',
					amount: cartTotal * 100
				},
				requestPayerName: true,
				requestPayerEmail: true
			})
			// Check the availability of the Payment Request API first.
			pr.canMakePayment().then((result) => {
				console.log('Can make payment: ', result)
				if (result) {
					pr.on('paymentmethod', async function (event) {
						try {
							const paymentMethodId = event.paymentMethod.id
							dispatch(createOrder(cart.id, paymentMethodId, deliveryPreferences.deliveryDate, deliveryPreferences.deliveryWindow, deliveryPreferences.deliveryInstructions, deliveryPreferences.deliveryAddress))
							event.complete('success')
						} catch (error) {
							console.log(error)
							event.complete('fail')
						}
					})
					setPaymentRequest(pr)
				}
			})
		}
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		const {error, paymentMethod} = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement),
			billing_details: billingDetails
		})

		if (error) {
     	console.log('[error]', error);
			setErrorMessage({ message: error.message });
    } else {
			if (transactionType == "membership"){
				dispatch(createMembership("Launch", paymentMethod.id, parseFloat(9.99), 3, deliveryPreferences.deliveryAddress))
			} else if (transactionType == "order"){
				dispatch(createOrder(cart.id, paymentMethod.id, deliveryPreferences.deliveryDate, deliveryPreferences.deliveryWindow, deliveryPreferences.deliveryInstructions, deliveryPreferences.deliveryAddress, couponId))
			}
    }
	}

  return (
    <Form onSubmit={handleSubmit}>
		<FormGroup>
          <Input
						className="mb-3"
          	type="text"
          	name="name_on_card"
          	id="name_on_card"
          	placeholder={t('nameOnCard')}
          	onChange={(e) => {
            	setBillingDetails({ ...billingDetails, name: e.target.value });
          	}}
        	/>
					<div className="card-element mb-3">
						<CardElement options={CARD_OPTIONS} />
					</div>
					{errorMessage.message == "" ? (
            null
          ) : <Alert color="danger">{errorMessage.message}</Alert>}
				<Button className="mb-2" style={{fontSize: 14, fontWeight: '500'}} disabled={billingDetails.name === "" || !isActive}>{t('pay')} {cartTotal} EUR</Button>
			</FormGroup>
			{paymentRequest ? <PaymentRequestButtonElement
			options={{
				paymentRequest,
				style: {
					paymentRequestButton: {
						type: 'buy',
						theme: 'light-outline'
					}
				}
			}}
		/> : null}
    </Form>
  )
}