import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { addDelivery_address } from "../../../../../actions/delivery_addressActions";
import { getAllItems } from "../../../../../actions/itemActions";

import Swal from "sweetalert2";

class AddDeliveryAddressButton extends Component {
  state = {
    modal: false,
    name: ""
  };

  componentDidMount() {
    this.props.getAllItems();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    if (!this.state.name || !this.state.street_address || !this.state.zip_code || !this.state.city || !this.state.country ){
      Swal.fire({
        title: "Bitte fülle alle Felder aus",
        icon: "warning",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    } else if (isNaN(parseInt(this.state.zip_code))) {
      Swal.fire({
        title: "Postleitzahl muss eine Zahl sein",
        icon: "warning",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    } else {
      const zip_code = this.state.zip_code
      var zip_code_int = parseInt(zip_code)
      const coverage_zone = [10623,10557,10789,10787,10785,10117,10777,10779,10781,10783,10963,10969,10823,10825,12159,12157,12103,12101,12049,10961,10827,10829,10965]
      if (coverage_zone.includes(zip_code_int)){
        const new_delivery_address = {
          name: this.state.name,
          street_address: this.state.street_address,
          street_address_2: this.state.street_address_2,
          zip_code: parseInt(this.state.zip_code),
          city: this.state.city,
          country: this.state.country
        };
    
        this.props.addDelivery_address(new_delivery_address);
    
        this.toggle();
        Swal.fire({
          title: "Lieferadresse hinzugefügt",
          text: "Die Lieferadresse wurde hinzugefügt.",
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      } else {
        Swal.fire({
          title: "Wir sind derzeit in Ihrem Ort nicht verfügbar, wir arbeiten daran",
          icon: "warning",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    }
  };

  render() {
    // const items = this.props.item.items;
    // const delivery_addresses = [
    //   {
    //     street_address: "Test address"
    //   },
    //   {
    //     street_address: "Test address 2"
    //   }
    // ];

    return (
      <div className="hinzufügen-modal change-address">
        <a href="#" onClick={this.toggle}>
          Neue Adresse hinzufügen
        </a>
        <Modal
          className="change-address-modal"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Neue Lieferadresse hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Label for="project">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label for="project">Straßenanschrift</Label>
                      <Input
                        type="text"
                        name="street_address"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Straßenanschrift eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label for="project">Straßenanschrift 2</Label>
                      <Input
                        type="text"
                        name="street_address_2"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Straßenanschrift 2 eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label for="project">Postleitzahl</Label>
                      <Input
                        type="text"
                        name="zip_code"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Postleitzahl eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label for="project">Stadt</Label>
                      <Input
                        type="text"
                        name="city"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Stadt eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label for="project">Land</Label>
                      <Input
                        type="text"
                        name="country"
                        id="craftsman"
                        placeholder="Land eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Addresse anlegen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddDeliveryAddressButton.propTypes = {
  getAllItems: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  addDelivery_address: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  craftsman: state.craftsman,
  item: state.item
});

export default connect(
  mapStateToProps,
  { getAllItems, addDelivery_address }
)(AddDeliveryAddressButton);
