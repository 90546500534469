import gql from "graphql-tag";

export const DELETE_ITEM_MUTATION = gql`
  mutation deleteItem($itemId: String!) {
    deleteItem(itemId: $itemId) {
			id
      name
      description
      nutritional_facts_per
      nutritional_facts
      image
      region
      is_recent
      is_active
      categories
      suppliers {
        ... on Supplier {
          id
          name
          stock {
            ... on StockItem {
              item
              price_per_unit
              price_per_unit_net
              price_per_unit_7_pct
              price_per_unit_19_pct
              unit
              amount
            }
          }
        }
      }
    }
  }
`;