import React, { Component } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Badge,
  Button,
  Card,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import classnames from "classnames";

import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as LinkIcon } from "../../../../../assets/LinkIcon.svg";
import { ReactComponent as FarmersSvg } from "../../../../../assets/FarmersIcon.svg";

class ItemSupplierList extends Component {

  render() {
    const projects = [];
    var selectedVendorPrice = 0;
    var selectedVendorPrice7Pct = 0;
    var selectedVendorUnit = "";
    var selectedVendorStock = 0;

    return (
      <div>
        <ListGroup>
          {/* <ListGroupItem className="item-supplier-list-item justify-content-between">
            <span>Matti's</span>
            <span className="float-right">
              <b>10,28€/kg</b>
            </span>
          </ListGroupItem> */}
          {this.props.suppliers?.map(supplier => {
            if (
              supplier.stock.some(
                stockItem => stockItem.item == this.props.item.id
              )
            ) {
              return (
                <Row>
                  <Col className="">
                <ListGroupItem
                  className={classnames(
                    "market-list-item",
                    "justify-content-between",
                    {
                      active: this.props.selectedVendor === `${supplier.id}`
                    }
                  )}
                  onClick={() => {
                    this.props.toggleVendor(
                      `${supplier.id}`,
                      `${supplier.name}`,
                      selectedVendorPrice,
                      selectedVendorPrice7Pct,
                      selectedVendorUnit,
                      selectedVendorStock
                    );
                  }}
                >
                  <span>{supplier.name}</span>
                  <span className="float-right">
                    {supplier.stock
                      .filter(stockItem => stockItem.item == this.props.item.id)
                      .map(stockItem => {
                        selectedVendorPrice = stockItem.price_per_unit;
                        selectedVendorPrice7Pct = stockItem.price_per_unit_net;
                        selectedVendorUnit = stockItem.unit;
                        selectedVendorStock = stockItem.amount
                        this.props.toggleVendor(
                          `${supplier.id}`,
                          `${supplier.name}`,
                          selectedVendorPrice,
                          selectedVendorPrice7Pct,
                          selectedVendorUnit,
                          selectedVendorStock
                        );
                        return <b>{this.props.t('currencySymbol')}{stockItem.price_per_unit_net.toFixed(2)}{stockItem.unit}</b>;
                      })}
                  </span>
                </ListGroupItem>
                </Col>
                <Col className="pl-0" sm={1}>
                  <Link
                    className=""
                    exact
                    to={`/farmers/${supplier.id}`}
                  >
                    <FarmersSvg />
                  </Link>
                </Col>
                </Row>
              );
            } else {
              return <p className="center mt-2 greyed-out">Out of stock</p>;
            }
          })}
        </ListGroup>
      </div>
    );
  }
}

ItemSupplierList.propTypes = {
};
const mapStateToProps = state => ({

});

export default connect(
  mapStateToProps,
  { }
)(withRouter(ItemSupplierList));
