import React, { Component } from "react";

import i18n from '../../../../../../helper/i18n';
import { withTranslation } from 'react-i18next';

import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent,
  Alert,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCheck } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import Toggle from "react-toggle";
import Avatar from "@material-ui/core/Avatar";

import UploadProfilePictureModal from "./tabs/UploadProfilePictureModal";
import ProfileTab from "./tabs/ProfileTab";
import NotificationsTab from "./tabs/NotificationsTab";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from '../../../../../../helper/history';

import { changePassword } from "../../../../../../actions/userActions";
import { changeUserLanguage } from "../../../../../../actions/userActions";
import { changeUserName } from "../../../../../../actions/userActions";
import { changeUserEmail } from "../../../../../../actions/userActions";
import { changeUserPhone } from "../../../../../../actions/userActions";
import { changeUserTaxNumber } from "../../../../../../actions/userActions";
import { changeUserTaxId } from "../../../../../../actions/userActions";
import { clearErrors } from "../../../../../../actions/errorActions";

import { toggleBrowserNotificationsPreference, toggleEmailNotificationsPreference, toggleItemRequestUpdatesPreference, toggleOrderUpdatesPreference, toggleSmsNotificationsPreference } from "../../../../../../actions/userActions";

import { ReactComponent as ProfileSettingsIcon } from "../../../../../../assets/ProfileSettingsIcon.svg";
import { ReactComponent as NotificationsSettingsIcon } from "../../../../../../assets/NotificationsSettingsIcon.svg";
import { ReactComponent as LanguageSettingsIcon } from "../../../../../../assets/LanguageSettingsIcon.svg";
import { ReactComponent as OrdersSettingsIcon } from "../../../../../../assets/OrdersSettingsIcon.svg";
import Logout from "../../../../../authentication/Logout";

class SettingsPage extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    tax_number: "",
    tax_id: "",
    current_password: "",
    new_password: "",
    new_password_confirmed: "",
    msg: null,
    password_change_success: "none",
    imageUrl: null,
    imageAlt: null
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPasswordChange = e => {
    e.preventDefault();
    const { current_password, new_password, new_password_confirmed } = this.state;
    if (new_password == new_password_confirmed){
      Swal.fire({
        text: this.props.t('passwordChangeConfirmationText'),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#C25982",
        cancelButtonColor: "#C25982",
        cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
      }).then(result => {
        if (result.value) {
          this.props.changePassword({ current_password, new_password });
          this.setState({ password_change_success: this.props.auth.password_change_successfull });
          // Swal.fire({
          //   title: "Artikelanforderung erstellt",
          //   text: "Die Artikelanforderung wurde erstellt.",
          //   icon: "success",
          //   confirmButtonColor: "#C25982",
          //   confirmButtonText: "Ok"
          // });
        }
      });
    }
    this.forceUpdate();
  };

  changeLanguage = (language) => {
    Swal.fire({
      text: this.props.t('languageChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserLanguage(language);
        if(language === "Deutsch"){
          i18n.changeLanguage('de');
        } else if (language === "Spanish") {
          i18n.changeLanguage('es');
        } else if (language === "English") {
          i18n.changeLanguage('en');
        }
        i18n.changeLanguage();
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  onNameChange = e => {
    e.preventDefault();
    const { name } = this.state;
    Swal.fire({
      text: this.props.t('nameChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserName(name);
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  onEmailChange = e => {
    e.preventDefault();
    const { email } = this.state;
    Swal.fire({
      text: this.props.t('emailChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserEmail(email);
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  onPhoneChange = e => {
    e.preventDefault();
    const { phone } = this.state;
    Swal.fire({
      text: this.props.t('phoneChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserPhone(phone);
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  onTaxNumberChange = e => {
    e.preventDefault();
    const { tax_number } = this.state;
    Swal.fire({
      text: this.props.t('taxNumberChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserTaxNumber(tax_number);
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  onTaxIdChange = e => {
    e.preventDefault();
    const { tax_id } = this.state;
    Swal.fire({
      text: this.props.t('vatChangeConfirmationText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.changeUserTaxId(tax_id);
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  }

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  passwordChangeStatusMsg = () => {
    var statusMsg = {}
    switch (this.state.password_change_success) {
      case "none":
        statusMsg = null
        // return (
        //   null
        // );
        break;
      case null:
        statusMsg = <Alert color="danger">FAILURE</Alert>
        // return (
        //   <Alert color="danger">FAILURE</Alert>
        // );    
        break;
      case true:
        statusMsg = <Alert color="success">SUCCESS</Alert>
        // return (
        //   <Alert color="success">SUCCESS</Alert>
        // ); 
        break;
    }
    return statusMsg
  }

  isPasswordChangeValid = () => {
    return this.state.current_password == "" || this.state.new_password == "" || this.state.new_password_confirmed == "";
  }

  // toggleBrowserNotifications = () => {
  //   Swal.fire({
  //     title: "Toggle",
  //     text:
  //       "Sicher, Sie wollen Browser Benachrichtigung an/ab -schalten?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#4a8950",
  //     cancelButtonColor: "#ff635f",
  //     cancelButtonText: "Nein",
  //     confirmButtonText: "Ja"
  //   }).then(result => {
  //     if (result.value) {
  //       this.props.toggleBrowserNotificationsPreference();
  //     }
  //   });
  // };

  toggleEmailNotifications = () => {
    Swal.fire({
      title: "",
      text:
        this.props.t('toggleEmailNotificationsText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.toggleEmailNotificationsPreference();
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  };

  // toggleSmsNotifications = () => {
  //   Swal.fire({
  //     title: "Toggle",
  //     text:
  //     "Sicher, Sie wollen SMS Benachrichtigung an/ab -schalten?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#4a8950",
  //     cancelButtonColor: "#ff635f",
  //     cancelButtonText: "Nein",
  //     confirmButtonText: "Ja"
  //   }).then(result => {
  //     if (result.value) {
  //       this.props.toggleSmsNotificationsPreference();
  //     }
  //   });
  // };

  toggleItemRequestUpdates = () => {
    Swal.fire({
      title: "",
      text:
        this.props.t('toggleItemRequestUpdatesText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.toggleItemRequestUpdatesPreference();
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  };

  toggleOrderUpdates = () => {
    Swal.fire({
      title: "",
      text:
        this.props.t('toggleOrderUpdatesText'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.toggleOrderUpdatesPreference();
        Swal.fire({
          text: this.props.t('changesWereSaved'),
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    });
  };

  responseMessage = field => {
    

    return (
      <Row>
        <Col>
          {this.state.msg ? (
            <Alert color="danger">{field}</Alert>
          ) : null}
        </Col>
      </Row>
    )
  }

  render() {
    const { user } = this.props.auth;

    return (
      <div id='settings-page' className="settings-page page">
        <Container>
          <Row id='settings-page-card' style={{backgroundColor: 'white', marginTop: 24, paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '3rem', paddingBottom: '1rem', borderRadius: 17}}>
            <Col>
          <Row className='header-row'>
            <Col style={{maxWidth: '25%'}}>
              <FontAwesomeIcon
            	  onClick={() => history.goBack()}
            	  icon={faChevronLeft}
            	  className="back-button float-left mr-3"
                style={{height: 18}}
          	  />
            </Col>
            <Col style={{maxWidth: '50%'}}>
              <h2>
                {this.props.t('settings')}
              </h2>
            </Col>
            <Col style={{maxWidth: '25%'}}>
            </Col>
          </Row>
          <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #9A9A9A', marginBottom: '0.75rem'}}>
            <ProfileSettingsIcon style={{marginTop: -2.5, marginRight: '0.5rem'}} />
            <h4>{this.props.t('editProfile')}</h4>
          </div>
          <Row>
            <Col className='mobile-full-width-col'>
              <Form className="custom-form">
                <FormGroup>
                          <Label className="greyed-out" for="name">{this.props.t('name')}</Label>
                          <Row>
                            <Col sm={9}>
                              <Input
                              type="text"
                              name="name"
                              id="name"
                              placeholder={user.name}
                              className="mb-2"
                              onChange={this.onChange}
                              />
                            </Col>
                            <Col id='settings-page-save-button-col'>
                              <Button disabled={!this.state.name} className="btn-dark btn-save-user-field" onClick={this.onNameChange} style={{width: '100%'}}>
                                {this.props.t('save')}
                              </Button>
                            </Col>
                          </Row>
                          {this.responseMessage("Name")}
                          <Label className="greyed-out" for="email">E-Mail</Label>
                          <Row>
                            <Col sm={9}>
                              <Input
                              type="email"
                              name="email"
                              id="email"
                              placeholder={user.email}
                              className="mb-2"
                              onChange={this.onChange}
                              />
                            </Col>
                            <Col id='settings-page-save-button-col'>
                              <Button disabled={!this.state.email} className="btn-dark btn-save-user-field" onClick={this.onEmailChange} style={{width: '100%'}}>
                                {this.props.t('save')}
                              </Button>
                            </Col>
                          </Row>
                          <Label className="greyed-out" for="email">{this.props.t('phoneNumber')}</Label>
                          <Row>
                            <Col sm={9}>
                              <Input
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder={user.telephone}
                              className="mb-2"
                              onChange={this.onChange}
                              />
                            </Col>
                            <Col id='settings-page-save-button-col'>
                              <Button disabled={!this.state.phone} className="btn-dark btn-save-user-field" onClick={this.onPhoneChange} style={{width: '100%'}}>
                                {this.props.t('save')}
                              </Button>
                            </Col>
                          </Row>
                          {/* <Button
                            className="custom-btn"
                            color="dark"
                            style={{ marginTop: "2rem" }}
                            block
                          >
                            Profil aktualisieren
                          </Button> */}
                        </FormGroup>
                      </Form>
            </Col>
            <Col>
              <Form className="custom-form">
                <FormGroup>
                <Label className="greyed-out" for="email">{this.props.t('taxNumber')}</Label>
                          <Row>
                            <Col sm={9}>
                              <Input
                              type="text"
                              name="tax_number"
                              id="tax_number"
                              placeholder={user.tax_number}
                              className="mb-2"
                              onChange={this.onChange}
                              />
                            </Col>
                            <Col id='settings-page-save-button-col'>
                              <Button disabled={!this.state.tax_number} className="btn-dark btn-save-user-field" onClick={this.onTaxNumberChange} style={{width: '100%'}}>
                                {this.props.t('save')}
                              </Button>
                            </Col>
                          </Row>
                          <Label className="greyed-out" for="email">{this.props.t('vat')}</Label>
                          <Row>
                            <Col sm={9}>
                              <Input
                              type="text"
                              name="tax_id"
                              id="tax_id"
                              placeholder={user.tax_id}
                              className="mb-2"
                              onChange={this.onChange}
                              />
                            </Col>
                            <Col id='settings-page-save-button-col'>
                              <Button disabled={!this.state.tax_id} className="btn-dark btn-save-user-field" onClick={this.onTaxIdChange} style={{width: '100%'}}>
                                {this.props.t('save')}
                              </Button>
                            </Col>
                          </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row style={{marginBottom: '2rem'}}>
            <Col className='mobile-full-width-col'>
            <Form
                        className="custom-form"
                      >
                        <FormGroup>
                          {this.passwordChangeStatusMsg()}
                          <Label className="greyed-out" for="password">{this.props.t('currentPassword')}</Label>
                          <Input
                            type="password"
                            name="current_password"
                            id="current_password"
                            placeholder={this.props.t('password')}
                            className="mb-2"
                            onChange={this.onChange}
                          />
                          <hr />
                          <Label className="greyed-out" for="password">{this.props.t('newPassword')}</Label>
                          <Input
                            type="password"
                            name="new_password"
                            id="new_password"
                            placeholder={this.props.t('newPassword')}
                            className="mb-2"
                            onChange={this.onChange}
                          />
                          <Label className="greyed-out" for="password">{this.props.t('confirmNewPassword')}</Label>
                          <Input
                            type="password"
                            name="new_password_confirmed"
                            id="new_password_confirmed"
                            placeholder={this.props.t('confirmNewPassword')}
                            className="mb-2"
                            onChange={this.onChange}
                          />
                          <Button
                            id='update-password-button'
                            className="custom-btn"
                            color="dark"
                            style={{ marginTop: "1rem", maxWidth: '100%' }}
                            onClick={this.onPasswordChange}
                            block
                            disabled={this.isPasswordChangeValid()}
                          >
                            {this.props.t('updatePassword')}
                          </Button>
                        </FormGroup>
                      </Form>
            </Col>
            <Col>
            </Col>
          </Row>
          <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #9A9A9A', marginBottom: '0.5rem'}}>
            <LanguageSettingsIcon style={{marginTop: 0, marginRight: '0.5rem'}} />
            <h4>{this.props.t('language')}</h4>
          </div>
          <Row id='settings-page-orders-links-row' style={{marginBottom: '1rem'}}>
            <Col>
              <div onClick={() => this.changeLanguage('Deutsch')} className='link'>
                <p>{this.props.t('german')}</p>
                {
                  user?.language === 'Deutsch' ?
                    <FontAwesomeIcon            	    
            	        icon={faCheck}
            	        className="back-button"
                      style={{height: 18, color: '#A9CB59'}}
          	        /> : null
                }
              </div>
              <div onClick={() => this.changeLanguage('Spanish')} className='link'>
                <p>{this.props.t('spanish')}</p>
                {
                  user?.language === 'Spanish' ?
                    <FontAwesomeIcon            	    
            	        icon={faCheck}
            	        className="back-button"
                      style={{height: 18, color: '#A9CB59'}}
          	        /> : null
                }
              </div>
              <div onClick={() => this.changeLanguage('English')} className='link'>
                <p>{this.props.t('english')}</p>
                {
                  user?.language === 'English' ?
                    <FontAwesomeIcon            	    
            	        icon={faCheck}
            	        className="back-button"
                      style={{height: 18, color: '#A9CB59'}}
          	        /> : null
                }
              </div>
            </Col>
          </Row>
          <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #9A9A9A'}}>
            <NotificationsSettingsIcon style={{marginTop: 0, marginRight: '0.5rem'}} />
            <h4>{this.props.t('notifications')}</h4>
          </div>
          <Row style={{marginBottom: '0.5rem'}}>
            <Col>
            <Row className="mt-3" style={{marginBottom: '-0.4rem'}}>
              <Col className="notification-toggle-text">{this.props.t('orders')}</Col>
              <Col sm={2} className="notification-toggle mb-1">
                <button
                  className="overview-button"
                  onClick={this.toggleOrderUpdates.bind(
                    this
                  )}
                >
                  <Toggle
                    checked={user ? user.notification_preferences?.order_updates : false}
                    icons={false}
                  />
                </button>
              </Col>
            </Row>
            <Row>
              <Col className="notification-toggle-text">{this.props.t('itemRequests')}</Col>
              <Col sm={2} className="notification-toggle">
                <button
                  className="overview-button"
                  onClick={this.toggleItemRequestUpdates.bind(
                    this
                  )}
                >
                  <Toggle
                    checked={user ? user.notification_preferences?.item_request_updates : false}
                    icons={false}
                  />
                </button>
              </Col>
            </Row>
            </Col>
          </Row>
          <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #9A9A9A', marginBottom: '0.5rem'}}>
            <OrdersSettingsIcon style={{marginTop: 0, marginRight: '0.5rem'}} />
            <h4>{this.props.t('orders')}</h4>
          </div>
          <Row id='settings-page-orders-links-row' style={{marginBottom: '2rem'}}>
            <Col>
              <div onClick={() => history.push('/orders')} className='link'>
                <p>{this.props.t('orderHistory')}</p>
                <FontAwesomeIcon            	    
            	    icon={faChevronRight}
            	    className="back-button"
                  style={{height: 18}}
          	    />
              </div>
              <div onClick={() => history.push('/marketlists')} className='link'>
                <p>{this.props.t('marketLists')}</p>
                <FontAwesomeIcon            	    
            	    icon={faChevronRight}
            	    className="back-button"
                  style={{height: 18}}
          	    />
              </div>
            </Col>
          </Row>
          <Logout />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  changePassword: PropTypes.func.isRequired,
  changeUserLanguage: PropTypes.func.isRequired,
  changeUserName: PropTypes.func.isRequired, changeUserEmail: PropTypes.func.isRequired, changeUserPhone: PropTypes.func.isRequired, changeUserTaxNumber: PropTypes.func.isRequired, changeUserTaxId: PropTypes.func.isRequired,
  toggleBrowserNotificationsPreference: PropTypes.func.isRequired,
  toggleEmailNotificationsPreference: PropTypes.func.isRequired, toggleItemRequestUpdatesPreference: PropTypes.func.isRequired, toggleOrderUpdatesPreference: PropTypes.func.isRequired, toggleSmsNotificationsPreference: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  error: state.error
});

export default withTranslation()(connect(
  mapStateToProps,
  { changePassword, changeUserLanguage, changeUserName, changeUserEmail, changeUserPhone, changeUserTaxNumber, changeUserTaxId, toggleBrowserNotificationsPreference, toggleEmailNotificationsPreference, toggleItemRequestUpdatesPreference, toggleOrderUpdatesPreference, toggleSmsNotificationsPreference, clearErrors }
)(withRouter(SettingsPage)));
