import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import Swal from "sweetalert2";

import AddItemButton from "./AddItemButton";
import RenameModal from "../RenameModal";

import { Image } from "cloudinary-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../../helper/history";

import { getCart } from "../../../../../actions/cartActions";
import { getSingleMarketlist } from "../../../../../actions/marketListActions";

import { removeItemFromMarketlist } from "../../../../../actions/marketListActions";
import { addItemToCart } from "../../../../../actions/cartActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SingleMarketListPage extends Component {
  componentDidMount() {
    const marketlistId = this.props.match.params.id;
    this.props.getSingleMarketlist(marketlistId);
    this.props.getCart();
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  aufgabenModuleItem = aufgabe => {
    return (
      <tr>
        <td>{/* Beschreibung */}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  removeItemFromMarketlist = (marketlistId, itemId) => {
    console.log("HELLO", itemId)
    this.props.removeItemFromMarketlist(marketlistId, itemId)
  }

  addMarketlistToCart = (marketlist, cart) => {
    if (marketlist.items.length === 0){
      Swal.fire({
        text: "Keine Artikel in der Einkaufszettel",
        icon: "error",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    } else {
      var cartContainsMarketlistItem = null;
      marketlist.items.forEach(marketlistItem => {
        if (cart.items.some(item => item.item === marketlistItem.item)) {
          cartContainsMarketlistItem = true;
        }
      })
      if (cartContainsMarketlistItem) {
        Swal.fire({
          text: "Mindestens ein Artikel aus Ihrem Einkaufszettel befindet sich bereits im Warenkorb",
          icon: "error",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      } else {
        marketlist.items.forEach(item => {
          const newCartitem = {
            image: item.image,
            item: item.item,
            item_name: item.item_name,
            quantity: item.quantity,
            price: item.price,
            price_7_pct: item.price_7_pct,
            supplier: item.supplier,
            supplier_name: item.supplier_name,
            unit: item.unit
          };
    
          this.props.addItemToCart(newCartitem);
        });
    
        Swal.fire({
          text: "Artikeln in den Warenkorb abgelegt",
          icon: "success",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    }
  }

  render() {
    const aufgaben = [];
    const suppliers = [];
    const marketlist = this.props.marketlist;
    const id = "singleitem";
    const { cart } = this.props.cart;

    return (
      <div id='single-marketlist-page'  className="page">
        <Container className="single-market-list-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
          />
          <div id='selected-order-card'>
            <div id='selected-order-header-container' style={{display: 'flex', flexDirection: 'row', border: '1px solid #DEDEDE', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.75rem',paddingRight: '0.75rem',  borderRadius: 8, marginBottom: '1.5rem'}}>
              <div>
                <p id='selected-order-header' style={{fontSize: 26, fontWeight: 'bold', color: 'black', marginBottom: 0}}>
                  Einkaufszettel
                </p>
                <p id='selected-order-number' style={{fontSize: 18, color: '#858585', marginBottom: '0.4rem'}}>
                  <RenameModal marketlist_id={marketlist.id} marketlist_name={marketlist.name}/>
                </p>
              </div>
            </div>
          </div>
          <Button onClick={() => this.addMarketlistToCart(marketlist, cart)} id="add-marketlist-to-cart-btn">Zum Warenkorb hinzufügen</Button>
          <div id='selected-order-items'>
            {marketlist.items?.map(item => {
              return (
                <Row style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
                  <Col style={{maxWidth: '35%', paddingRight: 0}}>
                    <Image publicId={item.image} />
                  </Col>
                  <Col style={{maxWidth: '45%', padding: 0}}>
                    <p style={{fontSize: 16, fontWeight: 'bold', color: 'black', marginBottom: 0}}>{item.item_name}</p>
                    <Row>
                      <Col>
                        <p className='label'>Packmass: <span className='value'>{item.unit}</span></p>
                        <p className='label'>Bestellmenge: <span className='value'>{item.quantity ? item.quantity : 'N/A'}</span></p>
                        {/* <p className='label'>Erhaltene Menge: <span className='value'>{item.quantity_received ? item.quantity_received : 'N/A'}</span></p> */}
                        <p className='label'>Preis: <span className='value'>{this.props.t('currencySymbol')}{item.price_7_pct.toFixed(2)}</span></p>
                      </Col>
                    </Row>              
                  </Col>
                  <Col style={{maxWidth: '20%'}}>
                    <p style={{textAlign: 'right', fontSize: 14, fontWeight: 'bold', color: '#C25982'}}>{this.props.t('currencySymbol')}{(item.price_7_pct * item.quantity).toFixed(2)}</p>
                  </Col>
                </Row>
              )
            })}
          </div>
          <AddItemButton marketlist_id={marketlist.id} />
          {/* <Row>
            <Col>
              <Button onClick={() => this.addMarketlistToCart(marketlist, cart)} id="add-marketlist-to-cart-btn">Zum Warenkorb hinzufügen</Button>
              <Card>
                <Row>
                  <Col className="mobile-full-width-col">
                    <RenameModal marketlist_id={marketlist.id} marketlist_name={marketlist.name}/>
                    <AddItemButton marketlist_id={marketlist.id} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table>
                      <thead>
                        <tr className="greyed-out">
                          <th>Name</th>
                          <th>Artikelbezeichnung</th>
                          <th>Menge</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="greyed-out">
                        {marketlist.items?.map(
                          ({ item_name, item, quantity }) => {
                            return (
                              <tr
                              >
                                <td>{item_name ? item_name : "None"}</td>
                                <td>Kilogram</td>
                                <td>{quantity}</td>
                                <td className="delete-market-list-item" onClick={() => this.removeItemFromMarketlist(marketlist.id, item)}>x</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    );
  }
}

SingleMarketListPage.propTypes = {
  getCart: PropTypes.func.isRequired,
  getSingleMarketlist: PropTypes.func.isRequired,
  removeItemFromMarketlist: PropTypes.func.isRequired,
  marketlist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cart: state.cart,
  marketlist: state.marketlist
});

export default connect(
  mapStateToProps,
  { getCart, getSingleMarketlist, removeItemFromMarketlist, addItemToCart }
)(withRouter(SingleMarketListPage));
