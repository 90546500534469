import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const PUBLIC_KEY = "pk_live_51IphCGJGsykiXePyjEFemqyirNiRZF4FJ6ijTGMvx13GUnZQZTXc2W6WFoYKALipkkHRBzwfUHzTySWDmpJ9q7uw00EOZ5O4in";

const stripePromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer({ transactionType, cart, deliveryPreferences, cartTotal, isActive, couponId}){
  console.log("CART TOTAL", cartTotal)
  return (
    <Elements stripe={stripePromise}>
			{cartTotal * 100 > 0 ? <PaymentForm transactionType={transactionType} cart={cart} deliveryPreferences={deliveryPreferences} cartTotal={cartTotal} isActive={isActive} couponId={couponId}/> : null}
    </Elements>
  )
}