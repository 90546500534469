import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  Row,
  Col,
	Table
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import history from "../../../helper/history";
import { getAllOrders } from "../../../actions/orderActions";
import { adminGetAllOrders } from "../../../actions/orderActions";
// import OrdersList from "./OrdersList";

// import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

import Avatar from "@material-ui/core/Avatar";

class AdminOrdersTab extends Component {
  state = {
    name: "",
    email: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

	componentDidMount() {
		this.props.getAllOrders();
		this.props.adminGetAllOrders();
	} 

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

	getShortOrderId = orderId => {
    var shortOrderId = ""
    shortOrderId = orderId.substring(0,6) + "..."
    return shortOrderId 
  }

  deliveryDateField = delivery_date => {
    if (delivery_date){
      if (delivery_date.charAt(4) == "-") {
        var shortOrderDate = delivery_date.split("T")[0]
        var splitOrderDate = shortOrderDate.split("-")
        const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
        return date
      } else {
        var splitDeliveryDate = delivery_date.split(' ')
        var day = splitDeliveryDate[2];
        var month = splitDeliveryDate[1];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months.indexOf(month);
        month += 1;
        var year = splitDeliveryDate[3];
        const date = day + '.' + ('0' + month).slice(-2) + '.' + year
        return date
      }
    }
  }

  orderDateField = orderDate => {
    if (orderDate){
      var shortOrderDate = orderDate.split("T")[0]
      var splitOrderDate = shortOrderDate.split("-")
      const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
      return date
    }
  }

  statusField = status => {
    switch (status) {
      case 0:
        return <span className="order-status-yellow">Verarbeitung</span>;
      case 1:
        return <span className="order-status-red">Abgesagt</span>;
      case 2:
        return <span>Auslieferung</span>;
      case 3:
        return <span>Unzustellbar</span>;
      case 4:
        return <span className="order-status-green">Bestätigt</span>;
    }
  };

  render() {
    const { user } = this.props.auth;
		const { all_orders } = this.props.order;
    const { loading } = this.props.order;
		const orderedOrders = [...all_orders].reverse()

    return (
      <LoadingOverlay
        className="rounded-edges"
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <Container className="admin-tab my-4 p-0 orders-tab">
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    Orders ({all_orders.length})
                  </h5>
                </Col>
              </Row>
							<div className="project-list">
        				<Table>
          				<thead>
           	 				<tr className="greyed-out">
              				<th className="pl-4">Bestellung #</th>
              				<th>Kunde</th>
              				<th>Bestelldatum</th>
              				<th className="hide-on-mobile">Anzahl der Artikel</th>
              				<th>Preis</th>
              				<th className="hide-on-mobile">Lieferdatum</th>
              				{/* <th>Beschreibung</th> */}
              				<th className="pr-4 hide-on-mobile">Status</th>
            				</tr>
          				</thead>
          			<tbody>
            			{orderedOrders.length ? (
             				orderedOrders.map(
                  ({ id, order_no, total_price, date, items, delivery_date, status, delivery_address }) => {
                    return (
                      <tr
                        className="orders-list-item dark-grey"
                        onClick={() => history.push(`/admin/orders/${id}`)}
                      >
                        <td className="pl-4">{order_no}</td>
                        <td>{delivery_address?.name}</td>
                        {/* <td>{supplier ? supplier.name : "None"}</td> */}
                        <td>{this.orderDateField(date)}</td>
                        <td className="hide-on-mobile">{items.length} Artikel</td>
                        <td>{this.props.t('currencySymbol')}{total_price.toFixed(2)}</td>
                        <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                        <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      </tr>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    There are currently no orders...
                  </p>
                )}
          			</tbody>
        			</Table>
      			</div>
            </Card>
          </Col>
        </Row>
      </Container>
      </LoadingOverlay>
    );
  }
}

AdminOrdersTab.propTypes = {
  user: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
	order: state.order
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllOrders, adminGetAllOrders }
)(withRouter(AdminOrdersTab)));