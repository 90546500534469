import { GET_ALL_ITEM_REQUESTS, CREATE_ITEM_REQUEST, DELETE_ITEM_REQUEST } from "../actions/types";

const initialState = {
  item_requests: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ITEM_REQUESTS:
      return {
        ...state,
        item_requests: action.payload,
        loading: false
      };
    case CREATE_ITEM_REQUEST:
      return {
        ...state,
        item_requests: [...state.item_requests, action.payload],
        loading: false
      };
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        item_requests: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
