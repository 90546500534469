import gql from "graphql-tag";
// Login user
export const REMOVE_ITEM_FROM_MARKETLIST_MUTATION = gql`
  mutation addItemToMarketlist(
    $marketlist_id: String!
    $marketlist_item_id: String!
  ) {
    removeItemFromMarketlist(marketlist_id: $marketlist_id, marketlist_item_id: $marketlist_item_id) {
      id
      name
      items {
        ... on Cartitem {
          item
					image
          item_name
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;