import {
  GET_CART,
  REMOVE_ITEM_FROM_CART,
  ADD_ITEM_TO_CART,
  CHANGE_CART_ITEM_QUANTITY,
  EMPTY_CART
} from "../actions/types";

const initialState = {
  cart: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        cart: action.payload,
        loading: false
      };
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        cart: action.payload,
        loading: false
      };
    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        cart: action.payload,
        loading: false
      };
    case CHANGE_CART_ITEM_QUANTITY:
      return {
        ...state,
        cart: action.payload,
        loading: false
      };
    case EMPTY_CART:
      return {
        ...state,
        cart: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
