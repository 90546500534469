import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../helper/history";

import Avatar from "@material-ui/core/Avatar";
import { Image } from "cloudinary-react";
import { getSingleUser } from "../../../actions/userActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AdminSingleUserPage extends Component {
	componentDidMount() {
    const userId = this.props.match.params.id;
    this.props.getSingleUser(userId);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  render() {
		const user = this.props.auth?.singleUser
    const {loading} = this.props.auth;
    
    return (
      <div className="page admin-single-element-page">
        <Container className="single-order-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
            className="float-left mr-3"
          />
          <h4 className="page-title my-4">User</h4>
          <Row>
            <Col>
              <LoadingOverlay
                className="rounded-edges"
                active={loading}
                spinner
                text={this.props.t('loading')}
              >
              <Card className="single-order-card">
                <Row>
                  <Col>
                    <h5 className="mb-3">
                      {user?.name}
                    </h5>
                  </Col>
                </Row>
                <Row className="px-4 greyed-out">
                  <Col>
                    <p>ID <span className="float-right">{user?.id}</span></p>
										<p>Name <span className="float-right">{user?.name}</span></p>
										<p>Email <span className="float-right">{user?.email}</span></p>
										<p>Phone number <span className="float-right">{user?.telephone}</span></p>
										<p className="mb-0">Address <span className="float-right">{user?.address?.name}</span></p>
										<p className="align-right mb-0">{user?.address?.street_address}</p>
										<p className="align-right mb-0">{user?.address?.street_address_2}</p>
										<p className="align-right mb-0">{user?.address?.zip_code}</p>
										<p className="align-right mb-0">{user?.address?.city}</p>
										<p className="align-right">{user?.address?.country}</p>
										<p>Region <span className="float-right">{user?.region}</span></p>
										<p>Language <span className="float-right">{user?.language}</span></p>
										<p>Admin <span className="float-right">{user?.admin.toString()}</span></p>
                  </Col>
                </Row>
							</Card>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AdminSingleUserPage.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withTranslation()(connect(
  mapStateToProps,
  { getSingleUser }
)(withRouter(AdminSingleUserPage)));