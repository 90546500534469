import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { returnErrors } from "./errorActions";
import {
  USER_LOADED,
  USER_LOADING,
  CHANGE_USER_LANGUAGE,
  CHANGE_USER_NAME,
  CHANGE_USER_EMAIL,
  CHANGE_USER_PHONE,
  CHANGE_USER_TAX_NUMBER,
  CHANGE_USER_TAX_ID,
  CHANGE_USER_PROFILE_PICTURE,
  CHANGE_PASSWORD,
  TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE,
  TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE,
  TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE,
  TOGGLE_ORDER_UPDATES_PREFERENCE,
  TOGGLE_SMS_NOTIFICATIONS_PREFERENCE,
  SEND_CONTACT_FORM,
  LOGIN_FAIL,
  ADMIN_GET_ALL_USERS,
  GET_SINGLE_USER
} from "./types";
import { CHANGE_USER_LANGUAGE_MUTATION } from "../graphql/mutation/user/changeUserLanguage";
import { CHANGE_USER_NAME_MUTATION } from "../graphql/mutation/user/changeUserName";
import { CHANGE_USER_EMAIL_MUTATION } from "../graphql/mutation/user/changeUserEmail";
import { CHANGE_USER_PHONE_MUTATION } from "../graphql/mutation/user/changeUserPhone";
import { CHANGE_USER_TAX_NUMBER_MUTATION } from "../graphql/mutation/user/changeUserTaxNumber";
import { CHANGE_USER_TAX_ID_MUTATION } from "../graphql/mutation/user/changeUserTaxId";
import { CHANGE_USER_PROFILE_PICTURE_MUTATION } from "../graphql/mutation/user/changeUserProfilePicture";
import { CHANGE_PASSWORD_MUTATION } from "../graphql/mutation/user/changePassword";
import { TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE_MUTATION } from "../graphql/mutation/user/toggleBrowserNotificationsPreference";
import { TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE_MUTATION } from "../graphql/mutation/user/toggleEmailNotificationsPreference";
import { TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE_MUTATION } from "../graphql/mutation/user/toggleItemRequestUpdatesPreference";
import { TOGGLE_ORDER_UPDATES_PREFERENCE_MUTATION } from "../graphql/mutation/user/toggleOrderUpdatesPreference";
import { TOGGLE_SMS_NOTIFICATIONS_PREFERENCE_MUTATION } from "../graphql/mutation/user/toggleSmsNotificationsPreference";
import { SEND_CONTACT_FORM_MUTATION } from "../graphql/mutation/user/sendContactForm";

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

export const getSingleUser = id => dispatch => {
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleUser($id: String!) {
          getSingleUser(id: $id) {
            id
            admin
            name
            email
            role
            region
            language
            profile_picture
            feed {
              ... on FeedItem {
                id
                creator
                receiver
                type
                sourceId
                creation_date
              }
            }
            delivery_address
            telephone
            tax_number
            tax_id
            recent_items
            notification_preferences {
              order_updates
              item_request_updates
              email_notifications
              sms_notifications
              browser_notifications
            }
            address {
              name
              street_address
              street_address_2
              zip_code
              city
              country
            }
          }
        }
      `
    })
    .then(res => {
      // console.log(res);
      dispatch({ type: GET_SINGLE_USER, payload: res.data.getSingleUser });
    })
    .catch(err => {
      // console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const adminGetAllUsers = () => dispatch => {
  client
    .query({
      query: gql`
        query users {
          adminGetAllUsers {
            id
            admin
            name
            email
            role
            region
            language
            profile_picture
            feed {
              ... on FeedItem {
                id
                creator
                receiver
                type
                sourceId
                creation_date
              }
            }
            delivery_address
            telephone
            tax_number
            tax_id
            recent_items
            notification_preferences {
              order_updates
              item_request_updates
              email_notifications
              sms_notifications
              browser_notifications
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: ADMIN_GET_ALL_USERS, payload: res.data.adminGetAllUsers });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const sendContactForm = ( user_name, email, topic, message ) => dispatch => {
  client
    .mutate({
      variables: { user_name: user_name, email: email, topic: topic, message: message },
      mutation: SEND_CONTACT_FORM_MUTATION
    })
    .then(res => {
      dispatch({
        type: SEND_CONTACT_FORM,
        payload: res.data.sendContactForm
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
}

export const changeUserProfilePicture = url => dispatch => {
  client
    .mutate({
      variables: { url: url },
      mutation: CHANGE_USER_PROFILE_PICTURE_MUTATION
    })
    .then(res => {
      dispatch({
        type: CHANGE_USER_PROFILE_PICTURE,
        payload: res.data.changeUserProfilePicture
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserLanguage = new_language => dispatch => {
  client
    .mutate({
      variables: { new_language: new_language },
      mutation: CHANGE_USER_LANGUAGE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_LANGUAGE,
          payload: res.data.changeUserLanguage
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserName = new_name => dispatch => {
  client
    .mutate({
      variables: { new_name: new_name },
      mutation: CHANGE_USER_NAME_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_NAME,
          payload: res.data.changeUserName
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserEmail = new_email => dispatch => {
  client
    .mutate({
      variables: { new_email: new_email },
      mutation: CHANGE_USER_EMAIL_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_EMAIL,
          payload: res.data.changeUserEmail
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserPhone = new_phone => dispatch => {
  client
    .mutate({
      variables: { new_phone: new_phone },
      mutation: CHANGE_USER_PHONE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_PHONE,
          payload: res.data.changeUserPhone
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserTaxNumber = new_tax_number => dispatch => {
  client
    .mutate({
      variables: { new_tax_number: new_tax_number },
      mutation: CHANGE_USER_TAX_NUMBER_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_TAX_NUMBER,
          payload: res.data.changeUserTaxNumber
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeUserTaxId = new_tax_id => dispatch => {
  client
    .mutate({
      variables: { new_tax_id: new_tax_id },
      mutation: CHANGE_USER_TAX_ID_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_USER_TAX_ID,
          payload: res.data.changeUserTaxId
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changePassword = ({ current_password, new_password }) => dispatch => {
  client
    .mutate({
      variables: { current_password: current_password, new_password: new_password },
      mutation: CHANGE_PASSWORD_MUTATION
    })
    .then(res => {
      try{
        console.log("SUCCESS!!!!");
        dispatch({
          type: CHANGE_PASSWORD,
          payload: res.data.changePassword
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "CHANGE_PASSWORD_FAIL"));
      }

    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleBrowserNotificationsPreference = () => dispatch => {
  client
    .mutate({
      mutation: TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE,
          payload: res.data.toggleBrowserNotificationsPreference
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleEmailNotificationsPreference = () => dispatch => {
  client
    .mutate({
      mutation: TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE,
          payload: res.data.toggleEmailNotificationsPreference
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleItemRequestUpdatesPreference = () => dispatch => {
  client
    .mutate({
      mutation: TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE,
          payload: res.data.toggleItemRequestUpdatesPreference
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleOrderUpdatesPreference = () => dispatch => {
  client
    .mutate({
      mutation: TOGGLE_ORDER_UPDATES_PREFERENCE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: TOGGLE_ORDER_UPDATES_PREFERENCE,
          payload: res.data.toggleOrderUpdatesPreference
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleSmsNotificationsPreference = () => dispatch => {
  client
    .mutate({
      mutation: TOGGLE_SMS_NOTIFICATIONS_PREFERENCE_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: TOGGLE_SMS_NOTIFICATIONS_PREFERENCE,
          payload: res.data.toggleSmsNotificationsPreference
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
