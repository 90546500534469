import gql from "graphql-tag";

export const TOGGLE_COUPON_STATUS_MUTATION = gql`
  mutation toggleCouponStatus($couponId: String!) {
    toggleCouponStatus(couponId: $couponId) {
      id
      code
      value_off
      pct_off
      redeemers
      is_active
    }
  }
`;