import React, { Component } from "react";
import {
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import Swal from "sweetalert2";

import { toggleBrowserNotificationsPreference, toggleEmailNotificationsPreference, toggleItemRequestUpdatesPreference, toggleOrderUpdatesPreference, toggleSmsNotificationsPreference } from "../../../../../../../actions/userActions";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
// import { withRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class NotificationsTab extends Component {
  // componentDidMount() {
  // 	const projectId = this.props.match.params.id;
  // 	this.props.getProject(projectId);
  // }
  // toggleBrowserNotifications = () => {
  //   Swal.fire({
  //     title: "Toggle",
  //     text:
  //       "Sicher, Sie wollen Browser Benachrichtigung an/ab -schalten?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#4a8950",
  //     cancelButtonColor: "#ff635f",
  //     cancelButtonText: "Nein",
  //     confirmButtonText: "Ja"
  //   }).then(result => {
  //     if (result.value) {
  //       this.props.toggleBrowserNotificationsPreference();
  //     }
  //   });
  // };

  toggleEmailNotifications = () => {
    Swal.fire({
      title: "",
      text:
        "Wollen Sie Email Benachrichtigung an/ab -schalten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.toggleEmailNotificationsPreference();
      }
    });
  };

  // toggleSmsNotifications = () => {
  //   Swal.fire({
  //     title: "Toggle",
  //     text:
  //     "Sicher, Sie wollen SMS Benachrichtigung an/ab -schalten?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#4a8950",
  //     cancelButtonColor: "#ff635f",
  //     cancelButtonText: "Nein",
  //     confirmButtonText: "Ja"
  //   }).then(result => {
  //     if (result.value) {
  //       this.props.toggleSmsNotificationsPreference();
  //     }
  //   });
  // };

  toggleItemRequestUpdates = () => {
    Swal.fire({
      title: "",
      text:
      "Wollen Sie Artikelanforderungen-Updates an/ab -schalten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.toggleItemRequestUpdatesPreference();
      }
    });
  };

  toggleOrderUpdates = () => {
    Swal.fire({
      title: "",
      text:
        "Wollen Sie  Bestell-Updates an/ab -schalten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.toggleOrderUpdatesPreference();
      }
    });
  };

  render() {
    const { user } = this.props.auth;
    
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <h5>
                <b>Benachrichtigungen</b>
              </h5>
              
              <p className="greyed-out">
                Verwalten Sie, worüber Sie Benachrichtigungen erhalten möchten
              </p>

              <Row className="mt-3 mb-2">
                <Col className="notification-toggle-text">Bestellungen</Col>
                <Col sm={2} className="notification-toggle mb-1">
                <button
                    className="overview-button"
                    onClick={this.toggleOrderUpdates.bind(
                      this
                    )}
                  >
                    <Toggle
                      checked={user ? user.notification_preferences?.order_updates : false}
                      icons={false}
                    />
                  </button>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="notification-toggle-text">Artikelanfragen</Col>
                <Col sm={2} className="notification-toggle">
                <button
                    className="overview-button"
                    onClick={this.toggleItemRequestUpdates.bind(
                      this
                    )}
                  >
                    <Toggle
                      checked={user ? user.notification_preferences?.item_request_updates : false}
                      icons={false}
                    />
                  </button>
                </Col>
              </Row>
            </Card>
            <Card>
              <h5>
                <b>Channels</b>
              </h5>
              <p className="greyed-out">
                Verwalten Sie, wo Sie Benachrichtigungen erhalten möchten
              </p>
              <Row className="mt-3 mb-2">
                <Col className="notification-toggle-text">E-Mail-Benachrichtigungen</Col>
                <Col sm={2} className="notification-toggle mb-1">
                <button
                    className="overview-button"
                    onClick={this.toggleEmailNotifications.bind(
                      this
                    )}
                  >
                    <Toggle
                      checked={user ? user.notification_preferences?.email_notifications : false}
                      icons={false}
                    />
                  </button>
                </Col>
              </Row>
              {/* <Row className="mb-2">
                <Col className="notification-toggle-text">SMS-Benachrichtigungen</Col>
                <Col sm={2} className="notification-toggle mb-1">
                <button
                    className="overview-button"
                    onClick={this.toggleSmsNotifications.bind(
                      this
                    )}
                  >
                    <Toggle
                      checked={user ? user.notification_preferences?.sms_notifications : false}
                      icons={false}
                    />
                  </button>
                </Col>
              </Row> */}
              {/* <Row className="mb-2">
                <Col className="notification-toggle-text">Browser-Benachrichtigungen</Col>
                <Col sm={2} className="notification-toggle">
                  <button
                    className="overview-button"
                    onClick={this.toggleBrowserNotifications.bind(
                      this
                    )}
                  >
                    <Toggle
                      checked={user ? user.notification_preferences?.browser_notifications : false}
                      icons={false}
                    />
                  </button>
                </Col>
              </Row> */}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

NotificationsTab.propTypes = {
	toggleBrowserNotificationsPreference: PropTypes.func.isRequired,
  toggleEmailNotificationsPreference: PropTypes.func.isRequired, toggleItemRequestUpdatesPreference: PropTypes.func.isRequired, toggleOrderUpdatesPreference: PropTypes.func.isRequired, toggleSmsNotificationsPreference: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
  user: state.user,
});

export default connect(
  mapStateToProps,
  {
    toggleBrowserNotificationsPreference, toggleEmailNotificationsPreference, toggleItemRequestUpdatesPreference, toggleOrderUpdatesPreference, toggleSmsNotificationsPreference
  }
)(withRouter(NotificationsTab));
