import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { createMarketlist } from "../../../../../actions/marketListActions";

import { createItemrequest } from "../../../../../actions/item_requestActions";

import PropTypes from "prop-types";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class AddItemRequestButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUnit: "kilograms",
      selectedCountry: "germany"
    };
  }

  state = {
    modal: false,
    name: ""
  };

  handleUnitChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedUnit: targetValue });
  };

  handleCountryChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedCountry: targetValue });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newItemRequest = {
      item_name: this.state.name,
      // item_quantity: parseInt(this.state.quantity),
      // item_country: this.state.selectedCountry,
      // item_unit: this.state.selectedUnit,
      item_description: this.state.description
    };
    // Add market list
    this.props.createItemrequest(newItemRequest);
    // Close Modal
    this.toggle();
    Swal.fire({
      title: "Artikelanforderung erstellt",
      text: "Die Artikelanforderung wurde erstellt.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  render() {
    const isGuest = this.props.isGuest
    return (
      <div className="hinzufügen-modal">
        <Button
          disabled={isGuest}
          color="dark"
          className="btn-list-item"
          style={{ marginBottom: "1rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Artikelanforderung hinzufügen
        </Button>
        <Modal
          className="add-market-list-modal"
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Artikelanforderung hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Input
                        type="text"
                        name="name"
                        id="craftsman"
                        className="mb-2"
                        placeholder="Artikel Name"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  {/* <Col> */}
                    {/* <div className="linediv">
                      <Label for="project">Land</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onChange={this.handleCountryChange}
                      >
                        <option value={"Germany"}>Deutschland</option>
                        <option value={"Liters"}>Liters</option>
                        <option value={"Cases"}>Cases</option>
                        <option value={"Pieces"}>Stück</option>
                      </Input>
                    </div> */}
                  {/* </Col>
                  <Col> */}
                    {/* <div className="linediv">
                      <Label for="project">Menge</Label>
                      <Input
                        type="text"
                        name="quantity"
                        id="craftsman"
                        className="mb-3"
                        placeholder="Qty"
                        onChange={this.onChange}
                      />
                    </div> */}
                  {/* </Col>
                  <Col> */}
                    {/* <div className="linediv">
                      <Label for="project">Einheit</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onChange={this.handleUnitChange}
                      >
                        <option value={"kilograms"}>Kilogramm</option>
                        <option value={"liters"}>Liter</option>
                        <option value={"cases"}>Koffer</option>
                        <option value={"pieces"}>Stück</option>
                      </Input>
                    </div> */}
                  {/* </Col> */}
                </Row>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Input
                        type="textarea"
                        name="description"
                        id="craftsman"
                        placeholder="Beschreibung"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddItemRequestButton.propTypes = {
  createMarketlist: PropTypes.func.isRequired,
  createItemrequest: PropTypes.func.isRequired,
  marketlist: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  craftsman: state.craftsman,
  marketlist: state.marketlist
});

export default connect(
  mapStateToProps,
  { createMarketlist, createItemrequest }
)(AddItemRequestButton);
