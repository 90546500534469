import gql from "graphql-tag";
// Login user

export const CHANGE_CART_ITEM_QUANTITY_MUTATION = gql`
  mutation changeCartItemQuantity($cartitemId: String!, $plus_or_minus: String!, $supplierId: String!, $stockItemId: String!) {
    changeCartItemQuantity(cartitemId: $cartitemId, plus_or_minus: $plus_or_minus, supplierId: $supplierId, stockItemId: $stockItemId) {
      id
      items {
        ... on Cartitem {
          id
          item
          item_name
          image
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
