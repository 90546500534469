import gql from "graphql-tag";
// Invite supplier
export const ADD_ITEM_MUTATION = gql`
  mutation addItem($item: ItemInput!) {
    addItem(item: $item) {
      id
      name
    }
  }
`;
