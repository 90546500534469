import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import AddDeliveryAddressButton from "./AddDeliveryAddressButton";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import Item from "./Item";

import { changeDelivery_address } from "../../../../../actions/delivery_addressActions";
import {deleteDelivery_address} from "../../../../../actions/delivery_addressActions";
import { getAllItems } from "../../../../../actions/itemActions";
import { getDelivery_addresses } from "../../../../../actions/delivery_addressActions";

// import tomatoes from "../../../../../assets/tomatoes.png";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class ChangeDeliveryAddressButton extends Component {
  state = {
    modal: false,
    name: ""
  };

  componentDidMount() {
    this.props.getAllItems();
    this.props.getDelivery_addresses();
    const delivery_addresses = this.props.delivery_address.deliveryaddresses;
    this.setAddressIfOnlyOneOrNoneActive(delivery_addresses)
  }

  componentDidUpdate() {
    const delivery_addresses = this.props.delivery_address.deliveryaddresses;
    this.setAddressIfOnlyOneOrNoneActive(delivery_addresses)
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectDeliveryAddress = delivery_address_id => {
    Swal.fire({
      title: "Lieferadresse auswählen",
      text: "Sind Sie sicher, dass Sie Ihre Lieferadresse ändern möchten",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeDelivery_address(delivery_address_id);
        this.toggle()
      }
    });
  };

  deleteDeliveryAddress = delivery_address_id => {
    Swal.fire({
      title: "Lieferadresse auswählen",
      text: "Sind Sie sicher, dass Sie diese Lieferadresse löschen möchten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.deleteDelivery_address(delivery_address_id);
      }
    });
  }

  setAddressIfOnlyOneOrNoneActive = delivery_addresses => {
    if (delivery_addresses.length == 1){
      const delivery_address = delivery_addresses[0]
      const delivery_address_id = delivery_address.id
      if(delivery_address.is_active == false){
        this.props.changeDelivery_address(delivery_address_id);
        if(this.props.origin == "checkout-page"){
          window.location.reload(false);
        }
      }
    } else if (delivery_addresses.length > 1){
      const checkIsActive = obj => obj.is_active === true;
      if (!delivery_addresses.some(checkIsActive)){
        const delivery_address_id = delivery_addresses[0].id
        this.props.changeDelivery_address(delivery_address_id);
      }
    }
  }

  onSubmit = e => {
    e.preventDefault();

    // this.props.changeDelivery_address(delivery_address_id);

    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  render() {
    const active_delivery_address = this.props.auth.user.delivery_address;
    const items = this.props.item.items;
    const delivery_addresses = this.props.delivery_address.deliveryaddresses;
    // this.setAddressIfOnlyOne(delivery_addresses);

    return (
      <div className="hinzufügen-modal change-address">
        <Button className="change-delivery-address-btn" onClick={this.toggle}>
          Ändern
        </Button>
        <Modal
          className="change-address-modal"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Lieferadresse ändern
          </ModalHeader>
          <ModalBody>
            <ListGroup>
              {delivery_addresses.map(delivery_address => {
                if (delivery_address.is_active == true) {
                  return (
                    <Row>
                    <Col>
                    <ListGroupItem
                      className="justify-content-between delivery-address-option invoice-list mb-2 active"
                      onClick={() => {
                        this.selectDeliveryAddress(delivery_address?.id);
                      }}
                    >
                      <p className="mb-1">{delivery_address?.name}</p>
                      <p className="mb-1">{delivery_address?.street_address}</p>
                      <p className="mb-1">{`${delivery_address?.city}, ${delivery_address?.country}, ${delivery_address?.zip_code}`}</p>
                    </ListGroupItem>
                    </Col>
                    <Col sm={1}>
                    <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => this.deleteDeliveryAddress(delivery_address?.id)}
                          className="remove-cart-item-btn"
                        />
                    </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row>
                    <Col>
                    <ListGroupItem
                      className="justify-content-between delivery-address-option invoice-list mb-2"
                      onClick={() => {
                        this.selectDeliveryAddress(delivery_address?.id);
                      }}
                    >
                      <p className="mb-1">{delivery_address?.name}</p>
                      <p className="mb-1">{delivery_address?.street_address}</p>
                      <p className="mb-1">{`${delivery_address?.city}, ${delivery_address?.country}, ${delivery_address?.zip_code}`}</p>
                    </ListGroupItem>
                    </Col>
                    <Col sm={1}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => this.deleteDeliveryAddress(delivery_address?.id)}
                          className="remove-cart-item-btn"
                        />
                    </Col>
                    </Row>
                  );
                }
              })}
              <ListGroupItem className="justify-content-between invoice-list">
                {/* <a href="/">Add a new address</a> */}
                <AddDeliveryAddressButton />
              </ListGroupItem>
            </ListGroup>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ChangeDeliveryAddressButton.propTypes = {
  getAllItems: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  delivery_address: PropTypes.object.isRequired,
  delivery_address_id: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  craftsman: state.craftsman,
  item: state.item,
  delivery_address: state.delivery_address
});

export default connect(
  mapStateToProps,
  { getAllItems, getDelivery_addresses, changeDelivery_address, deleteDelivery_address }
)(ChangeDeliveryAddressButton);
