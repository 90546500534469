import gql from "graphql-tag";

export const DELETE_ITEM_REQUEST_MUTATION = gql`
  mutation deleteItemrequest($itemrequest_id: String!) {
    deleteItemrequest(itemrequest_id: $itemrequest_id) {
      id
      item_name
      item_quantity
      item_country
      item_unit
      item_description
      status
    }
  }
`;