import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { createCoupon } from "../../../../actions/couponActions"
import classnames from "classnames";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

class AdminCreateCouponModal extends Component {
  state = {
    modal: false,
		discount: "value_off"
  };

	componentDidMount() {

  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    console.log(this.state.code, this.state.discount, parseFloat(this.state.discount_value))
		
		const newCoupon = {
			code: this.state.code,
			discount: this.state.discount,
			discount_value: parseFloat(this.state.discount_value)
		}
		
    // const newItem = {
    //   name: this.state.name,
		// 	region: this.state.region,
		// 	description: this.state.description,
		// 	image: this.state.imageUrl,
		// 	categories: this.state.categories,
		// 	supplier: this.state.selectedSupplier,
		// 	unit: "/" + this.state.unit,
		// 	price_per_unit: parseFloat(this.state.price_per_unit),
		// 	tax_pct: parseInt(this.state.tax_percentage),
		// 	amount: parseInt(this.state.quantity),
    //   nutritional_facts: this.state.nutritional_facts,
    //   item_cost: parseFloat(this.state.item_cost)
    // };
    
    this.props.createCoupon(newCoupon);
    
    this.toggle();
    Swal.fire({
      title: "Artikel erstellt",
      text: "Der Coupon wurde erstellt.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

	setDiscount = discount => {
		if(this.state.discount != discount){
			this.setState({
				discount: discount
			});
		}
	}

	getDiscountPlaceholder = () => {
		if(this.state.discount === "value_off"){
			return "Value off eingeben"
		} else if (this.state.discount === "pct_off"){
			return "Percentage off eingeben"
		}
	}

  render() {

    return (
      <div className="hinzufügen-modal">
				<span className="add-btn float-right" onClick={this.toggle}>
					<a>
            + Create Coupon
					</a>
        </span>
        <Modal
					id="admin-create-item-modal"
          className="add-market-list-modal"
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Coupon hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Input
                        type="text"
                        name="code"
                        id="code"
                        className="mb-2"
                        placeholder="Code eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<Row className="mb-2">
											<Col>
												<Button onClick={() => this.setDiscount("value_off")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.discount === "value_off"
                  				}
                				)}>Value off</Button>
											</Col>
											<Col>
												<Button onClick={() => this.setDiscount("pct_off")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.discount === "pct_off"
                  				}
                				)}>Percentage off</Button>
											</Col>
										</Row>
										<div className="linediv">
                      <Input
                        type="text"
                        name="discount_value"
                        id="discount_value"
                        className="mb-2"
                        placeholder={this.getDiscountPlaceholder()}
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AdminCreateCouponModal.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { createCoupon }
)(AdminCreateCouponModal);