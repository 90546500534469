import axios from "axios";
import {
  CREATE_FARMER,
  UPDATE_FARMER_ITEM_QUANTITY,
  INVITE_SUPPLIER,
  GET_ALL_SUPPLIERS,
  GET_SINGLE_SUPPLIER,
  REFER_A_FARMER,
  FARMERS_LOADING
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { INVITE_SUPPLIER_MUTATION } from "../graphql/mutation/suppliers/inviteSupplier";
import { CREATE_FARMER_MUTATION } from "../graphql/mutation/suppliers/createFarmer";
import { REFER_A_FARMER_MUTATION } from "../graphql/mutation/suppliers/referAFarmer";
import { UPDATE_FARMER_ITEM_QUANTITY_MUTATION } from "../graphql/mutation/suppliers/updateFarmerItemQuantity";

export const createFarmer = ({ name, email, bio, tax_id, telephone, address, website, farmer_picture, certifications, farm_name, location, year_founded, location_coordinates, type }) => dispatch => {
  client
    .mutate({
      variables: { name: name, email: email, bio: bio, tax_id: tax_id, telephone: telephone, address: address, website: website, farmer_picture: farmer_picture, certifications: certifications, farm_name: farm_name, location: location, year_founded: year_founded, location_coordinates: location_coordinates, type: type },
      mutation: CREATE_FARMER_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_FARMER,
        payload: res.data.createFarmer
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateFarmerItemQuantity = (farmerId, stockItemId, value) => (dispatch, getState) => {
  dispatch({ type: FARMERS_LOADING });
  client
    .mutate({
      variables: { farmerId, stockItemId, value },
      mutation: UPDATE_FARMER_ITEM_QUANTITY_MUTATION
    })
    .then(res => {
      dispatch({
        type: UPDATE_FARMER_ITEM_QUANTITY,
        payload: res.data.updateFarmerItemQuantity
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const referAFarmer = ( farmer_name, farmer_email, farmer_telephone, comments ) => dispatch => {
  console.log(farmer_name, farmer_email, farmer_telephone, comments);
  dispatch({ type: FARMERS_LOADING });
  client
    .mutate({
      variables: { farmer_name: farmer_name, farmer_email: farmer_email, farmer_telephone: farmer_telephone, comments: comments },
      mutation: REFER_A_FARMER_MUTATION
    })
    .then(res => {
      dispatch({
        type: REFER_A_FARMER,
        payload: res.data.referAFarmer
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
}

export const inviteSupplier = ({ name, email }) => dispatch => {
  client
    .mutate({
      variables: { name: name, email: email },
      mutation: INVITE_SUPPLIER_MUTATION
    })
    .then(res => {
      dispatch({
        type: INVITE_SUPPLIER,
        payload: res.data.inviteSupplier
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getAllSuppliers = () => dispatch => {
  dispatch({ type: FARMERS_LOADING });
  client
    .query({
      query: gql`
        query suppliers {
          getAllSuppliers {
            id
            name
            email
            status
            origin
            avatar
            type
            farmer_picture
            website
            farm_name
            year_founded
            location
            stock {
              ... on StockItem {
                item
                price_per_unit
                price_per_unit_net
                price_per_unit_7_pct
                price_per_unit_19_pct
                unit
                amount
              }
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: GET_ALL_SUPPLIERS, payload: res.data.getAllSuppliers });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getSingleSupplier = id => dispatch => {
  dispatch({ type: FARMERS_LOADING });
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleSupplier($id: String!) {
          getSingleSupplier(id: $id) {
            id
            name
            email
            bio
            tax_id
            telephone
            avatar
            type
            farmer_picture
            website
            stock {
              ... on StockItem {
                id
                item
                item_name
                price_per_unit
                price_per_unit_net
                price_per_unit_7_pct
                price_per_unit_19_pct
                unit
                amount
              }
            }
            farm_name
            year_founded
            location
            location_image
            location_coordinates {
              lat
              lng
            }
            certifications
            gallery
            articles
            address {
              street_address
              street_address_2
              zip_code
              city
              country
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: GET_SINGLE_SUPPLIER,
        payload: res.data.getSingleSupplier
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
