import {
  GET_NEWS,
  GET_SINGLE_NEWS_ITEM,
  NEWS_LOADING
} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";

export const getNews = () => dispatch => {
  dispatch({ type: NEWS_LOADING });
  client
    .query({
      query: gql`
        query news {
          getNews {
            id
            headline
						hero_image
						main_text
						type
            farmer_link
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: GET_NEWS, payload: res.data.getNews });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getSingleNewsItem = id => dispatch => {
  dispatch({ type: NEWS_LOADING });
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleNewsItem($id: String!) {
          getSingleNewsItem(id: $id) {
            id
            headline
						hero_image
            single_news_hero_image
						main_text
						type
            farmer_link
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: GET_SINGLE_NEWS_ITEM,
        payload: res.data.getSingleNewsItem
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};