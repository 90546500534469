import React, { Component } from "react";
import {
  Button,
  Card,
  CardFooter,
  Row, Col,
  Container,
  NavLink,
  Alert
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import logoTitle from "../../../assets/NewHelloFarmerLogo.svg";
import { ReactComponent as RegisterSuccessIcon } from "../../../assets/registerSuccessIcon.svg"

class RegisterSuccessPage extends Component {
	componentDidMount() {
    
  }

  state = {
    
  };

  static propTypes = {
    
  };

  render() {
    return (
      <div id="register-success-page">
        <Container className="register-success-page">
					<div>
						<RegisterSuccessIcon />
						<h4 className="page-title">Erfolgreich Registriert</h4>
						<p className="dark-grey mb-5">Die Registrierung war erfolgreich, Sie erhalten eine E-Mail für den Zugriff auf die HelloFarmer-App, wenn sie bereit ist</p>
						<Button href="https://www.hellofarmer.de">Zurück zur Webseite</Button>
					</div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default connect(
  mapStateToProps,
  {  }
)(RegisterSuccessPage);
