import axios from "axios";
import {
  CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  ADD_ITEM,
  GET_ALL_ITEMS,
  GET_SINGLE_ITEM,
  ITEMS_LOADING
} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { ADD_ITEM_MUTATION } from "../graphql/mutation/items/addItem";
import { CREATE_ITEM_MUTATION } from "../graphql/mutation/items/createItem";
import { UPDATE_ITEM_MUTATION } from "../graphql/mutation/items/updateItem";
import { DELETE_ITEM_MUTATION } from "../graphql/mutation/items/deleteItem";

export const createItem = ({ name, region, description, image, categories, supplier, unit, price_per_unit, tax_pct, amount, nutritional_facts, item_cost }) => dispatch => {
  client
    .mutate({
      variables: { name: name, region: region, description: description, image: image, categories: categories, supplier: supplier, unit: unit, price_per_unit: price_per_unit, tax_pct: tax_pct, amount: amount, nutritional_facts: nutritional_facts, item_cost: item_cost },
      mutation: CREATE_ITEM_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_ITEM,
        payload: res.data.createItem
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateItem = (itemId, newName, newDescription, newRegion, newCategory, newSupplier) => dispatch => {
  dispatch({ type: ITEMS_LOADING });
  client
    .mutate({
      variables: { itemId, newName, newDescription, newRegion, newCategory, newSupplier },
      mutation: UPDATE_ITEM_MUTATION
    })
    .then(res => {
      dispatch({
        type: UPDATE_ITEM,
        payload: res.data.updateItem
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteItem = itemId => dispatch => {
  dispatch({ type: ITEMS_LOADING });
  client
    .mutate({
      variables: { itemId },
      mutation: DELETE_ITEM_MUTATION
    })
    .then(res => {
      dispatch({
        type: DELETE_ITEM,
        payload: res.data.deleteItem
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addItem = item => dispatch => {
  client
    .mutate({
      variables: { item },
      mutation: ADD_ITEM_MUTATION
    })
    .then(res => {
      dispatch({
        type: ADD_ITEM,
        payload: res.data.addItem
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getAllItems = () => dispatch => {
  dispatch({ type: ITEMS_LOADING });
  client
    .query({
      query: gql`
        query items {
          getAllItems {
            id
            name
            description
            nutritional_facts_per
            nutritional_facts
            image
            region
            is_recent
            is_active
            categories
            suppliers {
              ... on Supplier {
                id
                name
                stock {
                  ... on StockItem {
                    item
                    price_per_unit
                    price_per_unit_net
                    price_per_unit_7_pct
                    price_per_unit_19_pct
                    unit
                    amount
                  }
                }
              }
            }
          }
        }
      `
    })
    .then(res => {
      dispatch({ type: GET_ALL_ITEMS, payload: res.data.getAllItems });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getSingleItem = id => dispatch => {
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleItem($id: String!) {
          getSingleItem(id: $id) {
            id
            name
            description
            nutritional_facts_per
            nutritional_facts
            image
            region
            is_recent
            is_active
            categories
            suppliers {
              ... on Supplier {
                id
                name
                stock {
                  ... on StockItem {
                    item
                    price_per_unit
                    price_per_unit_net
                    price_per_unit_7_pct
                    price_per_unit_19_pct
                    unit
                    amount
                  }
                }
              }
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: GET_SINGLE_ITEM, payload: res.data.getSingleItem });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};