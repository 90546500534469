import axios from "axios";
import { GET_ALL_ITEM_REQUESTS, CREATE_ITEM_REQUEST, DELETE_ITEM_REQUEST } from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { CREATE_ITEM_REQUEST_MUTATION } from "../graphql/mutation/item_requests/createItemrequest";
import { DELETE_ITEM_REQUEST_MUTATION } from "../graphql/mutation/item_requests/deleteItemrequest";

export const createItemrequest = itemrequest => dispatch => {
  console.log(itemrequest);
  client
    .mutate({
      variables: { itemrequest },
      mutation: CREATE_ITEM_REQUEST_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_ITEM_REQUEST,
        payload: res.data.createItemrequest
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteItemrequest = itemrequest_id => dispatch => {
  client
    .mutate({
      variables: { itemrequest_id },
      mutation: DELETE_ITEM_REQUEST_MUTATION
    })
    .then(res => {
      dispatch({
        type: DELETE_ITEM_REQUEST,
        payload: res.data.deleteItemrequest
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getAllItemrequests = () => dispatch => {
  client
    .query({
      query: gql`
        query itemrequests {
          getAllItemrequests {
            id
            item_name
            item_quantity
            item_country
            item_unit
            item_description
            status
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: GET_ALL_ITEM_REQUESTS,
        payload: res.data.getAllItemrequests
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
