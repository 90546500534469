import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
  NavItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Image } from "cloudinary-react";

import Logout from "../../../authentication/Logout";

import { getUserFeed } from "../../../../actions/feedActions";

import { ReactComponent as NotificationBell } from "../../../../assets/NotificationBell.svg";
import { ReactComponent as NotificationsModalIcon } from "../../../../assets/NotificationsModalIcon.svg";
import { ReactComponent as NewNotificationIcon } from "../../../../assets/NewNotificationIcon.svg";

import Avatar from "@material-ui/core/Avatar";

import { Link } from "react-router-dom";

class NotificationsModal extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getUserFeed();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  getDaysAgo = feedItemDate => {
    const millisecondsDiff = Date.now() - +new Date(feedItemDate);
    const daysDiff = millisecondsDiff / (60 * 60 * 24 * 1000);
    const days = Math.round(daysDiff);
    return days;
  };

  feedItem = item => {
    console.log(item)
    switch (item.type) {
      case "ORDER_CREATED":
        return (
          <Button className="notification card" style={{width: '100%'}}>
            <Row className="notification-content">
              <Col className="" sm={1}>
                <div style={{width: 10, height: 10, borderRadius: 50, backgroundColor: '#FBBC05', marginTop: 5}}>
                </div>
              </Col>
              <Col className="ml-2 pr-5">
                <Row>
                  <p className="notification-text">
                    Bestellung #12345 unterwegs
                  </p>
                  {/* <p className="greyed-out mb-0">
                    Vor {this.getDaysAgo(item.creation_date)} Tagen
                  </p> */}
                </Row>
              </Col>
            </Row>
          </Button>

          // <Button className="notification card" style={{width: '100%'}}>
          //   <Row className="notification-content">
          //     <Col className="" sm={1}>
          //       <div style={{width: 10, height: 10, borderRadius: 50, backgroundColor: '#A9CB59', marginTop: 5}}>
          //       </div>
          //     </Col>
          //     <Col className="ml-2 pr-5">
          //       <Row>
          //         <p className="notification-text">
          //           Bestellung #12345 geliefert
          //         </p>
          //       </Row>
          //     </Col>
          //   </Row>
          // </Button>

          // <Button className="notification card" style={{width: '100%'}}>
          //   <Row className="notification-content">
          //     <Col className="" sm={1}>
          //       <div style={{width: 10, height: 10, borderRadius: 50, backgroundColor: '#FBBC05', marginTop: 5}}>
          //       </div>
          //     </Col>
          //     <Col className="ml-2 pr-5">
          //       <Row>
          //         <p className="notification-text">
          //           Bestellung #12345 steht aus
          //         </p>
          //       </Row>
          //     </Col>
          //   </Row>
          // </Button>

          // <Button className="notification card" style={{width: '100%'}}>
          //   <Row className="notification-content">
          //     <Col className="" sm={1}>
          //       <div style={{width: 10, height: 10, borderRadius: 50, backgroundColor: '#FF695C', marginTop: 5}}>
          //       </div>
          //     </Col>
          //     <Col className="ml-2 pr-5">
          //       <Row>
          //         <p className="notification-text">
          //           Bestellung #12345 abgebrochen
          //         </p>
          //       </Row>
          //     </Col>
          //   </Row>
          // </Button>
        );
      default:
        break;
    }
  };

  render() {
    const { user } = this.props.auth;
    const { feed } = this.props.feed;
    const { loading } = this.props.feed;
    const orderedFeed = [...feed].reverse();

    return (
      <div style={{height: '100%', width: '100%'}}>
        <NavLink onClick={this.toggle} href="#" style={{padding: 0, paddingTop: 2.5, height: '100%'}}>
          <NotificationBell />
          <span className='mobile-navbar-item-label'>Benachrichtigungen</span>
        </NavLink>
        <Modal
          id="notifications-modal"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <LoadingOverlay
            active={loading}
            spinner
            text={this.props.t('loading')}
          >
          <ModalBody style={{borderRadius: 8}}>
            <Row id='notifications-modal-close-button-row'>
              <Col>
                <p onClick={this.toggle}>x</p>
              </Col>
            </Row>
            {feed.length ? (
              orderedFeed.map(item => {
                return this.feedItem(item);
              })
            ) : (
              <div className="center my-4">
                <NotificationsModalIcon />
                <p className="greyed-out pt-3 px-3">
                  Es gibt keine Benachrichtigungen
                </p>
              </div>
            )}
            {/* <Button className="full-width mb-2">Weitere Benachrichtigungen laden</Button>
            <Button className="full-width btn-white">Alles löschen</Button> */}
          </ModalBody>
          </LoadingOverlay>
        </Modal>
      </div>
    );
  }
}

NotificationsModal.propTypes = {
  feed: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  company: state.company,
  feed: state.feed,
  auth: state.auth
});

export default withTranslation()(connect(
  mapStateToProps,
  { getUserFeed }
)(NotificationsModal));
