import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent,
  Row,
  Col
} from "reactstrap";

import AdminUsersTab from "./tabs/AdminUsersTab";
import AdminOrdersTab from "./tabs/AdminOrdersTab";
import AdminFarmersTab from "./tabs/AdminFarmersTab";
import AdminItemsTab from "./tabs/AdminItemsTab";
import AdminCouponsTab from "./tabs/AdminCouponsTab";

// import { getCart } from "../../../../../actions/cartActions";
// import { removeItemFromCart } from "../../../../../actions/cartActions";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AdminPage extends Component {
  componentDidMount() {
    // this.props.getCart();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  getItemPrice = item => {
    var itemPrice = item.quantity * item.price_7_pct;
    itemPrice = itemPrice.toFixed(2);
    itemPrice = parseFloat(itemPrice);
    return itemPrice;
  };

  getOrderTotal = cart => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    return orderTotal;
  };

  removeCartItem = cartitemId => {
    this.props.removeItemFromCart(cartitemId);
    // this.props.removeItemFromCart(cartitemId);
  };

  render() {
    const { user } = this.props.auth;
    const cart = this.props.cart.cart;

    return (
      <div className="page" id="admin-page">
        <Row className="market-page mx-0">
          <Col className="p-0">
            <Container className="my-4">
              <Row>
                <Col>
                  <h4 className="page-title">Admin</h4>  
                </Col>
              </Row>
            </Container>
            <Container>
              <Nav tabs className="">
                <NavItem className="mobile-right-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Users
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Orders
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3"
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Farmers
                  </NavLink>
                </NavItem>
                <NavItem className="mobile-left-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4"
                    })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    Items
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs className="admin-page-nav-2">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "5"
                    })}
                    onClick={() => {
                      this.toggle("5");
                    }}
                  >
                    Coupons
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 1 ? (
                    <AdminUsersTab />
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 2 ? (
                    <AdminOrdersTab/>
                  ) : null}
                </TabPane>
                <TabPane tabId="3">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 3 ? <AdminFarmersTab /> : null}
                </TabPane>
                <TabPane tabId="4">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 4 ? <AdminItemsTab /> : null}
                </TabPane>
                <TabPane tabId="5">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 5 ? <AdminCouponsTab /> : null}
                </TabPane>
              </TabContent>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

AdminPage.propTypes = {
  getCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cart: state.cart,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  // { removeItemFromCart, getCart }
)(AdminPage);