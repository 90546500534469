import { GET_USER_FEED, FEED_LOADING } from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";

export const getUserFeed = () => dispatch => {
  dispatch({ type: FEED_LOADING });
  client
    .query({
      query: gql`
        query getUserFeed {
          getUserFeed {
            id
            creator
            receiver
            receiverAvatar
            type
            sourceId
            creation_date
          }
        }
      `
    })
    .then(res => {
      dispatch({ type: GET_USER_FEED, payload: res.data.getUserFeed });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
