import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Row, Col,
  Container,
  Alert
} from "reactstrap";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoTitle from "../../assets/NewHelloFarmerLogo.svg";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/PasswordIcon.svg";

import history from "../../helper/history";

class AuthPage extends Component {
  state = {
    modal: false,
    email: "",
    password: "",
    msg: null
  };

  static propTypes = {
    loading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { email, password } = this.state;

    const user = {
      email: email.toLowerCase(),
      password
    };

    this.props.login(user);
  };

  loginAsGuest = () => {
    const user = {
      email: "guest@hellofarmer.de",
      password: "HellofarmerGuest2022!"
    };

    this.props.login(user);
  }

  render() {
    const { loading } = this.props;
    
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <div id='authPage' className="login">
        <Container className="login-page center">
          <div className='logo'>
            <a href="https://www.hellofarmer.de/">
              <img src={logoTitle} alt="Logo" />
            </a>
          </div>
          <div className="panel">
						<a href='/login'>
							<Button
                className='authButtonSecondary'
              >
                {this.props.t('signIn').toUpperCase()}
              </Button>
						</a>
						<a href='/register'>
							<Button
								className='authButtonPrimary'
              >
                {this.props.t('register').toUpperCase()}
              </Button>
						</a>
						<div id='authOrContainer'>
							<hr style={{width: '40%', borderTop: '1px solid #C25982'}} />
							<p style={{width: '20%', color: '#C25982', fontSize: 16, marginBottom: 0}}>{this.props.t('or').toLowerCase()}</p>
							<hr style={{width: '40%', borderTop: '1px solid #C25982'}} />
						</div>
						<Button
              onClick={() => this.loginAsGuest()}
              className='authButtonAlt'
            >
              {this.props.t('continueAsGuest').toUpperCase()}
            </Button>
            {/* <Form className="p-4 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PasswordIcon className="input-icon"/>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Passwort"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <a className="forgot-pw-link" href="/forgot-password">
                  Passwort vergessen?
                </a>
                <a className="forgot-pw-link float-right" href="/register">
                  Registrieren
                </a>
                <Button
                  onClick={() => this.loginAsGuest()}
                  className="btn-white"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  GAST
                </Button>
                <Button
                  className="btn-gradient"
                  style={{ marginTop: "1rem" }}
                  block
                >
                  ANMELDEN
                </Button>
              </FormGroup>
            </Form> */}
          </div>
        </Container>
      </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default withTranslation()(connect(
  mapStateToProps,
  { login, clearErrors }
)(AuthPage));
