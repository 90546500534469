import gql from "graphql-tag";
// Invite supplier
export const INVITE_SUPPLIER_MUTATION = gql`
  mutation inviteSupplier($name: String!, $email: String!) {
    inviteSupplier(name: $name, email: $email) {
      id
      name
      email
    }
  }
`;
