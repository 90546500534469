import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../helper/history";

import Swal from "sweetalert2";
import { getAllSuppliers } from "../../../../actions/supplierActions";
import { getSingleItem } from "../../../../actions/itemActions";
import { updateItem } from "../../../../actions/itemActions"
import { deleteItem } from "../../../../actions/itemActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AdminSingleItemPage extends Component {
	state = {
    isEditting: false,
    itemName: '',
    itemDescription: '',
    itemRegion: '',
    itemCategory: '',
    itemSupplier: ''
  };
  
  componentDidMount() {
    const itemId = this.props.match.params.id;
    this.props.getSingleItem(itemId);
    this.props.getAllSuppliers();
  }

  componentDidUpdate() {
    const itemId = this.props.match.params.id;
    this.props.getSingleItem(itemId);
  }

  toggleIsEditting = () => {
    this.setState({
      isEditting: !this.state.isEditting
    });
  }

  static propTypes = {
    item: PropTypes.object.isRequired
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isInputDisabled = (field, previousValue) => {
    switch (field) {
      case 'name':
        if (previousValue == this.state.itemName){
          return true;
        }
        break;
    
      default:
        break;
    }
  }

  handleSupplierChange = e => {
    const targetValue = e.target.value;
    this.setState({ itemSupplier: targetValue });
  };

  handleItemUpdate = (currentItemId, currentItemName, currentItemDescription, currentItemRegion, currentItemCategory, currentItemSupplier) => {  
    const itemId = currentItemId;
    const newName = ((this.state.itemName === "") || (this.state.itemName === currentItemName)) ? currentItemName : this.state.itemName;
    const newDescription = ((this.state.itemDescription === "") || (this.state.itemDescription === currentItemDescription)) ? currentItemDescription : this.state.itemDescription;
    const newRegion = ((this.state.itemRegion === "") || (this.state.itemRegion === currentItemRegion)) ? currentItemRegion : this.state.itemRegion;
    const newCategory = ((this.state.itemCategory === "") || (this.state.itemCategory === currentItemCategory)) ? currentItemCategory : this.state.itemCategory;
    const newSupplier = ((this.state.itemSupplier === "") || (this.state.itemSupplier === currentItemSupplier.id)) ? currentItemSupplier.id : this.state.itemSupplier;;
    
    this.props.updateItem(itemId, newName, newDescription, newRegion, newCategory, newSupplier);
    // console.log('ID', itemId);
    // console.log('Name', newName);
    // console.log('Description', newDescription);
    // console.log('Region', newRegion);
    // console.log('Category', newCategory.toLowerCase());
    // console.log('Supplier', newSupplier);
    
    Swal.fire({
      title: "Artikel aktualisiert",
      text: "Die Artikel wurde aktualisiert.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  }

  handleItemDelete = (itemId) => {
    Swal.fire({
      title: "Artikel löschen",
      text: "Sind Sie sicher, dass Sie diesen Artikel löschen möchten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.deleteItem(itemId);
        history.goBack()
      }
    });
  }

  render() {
		const item = this.props.item;
    const farmers = this.props.supplier.suppliers;
    const {loading} = this.props.item;

    return (
      <div id='admin-single-item-page' className="page admin-single-element-page">
        <Container className="single-order-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
            className="float-left mr-3"
          />
          <h4 className="page-title my-4">Item</h4>
          <Row>
            <Col>
            <LoadingOverlay
              className="rounded-edges"
              active={loading}
              spinner
              text={this.props.t('loading')}
            >
              <Card className="single-order-card">
                <Row>
                  <Col>
                    <h5 className="mb-3">
                      {item?.name}
                      <Button id='delete-item-button'
                        onClick={() => this.handleItemDelete(item?.id)}
                      >
                        Delete
                      </Button>
                      <Button id='edit-item-button' className={classnames({
                          active: this.state.isEditting
                        })}
                        onClick={() => {
                          this.toggleIsEditting();
                        }}
                      >
                        Edit
                      </Button>
                    </h5>
                  </Col>
                </Row>
                <Row className="px-4 greyed-out">
                  <Col style={{paddingBottom: '1rem'}}>
                    <p>ID <span className="float-right">{item?.id}</span></p>
										<p>Name</p>
                    {this.state.isEditting ?
                      <div className='edit-item-input-row'>
                      <Input
                        type="text"
                        name="itemName"
                        id="itemName"
                        placeholder={item?.name}
                        value={this.state.itemName}
                        className="mb-2"
                        onChange={this.onChange}
                      />
                      </div> : <p className='value'>{item?.name}</p>
                    }
										<p>Description
                    </p>
                    {this.state.isEditting ?
                      <div className='edit-item-input-row'>
                      <Input
                        type="textarea"
                        name="itemDescription"
                        id="itemDescription"
                        placeholder={item?.description}
                        value={this.state.itemDescription}
                        className="mb-2"
                        onChange={this.onChange}
                      />
                      </div> : <p className='value'>{item?.description}</p>
                    }                                  
										<p>Region</p>
                    {this.state.isEditting ?
                      <div className='edit-item-input-row'>
                      <Input
                        type="text"
                        name="itemRegion"
                        id="itemRegion"
                        placeholder={item?.region}
                        value={this.state.itemRegion}
                        className="mb-2"
                        onChange={this.onChange}
                      />
                      </div> : <p className='value'>{item?.region}</p>
                    }      
										<p>Category</p>
                    {this.state.isEditting ?
                      <div className='edit-item-input-row'>
                      <Input
                        type="text"
                        name="itemCategory"
                        id="itemCategory"
                        placeholder={item?.categories ? (item?.categories[0].charAt(0).toUpperCase() + item?.categories[0].slice(1)) : null}
                        value={this.state.itemCategory}
                        className="mb-2"
                        onChange={this.onChange}
                      />
                      </div> : <p className='value'>{item?.categories ? (item?.categories[0].charAt(0).toUpperCase() + item?.categories[0].slice(1)) : null}</p>
                    }      
                    <p>Suppliers</p>
                    {this.state.isEditting ?
                      <Input
                			type="select"
                			name="select"
											className="mb-4"
                			id="supplierSelect"
                			onChange={this.handleSupplierChange}
              			>
                			{farmers.map(farmer => {
												return (
													<option value={farmer.id} selected={(item?.suppliers[0].id === farmer.id)}>
            								{farmer.name}
          								</option>
												)
											})}
             		 		</Input>
                      : item?.suppliers?.map(supplier => {
                        return(
                          <p className='value'>{supplier.name} ({supplier.id})</p>
                        )
                      })
                    }
                    {this.state.isEditting ?
                      <Button onClick={() => this.handleItemUpdate(item?.id, item?.name, item?.description, item?.region, item?.categories[0], item?.suppliers[0])} style={{width: '100%'}}>
                        Speichern
                      </Button> : null
                    }
										{/* <Row className="hide-on-mobile mb-4">
											<Col>
												<p className="mb-0">Suppliers</p>
											</Col>
											<Col>
												{item?.suppliers?.map(supplier => {
													return(
														<p className="align-right mb-0">{supplier.name} ({supplier.id})</p>
													)
												})}
											</Col>
										</Row>
                    <div className="hide-on-desktop mb-4">
                      <p className="mb-1">Suppliers</p>
                      {item?.suppliers?.map(supplier => {
												return(
													<p className="mb-0">{supplier.name} ({supplier.id})</p>
												)
											})}
                    </div> */}
                    
										{/* <p>Email <span className="float-right">{user?.email}</span></p>
										<p>Phone number <span className="float-right">{user?.telephone}</span></p>
										<p className="mb-0">Address <span className="float-right">{user?.address?.name}</span></p>
										<p className="align-right mb-0">{user?.address?.street_address}</p>
										<p className="align-right mb-0">{user?.address?.street_address_2}</p>
										<p className="align-right mb-0">{user?.address?.zip_code}</p>
										<p className="align-right mb-0">{user?.address?.city}</p>
										<p className="align-right">{user?.address?.country}</p>
										<p>Region <span className="float-right">{user?.region}</span></p>
										<p>Language <span className="float-right">{user?.language}</span></p>
										<p>Admin <span className="float-right">{user?.admin.toString()}</span></p> */}
                  </Col>
                </Row>
							</Card>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AdminSingleItemPage.propTypes = {
	getSingleItem: PropTypes.func.isRequired,
  supplier: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  item: state.item,
  supplier: state.supplier
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllSuppliers, getSingleItem, updateItem, deleteItem }
)(withRouter(AdminSingleItemPage)));