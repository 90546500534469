import {
  ORDER_LOADING,
  CREATE_ORDER,
  GET_ALL_ORDERS,
  GET_SINGLE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  ADMIN_GET_ALL_ORDERS,
  ORDERS_LOADING,
  CHANGE_ORDER_STATUS
} from "../actions/types";

const initialState = {
  new_order: {},
  orders: [],
  all_orders: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ORDER_LOADING:
      return {
        ...state,
        loading: true
      };
    case ORDERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CREATE_ORDER:
      return {
        ...state,
        new_order: action.payload,
        orders: [...state.orders, action.payload],
        loading: false
      };
    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case ADMIN_GET_ALL_ORDERS:
      return {
        ...state,
        all_orders: action.payload,
        loading: false
      };
    case GET_SINGLE_ORDER:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CREATE_ORDER_FAIL:
      return {
        state
      }
    case CREATE_ORDER_SUCCESS:
      return {
        ...state
      }
    default:
      return state;
  }
}
