import gql from "graphql-tag";
// Login user
export const CHANGE_MARKETLIST_NAME_MUTATION = gql`
  mutation changeMarketlistName(
    $marketlist_id: String!
    $new_name: String!
  ) {
    changeMarketlistName(marketlist_id: $marketlist_id, new_name: $new_name) {
      id
      name
      items {
        ... on Cartitem {
          item
          item_name
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;