import gql from "graphql-tag";

export const CREATE_FARMER_MUTATION = gql`
  mutation createFarmer($name: String, $email: String!, $bio: String!, $tax_id: String!, $telephone: String!, $address: Delivery_addressInput, $website: String!, $farmer_picture: String, $certifications: [String!], $farm_name: String!, $location: String!, $year_founded: String!, $location_coordinates: CoordinatesInput, $type: String!) {
    createFarmer(name: $name, email: $email, bio: $bio, tax_id: $tax_id, telephone: $telephone, address: $address, website: $website, farmer_picture: $farmer_picture, certifications: $certifications, farm_name: $farm_name, location: $location, year_founded: $year_founded, location_coordinates: $location_coordinates, type: $type) {
      id
      name
      email
      bio
      tax_id
      telephone
      avatar
      type
      farmer_picture
      website
      stock {
        ... on StockItem {
          item
          item_name
          price_per_unit
          price_per_unit_net
          price_per_unit_7_pct
          price_per_unit_19_pct
          unit
          amount
        }
      }
      farm_name
      year_founded
      location
      location_image
      location_coordinates {
        lat
        lng
      }
      certifications
      gallery
      articles
      address {
        street_address
        street_address_2
        zip_code
        city
        country
      }
    }
  }
`;