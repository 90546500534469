import gql from "graphql-tag";

export const UPDATE_ITEM_MUTATION = gql`
  mutation updateItem($itemId: String!, $newName: String!, $newDescription: String!, $newRegion: String!, $newCategory: String!, $newSupplier: String!) {
    updateItem(itemId: $itemId, newName: $newName, newDescription: $newDescription, newRegion: $newRegion, newCategory: $newCategory, newSupplier: $newSupplier) {
      id
      name
      description
      image
      region
      is_recent
      categories
      suppliers {
        ... on Supplier {
          id
          name
          stock {
            ... on StockItem {
              item
              price_per_unit
              price_per_unit_net
              price_per_unit_7_pct
              price_per_unit_19_pct
              unit
              amount
            }
          }
        }
      }
    }
  }
`;