import React, { Component } from "react";

/* eslint-disable-next-line */
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import LoginPage from "./components/authentication/LoginPage";
import RegisterPage from "./components/authentication/register/RegisterPage";
import RegisterSuccessPage from "./components/authentication/register/RegisterSuccessPage";
import ConfirmOrderPage from "./components/global/ConfirmOrderPage";

import { getMembership } from "./actions/membershipActions";

import SideBar from "./components/authenticated/navigation/SideBar";

import { ApolloProvider } from "@apollo/react-hooks";

import { CloudinaryContext } from "cloudinary-react";
import SimpleReactLightbox from 'simple-react-lightbox'

import client from "./helper/apolloClient";

import Cookies from "js-cookie";
import { AUTH_TOKEN } from "./helper/constants";
import MainContent from "./components/authenticated/content/MainContent";
import ForgotPasswordPage from "./components/authentication/ForgotPasswordPage";
import PasswordResetPage from "./components/authentication/PasswordResetPage";

import history from "./helper/history";
import AuthPage from "./components/authentication/AuthPage";

import { I18nextProvider } from "react-i18next";
import i18n from "./helper/i18n";

require("dotenv").config();

class App extends Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if (performance.navigation.type == 1) {
        console.log("This page is reloaded");
        const isAuthenticated = Cookies.get("token");
      } else {
        console.log("This page is not reloaded");
      }
    }
  }

  componentDidMount() {
    this.props.getMembership();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    sidebar: true,
    name: ""
  };

  toggleSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar
    });
  };

  userRoutes = (isAuthenticated, isAdmin, authorizedAsAdmin, authorized, unauthorized) => {
    if(isAuthenticated) {
      if(isAdmin){
        return authorizedAsAdmin
      } else {
        return authorized
      }
    } else {
      return unauthorized
    }
  }

  render() {
    const isAuthenticated = Cookies.get("token");
    console.log(isAuthenticated);
    const membership = this.props.membership
    const { user } = this.props.auth;
    const isAdmin = user?.admin;
    const isGuest = user?.guest;
    const isFarmer = user?.farmer;
    const userRole = "contractor";
    const userLanguage = user?.language;
    if(userLanguage !== undefined){
      console.log(userLanguage);
      
      if(userLanguage === "Deutsch"){
        i18n.changeLanguage('de');
      } else if (userLanguage === "Spanish") {
        i18n.changeLanguage('es');
      } else if (userLanguage === "English") {
        i18n.changeLanguage('en');
      }
    }

    const authorized = (
      isAdmin ? 
      <Router history={history}>
        {/* <SideBar
          isOpen={this.state.sidebar}
          toggleSidebar={this.toggleSidebar}
          membership={membership}
          isAdmin={true}
          isFarmer={isFarmer}
        /> */}
        <MainContent
          sidebarOpen={this.state.sidebar}
          toggleSidebar={this.toggleSidebar}
          userRole={userRole}
          isAdmin={true}
          isFarmer={isFarmer}
        />
      </Router> : 
      <Router history={history}>
      {/* <SideBar
        isOpen={this.state.sidebar}
        toggleSidebar={this.toggleSidebar}
        membership={membership}
        isGuest={isGuest}
        isFarmer={isFarmer}
      /> */}
      <MainContent
        sidebarOpen={this.state.sidebar}
        toggleSidebar={this.toggleSidebar}
        userRole={userRole}
        isGuest={isGuest}
        isFarmer={isFarmer}
      />
    </Router>
    );

    const unauthorized = (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            // render={() => {
            //   return <Redirect to="/login" />;
            // }}
          // />
          >
            <AuthPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/register">
            <RegisterPage />
          </Route>
          <Route exact path="/register-success">
            <RegisterSuccessPage />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password/:reset_token"
            render={(props) => {
              return <PasswordResetPage {...props}/>
            }}
          />
          {/* <Route path="/confirm_order/:id" component={ConfirmOrderPage}>
          </Route> */}
        </Switch>
      </Router>
    );

    // const [sidebarIsOpen, setSidebarOpen] = useState(true);
    // const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
    // const unauthorized = <RegisterPage />;

    return (
      // <Provider store={store}>
      <ApolloProvider client={client}>
        <CloudinaryContext cloudName="dqzo9yjly">
        <SimpleReactLightbox>
          <I18nextProvider i18n={i18n}>
            <div className="App">
            {/* <Row className="m-0">
              <Col className="no-padding-left no-padding-right" sm={2}> */}
            {/* </Col>
              <Col className="no-padding-left no-padding-right" sm={10}> */}
            {isAuthenticated ? authorized : unauthorized}
            {/* </Col>
            </Row> */}
            </div>
            {/* <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} /> */}
           </I18nextProvider>
        </SimpleReactLightbox>
        </CloudinaryContext>
      </ApolloProvider>
      // </Provider>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  membership: state.membership
});
export default connect(
  mapStateToProps,
  { getMembership }
)(App);
