import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  Nav,
  NavItem,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logout from "../../../authentication/Logout";

import history from "../../../../helper/history";

import Avatar from "@material-ui/core/Avatar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faChevronDown } from "@fortawesome/free-solid-svg-icons";
class AccountModal extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  render() {
    const { user } = this.props.auth;
    const isGuest = user?.guest;

    return (
      <div style={{height: '100%'}}>
        <NavLink
         onClick={() => history.push('/settings')} 
         href="#" style={{padding: 0, height: '100%'}}>
          <Row className='hide-on-mobile'>
            <Col style={{padding: 0, marginRight: '0.25rem'}}>
          {user.profile_picture ? (
            <Avatar
              className="float-left mr-2 navbar-avatar"
              src={user.profile_picture}
            />
          ) : (
            <Avatar
              className="float-left mr-2 navbar-avatar"
              src="../../assets/DefaultProfilePic.svg"
            />
          )}
          </Col>
          <Col style={{padding: 0}}>
            <p style={{marginTop: -2, marginBottom: -8, fontSize: 16, fontWeight: 'bold', color: 'black'}}>{this.props.t('hello')}</p>
            <p style={{marginBottom: 0, fontSize: 16, fontWeight: 'bold', color: '#7D7D7D'}}>{user ? `${user.name.replace(/ .*/, "")}` : ""}</p>
          </Col>
          </Row>
          <span className='mobile-navbar-item-label'>Profil</span>
        </NavLink>
        <Modal
          id="account-modal"
          className="account-modal list-modal"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          {/* <ModalHeader toggle={this.toggle}>
            <Row>
              <Col sm={3}>
                {user.profile_picture ? (
                  <Avatar className="avatar" src={user.profile_picture} />
                ) : (
                  <Avatar
                    className="avatar"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={9}>
                <p className="ml-2">{user ? user.name : "Username"}</p>
                <p className="role greyed-out ml-2">
                  {user ? this.returnUserRole(user.role) : "Role"}
                </p>
              </Col>
            </Row>
          </ModalHeader> */}
          <ModalBody>
            <Nav className="primary-nav" vertical>
              {/* <NavItem onClick={() => this.toggle()}>
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/user"
                  activeClassName="active"
                >
                  <p>Profil</p>
                </NavLink>
              </NavItem>
              <NavItem onClick={() => this.toggle()}>
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/company"
                  activeClassName="active"
                >
                  <p>Unternehmen</p>
                </NavLink>
              </NavItem> */}
              <NavItem onClick={() => this.toggle()}>
                <NavLink
                  disabled={isGuest}
                  tag={RRNavLink}
                  exact
                  to="/settings"
                  activeClassName="active"
                >
                  <Row>
                    <Col sm={1}>
                      <FontAwesomeIcon className="dark-grey" icon={faCog} />
                    </Col>
                    <Col>
                      <p className="dark-grey">Einstellungen</p>
                    </Col>
                  </Row>
                </NavLink>
              </NavItem>
              <NavItem onClick={() => this.toggle()}>
                <Logout />
              </NavItem>
            </Nav>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withTranslation()(connect(
  mapStateToProps,
  null
)(AccountModal));
