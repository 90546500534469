import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Image } from "cloudinary-react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import SubscribeToMembershipModal from "./SubscribeToMembershipModal"

import { getSingleNewsItem } from "../../../../../actions/newsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import history from "../../../../../helper/history";

class MembershipsPage extends Component {
  componentDidMount() {
    // const newsId = this.props.match.params.id;
    // this.props.getSingleNewsItem(newsId);
  }

  render() {
    const newsItem = this.props.news;
    
    return (
      <div id="news-item-page">
        <Container className="mt-4">
					<Row>
						<Col>
							<p>Membership</p>
              <SubscribeToMembershipModal />
						</Col>
					</Row>
				</Container>
      </div>
    );
  }
}

MembershipsPage.propTypes = {
  news: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  news: state.news
});

export default connect(
  mapStateToProps,
  { getSingleNewsItem }
)(MembershipsPage);