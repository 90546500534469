import axios from "axios";
import {
  ADD_DELIVERY_ADDRESS,
  CHANGE_DELIVERY_ADDRESS,
  DELETE_DELIVERY_ADDRESS,
  GET_DELIVERY_ADDRESSES,
} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { ADD_DELIVERY_ADDRESS_MUTATION } from "../graphql/mutation/deliveryaddresses/addDelivery_address";
import { CHANGE_DELIVERY_ADDRESS_MUTATION } from "../graphql/mutation/deliveryaddresses/changeDelivery_address";
import { DELETE_DELIVERY_ADDRESS_MUTATION } from "../graphql/mutation/deliveryaddresses/deleteDelivery_address";

export const addDelivery_address = delivery_address => dispatch => {
  client
    .mutate({
      variables: { delivery_address },
      mutation: ADD_DELIVERY_ADDRESS_MUTATION
    })
    .then(res => {
      dispatch({
        type: ADD_DELIVERY_ADDRESS,
        payload: res.data.addDelivery_address
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeDelivery_address = delivery_address_id => dispatch => {
  client
    .mutate({
      variables: { delivery_address_id },
      mutation: CHANGE_DELIVERY_ADDRESS_MUTATION
    })
    .then(res => {
      dispatch({
        type: CHANGE_DELIVERY_ADDRESS,
        payload: res.data.changeDelivery_address
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteDelivery_address = delivery_address_id => dispatch => {
  client
    .mutate({
      variables: { delivery_address_id },
      mutation: DELETE_DELIVERY_ADDRESS_MUTATION
    })
    .then(res => {
      dispatch({
        type: DELETE_DELIVERY_ADDRESS,
        payload: res.data.deleteDelivery_address
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getDelivery_addresses = () => dispatch => {
  client
    .query({
      query: gql`
        query delivery_addresses {
          getDelivery_addresses {
            id
            name
            street_address
            street_address_2
            zip_code
            city
            country
            is_active
          }
        }
      `
    })
    .then(res => {
      dispatch({
        type: GET_DELIVERY_ADDRESSES,
        payload: res.data.getDelivery_addresses
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
