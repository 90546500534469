import gql from "graphql-tag";
// Invite supplier
export const DELETE_DELIVERY_ADDRESS_MUTATION = gql`
  mutation deleteDelivery_address($delivery_address_id: String!) {
    deleteDelivery_address(delivery_address_id: $delivery_address_id) {
      id
      name
      street_address
      street_address_2
      zip_code
      city
      country
      is_active
    }
  }
`;