import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { createFarmer } from "../../../../actions/supplierActions";
import PropTypes from "prop-types";
import classnames from "classnames";
import Swal from "sweetalert2";

class AdminCreateFarmerModal extends Component {
  state = {
    modal: false,
    name: "",
		imageUrl: null,
    imageAlt: null,
		type: null,
		certifications: []
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newFarmer = {
      name: this.state.name,
			email: this.state.email,
			bio: this.state.bio,
			tax_id: this.state.tax_id,
			telephone: this.state.telephone,
			address:{
				street_address: this.state.street_address,
				street_address_2: this.state.street_address_2,
				zip_code: parseInt(this.state.zip_code),
				city: this.state.city,
				country: this.state.country
			},
			website: this.state.website,
			farmer_picture: this.state.imageUrl ? this.state.imageUrl : "https://res.cloudinary.com/dqzo9yjly/image/upload/v1684773679/farmers/default_profile_pic.png",
			certifications: this.state.certifications,
			farm_name: this.state.farm_name,
			location: this.state.location,
			year_founded: this.state.year_founded,
			location_coordinates:{
				lat: parseFloat(this.state.location_coordinate_lat),
				lng: parseFloat(this.state.location_coordinate_lng)
			},
			type: this.state.type
    };
    
    this.props.createFarmer(newFarmer);
    
    this.toggle();
    Swal.fire({
      title: "Farmer erstellt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

	handleImageUpload = () => {
    const { files } = document.querySelector('input[type="file"]');
    const formData = new FormData();
    formData.append("file", files[0]);
    
    formData.append("upload_preset", "upldzads");
    const options = {
      method: "POST",
      body: formData
    };

    return fetch(
      "https://api.Cloudinary.com/v1_1/dqzo9yjly/image/upload",
      options
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          imageUrl: res.secure_url,
          imageAlt: `An image of ${res.original_filename}`
        });
        // handleProfilePictureChange(res.secure_url)
      })
      .catch(err => console.log(err));
  };

	setType = type => {
		if(this.state.type != type){
			this.setState({
				type: type
			});
		}
	}

	setCertification = certification => {
		var certifications = this.state.certifications
		if(!this.state.certifications.includes(certification)){
			certifications.push(certification)
			this.setState({certifications: certifications})
		} else {
			const index = certifications.indexOf(certification);
			if (index > -1) {
  			certifications.splice(index, 1);
			}
			this.setState({certifications: certifications})
		}
	}

  render() {
    return (
      <div className="hinzufügen-modal">
				<span className="add-btn float-right" onClick={this.toggle}>
					<a>
            + Create Farmer
					</a>
        </span>
        <Modal
					id="admin-create-farmer-modal"
          className="add-market-list-modal"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Farmer hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        className="mb-2"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        className="mb-2"
                        placeholder="Email eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="telephone"
                        id="telephone"
                        className="mb-2"
                        placeholder="Handynummer eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="textarea"
                        name="bio"
                        id="bio"
                        className="mb-2"
                        placeholder="Bio eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      {/* <Input
                        type="text"
                        name="farmer_picture"
                        id="farmer_picture"
                        className="mb-2"
                        placeholder="Farmer picture URL eingeben"
                        onChange={this.onChange}
                      /> */}
                      <input type="file" id="file" className="inputfile" onChange={this.handleImageUpload}/>
                      <label for="file">Foto auswählen (228x228 & border radius of 16)</label>
                    </div>
										<h5 className="mt-1">Type</h5>
										<Row className="mb-2">
											<Col>
												<Button onClick={() => {this.setType("Farmer")}} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.type === "Farmer"
                  				}
                				)}>Farmer</Button>
											</Col>
											<Col>
												<Button onClick={() => {this.setType("Exklusiver Lieferant")}} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.type === "Exklusiver Lieferant"
                  				}
                				)}>Exklusiver Lieferant</Button>
											</Col>
										</Row>
										<div className="linediv">
                      <Input
                        type="text"
                        name="farm_name"
                        id="farm_name"
                        className="mb-2"
                        placeholder="Farm name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="year_founded"
                        id="year_founded"
                        className="mb-2"
                        placeholder="Year founded eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="website"
                        id="website"
                        className="mb-2"
                        placeholder="Website eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<h5 className="mt-3">Certifications</h5>
										<Row className="mb-2">
											<Col>
												<Button onClick={() => this.setCertification("green")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.certifications.includes("green")
                  				}
                				)}>Green</Button>
											</Col>
											<Col>
												<Button onClick={() => this.setCertification("blue")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.certifications.includes("blue")
                  				}
                				)}>Blue</Button>
											</Col>
											<Col>
												<Button onClick={() => this.setCertification("pink")} className={classnames(
													"full-width",
                  				"btn-white",
                  				{
                    				active: this.state.certifications.includes("pink")
                  				}
                				)}>Pink</Button>
											</Col>
										</Row>
										<div className="linediv">
                      <Input
                        type="text"
                        name="street_address"
                        id="street_address"
                        className="mb-2"
                        placeholder="Straßenanschrift eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="street_address_2"
                        id="street_address_2"
                        className="mb-2"
                        placeholder="Straßenanschrift 2 eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="zip_code"
                        id="zip_code"
                        className="mb-2"
                        placeholder="Postleitzahl eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        className="mb-2"
                        placeholder="Stadt eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="country"
                        id="country"
                        className="mb-2"
                        placeholder="Land eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Input
                        type="text"
                        name="location"
                        id="location"
                        className="mb-2"
                        placeholder="Display location eingeben (e.g. Berlin, Kremmen)"
                        onChange={this.onChange}
                      />
                    </div>
										<Row>
											<Col className="pr-1">
												<div className="linediv">
                      		<Input
                        		type="text"
                        		name="location_coordinate_lat"
                        		id="location_coordinate_lat"
                        		className="mb-2"
                        		placeholder="Location coordinate latitude eingeben"
                        		onChange={this.onChange}
                      		/>
                    		</div>
											</Col>
											<Col className="pl-1">
												<div className="linediv">
                      		<Input
                        		type="text"
                        		name="location_coordinate_lng"
                        		id="location_coordinate_lng"
                        		className="mb-2"
                        		placeholder="Location coordinate longitude eingeben"
                        		onChange={this.onChange}
                      		/>
                    		</div>
											</Col>
										</Row>
										<div className="linediv">
                      <Input
                        type="text"
                        name="tax_id"
                        id="tax_id"
                        className="mb-2"
                        placeholder="Tax ID eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AdminCreateFarmerModal.propTypes = {
  createFarmer: PropTypes.func.isRequired,
  farmer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  farmer: state.newFarmer
});

export default connect(
  mapStateToProps,
  { createFarmer }
)(AdminCreateFarmerModal);