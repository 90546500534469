import { ADD_ITEM, CREATE_ITEM, UPDATE_ITEM, DELETE_ITEM, GET_ALL_ITEMS, GET_SINGLE_ITEM, ITEMS_LOADING } from "../actions/types";

const initialState = {
  items: [],
  item: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ITEMS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CREATE_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
        loading: false
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
        loading: false
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.item],
        loading: false
      };
    case GET_ALL_ITEMS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case GET_SINGLE_ITEM:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    default:
      return state;
  }
}
