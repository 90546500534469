import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  Row,
  Col,
  Button
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SingleOrderItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: this.props.auth?.user?.region,
      selectedLanguage: this.props.auth?.user?.language
    };
  }

  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  // IMPLEMENT MUTATIONS FRONTEND & BACKEND (REGION CHANGE & LANGUAGE CHANGE)

  handleRegionChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedRegion: targetValue });
  };

  handleLanguageChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedLanguage: targetValue });
  };

  returnUserRegion = regionLong => {
    let region;
    switch (regionLong) {
      case "Europa":
        region = "EU";
        break;
      case "Central America":
        region = "CA";
        break;
    }
    return region;
  };

  returnUserLanguage = languageLong => {
    let language;
    switch (languageLong) {
      case "English":
        language = "EN";
      case "Deutsch":
        language = "DE";
    }
    return language;
  };

  getRegionSelectOptions = region => {
    let regions = ["Europa"];
    let regionOptions = [];
    regions.forEach(singleRegion => {
      if (singleRegion == region) {
        regionOptions.push(
          <option value={singleRegion} selected>
            {singleRegion}
          </option>
        );
      } else {
        regionOptions.push(
          <option value={singleRegion}>{singleRegion}</option>
        );
      }
    });
    return regionOptions;
  };

  getLanguageSelectOptions = language => {
    let languages = ["Deutsch"];
    var languageOptions = [];
    languages.forEach(singleLanguage => {
      if (singleLanguage == language) {
        languageOptions.push(
          <option value={singleLanguage} selected>
            {singleLanguage}
          </option>
        );
      } else {
        languageOptions.push(
          <option value={singleLanguage}>{singleLanguage}</option>
        );
      }
    });

    return languageOptions;
  };

  render() {
		const orderId = this.props.orderId
		const orderItem = this.props.orderItem;

    return (
      <div>
        <NavLink onClick={this.toggle} href="#">
          {/* {user.region ? this.returnUserRegion(user.region) : "Region"} /{" "}
          {user.language ? this.returnUserLanguage(user.language) : "Language"} */}
          <tr
            className="single-order-list-item dark-grey"
          >
            <td className="pl-4">{orderItem.item_name}</td>
            <td className="hide-on-mobile">1kg</td>
            <td>{orderItem.quantity}</td>
            <td className="hide-on-mobile">{orderItem.quantity_accepted ? orderItem.quantity_accepted : "NA"}</td>
            <td className="hide-on-mobile">{orderItem.quantity_delivered ? orderItem.quantity_delivered : "NA"}</td>
            <td className="hide-on-mobile">{this.props.t('currencySymbol')}{orderItem.price_7_pct.toFixed(2)}</td>
            <td className="pr-4">{this.props.t('currencySymbol')}{(orderItem.price_7_pct * orderItem.quantity).toFixed(2)}</td>
          </tr>
        </NavLink>
        <Modal className="" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>#ID {orderId}</ModalHeader>
          <ModalBody>
            
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default connect(
  mapStateToProps,
  null
)(SingleOrderItemModal);
