import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import AddItemButton from "./AddItemButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../../helper/history";

// import tomatoes from "../../../../../assets/tomatoes.png";
import supplier from "../../../../../assets/supplier.png";

import { getCart } from "../../../../../actions/cartActions";
import { getSingleItem } from "../../../../../actions/itemActions";
import { addItemToCart } from "../../../../../actions/cartActions";
import { addItemToMarketlist } from "../../../../../actions/marketListActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Swal from "sweetalert2";

import ItemSupplierList from "../suppliers/ItemSupplierList";
import ItemNutritionalFactsModal from "./ItemNutritionalFactsModal";

import { Image } from "cloudinary-react";
import { ReactComponent as PlusIconSvg } from "../../../../../assets/PlusIcon.svg";
import { ReactComponent as MinusIconSvg } from "../../../../../assets/MinusIcon.svg";
import { ReactComponent as AddItemIcon } from "../../../../../assets/AddItemIcon.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class SingleItemPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      selectedVendor: "0",
      selectedVendorName:"0",
      selectedVendorUnit:"0",
      selectedVendorPrice: 0,
      selectedVendorPrice7Pct: 0,
      selectedVendorStock: 0,
      selectedQuantity: 1,
      selectedTab: 1
    };
  }
  
  componentDidMount() {
    const itemId = this.props.match.params.id;
    this.props.getSingleItem(itemId);
    this.props.getCart();
  }

  addToCart = (item, quantity, price, supplier, supplierName, itemIsInCart) => {
    console.log(itemIsInCart.toString())
    const newCartitem = {
      image: item.image,
      item: item.id,
      item_name: item.name,
      quantity: this.state.selectedQuantity,
      price: this.state.selectedVendorPrice,
      price_7_pct: this.state.selectedVendorPrice7Pct,
      supplier: this.state.selectedVendor,
      supplier_name: this.state.selectedVendorName,
      unit: this.state.selectedVendorUnit,
      item_is_in_cart: itemIsInCart
    };

    this.props.addItemToCart(newCartitem);

    this.toggle();
    Swal.fire({
      // title: "Artikel abgelegt",
      text: "Artikel in den Warenkorb abgelegt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  addToMarketlist = (item, marketlist_id, quantity, price, supplier) => {
    const newCartitem = {
      image: item.image,
      item: item.id,
      item_name: item.name,
      quantity: quantity,
      price: this.state.selectedVendorPrice,
      price_7_pct: this.state.selectedVendorPrice7Pct,
      supplier: this.state.selectedVendor,
      supplier_name: this.state.selectedVendorName,
      unit: this.state.selectedVendorUnit
    };
    this.props.addItemToMarketlist(marketlist_id, newCartitem);
    this.toggle();
    Swal.fire({
      // title: "Artikel abgelegt",
      text: "Artikel zur Einkaufszettel abgelegt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  toggleVendor = (vendor, vendorName, price, price7Pct, vendorUnit, vendorStock) => {
    if (this.state.selectedVendor !== vendor) {
      this.setState({ selectedVendor: vendor });
      this.setState({ selectedVendorName: vendorName });
      this.setState({ selectedVendorUnit: vendorUnit });
      this.setState({ selectedVendorPrice: price });
      this.setState({ selectedVendorPrice7Pct: price7Pct });
      this.setState({ selectedVendorStock: vendorStock });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  costPerUnit = (itemId, stock) => {
    var costPerUnit = 0
    stock.forEach(stockItem => {
      if (stockItem.item == itemId){
        costPerUnit = stockItem.price_per_unit
      }
    })
    return (
      <td>{costPerUnit ? costPerUnit + ` ${this.props.t('currencySymbol')}/kg` : "NA"} </td>
    )
  }

  costPerPack = (itemId, stock) => {
    var costPerPack = 0
    stock.forEach(stockItem => {
      if (stockItem.item == itemId){
        costPerPack = stockItem.price_per_pack
      }
    })
    return (
      <td>{costPerPack ? costPerPack + ` ${this.props.t('currencySymbol')}` : "NA"}</td>
    )
  }

  handleQuantityChange = (plusOrMinus) => {
    if(plusOrMinus == "+") {
      this.setState({ selectedQuantity: this.state.selectedQuantity + 1 });
    } else if (plusOrMinus = "-") {
      if (this.state.selectedQuantity != 1){
        this.setState({ selectedQuantity: this.state.selectedQuantity - 1 });
      }
    }
  }

  getItemAvgPrice = item => {
    var count = 0;
    var total_price = 0;
    var unit = null;
    console.log(item.suppliers)
    if (item.suppliers){
      
      item.suppliers.forEach(supplier => {
        for (var i = 0, iLen = supplier.stock.length; i < iLen; i++) {
          if (supplier.stock[i].item == item.id) {
            count += 1;
            total_price += supplier.stock[i].price_per_unit_net;
            unit = supplier.stock[i].unit;
          }
        }
      });
    }
    var itemAvgPrice = total_price;
    itemAvgPrice = itemAvgPrice.toFixed(2)
    // const itemAvgPriceString = itemAvgPrice + `€${unit}`
    
    return (
      <div className="item-price" style={{fontSize: 15, fontWeight: '400'}}>
        <span style={{fontSize: 15, fontWeight: 'bold'}}>{itemAvgPrice + this.props.t('currencySymbol')}</span><br/>{unit}
      </div>
    );
  };

  getSubmitButton = (isGuest, page, cartItems) => {
    if (page == "Marketlist") {
      return (
        <Button
          className="float-right btn-modal"
          color="dark"
          style={{ marginTop: "2rem" }}
          block
          onClick={!isGuest ? () =>
            this.addToMarketlist(
              this.props.item,
              this.props.marketlist_id,
              this.state.selectedQuantity,
              this.state.selectedVendorPrice,
              this.state.selectedVendor,
              this.state.selectedVendorName
            ) : this.alertForGuest
          }
        >
          Anlegen
        </Button>
      );
    } else {
      return (
        <Button id='addItemToCartButton'
          onClick={!isGuest ? () => {
              if (
                this.state.selectedVendor == "0" ||
                this.state.selectedQuantity == 0
              ) {
                Swal.fire({
                  text: "Bitte fülle alle Felder aus",
                  icon: "warning",
                  confirmButtonColor: "#C25982",
                  confirmButtonText: "Ok"
                });
              } else if (this.state.selectedQuantity > this.state.selectedVendorStock){
                Swal.fire({
                  text: `Entschuldigung! Die Höchstmenge beträgt ${this.state.selectedVendorStock}`,
                  icon: "warning",
                  confirmButtonColor: "#C25982",
                  confirmButtonText: "Ok"
                });
              } else if (cartItems.some(e => e.item === this.props.item.id)){
                this.addToCart(
                  this.props.item,
                  this.state.selectedQuantity,
                  this.state.selectedVendorPrice,
                  this.state.selectedVendor,
                  this.state.selectedVendorName,
                  true
                );
              } else {
                this.addToCart(
                  this.props.item,
                  this.state.selectedQuantity,
                  this.state.selectedVendorPrice,
                  this.state.selectedVendor,
                  this.state.selectedVendorName,
                  false
                );
              }
            } : this.alertForGuest}
        >
          <PlusIconSvg />
        </Button>
        // <Button
        //   className="float-right btn-modal"
        //   color="dark"
        //   style={{ marginTop: "2rem" }}
        //   block
        //   onClick={!isGuest ? () => {
        //     if (
        //       this.state.selectedVendor == "0" ||
        //       this.state.selectedQuantity == 0
        //     ) {
        //       Swal.fire({
        //         text: "Bitte fülle alle Felder aus",
        //         icon: "warning",
        //         confirmButtonColor: "#C25982",
        //         confirmButtonText: "Ok"
        //       });
        //     } else if (this.state.selectedQuantity > this.state.selectedVendorStock){
        //       Swal.fire({
        //         text: `Entschuldigung! Die Höchstmenge beträgt ${this.state.selectedVendorStock}`,
        //         icon: "warning",
        //         confirmButtonColor: "#C25982",
        //         confirmButtonText: "Ok"
        //       });
        //     } else if (cartItems.some(e => e.item === this.props.item.id)){
        //       this.addToCart(
        //         this.props.item,
        //         this.state.selectedQuantity,
        //         this.state.selectedVendorPrice,
        //         this.state.selectedVendor,
        //         this.state.selectedVendorName,
        //         true
        //       );
        //     } else {
        //       this.addToCart(
        //         this.props.item,
        //         this.state.selectedQuantity,
        //         this.state.selectedVendorPrice,
        //         this.state.selectedVendor,
        //         this.state.selectedVendorName,
        //         false
        //       );
        //     }
        //   } : this.alertForGuest}
        // >
        //   In den Warenkorb
        // </Button>
      );
    }
  };

  toggleSelectedTab = (tab) => {
    if(tab !== this.state.selectedTab){
      this.setState({selectedTab: tab})
    }
  }

  getItemInfoTab = () => {
    if(this.state.selectedTab === 1){
      return (
        <p style={{fontSize: 11, color: '#9A9A9A', marginBottom: '2rem'}}>
          Das Bioapfel Sortenteam gibt es seit 2002. Es besteht aus Persönlichkeiten des Endverkaufs, des Obsthandels, der Produktion und der Forschung. Ziel ist die Optimierung des Biokernobstsortimentes nach sensorischen, verkaufstechnischen und ökologischen Kriterien. Die Mitglieder versuchen, die mit der Auswahl, Prüfung und Lancierung der Sorten verbundenen Arbeiten, Kosten und Risiken fair untereinander aufzuteilen. Die pro Sorte spezifischen Abmachungen werden vertraglich geregelt. Das Sortenteam hat sich national als kompetentes und richtungsweisendes Gremium der Biosortimentsentwicklung bewährt und etabliert.
        </p>
      )
    }
  }

  render() {
    const { user } = this.props.auth;

    const item = this.props.item;
    const isGuest = user?.guest;
    const cart = this.props.cart.cart;
    console.log(item)

    return (
      <div id="single-item-page" className="page">
        <Container className="add-item-to-cart-modal mt-4">
          <Row className="mb-3">
            <Col>
						<FontAwesomeIcon
            	onClick={() => history.goBack()}
            	icon={faChevronLeft}
            	className="back-button float-left mr-3"
              style={{height: 18}}
          	/>
              <h4 onClick={() => history.goBack()} className="page-title" style={{fontSize: 14, fontWeight: '500'}}>{this.props.t('back')}</h4>
            </Col>
					</Row>
          <Row style={{marginBottom: '2rem'}}>
            <Col className="item-image-col">
              <Image
                publicId={item.image}
                width="100%"
                className="Card image cap"
                style={{maxHeight: 361}}
              />
            </Col>
            <Col className="item-info-col" sm={8} style={{paddingTop: 192}}>
              <Row>
                  <Col>
                    <h5 className="mb-0 item-name" style={{fontSize: 20, fontWeight: 'bold'}}>{item.name}</h5>
                    <p className="mb-0 greyed-out" style={{fontSize: 13, color: '#9A9A9A'}}>{item.region}</p>
                    <h5 className="item-price hide-on-desktop">
                      {this.getItemAvgPrice(item)}
                    </h5>
                    {/* <ItemNutritionalFactsModal item={item}/> */}
                  </Col>
                  <Col className="item-quantity-col">
                    <Row className="item-quantity">
                      <Col sm="5">
                        <p className="hide-on-desktop">{this.props.t('quantity')}: </p>
                      </Col>
                      <Col>
                        <Label className="hide-on-mobile">{this.props.t('quantity')}:</Label>
                        <div className="item-quantity-selector">
                          <Button className="minus-btn" onClick={() => this.handleQuantityChange("-")}><MinusIconSvg /></Button>
                          <p>{this.state.selectedQuantity}</p>
                          <Button className="plus-btn" onClick={() => this.handleQuantityChange("+")}><PlusIconSvg /></Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {this.getSubmitButton(isGuest, this.props.page, cart?.items)}
                  </Col>
                </Row>
                <Row className='hide-on-mobile'>
                  <Col>
                    <h5 className="item-price">
                      {this.getItemAvgPrice(item)}
                    </h5>
                  </Col>
                </Row>
                <div className="item-farmer hide-on-mobile">
                  <p className="mb-2">{this.props.t('pickAFarmer')}</p>
                  <ItemSupplierList
                    selectedVendor={this.state.selectedVendor}
                    toggleVendor={this.toggleVendor}
                    setVendorPrice={this.setVendorPrice}
                    item={item}
                    suppliers={item.suppliers}
                  />
                </div>
              <div>
            </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mobile-item-farmer">
                  <p className="mb-2">{this.props.t('pickAFarmer')}</p>
                  <ItemSupplierList
                    selectedVendor={this.state.selectedVendor}
                    toggleVendor={this.toggleVendor}
                    setVendorPrice={this.setVendorPrice}
                    item={item}
                    suppliers={item.suppliers}
                  />
                </div>
                {/* {this.getSubmitButton(isGuest, this.props.page, cart?.items)} */}
                <div className='hide-on-mobile'>
                <Row id='single-item-page-subnavigation-row'>
                  <Col onClick={() => this.toggleSelectedTab(1)} className={classnames(
                    {
                      active: this.state.selectedTab === 1
                    }
                  )}>
                    <p>
                      {this.props.t('productInformation')}
                    </p>
                  </Col>
                  <Col onClick={() => this.toggleSelectedTab(2)} className={classnames(
                    {
                      active: this.state.selectedTab === 2
                    }
                  )}>
                    <p>
                      {this.props.t('ingredientsAndAllergens')}
                    </p>
                  </Col>
                  <Col onClick={() => this.toggleSelectedTab(3)} className={classnames(
                    {
                      active: this.state.selectedTab === 3
                    }
                  )}>
                    <p>
                      {this.props.t('nutritionalValues')}
                    </p>
                  </Col>
                </Row>
                {this.getItemInfoTab()}
                <Row id='single-item-page-recommendations-row'>
                  <Col>
                    <h3>
                      {this.props.t('recommendedProducts')}
                    </h3>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                      <div className='card'>
                        <Image
                          publicId={item.image}
                          width="100%"
                          className="Card image cap"
                          style={{maxWidth: 92 , maxHeight: 92}}
                        />
                        <p className='price'>
                          3.16{this.props.t('currencySymbol')}/kg
                        </p>
                        <p className='name'>
                          Äpfel Jona Gold
                        </p>
                        <p className='origin'>
                          Kremmen
                        </p>
                        <AddItemIcon style={{width: 17, height: 17, position: 'absolute', bottom: 55, right: 15}} />
                      </div>
                    </div>
                  </Col>
                </Row>
                </div>
                <div id='mobile-single-item-accordion-container' className='hide-on-desktop'>
                  <Row>
                    <Col>
                      <div>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <p className='accordion-header'>{this.props.t('productInformation')}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <p className='accordion-text'>In venenatis enim vitae efficitur dapibus. Mauris ut odio est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin eu placerat libero, vitae auctor diam.</p>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <p className='accordion-header'>{this.props.t('ingredientsAndAllergens')}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <p className='accordion-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            <p className='accordion-header'>{this.props.t('nutritionalValues')}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <p className='accordion-text'>Sed at nunc nec dui tempor volutpat id ullamcorper ex.</p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

SingleItemPage.propTypes = {
  getSingleItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cart: state.cart,
  auth: state.auth,
  item: state.item
});

export default withTranslation()(connect(
  mapStateToProps,
  { getSingleItem, getCart, addItemToCart, addItemToMarketlist }
)(withRouter(SingleItemPage)));
