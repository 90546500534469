import {
  CREATE_MEMBERSHIP,
	CREATE_MEMBERSHIP_SUCCESS,
	CREATE_MEMBERSHIP_FAIL,
	GET_MEMBERSHIP
} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { CREATE_MEMBERSHIP_MUTATION } from "../graphql/mutation/memberships/createMembership.js";

export const createMembership = (membership_type, payment_method_id, membership_price, duration_months, delivery_address) => dispatch => {
  console.log("LOG", membership_type, payment_method_id, membership_price, duration_months, delivery_address)
  client
    .mutate({
      variables: { type: membership_type, paymentMethodId: payment_method_id, price: membership_price, durationMonths: duration_months, billingAddress: delivery_address },
      mutation: CREATE_MEMBERSHIP_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CREATE_MEMBERSHIP,
          payload: res.data.createMembership
        });
        // console.log(res)
        if (res.errors[0]){
          // console.log("ERROR ERROR ERROR")
          dispatch(returnErrors(res.errors[0].message, null, "CREATE_MEMBERSHIP_FAIL"));
          dispatch({
            type: CREATE_MEMBERSHIP_FAIL
          });  
        } 
        // else {
        //   console.log("SUCCESS")
        //   dispatch({
        //     type: CREATE_ORDER_SUCCESS
        //   });  
        // }
      } catch {
        console.log("ERROR RECEIVED: ", res)
        // console.log("SUCCESS")
          dispatch({
            type: CREATE_MEMBERSHIP_SUCCESS
          });  
        // dispatch(returnErrors(res.errors[0].message, null, "CREATE_ORDER_FAIL"));
        // dispatch({
        //   type: CREATE_ORDER_FAIL
        // });  
      }
    })
    .catch(res => {
      console.log("ERROR DETECTED", res)
      // dispatch(
      //   returnErrors(err.response.data, err.response.status, "CREATE_ORDER_FAIL")
      // );
      // dispatch({
      //   type: CREATE_ORDER_FAIL
      // });
    });
};

export const getMembership = () => dispatch => {
  client
    .query({
      variables: {},
      query: gql`
        query getMembership {
          getMembership {
            id
						type
            billing_address {
              name
              street_address
              street_address
              zip_code
              city
              country
            }
						price
						date_started
						date_ending
            status
            duration_months
          }
        }
      `
    })
    .then(res => {
      // console.log(res);
      dispatch({ type: GET_MEMBERSHIP, payload: res.data.getMembership });
    })
    .catch(err => {
      // console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};