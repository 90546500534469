import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  CardDeck,
  CardBody,
  // CardTitle,
  // CardText,
  // Nav,
  // NavItem,
  // NavLink,
  // TabPane,
  Form,
  FormGroup,
  // Label,
  Container,
  Card,
  Input,
  // TabContent,
  Row,
  Col,
  // Button,
  // Table,
  // ListGroup,
  // ListGroupItem
} from "reactstrap";

// import SuppliersList from "./SuppliersList";
// import AddSupplierButton from "./AddSupplierButton";

import { Image } from "cloudinary-react";

import ReferAFarmerModal from "./ReferAFarmerModal";
// import SuppliersTab from "./SuppliersTab";
import { getAllSuppliers } from "../../../../../actions/supplierActions";

// import classnames from "classnames";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as SearchIcon } from "../../../../../assets/SearchIcon.svg";
// import sampleFarmerImage from "../../../../../assets/SampleFarmerImage.png";

class FarmersPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      suppliersFilter: null
    };
  }

  componentDidMount() {
    this.props.getAllSuppliers();
  }

  filterSuppliers(filter) {
    if (this.state.suppliersFilter !== filter) {
      this.setState({ suppliersFilter: filter });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  farmerCard = farmer => {
    return (
      <div>
        <Link
          className=""
          exact
          to={`/farmers/${farmer.id}`}
        >
        <Card>
          <div className="center p-3">
            <Image
              publicId={farmer.farmer_picture}
              // width="50%"
              // height="50%"
              className="Card image cap"
            />
          </div>
          {/* <CardImg top width="100%" src={tomatoes} alt="Card image cap" /> */}
          <CardBody>
            <p className="farmer-card-title">{farmer.name}</p>
            <div className="px-3 pb-3">
              <p className="mb-0"><b>Name</b></p>
              <p className="mb-0 greyed-out farm-name">{farmer.farm_name}</p>
              <p className="mb-0"><b>Gegründet</b></p>
              <p className="mb-0 greyed-out">{farmer.year_founded}</p>
              <p className="mb-0"><b>Ort</b></p>
              <p className="mb-0 greyed-out">{farmer.location}</p>
            </div>
          </CardBody>
        </Card>
        </Link>
      </div>
    )
  }

  render() {
    // const aufgaben = [];
    // const farmers = [{
    //   id: "1",
    //   name: "Malte Voigts",
    //   farm_name: "Spargelhof Kremmen",
    //   year_founded: "2007",
    //   location: "Berlin, Kremmen",
    //   short_description: "Malte Voigts lenkt und leitet die Geschicke des Spargelhof Kremmen als Betriebsleiter seit bereits seit dessen Gründung. Der leidenschaftliche und äußerst charismatische Landwirt liebt was er tut und isst selbst am liebsten das, was er gemeinsam mit seinem Team auf dem fruchtbaren Boden erzeugt. ... mehr" 
    // },{
    //   id: "1",
    //   name: "Malte Voigts",
    //   farm_name: "Spargelhof Kremmen",
    //   year_founded: "2007",
    //   location: "Berlin, Kremmen",
    //   short_description: "Malte Voigts lenkt und leitet die Geschicke des Spargelhof Kremmen als Betriebsleiter seit bereits seit dessen Gründung. Der leidenschaftliche und äußerst charismatische Landwirt liebt was er tut und isst selbst am liebsten das, was er gemeinsam mit seinem Team auf dem fruchtbaren Boden erzeugt. ... mehr" 
    // },{
    //   id: "1",
    //   name: "Malte Voigts",
    //   farm_name: "Spargelhof Kremmen",
    //   year_founded: "2007",
    //   location: "Berlin, Kremmen",
    //   short_description: "Malte Voigts lenkt und leitet die Geschicke des Spargelhof Kremmen als Betriebsleiter seit bereits seit dessen Gründung. Der leidenschaftliche und äußerst charismatische Landwirt liebt was er tut und isst selbst am liebsten das, was er gemeinsam mit seinem Team auf dem fruchtbaren Boden erzeugt. ... mehr" 
    // },{
    //   id: "1",
    //   name: "Malte Voigts",
    //   farm_name: "Spargelhof Kremmen",
    //   year_founded: "2007",
    //   location: "Berlin, Kremmen",
    //   short_description: "Malte Voigts lenkt und leitet die Geschicke des Spargelhof Kremmen als Betriebsleiter seit bereits seit dessen Gründung. Der leidenschaftliche und äußerst charismatische Landwirt liebt was er tut und isst selbst am liebsten das, was er gemeinsam mit seinem Team auf dem fruchtbaren Boden erzeugt. ... mehr" 
    // }];

    const farmers = this.props.supplier.suppliers;
    const { loading } = this.props.supplier
    const orderedFarmers = [...farmers].reverse();

    return (
      <div className="page" id="farmers-page">
        <Container className="my-4">
          <Row className="my-4">
            <Col>
              <h4 className="page-title">Farmer & Exklusive Lieferanten</h4>
              <Row>
                <Col>
                  <Form className="custom-form" onSubmit={this.onSubmit}>
                    <FormGroup>
                      <Row form>
                        <Col>
                          <div className="form-input">
                            <SearchIcon className="input-icon"/>
                            <Input
                              type="text"
                              name="itam_search"
                              id="item_search"
                              className=""
                              placeholder="Bauernsuche..."
                              onChange={this.onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col>
                <Col className="mobile-full-width-col">
                  <ReferAFarmerModal />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5>
                      <b>Suppliers:</b>
                    </h5>
                  </Col>
                  <Col>
                    <AddSupplierButton />
                  </Col>
                </Row>
                <SuppliersList suppliersFilter={this.state.suppliersFilter} />
              </Card>
            </Col>
          </Row> */}
        </Container>
        <Container>
        <LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
        >
          <CardDeck>
            <Row className="farmer-list-row">
              {orderedFarmers.map(farmer => {
                return (
                  <Col className="farmer-list-item" sm={3}>
                    {this.farmerCard(farmer)}
                  </Col>
                );
              })
              }
            </Row>
          </CardDeck>
          </LoadingOverlay>
        </Container>
      </div>
    );
  }
}

FarmersPage.propTypes = {
  supplier: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllSuppliers }
)(FarmersPage));
