import gql from "graphql-tag";
// Invite supplier
export const ADD_DELIVERY_ADDRESS_MUTATION = gql`
  mutation addDelivery_address($delivery_address: Delivery_addressInput!) {
    addDelivery_address(delivery_address: $delivery_address) {
      id
      name
      street_address
      street_address_2
      zip_code
      city
      is_active
      country
    }
  }
`;
