import React, { Component } from "react";
import {
  Button,
  Card,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  NavLink,
  Alert,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetForgottenPassword } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import Swal from "sweetalert2";

import logoTitle from "../../assets/NewHelloFarmerLogo.svg";
import { ReactComponent as FieldCheckGray } from "../../assets/FieldCheckGray.svg";
import { ReactComponent as FieldCheckGreen } from "../../assets/FieldCheckGreen.svg";
import { ReactComponent as FieldCheckRed } from "../../assets/FieldCheckRed.svg";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/PasswordIcon.svg";

class PasswordResetPage extends Component {
  state = {
    modal: false,
    resetToken: "",
    email: "",
    newPassword: "",
    newPasswordRepeat: "",
    msg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidMount() {
    const resetToken = this.props.match.params.reset_token;
    this.setState({ resetToken: resetToken });
    console.log(this.props.match.params.reset_token)
  }

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { resetToken, email, newPassword, newPasswordRepeat } = this.state;
    
    this.props.resetForgottenPassword(resetToken, email, newPassword, newPasswordRepeat);
    Swal.fire({
      text: "Passwort geändert",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  getFormCheckIcon = (field) => {
    switch (field){
      case 1:
        var re = /(?=.*[a-z])/ ;
        if (this.state.newPassword == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.newPassword)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 2:
        var re = /(?=.*[A-Z])/;
        if (this.state.newPassword == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.newPassword)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 3:
        var re = /^.{8,}/;
        if (this.state.newPassword == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.newPassword)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 4:
        var re = /(.*\W.*)/;
        if (this.state.newPassword == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.newPassword)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }     
    }
  }

  isReadyForReset = () => {
    var re1 = /(?=.*[a-z])/;
    var re2 = /(?=.*[A-Z])/;
    var re3 = /^.{8,}/;
    var re4 = /(.*\W.*)/;

    if ((this.state.newPassword !== this.state.newPasswordRepeat) || !(re1.test(this.state.newPassword)) || !(re2.test(this.state.newPassword)) || !(re3.test(this.state.newPassword)) || !(re4.test(this.state.newPassword))){
      return false
    } else {
      return true
    }
  }

  render() {
    var isReadyForReset = this.isReadyForReset()

    return (
      <div className="">
        <Container className="login-page center">
          <div className="mb-4">
            <img src={logoTitle} alt="Logo" />
          </div>

          <div className="panel">
            <h2 className="center">Passwort zurücksetzen</h2>
            <p className="greyed-out">Geben Sie ein neues Passwort ein</p>
            <Form className="p-4 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PasswordIcon className="input-icon"/>
                  <Input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    placeholder="Neues Passwort"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PasswordIcon className="input-icon"/>
                  <Input
                    type="password"
                    name="newPasswordRepeat"
                    id="newPasswordRepeat"
                    placeholder="Neues Passwort wdh."
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <Row id="password-validation" className="px-2">
                  <Col>
                    <p className="form-check-field">{this.getFormCheckIcon(1)}<span className="disabled-text">Mindestens 1 Kleinbuchstabe</span></p>
                    <p className="form-check-field">{this.getFormCheckIcon(2)}<span className="disabled-text">Mindestens 1 Großbuchstabe</span></p>
                  </Col>
                  <Col>
                    <p className="form-check-field">{this.getFormCheckIcon(3)}<span className="disabled-text">Mindestens 8 Zeichen insgesamt</span></p>
                    <p className="form-check-field">{this.getFormCheckIcon(4)}<span className="disabled-text">Mindestens 1 Zahl oder Sonderzeichen (z. B. @#$)</span></p>
                  </Col>
                </Row>
                <Button
                  disabled={!isReadyForReset}
                  className="btn-gradient"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  PASSWORT ZURÜCKSETZEN
                </Button>
              </FormGroup>
            </Form>
            {/* <a className="signup-link" href="/login">
              <CardFooter>
                <span className="greyed-out">Zurück zum </span>
                <span className="underline">Login</span>
              </CardFooter>
            </a> */}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(
  mapStateToProps,
  { resetForgottenPassword, clearErrors }
)(PasswordResetPage);