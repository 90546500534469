import gql from "graphql-tag";
export const CHANGE_USER_PHONE_MUTATION = gql`
  mutation changeUserPhone($new_phone: String!) {
    changeUserPhone(new_phone: $new_phone) {
      name
      email
      role
      region
      language
      profile_picture
      feed {
        ... on FeedItem {
          id
          creator
          receiver
          type
          sourceId
          creation_date
        }
      }
      delivery_address
      telephone
      tax_number
      tax_id
      notification_preferences {
        order_updates
        item_request_updates
        email_notifications
        sms_notifications
        browser_notifications
      }
    }
  }
`;