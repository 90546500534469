import gql from "graphql-tag";

export const CREATE_COUPON_MUTATION = gql`
  mutation createCoupon($code: String, $discount: String, $discount_value: Float) {
    createCoupon(code: $code, discount: $discount, discount_value: $discount_value) {
      id
      code
			value_off
			pct_off
			redeemers
			is_active
    }
  }
`;