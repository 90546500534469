import React, { Component } from "react";
import {
  Button,
  Card,
  CardFooter,
  Row, Col,
  Container,
  NavLink,
  Alert
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sendPasswordResetEmail } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoTitle from "../../assets/HelloFarmerLogo.png";

class ConfirmOrderPage extends Component {
	componentDidMount() {
    const orderId = this.props.match.params.id;
  }

  state = {
    
  };

  static propTypes = {
    
  };

  render() {
    return (
      <div className="confirm-order">
        <Container className="login-page center">
          <div className="mb-4">
            <img src={logoTitle} alt="Logo" />
          </div>

          <Card className="panel">
            <h3 className="center mt-4">Bestellung bestätigen</h3>

						<Row className="mb-4">
							<Col>
							<p className="greyed-out mb-4 pl-1 pr-1">Bestellung Nr. {this.props.match.params.id} bestätigen oder ablehnen</p>
							<Button className="mr-4">Bestätigen</Button>
							<Button className="btn-white">Ablehnen</Button>
							</Col>
						</Row>
            
            
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default connect(
  mapStateToProps,
  {  }
)(ConfirmOrderPage);
