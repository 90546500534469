import React, { Component } from "react";
import { Container } from "reactstrap";
// import { Link } from "react-router-dom";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="page" id="not_found">
        <Container>
          <h1 className="mb-5">
            <b>Seite nicht gefunden</b>
          </h1>
          <p>Fehlercode: 404</p>
          <h3>Wir können die Seite nicht finden, die du suchst</h3>
        </Container>
      </div>
    );
  }
}

export default NotFoundPage;
