import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Alert
} from "reactstrap";

import Swal from "sweetalert2";

import { ReactComponent as OrderSuccessIcon } from "../../../../../assets/orderSuccessIcon.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

import ChangeDeliveryAddressButton from "../market/ChangeDeliveryAddressButton";
import StripeContainer from "../payments/StripeContainer"
import PaypalButton from "../payments/Paypal";

import { Image } from "cloudinary-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import classnames from "classnames";
import history from "../../../../../helper/history";

import { getDelivery_addresses } from "../../../../../actions/delivery_addressActions";
import { getMembership } from "../../../../../actions/membershipActions";
import { logout } from "../../../../../actions/authActions";

class SubscribeToMembershipModal extends Component {
  componentDidMount() {
    this.props.getDelivery_addresses();
    this.props.getMembership();
  }

  state = {
    modal: false,
    imageUrl: null,
    imageAlt: null,
    msg: null
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { membership } = this.props;

    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "CREATE_MEMBERSHIP_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    if (membership !== prevProps.membership) {
      if (membership?.new_membership?.type){
        history.push("/membership-success");
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  activeDeliveryAddress = (delivery_addresses, user_delivery_address) => {
    var active_delivery_address = null;
    delivery_addresses.forEach(deliv_address => {
      if (deliv_address.is_active == true) {
        active_delivery_address = deliv_address;
      }
    });
    return active_delivery_address;
  };

  paypalContainer = (isReadyForTransaction) => {
    if(isReadyForTransaction){
      return (
        <div id="paypal-gateway-div" className={classnames(
          {
            disabled: isReadyForTransaction == false
          }
        )}>
          <PaypalButton cartTotal={9.99} type={"membership"}/>
        </div>
      )
    }
  }

  isReadyForTransaction = (deliveryAddress) => {
    if (deliveryAddress == null){
      return false
    } else {
      return true
    }
  }

  alertForGuest = () => {
    Swal.fire({
      title: "Bitte registriere Dich um Premium Mitglied zu werden",
      icon: "warning",
      iconColor:'#C25982',
      confirmButtonColor: "#C25982",
      confirmButtonText: "Jetzt registrieren"
    }).then(result => {
      if (result.value) {
        this.props.logout();
        history.push("/register");
      }
    });
  }

  getToggler = (isGuest, source, discount) => {
    if(source == "dashboard"){
      return (
        <div>
        <Image
          publicId="https://res.cloudinary.com/dqzo9yjly/image/upload/v1643599327/news/headliners/hdnuz7do4yxhabmksu0s.png"
					width="100%"
          className="hide-on-mobile"
          onClick={!isGuest ? this.toggle : this.alertForGuest}
        /> 
        <Image
          publicId="https://res.cloudinary.com/dqzo9yjly/image/upload/v1643599327/news/headliners/hdnuz7do4yxhabmksu0s.png"
					width="100%"
          className="hide-on-desktop"
          onClick={!isGuest ? this.toggle : this.alertForGuest}
        />
        </div>
      )
    } else if(source == "checkout"){
      var deliveryCost = 0;
      console.log(this.props.isFirstOrder)
      if (!this.props.isFirstOrder){
        deliveryCost = 5
      }

      return (
        <div>
          <p className="mb-0 dark-grey center">
            Du kannst <b>{this.props.t('currencySymbol')}{(discount + deliveryCost).toFixed(2)}</b> mit HelloFarmer Premium sparen. <a className="hellofarmer-premium-link" onClick={this.toggle}>Klicke hier</a>
          </p>
        </div>
      )
    }
  }

  showPaymentGatewayIfNotYetMember = (membership, active_delivery_address, isReadyForTransaction, deliveryPreferences) => {
    if (membership.type){
      return (
        <div className="already-a-member-modal">
          <OrderSuccessIcon />
					<h4 className="dark-grey mb-3">Du bist bereits Premium Mitglied</h4>
        </div>
      )
    } else {
      return (
        <div>
          {this.state.msg ? (
            <Alert color="danger">
              {this.state.msg}
            </Alert>
            ) : null}
            <div className="align-left">
              <p>- 20% Nachlass auf unsere Farmprodukte</p>
              <p>- Kostenfreie Lieferung</p>
              <p>- Keine Mindestlaufzeit und automatische Verlängerung</p>
            </div>
                <h5 className="mb-3">Rechnungsadresse</h5>
                <Row>
                  <Col className="align-left ml-1">
                    {active_delivery_address ? (
                      <div>
                        <p className="mb-1">{active_delivery_address?.name}</p>
                        <p className="mb-1">
                          {active_delivery_address?.street_address}
                        </p>
                        <p className="mb-1">{`${active_delivery_address?.city}, ${active_delivery_address?.country}, ${active_delivery_address?.zip_code}`}</p>
                      </div>
                    ) : (
                      <p>Keine</p>
                    )}
                  </Col>
                  <Col className="mr-1">
                    <ChangeDeliveryAddressButton
                      origin="membership-modal"
                      active_delivery_address={active_delivery_address?.id}
                    />
                  </Col>
                </Row>
                <Row className="membership-item my-3">
                  <Col sm={3}>
                    <FontAwesomeIcon
                      icon={faTag}
                    />
                  </Col>
                  <Col className="align-left">
                    <p>Mitgliedschaft</p>
                  </Col>
                  <Col className="align-right">
                    <p className="price">{this.props.t('currencySymbol')}9.99</p>
                  </Col>
                </Row>
                <h5 className="mb-3">Zahlungsdetails</h5>
                <StripeContainer
                  transactionType={"membership"} isActive={isReadyForTransaction} cartTotal={9.99} deliveryPreferences={deliveryPreferences}
                />
                {
                  this.paypalContainer(isReadyForTransaction)
                }
                <p className="mt-2 dark-grey center">Mit deiner Bestellung erklärst du dich mit unseren AGB einverstanden.</p>
        </div>
      )
    }
  }

  render() {
    const { loading } = this.props.membership;
    const { user } = this.props.auth;
    const isGuest = user?.guest
    const membership = this.props.membership
    const delivery_addresses = this.props.delivery_address.deliveryaddresses;
    const active_delivery_address = this.activeDeliveryAddress(
      delivery_addresses,
      user.delivery_address
    );
    var isReadyForTransaction = this.isReadyForTransaction(active_delivery_address)
    const deliveryPreferences = {
      deliveryAddress: {
        name: active_delivery_address?.name,
        street_address: active_delivery_address?.street_address,
        street_address_2: active_delivery_address?.street_address_2,
        zip_code: active_delivery_address?.zip_code,
        city: active_delivery_address?.city,
        country: active_delivery_address?.country
      }
    }

    return (
      <div>
        {this.getToggler(isGuest, this.props.source, this.props.discount)}
        <Modal
          className="subscribe-to-membership-modal"
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
          >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            HelloFarmer Premium abonnieren
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="center dark-grey">
                {this.showPaymentGatewayIfNotYetMember(membership, active_delivery_address, isReadyForTransaction, deliveryPreferences)}
              </Col>
            </Row>
          </ModalBody>
          </LoadingOverlay>
        </Modal>
      </div>
    );
  }
}

SubscribeToMembershipModal.propTypes = {
  delivery_address: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  delivery_address: state.delivery_address,
  auth: state.auth,
  membership: state.membership,
  error: state.error,
});

export default withTranslation()(connect(
  mapStateToProps,
  { logout, getDelivery_addresses, getMembership }
)(withRouter(SubscribeToMembershipModal)));