import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card
} from "reactstrap";

import { Image } from "cloudinary-react";

import ItemsTab from "./ItemsTab";
import MarketListsTab from "./MarketListsTab";
import ItemRequestsTab from "./ItemRequestsTab";

import { getCart } from "../../../../../actions/cartActions";
import { removeItemFromCart } from "../../../../../actions/cartActions";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as AppleIcon } from "../../../../../assets/AppleIcon.svg"
import { ReactComponent as CarrotIcon } from "../../../../../assets/CarrotIcon.svg"
import { ReactComponent as CroissantIcon } from "../../../../../assets/CroissantIcon.svg"
import { ReactComponent as GlassWaterIcon } from "../../../../../assets/GlassWaterIcon.svg"
import { ReactComponent as CoffeeIcon } from "../../../../../assets/CoffeeIcon.svg"
import { ReactComponent as MilkIcon } from "../../../../../assets/MilkIcon.svg"
import { ReactComponent as NoodlesIcon } from "../../../../../assets/NoodlesIcon.svg"
import { ReactComponent as EggFriedIcon } from "../../../../../assets/EggFriedIcon.svg"

class MarketPage extends Component {
  componentDidMount() {
    this.props.getCart();
  }

  state = {
    modal: false,
    activeFilter: null
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  getItemPrice = item => {
    var itemPrice = item.quantity * item.price_7_pct;
    itemPrice = itemPrice.toFixed(2);
    itemPrice = parseFloat(itemPrice);
    return itemPrice;
  };

  getOrderTotal = cart => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    return orderTotal;
  };

  removeCartItem = cartitemId => {
    this.props.removeItemFromCart(cartitemId);
    // this.props.removeItemFromCart(cartitemId);
  };

  setActiveFilter = filter => {
    if (filter !== this.state.activeFilter){
      this.setState({
        activeFilter: filter
      });
    } else {
      this.setState({
        activeFilter: null
      });
    }
  }

  render() {
    const { user } = this.props.auth;
    const isGuest = user?.guest;
    const cart = this.props.cart.cart;
    const filter = this.state.activeFilter;

    return (
      <div className="page">
        <Row className="market-page mx-0">
          <Col id='market-filter-col'>
            {/* <div style={{marginBottom: '1rem'}}>
              <h3>Ready to eat</h3>
              <Row onClick={() => this.setActiveFilter('power-pot-beef')} className={classnames(
							  "market-filter",
                {
                  active: this.state.activeFilter === "power-pot-beef"
                }
              )}>
                <Col>
                  <p>Power pot beef</p>
                </Col>
              </Row>
            <Row onClick={() => this.setActiveFilter('power-pot-chicken')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "power-pot-chicken"
              }
            )}>
              <Col>
                <p>Power pot chicken</p>
              </Col>
            </Row>
            </div> */}
            <h3>{this.props.t('categories')}</h3>
            <Row onClick={() => this.setActiveFilter('obst')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "obst"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <AppleIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('fruits')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('gemüse')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "gemüse"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <CarrotIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('vegetables')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('backwaren')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "backwaren"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <CroissantIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('bakedGoods')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('getränke')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "getränke"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <GlassWaterIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('drinks')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('kaffee')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "kaffee"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <CoffeeIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('coffee')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('milch')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "milch"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <MilkIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('milk')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('nudeln')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "nudeln"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <NoodlesIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('noodles')}</p>
              </Col>
            </Row>
            <Row onClick={() => this.setActiveFilter('eier')} className={classnames(
							"market-filter",
              {
                active: this.state.activeFilter === "eier"
              }
            )}>
              <Col className='market-filter-icon-col'>
                <EggFriedIcon style={{width: 20, height: 20}} />
              </Col>
              <Col>
                <p>{this.props.t('eggs')}</p>
              </Col>
            </Row>
          </Col>
          <Col id='market-items-col'>
            {/* <Container className="my-4 hide-on-mobile"> */}
              {/* <h4 className="page-title">{project ? project.name : "None"}</h4> */}
              {/* <Row>
                <Col>
                  <h4 className="page-title">Markt</h4>  
                </Col> */}
                {/* <Col>
                  <Form className="custom-form" onSubmit={this.onSubmit}>
                    <FormGroup>
                      <Row form>
                        <Col>
                          <div className="linediv">
                            <Input
                            type="text"
                            name="itam_search"
                            id="item_search"
                            className=""
                            placeholder="Produktsuche..."
                            onChange={this.onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col> */}
              {/* </Row> */}
            {/* </Container> */}
            <Container style={{maxWidth: 'none'}}>
              {/* <Nav tabs className="">
                <NavItem className="mobile-right-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Alle Produkte
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Letzte Produkte
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3"
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Einkaufszettel
                  </NavLink>
                </NavItem>
                <NavItem className="mobile-left-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4"
                    })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    Anfordern
                  </NavLink>
                </NavItem> */}
                {/* <NavItem className="mobile-left-margin">
                  <NavLink
                    className="active filter-navlink"
                    onClick={this.toggleModal}
                  >
                    <FilterIcon />
                  </NavLink>
                </NavItem> */}
              {/* </Nav> */}
              <Row id='mobile-market-categories-container' className='hide-on-desktop'>
          <Col>
            <h2>
              Kategorien
            </h2>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Card onClick={() => this.setActiveFilter('obst')} className={classnames(
                  {
                    active: this.state.activeFilter === "obst"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "obst"
                  }
                )}>{this.props.t('fruits')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640237/categories/fruits_nsavlz.png'
                  className="category-image"
                />
              </Card>
              <Card onClick={() => this.setActiveFilter('gemüse')} className={classnames(
                  {
                    active: this.state.activeFilter === "gemüse"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "gemüse"
                  }
                )}>{this.props.t('vegetables')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640237/categories/Veggies_zjnsw4.png'
                  className="category-image"
                />
              </Card>
              <Card onClick={() => this.setActiveFilter('backwaren')} className={classnames(
                  {
                    active: this.state.activeFilter === "backwaren"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "backwaren"
                  }
                )}>{this.props.t('bakedGoods')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/Bread_icglkd.png'
                  className="category-image"
                />
              </Card>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Card onClick={() => this.setActiveFilter('getränke')} className={classnames(
                  {
                    active: this.state.activeFilter === "getränke"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "getränke"
                  }
                )}>{this.props.t('drinks')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/drinks_rdwb3l.png'
                  className="category-image"
                />
              </Card>
              <Card onClick={() => this.setActiveFilter('kaffee')} className={classnames(
                  {
                    active: this.state.activeFilter === "kaffee"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "kaffee"
                  }
                )}>{this.props.t('coffee')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/Coffee_xwha0c.png'
                  className="category-image"
                />
              </Card>
              <Card onClick={() => this.setActiveFilter('milch')} className={classnames(
                  {
                    active: this.state.activeFilter === "milch"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "milch"
                  }
                )}>{this.props.t('milk')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/Milk_qaaaov.png'
                  className="category-image"
                />
              </Card>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Card onClick={() => this.setActiveFilter('nudeln')} className={classnames(
                  {
                    active: this.state.activeFilter === "nudeln"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "nudeln"
                  }
                )}>{this.props.t('noodles')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/Pasta_frcy1g.png'
                  className="category-image"
                />
              </Card>
              <Card onClick={() => this.setActiveFilter('eier')} className={classnames(
                  {
                    active: this.state.activeFilter === "eier"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "eier"
                  }
                )}>{this.props.t('eggs')}</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683640236/categories/eggs_uuyolm.png'
                  className="category-image"
                />
              </Card>
              {/* <Card onClick={() => this.setActiveFilter('eier')} className={classnames(
                  {
                    active: this.state.activeFilter === "eier"
                  }
                )}>
                <p className={classnames(
                  {
                    active: this.state.activeFilter === "eier"
                  }
                )}>Ready to eat</p>
                <Image
                  publicId='https://res.cloudinary.com/dqsl1s6nf/image/upload/v1683645319/categories/WhatsApp_Image_2023-04-19_at_3.55_1_z2bwmp.png'
                  className="category-image"
                />
              </Card> */}
            </div>
          </Col>
        </Row>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 1 ? (
                    <ItemsTab isGuest={isGuest} cart={cart} userRegion={user?.region} areRecent={false} filter={filter} />
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 2 ? (
                    <ItemsTab isGuest={isGuest} cart={cart} userRegion={user?.region} areRecent={true} />
                  ) : null}
                </TabPane>
                <TabPane tabId="3">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 3 ? <MarketListsTab isGuest={isGuest}/> : null}
                </TabPane>
                <TabPane tabId="4">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 4 ? <ItemRequestsTab isGuest={isGuest}/> : null}
                </TabPane>
              </TabContent>
            </Container>
          </Col>
          {/* <Col className="market-page-cart">
            <p>
              Warenkorb Zwischensumme:{" "}
              <span className="subtotal-price">
                EUR {this.getOrderTotal(cart).toFixed(2)}
              </span>
            </p>
            <Link
              exact
              to="/checkout"
              
            >
              <Button>Prüfen und senden</Button>
            </Link>
            <ListGroup>
              {cart.items?.map(item => {
                return (
                  
                  <div className="market-list-item">
                    <ListGroupItem className="justify-content-between">
                      
                      <Link
                        className="market-list-item"
                        exact
                        to={`/items/${item.item}`}
                        
                      >
                        <div className="item-icon-container">
                        <Image publicId={`items/${item.image}`} />
                        </div>
                        
                      </Link>
                      <Button onClick={() => this.removeCartItem(item.id)}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => this.removeCartItem(item.id)}
                          className="remove-cart-item-btn"
                        />
                      </Button>
                      <span className="price">
                        EUR {this.getItemPrice(item).toFixed(2)}
                      </span>
                    </ListGroupItem>
                  </div>
                  
                );
              })}
            </ListGroup>
          </Col> */}
        </Row>
      </div>
    );
  }
}

MarketPage.propTypes = {
  getCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cart: state.cart,
  auth: state.auth
});

export default withTranslation()(connect(
  mapStateToProps,
  { removeItemFromCart, getCart }
)(MarketPage));
