  import {
  GET_NEWS,
  GET_SINGLE_NEWS_ITEM,
  NEWS_LOADING
} from "../actions/types";

const initialState = {
  news: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEWS_LOADING:
      return {
        ...state,
        loading: false
      };
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
        loading: false
      };
    case GET_SINGLE_NEWS_ITEM:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    default:
      return state;
  }
}