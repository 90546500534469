import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { createMarketlist } from "../../../../../actions/marketListActions";

import PropTypes from "prop-types";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class AddMarketListButton extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newMarketList = {
      name: this.state.name
    };
    // Add market list
    this.props.createMarketlist(newMarketList);
    // Close Modal
    this.toggle();
    Swal.fire({
      title: "Einkaufszettel erstellt",
      text: "Die Einkaufszettel " + this.state.name + " wurde erstellt.",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  render() {
    const isGuest = this.props.isGuest;

    return (
      <div id='add-marketlist-button' className="hinzufügen-modal">
        <Button
          disabled={isGuest}
          color="dark"
          className="btn-list-item"
          style={{ marginBottom: "1rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>          
          {this.props.t('createMarketList')}
        </Button>
        <Modal
          className="add-market-list-modal"
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
          {this.props.t('createMarketList')}
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Label for="project">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="craftsman"
                        placeholder={this.props.t('yourName')}
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  {this.props.t('create')}
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  block
                >
                  {this.props.t('cancel')}
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddMarketListButton.propTypes = {
  createMarketlist: PropTypes.func.isRequired,
  marketlist: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  craftsman: state.craftsman,
  marketlist: state.marketlist
});

export default withTranslation()(connect(
  mapStateToProps,
  { createMarketlist }
)(AddMarketListButton));
