import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import Swal from "sweetalert2";

import supplier from "../../../../../assets/supplier.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../../helper/history";

import Avatar from "@material-ui/core/Avatar";
import { Image } from "cloudinary-react";
import { getSingleOrder } from "../../../../../actions/orderActions";
import { changeOrderStatus } from "../../../../../actions/orderActions";
import { createMarketlistFromOrder } from "../../../../../actions/marketListActions";
import { addItemToCart } from "../../../../../actions/cartActions";
import { getCart } from "../../../../../actions/cartActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./invoices/Invoice";
import invoice from "./invoices/invoiceData";

import SingleOrderItemModal from "./SingleOrderItemModal";

class SingleOrderPage extends Component {
  componentDidMount() {
    const orderId = this.props.match.params.id;
    this.props.getSingleOrder(orderId);
  }

  state = {
    modal: false,
    singleItemObject: {}
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle = (item) => {
    this.setState({
      modal: !this.state.modal, singleItemObject: item
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  aufgabenModuleItem = aufgabe => {
    return (
      <tr>
        <td>{/* Beschreibung */}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  statusField = status => {
    switch (status) {
      case 0:
        return <span className="order-status-yellow">Verarbeitung</span>;
      case 1:
        return <span className="order-status-red">Abgesagt</span>;
      case 2:
        return <span className="order-status-yellow">Auslieferung</span>;
      case 3:
        return <span className="order-status-red">Unzustellbar</span>;
      case 4:
        return <span className="order-status-green">Bestätigt</span>;
    }
  };

  deliveryNoteOrInvoice = status => {
    if (status == 4){
      return "Rechnung"
    } else {
      return "Lieferschein"
    }
  }

  getShortOrderId = orderId => {
    var shortOrderId = ""
    shortOrderId = orderId.substring(0,6) + "..."
    return shortOrderId 
  }

  orderDateField = orderDate => {
    if (orderDate){
      var shortOrderDate = orderDate.split("T")[0]
      var splitOrderDate = shortOrderDate.split("-")
      const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
      return date
    }
  }

  deliveryDateField = delivery_date => {
    if (delivery_date){
      if (delivery_date.charAt(4) == "-") {
        var shortOrderDate = delivery_date.split("T")[0]
        var splitOrderDate = shortOrderDate.split("-")
        const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
        return date
      } else {
        var splitDeliveryDate = delivery_date.split(' ')
        var day = splitDeliveryDate[2];
        var month = splitDeliveryDate[1];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months.indexOf(month);
        month += 1;
        var year = splitDeliveryDate[3];
        const date = day + '.' + month + '.' + year
        return date
      }
    }
  }

  discountTableRow = (discount) => {
    if (discount != 0 && discount != null) {
      return (
        <tr
          className="single-order-list-item dark-grey hide-on-mobile"
        >
          <td className="pl-4">Gutschein</td>
          <td className="hide-on-mobile"></td>
          <td></td>
          <td className="hide-on-mobile"></td>
          <td className="hide-on-mobile"></td>
          <td className="hide-on-mobile"></td>
          <td className="pr-4">-{this.props.t('currencySymbol')}{discount?.toFixed(2)}</td>
        </tr>
      )
    }
  }

  createMarketListFromOrder = (orderId) => {
    this.props.createMarketlistFromOrder(orderId);
    Swal.fire({
      text: "Einkaufszettel erstellt",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  }

  addOrderItemsToCart = (orderItems, cart) => {
    var cartContainsOrderItem = null;
    orderItems.forEach(orderItem => {
      if (cart.items.some(item => item.item === orderItem.item)) {
        cartContainsOrderItem = true;
      }
    })
    if (cartContainsOrderItem) {
      Swal.fire({
        text: "Mindestens ein Artikel aus Ihrem Bestellung befindet sich bereits im Warenkorb",
        icon: "error",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    } else {
      orderItems.forEach(item => {
        const newCartitem = {
          image: item.image,
          item: item.item,
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.price,
          price_7_pct: item.price_7_pct,
          supplier: item.supplier,
          supplier_name: item.supplier_name,
          unit: item.unit
        };

        this.props.addItemToCart(newCartitem);

      });
      Swal.fire({
        text: "Artikeln in den Warenkorb abgelegt",
        icon: "success",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    }
  }

  onOrderStatusChange = (orderId, status) => {
    this.props.changeOrderStatus(orderId, status)
  }

  render() {
    const { user } = this.props.auth;
    const isAdmin = user?.admin;
    const aufgaben = [];
    const suppliers = [];
    const order = this.props.order;
    const deliveryNoteOrInvoice = this.deliveryNoteOrInvoice(order.status);
    const variableFileName = `hellofarmer_${deliveryNoteOrInvoice.toLowerCase()}.pdf`
    const { cart } = this.props.cart;

    return (
      <div id='single-order-page' className="page">
        <Container className="single-order-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
          />
          <div id='selected-order-card'>
            <div id='selected-order-header-container' style={{display: 'flex', flexDirection: 'row', border: '1px solid #DEDEDE', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.75rem',paddingRight: '0.75rem',  borderRadius: 8, marginBottom: '1.5rem'}}>
              <div>
                <p id='selected-order-header' style={{fontSize: 26, fontWeight: 'bold', color: 'black', marginBottom: 0}}>
                  Bestellung
                </p>
                <p id='selected-order-number' style={{fontSize: 18, color: '#858585', marginBottom: '0.4rem'}}>
                  #{order.order_no}
                </p>
              </div>
              <p id='selected-order-status' style={{marginLeft: 'auto', marginTop: 5}}>
                {this.statusField(order.status)}
              </p>
            </div>
        <div id='selected-order-items'>
        {order.items?.map(item => {
          console.log(item)
          return (
            <Row style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
              <Col style={{maxWidth: '35%', paddingRight: 0}}>
                <Image publicId={item.image} />
              </Col>
              <Col style={{maxWidth: '45%', padding: 0}}>
                <p style={{fontSize: 16, fontWeight: 'bold', color: 'black', marginBottom: 0}}>{item.item_name}</p>
                <Row>
                  <Col>
                    <p className='label'>Packmass: <span className='value'>{item.unit}</span></p>
                    <p className='label'>Bestellmenge: <span className='value'>{item.quantity_accepted ? item.quantity_accepted : 'N/A'}</span></p>
                    <p className='label'>Erhaltene Menge: <span className='value'>{item.quantity_received ? item.quantity_received : 'N/A'}</span></p>
                    <p className='label'>Preis: <span className='value'>{this.props.t('currencySymbol')}{item.price.toFixed(2)}</span></p>
                  </Col>
                </Row>              
              </Col>
              <Col style={{maxWidth: '20%'}}>
                <p style={{textAlign: 'right', fontSize: 14, fontWeight: 'bold', color: '#C25982'}}>{this.props.t('currencySymbol')}{item.price.toFixed(2)}</p>
              </Col>
            </Row>
          )
        })}
        </div>
        <div id='selected-order-details-container' style={{marginBottom: '1rem'}}>
          <h3>
            Bestellübersicht
          </h3>
          <div className='label-value'>
          <p className='label'>
            Artikel
          </p>
          <p className='value' style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}{order.total_price ? order.total_price.toFixed(2) : `${this.props.t('currencySymbol')}0.00`}
          </p>
        </div>
        <div className='label-value' style={{borderBottom: '2px solid #A9CB59', paddingBottom: '0.25rem', marginBottom: '0.75rem'}}>
          <p className='label'>
            Lieferung
          </p>
          <p className='value' style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}5
          </p>
        </div>
        <div className='label-value'>
          <h3>
            Bestellübersicht
          </h3>
          <h3 style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}{order.total_price ? (order.total_price + 5).toFixed(2) : `${this.props.t('currencySymbol')}0.00`}
          </h3>
        </div>
        </div>
        <div style={{textAlign: 'center', marginBottom: '1rem'}}>
          <Row style={{marginBottom: '1rem'}}>
            <Col>
            <Button style={{width: '80%'}} onClick={() => this.addOrderItemsToCart(order?.items, cart)}>
              Neu bestellen
            </Button>
            </Col>
          </Row>
          <Row>
            <Col>
            <Button onClick={() => this.createMarketListFromOrder(order.id)} style={{backgroundColor: 'white', color: '#C25982', borderWidth: 2, width: '80%'}}>
              Einkaufszettle
            </Button>
            </Col>
          </Row>
        </div>
        <div style={{borderBottom: '1px solid #CECECE', marginLeft: '-1rem', marginRight: '-1rem', marginBottom: '2rem'}}>
        </div>
        <div style={{textAlign: 'center'}}>
          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: '0.5rem'}}>Lieferschein?</p>
          <Button style={{width: '80%'}} className="full-width download-invoice-btn download-lieferschein-btn mb-3">
            <PDFDownloadLink
              document={
                <Invoice
                  title="Lieferschein"
                  invoice={invoice}
                  order_no={order?.order_no}
                  order_date={
                    order?.date ? this.orderDateField(order.date) : "None"
                  }
                  order_price={order?.price?.toFixed(2)}
                  order_7_pct_tax={order?.seven_pct_tax?.toFixed(2)}
                  order_total={order?.total_price?.toFixed(2)}
                  customer_name={order?.delivery_address?.name}
                  customer_street_address={
                    order?.delivery_address?.street_address
                  }
                  customer_zip_code={
                    order?.delivery_address?.zip_code
                  }
                  customer_city={order?.delivery_address?.city}
                  customer_phone={""}
                  customer_email={user?.email}
                  items={order?.items}
                  discount={order?.discount}
                />
              }
              fileName="hellofarmer_lieferschein.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Laden......" : "Herunterladen"
              }
            </PDFDownloadLink>
          </Button>
        </div>
      </div>
        </Container>
      </div>
    );
  }
}

SingleOrderPage.propTypes = {
  getCart: PropTypes.func.isRequired,
  getSingleOrder: PropTypes.func.isRequired,
  changeOrderStatus: PropTypes.func.isRequired,
  createMarketlistFromOrder: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  order: state.order,
  auth: state.auth,
  cart: state.cart
});

export default connect(
  mapStateToProps,
  { getSingleOrder, changeOrderStatus, createMarketlistFromOrder, addItemToCart, getCart }
)(withRouter(SingleOrderPage));
