import axios from "axios";

import { returnErrors } from "./errorActions";

import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SEND_PASSWORD_RESET_EMAIL,
  RESET_FORGOTTEN_PASSWORD
} from "./types";
import client from "../helper/apolloClient";

import { API_LINK } from "../helper/constants";
import { LOGIN } from "../graphql/mutation/auth/login";
import { REGISTER } from "../graphql/mutation/auth/register";
import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from "../graphql/mutation/auth/sendPasswordResetEmail";
import { RESET_FORGOTTEN_PASSWORD_MUTATION } from "../graphql/mutation/auth/resetForgottenPassword";

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  axios
    .get("/api/auth/user", tokenConfig(getState))
    .then(res =>
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
      });
    });
};

// Register User
export const register = ({
  name,
  email,
  telephone,
  password,
  language,
  region,
  city,
  userType,
  sendMarketingMaterial,
  address
}) => dispatch => {
  console.log("SENDMARKETINGMATERIAL", sendMarketingMaterial);
  dispatch({ type: USER_LOADING });
  client
    .mutate({
      variables: {
        name: name,
        email: email,
        telephone: telephone,
        password: password,
        language,
        region,
        city,
        userType,
        sendMarketingMaterial,
        address
      },
      mutation: REGISTER
    })
    .then(res => {
      try {
        console.log(res);
        console.log(API_LINK);
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data.register
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "REGISTER_FAIL"));
        dispatch({
          type: REGISTER_FAIL
        });
      }
    })
    .catch(err => {
      console.log(err);
      console.log(API_LINK);
      console.log(process.env);
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({
        type: REGISTER_FAIL
      });
    });
};

export const login = ({ email, password }) => dispatch => {
  dispatch({ type: USER_LOADING });
  client
    .mutate({
      variables: { email: email, password: password },
      mutation: LOGIN
    })
    .then(res => {
      try {
        console.log(res);
        console.log(API_LINK);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.login
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "LOGIN_FAIL"));
        dispatch({
          type: LOGIN_FAIL
        });
      }
    })
    .catch(err => {
      console.log(err);
      console.log(API_LINK);
      console.log(process.env);
      dispatch(
        returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
      );
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// Logout user
export const logout = () => dispatch =>  {
  dispatch({
    type: LOGOUT_SUCCESS
  });
};

export const sendPasswordResetEmail = email => dispatch => {
  dispatch({ type: USER_LOADING });
  client
    .mutate({
      variables: { email: email },
      mutation: SEND_PASSWORD_RESET_EMAIL_MUTATION
    })
    .then(res => {
      console.log(res)
      try {
        dispatch({
          type: SEND_PASSWORD_RESET_EMAIL,
          payload: res.data.sendPasswordResetEmail
        });   
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "SEND_PASSWORD_RESET_EMAIL_FAIL"));
      }
      if (res.errors){
        dispatch(returnErrors(res.errors[0].message, null, "SEND_PASSWORD_RESET_EMAIL_FAIL"));
      }
    })
    .catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const resetForgottenPassword = (resetToken, email, newPassword, newPasswordRepeat) => dispatch => {
  dispatch({ type: USER_LOADING });
  console.log("TEST MUTATION", resetToken, email, newPassword, newPasswordRepeat)
  client
    .mutate({
      variables: { resetToken: resetToken, email: email, newPassword: newPassword, newPasswordRepeat: newPasswordRepeat },
      mutation: RESET_FORGOTTEN_PASSWORD_MUTATION
    })
    .then(res => {
      dispatch({
        type: RESET_FORGOTTEN_PASSWORD
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// Set up config/headers and token
export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
};
