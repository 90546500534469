import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { inviteSupplier } from "../../../../../actions/supplierActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class AddSupplierButton extends Component {
  state = {
    modal: false,
    name: "",
    email: ""
  };

  static propTypes = {
    inviteSupplier: PropTypes.func.isRequired
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { name, email } = this.state;

    const supplier = {
      name,
      email
    };

    this.props.inviteSupplier(supplier);

    this.toggle();
    Swal.fire({
      title: "Supplier invite sent!",
      text: "You've invited " + this.state.name + " as a supplier",
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="add-supplier-btn btn-modal float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
          disabled
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Farmer hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Add Supplier
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="craftsman"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">E-Mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="craftsman"
                        placeholder="E-Mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { inviteSupplier }
)(AddSupplierButton);
