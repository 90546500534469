import axios from "axios";
import {
  CREATE_MARKET_LIST,
  CREATE_MARKET_LIST_FROM_CART,
  CREATE_MARKET_LIST_FROM_ORDER,
  DELETE_MARKET_LIST,
  ADD_ITEM_TO_MARKETLIST,
  REMOVE_ITEM_FROM_MARKETLIST,
  GET_ALL_MARKET_LISTS,
  GET_SINGLE_MARKET_LIST,
  CHANGE_MARKETLIST_NAME,
  MARKETLISTS_LOADING
} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { CREATE_MARKET_LIST_MUTATION } from "../graphql/mutation/marketLists/createMarketlist";
import { CREATE_MARKET_LIST_FROM_CART_MUTATION } from "../graphql/mutation/marketLists/createMarketlistFromCart";
import { CREATE_MARKET_LIST_FROM_ORDER_MUTATION } from "../graphql/mutation/marketLists/createMarketlistFromOrder";
import { DELETE_MARKET_LIST_MUTATION } from "../graphql/mutation/marketLists/deleteMarketlist";
import { ADD_ITEM_TO_MARKETLIST_MUTATION } from "../graphql/mutation/marketLists/addItemToMarketlist";
import { REMOVE_ITEM_FROM_MARKETLIST_MUTATION } from "../graphql/mutation/marketLists/removeItemFromMarketlist";
import { CHANGE_MARKETLIST_NAME_MUTATION } from "../graphql/mutation/marketLists/changeMarketlistName";

export const createMarketlist = marketlist => dispatch => {
  dispatch({ type: MARKETLISTS_LOADING });
  console.log(marketlist);
  client
    .mutate({
      variables: { marketlist },
      mutation: CREATE_MARKET_LIST_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_MARKET_LIST,
        payload: res.data.createMarketlist
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createMarketlistFromCart = id => dispatch => {
  dispatch({ type: MARKETLISTS_LOADING });
  console.log(id);
  client
    .mutate({
      variables: { cartId: id },
      mutation: CREATE_MARKET_LIST_FROM_CART_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_MARKET_LIST_FROM_CART,
        payload: res.data.createMarketlistFromCart
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createMarketlistFromOrder = id => dispatch => {
  dispatch({ type: MARKETLISTS_LOADING });
  client
    .mutate({
      variables: { orderId: id },
      mutation: CREATE_MARKET_LIST_FROM_ORDER_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_MARKET_LIST_FROM_ORDER,
        payload: res.data.createMarketlistFromOrder
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteMarketlist = marketlist_id => dispatch => {
  dispatch({ type: MARKETLISTS_LOADING });
  client
    .mutate({
      variables: { marketlist_id },
      mutation: DELETE_MARKET_LIST_MUTATION
    })
    .then(res => {
      dispatch({
        type: DELETE_MARKET_LIST,
        payload: res.data.deleteMarketlist
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addItemToMarketlist = (marketlist_id, cartitem) => (
  dispatch,
  getState
) => {
  dispatch({ type: MARKETLISTS_LOADING });
  console.log(marketlist_id);
  console.log(cartitem);
  client
    .mutate({
      variables: { marketlist_id, cartitem },
      mutation: ADD_ITEM_TO_MARKETLIST_MUTATION
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: ADD_ITEM_TO_MARKETLIST,
        payload: res.data.addItemToMarketlist
      });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeItemFromMarketlist = (marketlist_id, marketlist_item_id) => (
  dispatch,
  getState
) => {
  dispatch({ type: MARKETLISTS_LOADING });
  client
    .mutate({
      variables: { marketlist_id, marketlist_item_id },
      mutation: REMOVE_ITEM_FROM_MARKETLIST_MUTATION
    })
    .then(res => {
      dispatch({
        type: REMOVE_ITEM_FROM_MARKETLIST,
        payload: res.data.removeItemFromMarketlist
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const changeMarketlistName = (marketlist_id, new_name) => (
  dispatch,
  getState
) => {
  dispatch({ type: MARKETLISTS_LOADING });
  client
    .mutate({
      variables: { marketlist_id, new_name },
      mutation: CHANGE_MARKETLIST_NAME_MUTATION
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: CHANGE_MARKETLIST_NAME,
        payload: res.data.changeMarketlistName
      });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};


export const getAllMarketlists = () => dispatch => {
  dispatch({ type: MARKETLISTS_LOADING });
  client
    .query({
      query: gql`
        query marketlists {
          getAllMarketlists {
            id
            name
            items {
              ... on Cartitem {
                item
                image
                item_name
                quantity
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: GET_ALL_MARKET_LISTS,
        payload: res.data.getAllMarketlists
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getSingleMarketlist = id => dispatch => {
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleMarketlist($id: String!) {
          getSingleMarketlist(id: $id) {
            id
            name
            items {
              ... on Cartitem {
                item
                image
                item_name
                quantity
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: GET_SINGLE_MARKET_LIST,
        payload: res.data.getSingleMarketlist
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};