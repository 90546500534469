import React, { Component } from "react";
import { Container, Button } from "reactstrap";

import history from "../../../../helper/history";

// import { Link } from "react-router-dom";
import { ReactComponent as OrderSuccessIcon } from "../../../../assets/OrderSuccessIcon1.svg"

class NotFoundPage extends Component {
  render() {
    return (
      <div className="page">
        <Container className="order-success-page">
					<div>
						<OrderSuccessIcon style={{marginBottom: '1.5rem'}} />
						<h4 style={{fontSize: 32, fontWeight: '600', color: 'black'}}>Vielen Dank für Ihre Bestellung</h4>
            <p style={{fontSize: 20, fontWeight: '400', color: 'black'}}>Ihre Lebensmittel sind in Vorbereitung!</p>
						<Button onClick={() => history.push("/market")} style={{fontSize: 14, fontWeight: 'bold', color: 'white', width: '20%'}}>Zurück zur Startseite</Button>
					</div>
        </Container>
      </div>
    );
  }
}

export default NotFoundPage;