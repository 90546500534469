import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@material-ui/core/Avatar";

import SuppliersList from "./SuppliersList";
import AddSupplierButton from "./AddSupplierButton";

import history from "../../../../../helper/history";

import { getSingleSupplier } from "../../../../../actions/supplierActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Image } from "cloudinary-react";

class SingleSupplierPage extends Component {
  componentDidMount() {
    const supplierId = this.props.match.params.id;
    this.props.getSingleSupplier(supplierId);
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  aufgabenModuleItem = aufgabe => {
    return (
      <tr>
        <td>{/* Beschreibung */}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  render() {
    const aufgaben = [];
    const supplier = this.props.supplier;
    return (
      <div>
        <Container className="single-supplier-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
            className="back-arrow float-left mr-3"
          />
          <h4 className="page-title my-4">Supplier</h4>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5>
                      <b>Überblick</b>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="supplier-img-col">
                    {supplier.avatar ? (
                      <Image
                        publicId={`suppliers/${supplier.avatar}`}
                        width="15%"
                        className=""
                      />
                    ) : (
                      <Avatar src="../../assets/DefaultProfilePic.svg" />
                    )}
                  </Col>
                  <Table className="custom-table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{supplier.name ? supplier.name : "NA"}</td>
                        {/* <td>{supplier.name}</td> */}
                      </tr>
                      <tr>
                        <td>Adresse</td>
                        <td>
                          {supplier.address?.street_address
                            ? supplier.address?.street_address
                            : "NA"}
                        </td>
                        {/* <td>{supplier.address}</td> */}
                      </tr>
                      <tr>
                        <td>Adresse 2</td>
                        <td>
                          {supplier.address?.street_address_2
                            ? supplier.address?.street_address_2
                            : "NA"}
                        </td>
                        {/* <td>{supplier.address2}</td> */}
                      </tr>
                      <tr>
                        <td>Postleitzahl</td>
                        <td>
                          {supplier.address?.zip_code
                            ? supplier.address?.zip_code
                            : "NA"}
                        </td>
                        {/* <td>{supplier.zip_code}</td> */}
                      </tr>
                      <tr>
                        <td>Land</td>
                        <td>
                          {supplier.address?.country
                            ? supplier.address?.country
                            : "NA"}
                        </td>
                        {/* <td>{supplier.country}</td> */}
                      </tr>
                      <tr>
                        <td>Stadt</td>
                        <td>
                          {supplier.address?.city
                            ? supplier.address?.city
                            : "NA"}
                        </td>
                        {/* <td>{supplier.city}</td> */}
                      </tr>
                      <tr>
                        <td>Telefon</td>
                        <td>
                          {supplier.telephone ? supplier.telephone : "NA"}
                        </td>
                        {/* <td>{supplier.phone_number}</td> */}
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{supplier.email ? supplier.email : "NA"}</td>
                        {/* <td>{supplier.email}</td> */}
                      </tr>
                      <tr>
                        <td>Website</td>
                        <td>{supplier.website ? supplier.website : "NA"}</td>
                        {/* <td>{supplier.website}</td> */}
                      </tr>
                      <tr>
                        <td>SteuerNr.</td>
                        <td>{supplier.tax_id ? supplier.tax_id : "NA"}</td>
                        {/* <td>{supplier.tax_code}</td> */}
                      </tr>
                      <tr>
                        <td>Bio</td>
                        <td>{supplier.bio ? supplier.bio : "NA"}</td>
                        {/* <td>{supplier.description}</td> */}
                      </tr>
                    </tbody>
                  </Table>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

SingleSupplierPage.propTypes = {
  getSingleSupplier: PropTypes.func.isRequired,
  supplier: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier
});

export default connect(
  mapStateToProps,
  { getSingleSupplier }
)(withRouter(SingleSupplierPage));
