import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

/* eslint-disable-next-line */
// import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  // NavItem,
  NavLink,
  Row,
  Col,
  // InputGroup,
  Input,
  // InputGroupAddon,
  // Button
} from "reactstrap";
import "../../../../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "../../../../helper/history";
import { logout } from "../../../../actions/authActions";

import { getMembership } from "../../../../actions/membershipActions"

import CartModal from "./CartModal";
import NotificationsModal from "./NotificationsModal";
// import UserRegionModal from "./UserRegionModal";
import AccountModal from "./AccountModal";
import Logout from "../../../authentication/Logout";
// import { Link } from "react-router-dom";

import logoTextTitle from "../../../../assets/NewHelloFarmerLogo.svg";

// import premiumIcon from "../../../../assets/PremiumIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { faCog } from "@fortawesome/free-solid-svg-icons";

// import { ReactComponent as DashboardSvg } from "../../../../assets/DashboardIcon.svg";
// import { ReactComponent as MarketSvg } from "../../../../assets/MarketIcon.svg";
// import { ReactComponent as OrdersSvg } from "../../../../assets/OrdersIcon.svg";
// import { ReactComponent as HelpSvg } from "../../../../assets/HelpIcon.svg";
// import { ReactComponent as FarmersSvg } from "../../../../assets/FarmersIcon.svg";
// import { ReactComponent as ExpressShippingBanner } from "../../../../assets/NextDayShippingBanner.svg";

import { ReactComponent as SettingsIcon } from "../../../../assets/SettingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/SupportIcon.svg";

import SettingsModal from "./SettingsModal";

class AppNavbar extends Component {
  
  componentDidMount() {
    this.props.getMembership();
  }

  state = {
    isOpen: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getMembership: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  logout = () => {
    this.props.logout();
    history.push("/register");
  }

  render() {
    // const { isAuthenticated } = this.props.auth;
    const { user } = this.props.auth;
    const isAdmin = user?.admin;
    const isGuest = user?.guest;
    // const isFarmer = user?.farmer;
    // const userRole = this.props.userRole;
    // const membership = this.props.membership

    return (
      <div>
        <Navbar fixed="top" expand="sm" className="mb-1 navbar-div primary-nav bottom-edge-shadow">
          <img onClick={() => history.push('/')} id="desktop-navbar-logo" src={logoTextTitle} alt="Logo-long" className="Logo-long" style={{cursor: 'pointer'}} />
          {/* <NavbarBrand onClick={this.props.toggleSidebar}>
            <div className="toggle-sidebar-btn svg-container ml-auto">
              <FontAwesomeIcon icon={faBars} />
            </div>
          </NavbarBrand> */}
          <NavLink id="mobile-navbar-logo" className="mobile-nav-item" href="/">
            <img src={logoTextTitle} alt="Logo-long" className="Logo-long" />
          </NavLink>
          <div className="mobile-nav-item">
            <CartModal/>
          </div>
          {/* <div className="mobile-nav-item">
            <UserRegionModal />
          </div> */}
          <div className="mobile-nav-item">
            <NotificationsModal />
          </div>
          {/* {membership.type ? 
          <div className="mobile-nav-item premium-icon">
            <img src={premiumIcon} alt="Premium Icon"/>
          </div> : null} */}
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {/* {isAdmin ? 
              <NavLink id="admin-navbar-link" href="/admin">
                ADMIN
          	  </NavLink> : null
            }
            {isFarmer ? 
              <NavLink id="admin-navbar-link" href="/farmer">
                FARMER
          	  </NavLink> : null
            } */}
            <Row id='navbar-search-item' style={{ height: 36, width: '50%', border: '1px solid #9A9A9A', borderRadius: 6, marginRight: '0.5rem' }}>
              <Col id='navbar-search-item-col-1' style={{maxWidth: '90%', textAlign: 'left', padding: 0}}>
                <Input placeholder={this.props.t('search')} style={{height: '100%', boxShadow: 'none' }}/>
              </Col>
              <Col id='navbar-search-item-col-2' style={{maxWidth: '10%', textAlign: 'right', paddingTop: 4}}>
                <FontAwesomeIcon icon={faSearch} style={{color: '#9A9A9A'}} />
              </Col>
            </Row>
            <div id='navbar-notifications-item' className='hide-on-mobile' style={{height: 36, width: 30, border: '1px solid #9A9A9A', borderRadius: 6, marginRight: '0.5rem', textAlign: 'center'}}>
              <NotificationsModal />
            </div>
            <div id='navbar-settings-item' className='hide-on-mobile' style={{height: 36, width: 30, border: '1px solid #9A9A9A', borderRadius: 6, textAlign: 'center', marginRight: '2.25rem'}}>
              <SettingsModal toggleModal={this.toggle}/>
            </div>
            <div id='navbar-account-item' className='hide-on-mobile' style={{height: 36}}>
              <AccountModal />
            </div>
            <div id='navbar-cart-item' style={{height: 46, marginLeft: 'auto', marginTop: -5}}>
              <CartModal />
            </div>
            <NavLink href="/settings" className='hide-on-desktop' style={{padding: 0, paddingTop: 2.5, height: 40, paddingTop: '0.5rem', textAlign: 'center'}}>
              <SettingsIcon />
              <span className='mobile-navbar-item-label'>{this.props.t('settings')}</span>
            </NavLink>
            {isAdmin &&
              <NavLink href="/admin" className='hide-on-desktop' style={{padding: 0, paddingTop: 2.5, height: 40, paddingTop: '0.5rem', textAlign: 'center'}}>
                <SettingsIcon />
                <span className='mobile-navbar-item-label'>{this.props.t('adminDashboard')}</span>
              </NavLink>
            }
            <NavLink href="/help" className='hide-on-desktop' style={{padding: 0, paddingTop: 2.5, height: 40, paddingTop: '0.5rem', textAlign: 'center'}}>
              <SupportIcon id='mobile-navbar-settings-icon' />
              <span className='mobile-navbar-item-label'>{this.props.t('support')}</span>
            </NavLink>
            <NavLink className='hide-on-desktop' style={{padding: 0, paddingTop: 2.5, height: 40, paddingTop: '0.5rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
              <Logout />
            </NavLink>
            <Nav navbar>
              {/* <ExpressShippingBanner className="express-shipping-svg"/> */}
              {/* <NavItem className="ml-2">
                <a
                  href="https://www.helpdocs.io/"
                  target="_blank"
                  className="nav-link"
                >
                  <NavLink className="nav-link" to="/help">
                    Hilfe
                  </NavLink>
                </a>
              </NavItem> */}
              {isGuest ? 
                <NavLink className="mobile-navbar-register mobile-nav-item" href="/" onClick={() => this.logout()}>
                  <Row>
                    <Col>
                      {this.props.t('toRegister')}
                    </Col>
                  </Row>
                </NavLink> : null
              }
              {/* <NavLink className="dashboard-icon mobile-nav-item" href="/">
                <Row>
                  <Col sm={1}>
                    <DashboardSvg />
                  </Col>
                  <Col>
                    Home
                  </Col>
                </Row>
              </NavLink> */}
              {/* <NavLink className="mobile-nav-item" href="/market">
                <Row>
                  <Col sm={1}>
                    <MarketSvg />
                  </Col>
                  <Col>
                    Markt
                  </Col>
                </Row>
              </NavLink> */}
              {/* <NavLink className="mobile-nav-item" href="/farmers">
                <Row>
                  <Col sm={1}>
                    <FarmersSvg />
                  </Col>
                  <Col id="mobile-nav-farmers-label">
                    Farmer
                  </Col>
                </Row>
              </NavLink> */}
              {/* <NavLink className="mobile-nav-item" href="/orders">
                <Row>
                  <Col sm={1}>
                    <OrdersSvg />
                  </Col>
                  <Col>
                    Bestellungen
                  </Col>
                </Row>
              </NavLink> */}
              {/* <NavLink className="mobile-nav-item" href="/help">
                <Row>
                  <Col sm={1}>
                    <HelpSvg />
                  </Col>
                  <Col>
                    Hilfe
                  </Col>
                </Row>
              </NavLink> */}
              {/* <NavLink className="mobile-nav-item" href="/feedback">
                <Row>
                  <Col sm={1}>
                    <HelpSvg />
                  </Col>
                  <Col>
                    Feedback
                  </Col>
                </Row>
              </NavLink> */}
              {/* {!isGuest ? 
                <NavLink className="mobile-nav-item" href="/settings">
                  <Row>
                    <Col sm={1}>
                      <FontAwesomeIcon className="dark-grey" icon={faCog} />
                    </Col>
                    <Col>
                      Einstellungen
                    </Col>
                  </Row>
                </NavLink> : null
              } */}

              {/* <NavLink className="mobile-nav-item">
                <Logout />
              </NavLink>
              <NavItem className="ml-4 mr-3 cart-nav-item">
                <CartModal />
              </NavItem>
              <NavItem className="mr-3 user-region-nav-item">
                <UserRegionModal />
              </NavItem>
              <NavItem className="pr-3 mr-3 notifications-nav-item notifications-modal">
                <NotificationsModal />
              </NavItem>
              <NavItem className="user-account-nav-item">
                <AccountModal />
              </NavItem> */}
              
              {/* {isAuthenticated ? authLinks : guestLinks} */}
            </Nav>
          </Collapse>
        </Navbar>
        {/* <hr className="m-0"></hr> */}
        {/* <Navbar expand="sm" className="secondary-navbar">
              {secondaryNavItems}
          </Navbar>
          <hr className="m-0"></hr> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  membership: state.membership
});

export default withTranslation()(connect(
  mapStateToProps,
  { getMembership, logout }
)(AppNavbar));
