import React, { Component } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";

import Swal from "sweetalert2";

import { Image } from "cloudinary-react";

class ItemNutritionalFactsModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const item = this.props.item
    console.log(item)
    return (
      <div>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="toggle-item-nutritional-facts-btn"
					onClick={this.toggle}
        />
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Beschreibung
          </ModalHeader>
          <ModalBody>
            {item.description !== "" ? <p className="greyed-out">{item.description}</p> : null}
            {/* TODO: FIX NUTRITIONAL FACTS */}
            {item.nutritional_facts?.length !== 0 ? <p className="mb-0">Nährwerte (pro {item.nutritional_facts_per}):</p> : null}
            {item.nutritional_facts?.map(nutrient => {
              return(
                <p className="mb-0 greyed-out">{nutrient}</p>
              )
            })}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ItemNutritionalFactsModal.propTypes = {};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { }
)(withRouter(ItemNutritionalFactsModal));
