import React, { Component } from "react";
import { Container, ListGroup, ListGroupItem, Badge, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";

import { getAllSuppliers } from "../../../../../actions/supplierActions";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../../../../helper/history";

class SuppliersList extends Component {
  componentDidMount() {
    this.props.getAllSuppliers();
  }

  onDeleteClick = id => {
    this.props.deleteProject(id);
  };

  statusLabel = status => {
    switch (status) {
      case 0:
        return <div>Active</div>;
      case 1:
        return <div>Inactive</div>;
    }
  };

  // handleProjectClick = id => {
  //   history.push('/user')
  // }

  render() {
    const suppliers = this.props.supplier.suppliers;
    const id = "singlesupplier";
    const isArchived = this.props.isArchived;
    const page = this.props.page;

    return (
      <div className="project-list">
        <Table>
          <thead>
            <tr className="greyed-out">
              <th>Status</th>
              <th>Name</th>
              <th>Letztes Bestelldatum</th>
            </tr>
          </thead>
          <tbody>
            {this.props.filter
              ? suppliers
                  .filter(supplier => supplier.origin == this.props.filter)
                  .map(
                    ({
                      id,
                      status,
                      address,
                      name,
                      origin,
                      client,
                      start_date,
                      end_date,
                      budget,
                      is_complete,
                      is_archived
                    }) => {
                      return (
                        <tr
                          className="supplier-list-item"
                          onClick={() => history.push(`/suppliers/${id}`)}
                        >
                          <td>{this.statusLabel(status)}</td>
                          <td>{name}</td>
                          <td>29-08-20</td>
                        </tr>
                      );
                    }
                  )
              : suppliers.map(
                  ({
                    id,
                    status,
                    address,
                    name,
                    origin,
                    client,
                    start_date,
                    end_date,
                    budget,
                    is_complete,
                    is_archived
                  }) => {
                    return (
                      <tr
                        className="supplier-list-item"
                        onClick={() => history.push(`/suppliers/${id}`)}
                      >
                        <td>{this.statusLabel(status)}</td>
                        <td>{name}</td>
                        <td>29-08-20</td>
                      </tr>
                    );
                  }
                )}
            {/* {this.props.suppliersFilter
              ? suppliers
                  .filter(
                    supplier => supplier.status == this.props.suppliersFilter
                  )
                  .map(
                    ({
                      id,
                      status,
                      address,
                      name,
                      client,
                      start_date,
                      end_date,
                      budget,
                      is_complete,
                      is_archived
                    }) => {
                      return (
                        <tr
                          className="supplier-list-item"
                          onClick={() => history.push(`/suppliers/${id}`)}
                        >
                          <td>{this.statusLabel(status)}</td>
                          <td>{name}</td>
                          
                          <td>29-08-20</td>
                        </tr>
                      );
                    }
                  )
              : suppliers.map(
                  ({
                    id,
                    status,
                    address,
                    name,
                    client,
                    start_date,
                    end_date,
                    budget,
                    is_complete,
                    is_archived
                  }) => {
                    return (
                      <tr
                        className="supplier-list-item"
                        onClick={() => history.push(`/suppliers/${id}`)}
                      >
                        <td>{this.statusLabel(status)}</td>
                        <td>{name}</td>
                        
                        <td>29-08-20</td>
                      </tr>
                    );
                  }
                )} */}
          </tbody>
        </Table>
      </div>
    );
  }
}

SuppliersList.propTypes = {
  supplier: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier
});

export default connect(
  mapStateToProps,
  { getAllSuppliers }
)(SuppliersList);
