import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Image } from "cloudinary-react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { getSingleNewsItem } from "../../../../actions/newsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import history from "../../../../helper/history";

class NewsItemPage extends Component {
  componentDidMount() {
    const newsId = this.props.match.params.id;
    this.props.getSingleNewsItem(newsId);
  }

  render() {
    const newsItem = this.props.news;

    return (
      <div id="news-item-page">
        <Container className="mt-4">
					<Row>
            <Col>
							<FontAwesomeIcon
            	onClick={() => history.goBack()}
            	icon={faChevronLeft}
            	className="back-button float-left mr-3"
          	/>
              <h4 className="page-title">News</h4>
							{/* <p className="dark-grey news-item-date">Veröffentlicht am 27.10.211</p> */}
            </Col>
					</Row>
					<Row>
						<Col sm={8}>
							<h1><b>{newsItem.headline}</b></h1>
							<Image
                publicId={newsItem.single_news_hero_image}
								width="100%"
                className="my-3"
              />
							<p className="dark-grey">
                {newsItem.main_text}
							</p>
	  					</Col>
						<Col className="news-page-related-items">
							<Link
                    className="feed-item"
                    exact
                    to={`/feed`}
                  >
                  <Row className="feed-item mb-4">
                    <Col className="">
                      <Image
                        publicId={"feed/feed1"}
                        width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col>
                      <h5 className="">20% Rabatt am Mittwoch bis 14 Uhr</h5>
                      <p className="greyed-out">Lorem ipsum dolor sit amet, consectetur...</p>
                    </Col>
                  </Row>
                </Link>
								<Link
                    className="feed-item"
                    exact
                    to={`/feed`}
                  >
                  <Row className="feed-item mb-4">
                    <Col className="">
                      <Image
                        publicId={"feed/feed2"}
                        width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col>
                      <h5 className="">Neue Gewürze sind auf Farm X angekommen</h5>
                      <p className="greyed-out">Lorem ipsum dolor sit amet, consectetur...</p>
                    </Col>
                  </Row>
                </Link>
								<Link
                    className="feed-item"
                    exact
                    to={`/feed`}
                  >
                  <Row className="feed-item mb-4">
                    <Col className="">
                      <Image
                        publicId={"feed/feed3"}
                        width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col>
                      <h5 className="">Farm X Marmelade besser als Weltraum</h5>
                      <p className="greyed-out">Lorem ipsum dolor sit amet, consectetur...</p>
                    </Col>
                  </Row>
                </Link>
								<Link
                    className="feed-item"
                    exact
                    to={`/feed`}
                  >
                  <Row className="feed-item mb-4">
                    <Col className="">
                      <Image
                        publicId={"feed/feed4"}
                        width="100%"
                        // height="100%"
                        className="Card image cap"
                      />
                    </Col>
                    <Col>
                      <h5 className="">Rudolf Manger neue Bauernbiografie</h5>
                      <p className="greyed-out">Lorem ipsum dolor sit amet, consectetur...</p>
                    </Col>
                  </Row>
                </Link>
						</Col>
					</Row>
				</Container>
      </div>
    );
  }
}

NewsItemPage.propTypes = {
  news: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  news: state.news
});

export default connect(
  mapStateToProps,
  { getSingleNewsItem }
)(NewsItemPage);