import gql from "graphql-tag";
// Invite supplier
export const EMPTY_CART_MUTATION = gql`
  mutation emptyCart($cartId: String!) {
    emptyCart(cartId: $cartId) {
      id
      items {
        ... on Cartitem {
          id
          item
          item_name
          image
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
