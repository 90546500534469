import gql from "graphql-tag";
// Login user
export const ADD_ITEM_TO_CART_MUTATION = gql`
  mutation addItemToCart($cartitem: CartitemInput!) {
    addItemToCart(cartitem: $cartitem) {
      id
      items {
        ... on Cartitem {
          id
          item
          item_name
          image
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
