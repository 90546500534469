import React, { Component } from "react";
import {
  Table,
  NavItem,
  NavLink,
  Container,
  Card,
  TabPane,
  TabContent,
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";

import { getAllOrders } from "../../../../../actions/orderActions";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label
} from "recharts";

class AnalyticsPage extends Component {
  constructor(props) {
    super(props);

    this.toggleTimeframeFilter = this.toggleTimeframeFilter.bind(this);
    this.toggleGraphType = this.toggleGraphType.bind(this);
    this.state = {
      selectedTimeframeFilter: "all",
      selectedGraphType: "line"
    };
  }

  componentDidMount() {
    this.props.getAllOrders();
  }

  toggleTimeframeFilter(timeframe) {
    if (this.state.selectedTimeframeFilter !== timeframe) {
      this.setState({ selectedTimeframeFilter: timeframe });
    }
  }

  toggleGraphType(graphType) {
    if (this.state.selectedGraphType !== graphType) {
      this.setState({ selectedGraphType: graphType });
    }
  }

  getOrdersChartData = orders => {
    const data = [];
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
    orders.forEach(order => {
      const chartItem = {
        date: order.date.split("T")[0],
        price: order.total_price
      };

      var orderDateString = chartItem.date.toString();
      orderDateString = orderDateString.replace(/-0/g, '-');
    
      var currentDateString = date.toString();
      currentDateString = currentDateString.replace(/-0/g, '-');

      var orderDateSplit = orderDateString.split("-");
      var currentDateSplit = currentDateString.split("-");

      const orderDateYear = orderDateSplit[0]
      const currentDateYear = orderDateSplit[0]
      
      const orderDateMonth = orderDateSplit[1];
      const currentDateMonth = currentDateSplit[1];

      const orderDateDay = orderDateSplit[2]
      const currentDateDay = currentDateSplit[2]

      if(this.state.selectedTimeframeFilter == "all"){
        data.push(chartItem);
      } else if (this.state.selectedTimeframeFilter == "yearly"){
        if(orderDateYear == currentDateYear){
          data.push(chartItem);
        }
      } else if (this.state.selectedTimeframeFilter == "monthly"){
        if(orderDateMonth == currentDateMonth){
          data.push(chartItem);
        }
      } else if (this.state.selectedTimeframeFilter == "daily"){
        if(orderDateDay == currentDateDay){
          data.push(chartItem);
        }
      }
    });
    return data;
  };

  getPerItemExpenditures = orders => {
    const perItemExpenditures = [];
    const allOrderItems = [];  
    
    orders.forEach(order => {
      order.items.forEach(item => {
        allOrderItems.push({"item_id": item.item, "item_name": item.item_name, "price": item.price, "quantity": item.quantity});
      });
    });

    allOrderItems.forEach(item => {
      if(perItemExpenditures.some(array_item => array_item.item_name == item.item_name)){
        for(var i = 0; i < perItemExpenditures.length; i++) {
          if (perItemExpenditures[i].item_name == item.item_name) {
            perItemExpenditures[i].quantity += item.quantity
          }  
        }
      } 
      else {
        perItemExpenditures.push({"item_id": item.item_id, "item_name": item.item_name, "price": item.price, "quantity": item.quantity})
      }
    })

    perItemExpenditures.forEach(item => {
      var item_total_price = item.price * item.quantity;
      var item_total_price_string = item_total_price.toFixed(2);
      item_total_price = parseFloat(item_total_price_string);

      item.price = item_total_price
    })

    return perItemExpenditures;
  };

  getPerSupplierExpenditures = orders => {
    var perSupplierExpenditures = [];
    orders.forEach(order => {
      if (perSupplierExpenditures.length){
        for(var i = 0; i < perSupplierExpenditures.length; i++) {
          if (perSupplierExpenditures[i].supplier_id == order?.supplier?.id) {
            perSupplierExpenditures[i].amount += order?.total_price
          }
        }
      } else {
        perSupplierExpenditures.push({"supplier_id": order?.supplier?.id, "supplier_name": order?.supplier?.name, "amount": order?.total_price})
      }
    })

    perSupplierExpenditures.forEach(supplier => {
      var supplier_amount = supplier.amount
      var supplier_amount_string = supplier_amount.toFixed(2);
      supplier_amount = parseFloat(supplier_amount_string);

      supplier.amount = supplier_amount
    })
    
    return perSupplierExpenditures;
  };

  render() {
    const orders = this.props.order.orders;
    const data = this.getOrdersChartData(orders);
    const perItemExpenditures = this.getPerItemExpenditures(orders);
    // console.log("PER ITEM EXP", perItemExpenditures);
    const perSupplierExpenditures = this.getPerSupplierExpenditures(orders);

    return (
      <div className="analytics-page">
        <Container className="my-4">
          <h4 className="page-title mb-5">Analysen</h4>
          <Row>
            <Col>
              <FormGroup>
                <span className="mr-1">Von</span>
                <Input
                  type="date"
                  name="date"
                  id="exampleDate"
                  placeholder="date placeholder"
                  className="mr-2"
                />
                <span className="mr-1">bis</span>
                <Input
                  type="date"
                  name="date"
                  id="exampleDate"
                  placeholder="date placeholder"
                />
              </FormGroup>
            </Col>
            <Col className="mb-3">
              <Button
                className={classnames(
                  "btn-white",

                  {
                    active: this.state.selectedTimeframeFilter === "all"
                  }
                )}
                onClick={() => {
                  this.toggleTimeframeFilter("all");
                }}
              >
                Alle
              </Button>
              <Button
                className={classnames(
                  "btn-white",

                  {
                    active: this.state.selectedTimeframeFilter === "daily"
                  }
                )}
                // onClick={() => {
                //   this.toggleTimeframeFilter("daily");
                // }}
              >
                Täglich
              </Button>
              <Button
                className={classnames(
                  "btn-white",

                  {
                    active: this.state.selectedTimeframeFilter === "weekly"
                  }
                )}
                // onClick={() => {
                //   this.toggleTimeframeFilter("weekly");
                // }}
              >
                Wöchentlich
              </Button>
              <Button
                className={classnames(
                  "btn-white",

                  {
                    active: this.state.selectedTimeframeFilter === "monthly"
                  }
                )}
                // onClick={() => {
                //   this.toggleTimeframeFilter("monthly");
                // }}
              >
                Monatlich
              </Button>
              <Button
                className={classnames(
                  "btn-white",

                  {
                    active: this.state.selectedTimeframeFilter === "yearly"
                  }
                )}
                // onClick={() => {
                //   this.toggleTimeframeFilter("yearly");
                // }}
              >
                Jährlich
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5 style={{ marginBottom: "2rem" }}>
                      <b>Total:</b>
                    </h5>
                  </Col>
                  <Col>
                    <Button
                      className={classnames(
                        "btn-white",

                        {
                          active: this.state.selectedGraphType === "line"
                        }
                      )}
                      onClick={() => {
                        this.toggleGraphType("line");
                      }}
                    >
                      Line
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LineChart
                      width={1000}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 20
                      }}
                    >
                      <CartesianGrid />

                      <XAxis dataKey="date">
                        <Label value="Datum" position="bottom" />
                      </XAxis>
                      <YAxis>
                        <Label
                          angle={-90}
                          value="Kosten bestellen (EUR)"
                          position="left"
                          style={{ textAnchor: "middle" }}
                        />
                      </YAxis>
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="price"
                        stroke="#8cc63f"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5 style={{ marginBottom: "2rem" }}>
                      <b>Ausgegebener Betrag pro Artikel</b>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table>
                      <tbody>
                        {/* {order.items ?.map(
                          ({ item, item_name, quantity, price }) => {
                            return ( */}
                        {/* );
                          }
                        )} */}
                        {perItemExpenditures?.map(
                          ({ item_name, price }) => {
                            return (
                              <tr>
                                <td>{item_name}</td>
                                <td>{price}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              {/* <Card>
                <Row>
                  <Col>
                    <h5 style={{ marginBottom: "2rem" }}>
                      <b>Ausgegebener Betrag pro Farmer</b>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table>
                      <tbody>
                        {perSupplierExpenditures?.map(
                          ({ supplier_name, amount }) => {
                            return (
                              <tr>
                                <td>{supplier_name}</td>
                                <td>{amount}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AnalyticsPage.propTypes = {
  order: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  order: state.order
});

export default connect(
  mapStateToProps,
  { getAllOrders }
)(AnalyticsPage);
