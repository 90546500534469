import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Form,
  FormGroup,
  Label,
  Container,
  Card,
  Input,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import history from "../../../../helper/history";

import Avatar from "@material-ui/core/Avatar";
import { Image } from "cloudinary-react";
import { getSingleSupplier } from "../../../../actions/supplierActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AdminSingleFarmerPage extends Component {
	componentDidMount() {
    const supplierId = this.props.match.params.id;
    this.props.getSingleSupplier(supplierId);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  render() {
		const farmer = this.props.supplier;
    const {loading} = this.props.supplier;

    return (
      <div className="page admin-single-element-page">
        <Container className="single-order-page">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
            className="float-left mr-3"
          />
          <h4 className="page-title my-4">Farmer</h4>
          <Row>
            <Col>
            <LoadingOverlay
              className="rounded-edges"
              active={loading}
              spinner
              text={this.props.t('loading')}
            >
              <Card className="single-order-card">
                <Row>
                  <Col>
                    <h5 className="mb-3">
                      {farmer?.name}
                    </h5>
                  </Col>
                </Row>
                <Row className="px-4 greyed-out">
                  <Col>
                    <p>ID <span className="float-right">{farmer?.id}</span></p>
										<p>Name <span className="float-right">{farmer?.name}</span></p>
										<p>Email <span className="float-right">{farmer?.email}</span></p>
										<p>Phone <span className="float-right">{farmer?.telephone}</span></p>
										<p>Tax id <span className="float-right">{farmer?.tax_id}</span></p>
										<p>Type <span className="float-right">{farmer?.type}</span></p>
										<p>Farm name <span className="float-right">{farmer?.farm_name}</span></p>
										<p>Year founded <span className="float-right">{farmer?.year_founded}</span></p>
										<p>Location <span className="float-right">{farmer?.location}</span></p>
										<p>Website <span className="float-right">{farmer?.website}</span></p>
										<p className="mb-0">Address <span className="float-right">{farmer?.address?.street_address}</span></p>
										<p className="align-right mb-0">{farmer?.address?.street_address_2}</p>
										<p className="align-right mb-0">{farmer?.address?.zip_code}</p>
										<p className="align-right mb-0">{farmer?.address?.city}</p>
										<p className="align-right">{farmer?.address?.country}</p>
										<Row>
											<Col>
												<p>Bio </p>
											</Col>
											<Col>
												<p className="align-right">{farmer?.bio}</p>
											</Col>
										</Row>
										{/* <p>Phone number <span className="float-right">{user?.telephone}</span></p>
										<p className="mb-0">Address <span className="float-right">{user?.address?.name}</span></p>
										<p className="align-right mb-0">{user?.address?.street_address}</p>
										<p className="align-right mb-0">{user?.address?.street_address_2}</p>
										<p className="align-right mb-0">{user?.address?.zip_code}</p>
										<p className="align-right mb-0">{user?.address?.city}</p>
										<p className="align-right">{user?.address?.country}</p>
										<p>Region <span className="float-right">{user?.region}</span></p>
										<p>Language <span className="float-right">{user?.language}</span></p>
										<p>Admin <span className="float-right">{user?.admin.toString()}</span></p> */}
                  </Col>
                </Row>
							</Card>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AdminSingleFarmerPage.propTypes = {
	getSingleSupplier: PropTypes.func.isRequired,
  supplier: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier
});

export default withTranslation()(connect(
  mapStateToProps,
  { getSingleSupplier }
)(withRouter(AdminSingleFarmerPage)));