import {
  CREATE_COUPON,
  TOGGLE_COUPON_STATUS,
  GET_COUPONS,
	COUPONS_LOADING
} from "../actions/types";

const initialState = {
  coupons: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COUPONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
        loading: false
      };
    case CREATE_COUPON:
      return {
        ...state,
        coupons: [...state.coupons, action.payload],
        loading: false
      };  
    case TOGGLE_COUPON_STATUS:
      return {
        ...state,
        coupons: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
