import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  Row,
  Col
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import OrdersList from "./OrdersList";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

import Avatar from "@material-ui/core/Avatar";

class OrdersTab extends Component {
  state = {
    name: "",
    email: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  render() {
    const { user } = this.props.auth;
    return (
      <Container id='orders-page' className="mb-4 p-0 orders-tab">
        <Row>
          <Col>
            <h3>{this.props.t('yourOrders')}</h3>
            <OrdersList filter={this.props.filter} />
            {/* <Card>
              <Row>
                <Col>
                  <h5>
                    Deine Bestellungen
                  </h5>
                </Col> */}
                {/* <Col className="create-order-link" >
                  <Link exact to="/market">
                    <Button
                      color="dark"
                      className="create-order-btn btn-modal float-right"
                      style={{ marginBottom: "2rem" }}
                      block
                    >
                      <div className="button-icon">
                        <NeueBaustelleSvg />
                      </div>
                      Bestellung erstellen
                    </Button>
                  </Link>
                </Col> */}

              {/* </Row>
              <OrdersList filter={this.props.filter} />
            </Card> */}
          </Col>
        </Row>
      </Container>
      // <Container className="settings-profile-tab p-0">
      //   <Row className="mt-3">
      //     <Col>
      //       <Card>
      //         <p>
      //           <strong>Profil bearbeiten</strong>
      //         </p>
      //         <Row>
      //           <Col sm={2}>
      //             {user.avatar ? (
      //               <Avatar src="../../assets/DefaultProfilePic.svg" />
      //             ) : (
      //               <Avatar
      //                 className="avatar"
      //                 src="../../assets/DefaultProfilePic.svg"
      //               />
      //             )}
      //           </Col>
      //           <Col>
      //             <Form className="custom-form" onSubmit={this.onSubmit}>
      //               <FormGroup>
      //                 <Label for="name">Name</Label>
      //                 <Input
      //                   type="text"
      //                   name="name"
      //                   id="name"
      //                   placeholder="Name"
      //                   className="mb-3"
      //                   onChange={this.onChange}
      //                 />
      //                 <Label for="email">E-Mail</Label>
      //                 <Input
      //                   type="email"
      //                   name="email"
      //                   id="email"
      //                   placeholder="E-Mail"
      //                   className="mb-3"
      //                   onChange={this.onChange}
      //                 />
      //                 <Button
      //                   className="custom-btn"
      //                   color="dark"
      //                   style={{ marginTop: "2rem" }}
      //                   block
      //                 >
      //                   Profil aktualisieren
      //                 </Button>
      //               </FormGroup>
      //             </Form>
      //           </Col>
      //         </Row>
      //       </Card>
      //       <Card>
      //         <p>
      //           <strong>Passwort ändern</strong>
      //         </p>
      //         <Form
      //           className="custom-form"
      //           // onSubmit={this.onSubmit}
      //         >
      //           <FormGroup>
      //             <Label for="email">Aktuelles Passwort</Label>
      //             <Input
      //               type="email"
      //               name="email"
      //               id="email"
      //               placeholder="Passwort"
      //               className="mb-3"
      //               onChange={this.onChange}
      //             />
      //             <hr />
      //             <Label for="password">Neues Passwort</Label>
      //             <Input
      //               type="password"
      //               name="password"
      //               id="password"
      //               placeholder="Neues Passwort"
      //               className="mb-3"
      //               onChange={this.onChange}
      //             />
      //             <Label for="password">Neues Passwort bestätigen</Label>
      //             <Input
      //               type="password"
      //               name="password"
      //               id="password"
      //               placeholder="Neues Passwort bestätigen"
      //               className="mb-3"
      //               onChange={this.onChange}
      //             />
      //             <Button
      //               className="custom-btn"
      //               color="dark"
      //               style={{ marginTop: "2rem" }}
      //               block
      //             >
      //               Passwort aktualisieren
      //             </Button>
      //           </FormGroup>
      //         </Form>
      //       </Card>
      //     </Col>
      //   </Row>
      // </Container>
    );
  }
}

OrdersTab.propTypes = {
  user: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default withTranslation()(connect(
  mapStateToProps,
  { }
)(withRouter(OrdersTab)));
