import React, { Component } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card } from "reactstrap";
import Swal from "sweetalert2";

import PropTypes from "prop-types";
import { sendContactForm } from "../../../../actions/userActions";

import history from "../../../../helper/history";
import { connect } from "react-redux";

class FeedbackPage extends Component {

	state = {
		user_name: null,
		email: null,
		topic: null,
		message: null
  };

	static propTypes = {
    inviteSupplier: PropTypes.func.isRequired
  };

	onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

	onSubmit = e => {
    e.preventDefault();
		if (!this.state.user_name || !this.state.email || !this.state.topic || !this.state.message){
			Swal.fire({
				text: "Bitte füllen Sie alle Felder aus",
				icon: "error",
				confirmButtonColor: "#C25982",
				confirmButtonText: "Ok"
			});
		} else {
			this.props.sendContactForm(this.state.user_name, this.state.email, this.state.topic, this.state.message)
      Swal.fire({
        text: "Ihre Nachricht wurde gesendet! Wir werden so schnell wie möglich antworten",
        icon: "success",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      }).then(() => {
        window.location.reload();
      	}
      );
		}
  };

  render() {
    return (
      <div id="help-page" className="page">
        <Container>
					<Row className="my-4">
            <Col>
              <h4 className="page-title">Feedback</h4>
            </Col>
          </Row>
					<Row className="contact-form">
						<Col>
              <Row className="header">
                <Col>
                  <h5>
                    Feedback-Formular
                  </h5>
                </Col>
              </Row>
              <Row className="body">
                <Col>
					<Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="name">Name</Label>
                      <Input
                        type="text"
                        name="user_name"
                        id="user_name"
                        className="mb-2"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="email">E-mail</Label>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        className="mb-2"
                        placeholder="E-mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Label className="greyed-out ml-2" for="name">Thema</Label>
                      <Input
                        type="text"
                        name="topic"
                        id="topic"
                        className="mb-2"
                        placeholder="Thema eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="message">Nachricht</Label>
                      <Input
                        type="textarea"
                        name="message"
                        id="message"
                        className="mb-2"
                        placeholder="Nachricht eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
									Senden
                </Button>
              </FormGroup>
            </Form>
            </Col>
            </Row>
						</Col>
					</Row>
				</Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default connect(
  mapStateToProps,
  { sendContactForm }
)(FeedbackPage);