import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  CardDeck,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Item from "./Item";

import { getAllItems } from "../../../../../actions/itemActions";
import { getCart } from "../../../../../actions/cartActions";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class AddItemButton extends Component {
  state = {
    modal: false,
    name: ""
  };

  componentDidMount() {
    this.props.getAllItems();
    this.props.getCart();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const items = this.props.item.items;
    const orderedItems = [...items].reverse();
    const cart = this.props.cart.cart;

    return (
      <div id='add-item-to-marketlist-button' className="hinzufügen-modal">
        <Button
          className="btn-list-item"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          {this.props.t('addItem')}
        </Button>
        {/* <Button
          className="btn-modal btn-add-item btn-white"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          Artikel hinzufügen
        </Button> */}
        <Modal
          className="add-item-modal"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            {this.props.t('addItem')}
          </ModalHeader>
          <ModalBody>
            <CardDeck>
              <Row className="market-list-add-items item-list-row">
                {/* <Col className="item-list-item" sm={3}>
                    <Card>
                      <CardImg
                        top
                        width="100%"
                        src={tomatoes}
                        alt="Card image cap"
                      />
                      <CardBody>
                        <CardTitle>Item name</CardTitle>
                        <CardSubtitle># of items found</CardSubtitle>
                        <CardText>Price per kg</CardText>
                      </CardBody>
                    </Card>
                  </Col> */}
                {this.props.areRecent
                  ? orderedItems
                      .filter(item => item.is_recent == true)
                      .map(item => {
                        return (
                          <Col className="item-list-item" sm={3}>
                            <Item itemObject={item} />
                          </Col>
                        );
                      })
                  : orderedItems.map(item => {
                      return (
                        <Col className="item-list-item" sm={3}>
                          <Item
                            page={"Marketlist"}
                            cart={cart}
                            marketlist_id={this.props.marketlist_id}
                            itemObject={item}
                          />
                        </Col>
                      );
                    })}
              </Row>
            </CardDeck>
            <Button
              className="float-right btn-modal ml-2"
              color="dark"
              style={{ marginTop: "2rem" }}
              block
            >
              {this.props.t('create')}
            </Button>
            <Button
              onClick={this.toggle}
              className="btn-white cancel-btn float-right btn-modal"
              color="dark"
              style={{ marginTop: "2rem" }}
              block
            >
              {this.props.t('cancel')}
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddItemButton.propTypes = {
  getCart: PropTypes.func.isRequired,
  getAllItems: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  craftsman: state.craftsman,
  item: state.item,
  cart: state.cart,
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllItems, getCart }
)(AddItemButton));
