import axios from "axios";
import {
  ORDER_LOADING,
  ORDERS_LOADING,
  CHANGE_ORDER_STATUS,
  CREATE_ORDER,
  GET_ALL_ORDERS,
  ADMIN_GET_ALL_ORDERS,
  GET_SINGLE_ORDER, CREATE_ORDER_FAIL, CREATE_ORDER_SUCCESS} from "./types";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
import { CREATE_ORDER_MUTATION } from "../graphql/mutation/orders/createOrder";
import { CHANGE_ORDER_STATUS_MUTATION } from "../graphql/mutation/orders/changeOrderStatus";

export const createOrder = (id, payment_method_id, delivery_date, delivery_window, delivery_instructions, delivery_address, coupon_id) => dispatch => {
  console.log(id, payment_method_id, delivery_date, delivery_window, delivery_instructions, delivery_address, coupon_id)
  dispatch({ type: ORDER_LOADING });
  client
    .mutate({
      variables: { cartId: id, paymentMethodId: payment_method_id, deliveryDate: delivery_date, deliveryWindow: delivery_window, deliveryInstructions: delivery_instructions, deliveryAddress: delivery_address, couponId: coupon_id },
      mutation: CREATE_ORDER_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CREATE_ORDER,
          payload: res.data.createOrder
        });
        // console.log(res)
        if (res.errors[0]){
          // console.log("ERROR ERROR ERROR")
          dispatch(returnErrors(res.errors[0].message, null, "CREATE_ORDER_FAIL"));
          dispatch({
            type: CREATE_ORDER_FAIL
          });  
        } 
        // else {
        //   console.log("SUCCESS")
        //   dispatch({
        //     type: CREATE_ORDER_SUCCESS
        //   });  
        // }
      } catch {
        console.log("ERROR RECEIVED: ", res)
        // console.log("SUCCESS")
          dispatch({
            type: CREATE_ORDER_SUCCESS
          });  
        // dispatch(returnErrors(res.errors[0].message, null, "CREATE_ORDER_FAIL"));
        // dispatch({
        //   type: CREATE_ORDER_FAIL
        // });  
      }
    })
    .catch(res => {
      console.log("ERROR DETECTED", res)
      // dispatch(
      //   returnErrors(err.response.data, err.response.status, "CREATE_ORDER_FAIL")
      // );
      // dispatch({
      //   type: CREATE_ORDER_FAIL
      // });
    });
};

export const changeOrderStatus = (orderId, status) => dispatch => {
  client
    .mutate({
      variables: { orderId: orderId, status: status },
      mutation: CHANGE_ORDER_STATUS_MUTATION
    })
    .then(res => {
      try {
        dispatch({
          type: CHANGE_ORDER_STATUS,
          payload: res.data.changeOrderStatus
        });
      } catch {
        dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
}

export const getAllOrders = () => dispatch => {
  dispatch({ type: ORDERS_LOADING });
  client
    .query({
      query: gql`
        query orders {
          getAllOrders {
            id
            order_no
            date
            delivery_date
            delivery_window
            price
            seven_pct_tax
            total_price
            status
            items {
              ... on Cartitem {
                item
                image
                item_name
                quantity
                quantity_accepted
                quantity_delivered
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
            supplier {
              ... on Supplier {
                id
                name
              }
            }
          }
        }
      `
    })
    .then(res => {
      // console.log(res);
      dispatch({ type: GET_ALL_ORDERS, payload: res.data.getAllOrders });
    })
    .catch(err => {
      // console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const adminGetAllOrders = () => dispatch => {
  client
    .query({
      query: gql`
        query orders {
          adminGetAllOrders {
            id
            order_no
            date
            delivery_date
            delivery_window
            price
            seven_pct_tax
            total_price
            delivery_address {
              name
              street_address
              street_address
              zip_code
              city
              country
            }
            status
            items {
              ... on Cartitem {
                item
                image
                item_name
                quantity
                quantity_accepted
                quantity_delivered
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
            supplier {
              ... on Supplier {
                id
                name
              }
            }
          }
        }
      `
    })
    .then(res => {
      // console.log(res);
      dispatch({ type: ADMIN_GET_ALL_ORDERS, payload: res.data.adminGetAllOrders });
    })
    .catch(err => {
      // console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getSingleOrder = id => dispatch => {
  client
    .query({
      variables: { id },
      query: gql`
        query getSingleOrder($id: String!) {
          getSingleOrder(id: $id) {
            id
            order_no
            description
            price
            seven_pct_tax
            total_price
            date
            delivery_date
            delivery_window
            discount
            delivery_address {
              name
              street_address
              street_address
              zip_code
              city
              country
            }
            status
            supplier {
              ... on Supplier {
                id
                name
                avatar
              }
            }
            items {
              ... on Cartitem {
                item
                image
                item_name
                quantity
                quantity_accepted
                quantity_delivered
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
          }
        }
      `
    })
    .then(res => {
      // console.log(res);
      dispatch({ type: GET_SINGLE_ORDER, payload: res.data.getSingleOrder });
    })
    .catch(err => {
      // console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
