import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card } from "reactstrap";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import PropTypes from "prop-types";
import { sendContactForm } from "../../../../actions/userActions";

import history from "../../../../helper/history";
import { connect } from "react-redux";

class HelpPage extends Component {

	state = {
		user_name: null,
		email: null,
		topic: null,
		message: null
  };

	static propTypes = {
    inviteSupplier: PropTypes.func.isRequired
  };

	onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

	onSubmit = e => {
    e.preventDefault();

    if (!this.state.user_name || !this.state.email || !this.state.topic || !this.state.message){
			Swal.fire({
				text: this.props.t('incompleteFormText'),
				icon: "error",
				confirmButtonColor: "#C25982",
				confirmButtonText: "Ok"
			});
		} else {
		  this.props.sendContactForm(this.state.user_name, this.state.email, this.state.topic, this.state.message)
      Swal.fire({
        text: this.props.t('contactFormSendSuccessfullyText'),
        icon: "success",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      }).then(() => {
        window.location.reload();
        }
      );
    }
  };

  render() {
    return (
      <div id="help-page" className="page">
        <Container>
					<Row className="contact-form">
						<Col>
              <Row>
                <Col style={{maxWidth: '25%'}}>
                  <FontAwesomeIcon
            	      onClick={() => history.goBack()}
            	      icon={faChevronLeft}
            	      className="back-button float-left mr-3"
                    style={{height: 18}}
          	      />
                </Col>
                <Col style={{maxWidth: '50%'}}>
                  <h2>
                    {this.props.t('support')}
                  </h2>
                  <p className='hide-on-mobile'>
                    {this.props.t('supportPageText')}
                  </p>
                </Col>
                <Col style={{maxWidth: '25%'}}>
                </Col>
              </Row>
              <p className='hide-on-desktop' style={{marginBottom: '1rem'}}>
                {this.props.t('supportPageText')}
              </p>
              <Row className="body">
                <Col>
					      <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="name">{this.props.t('name')}</Label>
                      <Input
                        type="text"
                        name="user_name"
                        id="user_name"
                        placeholder={this.props.t('yourName')}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="email">E-mail</Label>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={this.props.t('yourEmail')}
                        onChange={this.onChange}
                      />
                    </div>
										<div className="linediv">
                      <Label className="greyed-out ml-2" for="name">{this.props.t('topic')}</Label>
                      <Input
                        type="text"
                        name="topic"
                        id="topic"
                        placeholder={this.props.t('inputTopic')}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="linediv">
                      <Label className="greyed-out ml-2" for="message">{this.props.t('message')}</Label>
                      <Input
                        type="textarea"
                        name="message"
                        id="message"
                        className="mb-2"
                        placeholder={this.props.t('inputMessage')}
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  {this.props.t('send')}
                </Button>
              </FormGroup>
            </Form>
            </Col>
            </Row>
						</Col>
					</Row>
				</Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default withTranslation()(connect(
  mapStateToProps,
  { sendContactForm }
)(HelpPage));