import gql from "graphql-tag";
// Invite supplier
export const CREATE_ITEM_REQUEST_MUTATION = gql`
  mutation createItemrequest($itemrequest: ItemrequestInput!) {
    createItemrequest(itemrequest: $itemrequest) {
      id
      item_name
      item_quantity
      item_country
      item_unit
      item_description
      status
    }
  }
`;
