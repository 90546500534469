import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Container,
  Row,
  Col
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { getAllMarketlists } from "../../../../../actions/marketListActions";
import { deleteMarketlist } from "../../../../../actions/marketListActions";
import { removeItemFromMarketlist } from "../../../../../actions/marketListActions";

import AddMarketListButton from "../market/AddMarketListButton";

import classnames from "classnames";
import { Image } from "cloudinary-react";
import history from "../../../../../helper/history";

import AddItemButton from "../market/AddItemButton";
import RenameModal from "../RenameModal";

class MarketlistsPage extends Component {
  componentDidMount() {
    this.props.getAllMarketlists();
  }

  state = {
    selectedMarketlist: null
  };

  removeItemFromMarketlist = (marketlistId, itemId) => {
    console.log("HELLO", itemId)
    this.props.removeItemFromMarketlist(marketlistId, itemId)
  }

  selectMarketlist = (marketlist) => {
    console.log(marketlist)
    this.setState({
      selectedMarketlist: marketlist
    });
  }

  getMarketlistItemsPreviewImages = items => {
    let marketlistItemPreviewImages = [];
    
    for (let i = 0; i < 7; i++){
      if (items[i]){
        marketlistItemPreviewImages.push(items[i].image)
      }
    }

    return marketlistItemPreviewImages;
  }

  getSelectedMarketlistCard = () => {
    let marketlist = this.state.selectedMarketlist;

    return (
      <div id='selected-order-card'>
        <div style={{display: 'flex', flexDirection: 'row', border: '1px solid #DEDEDE', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.75rem',paddingRight: '0.75rem',  borderRadius: 8, marginBottom: '1.5rem'}}>
          <div>
            <p style={{fontSize: 26, fontWeight: 'bold', color: 'black', marginBottom: 0}}>
              {this.props.t('marketLists')}
            </p>
            <RenameModal marketlist_id={marketlist.id} marketlist_name={marketlist.name}/>
            {/* <p style={{fontSize: 18, color: '#858585', marginBottom: '0.4rem'}}>
              {marketlist.name}
            </p> */}
          </div>
        </div>
        <AddItemButton marketlist_id={marketlist.id} />
        <div id='selected-order-items'>
        {marketlist.items.map(item => {
          return (
            <Row style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
              <Col style={{maxWidth: '20%', paddingRight: 0}}>
                <Image publicId={item.image} />
              </Col>
              <Col style={{maxWidth: '60%', padding: 0}}>
                <p style={{fontSize: 16, fontWeight: 'bold', color: 'black'}}>{item.item_name}</p>
                <Row>
                  <Col>
                    <p className='label'>{this.props.t('unit')}: <span className='value'>{item.unit}</span></p>
                    <p className='label'>{this.props.t('orderQuantity')}: <span className='value'>{item.quantity ? item.quantity : 'N/A'}</span></p>
                  </Col>
                  <Col>
                    <p className='label'>{this.props.t('price')}: <span className='value'>{this.props.t('currencySymbol')}{item.price_7_pct.toFixed(2)}</span></p>
                  </Col>
                </Row>              
              </Col>
              <Col id='selected-order-item-price-col' style={{maxWidth: '20%'}}>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => this.removeItemFromMarketlist(marketlist.id, item)}
                  id="remove-item-from-marketlist-btn"
                />
                <p style={{textAlign: 'right', fontSize: 14, fontWeight: 'bold', color: '#C25982'}}>{this.props.t('currencySymbol')}{(item.price_7_pct * item.quantity).toFixed(2)}</p>
              </Col>
            </Row>
          )
        })}
        </div>
      </div>
    )
  }

  deleteMarketlist = (e, marketlistId) => {
    e.preventDefault();
    this.props.deleteMarketlist(marketlistId);
    // this.props.removeItemFromCart(cartitemId);
  };

  render() {
    const marketlists = this.props.marketlist.marketlists;
    const isGuest=this.props.isGuest

    return (
      <Container className="page" id="marketlists-page">
				<Container className="my-4 hide-on-mobile">
        </Container>
				<Container id='marketlists-container' className='mt-5'>
        <Row>
          <Col>
						<h3>{this.props.t('yourMarketLists')}</h3>
            <AddMarketListButton isGuest={isGuest}/>
            {marketlists.length ? (
              marketlists.map(
                marketlist => {
                  return (
                    <div>
                      <div id='order-list-item-mobile' onClick={() => history.push(`/marketlists/${marketlist.id}`)} className='order-list-item-container' style={{borderBottom: '1px solid #C25982', paddingBottom: '1rem', marginBottom: '1rem'}}>
                        <div onClick={() => this.selectMarketlist(marketlist)} className={classnames(
													"order-list-item",
                  				{
                    				active: this.state.selectedMarketlist?.id === marketlist?.id
                  				}
                				)} style={{padding: '0.75rem', borderRadius: 14}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {marketlist.name}
                          </p>
                        </div>
                        {(marketlist.items.length >= 6) ?
                        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem', justifyContent: 'space-between'}} id='order-items-preview-container-1'>
                          {this.getMarketlistItemsPreviewImages(marketlist.items).map((image) => { 
                            return (
                              <Image publicId={image} />
                            )
                          })}  
                        </div>
                        : <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem'}} id='order-items-preview-container-2'>
                        {this.getMarketlistItemsPreviewImages(marketlist.items).map((image) => { 
                          return (
                            <Image publicId={image} style={{marginRight: '1.25rem'}} />
                          )
                        })}  
                      </div>
                        }
                      </div>
                      </div>
                      
                      <div id='order-list-item-desktop' className='order-list-item-container' style={{borderBottom: '1px solid #C25982', paddingBottom: '1rem', marginBottom: '1rem'}}>
                      <div onClick={() => this.selectMarketlist(marketlist)} className={classnames(
													"order-list-item",
                  				{
                    				active: this.state.selectedMarketlist?.id === marketlist?.id
                  				}
                				)} style={{padding: '0.75rem', borderRadius: 14}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {marketlist.name}
                          </p>
                        </div>
                        {(marketlist.items.length >= 6) ?
                        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem', justifyContent: 'space-between'}} id='order-items-preview-container-1'>
                          {this.getMarketlistItemsPreviewImages(marketlist.items).map((image) => { 
                            return (
                              <Image publicId={image} />
                            )
                          })}  
                        </div>
                        : <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem'}} id='order-items-preview-container-2'>
                        {this.getMarketlistItemsPreviewImages(marketlist.items).map((image) => { 
                          return (
                            <Image publicId={image} style={{marginRight: '1.25rem'}} />
                          )
                        })}  
                      </div>
                        }
                      </div>
                      </div>
                      </div>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    {this.props.t('youDontHaveAnyMarketListsAtTheMoment')}...
                  </p>
                )}
            </Col>
            <Col className='hide-on-mobile'>
              {this.state.selectedMarketlist ? this.getSelectedMarketlistCard() : null}
            </Col>
          </Row>

            {/* <ListGroup>
              <AddMarketListButton isGuest={isGuest}/>
              {marketlists.length ? (
                marketlists.map(marketlist => {
                return (
                  <Link
                    className="market-list-item"
                    exact
                    to={`/marketlists/${marketlist?.id}`}
                  >
                    <ListGroupItem className="justify-content-between">
                      <Badge className="mr-4">{marketlist?.items?.length}</Badge>
                      <span>{marketlist?.name}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={(e) => this.deleteMarketlist(e, marketlist.id)}
                        className="delete-marketlist-btn float-right"
                      />
                    </ListGroupItem>
                  </Link>
                );
              })
              ) : (
                <p className="greyed-out">
                  Sie haben zur Zeit keine Einkaufszettel...
                </p>
              )}
            </ListGroup> */}
            {/* </Col>
          </Row> */}
				</Container>
      </Container>
    );
  }
}

MarketlistsPage.propTypes = {
  project: PropTypes.object.isRequired,
  marketlist: PropTypes.object.isRequired,
  deleteMarketlist: PropTypes.func.isRequired,
  removeItemFromMarketlist: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  project: state.project,
  marketlist: state.marketlist
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllMarketlists, deleteMarketlist, removeItemFromMarketlist }
)(withRouter(MarketlistsPage)));
