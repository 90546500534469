import gql from "graphql-tag";
// Invite supplier
export const DELETE_MARKET_LIST_MUTATION = gql`
  mutation deleteMarketlist($marketlist_id: String!) {
    deleteMarketlist(marketlist_id: $marketlist_id) {
      id
      name
      items {
        ... on Cartitem {
          item
          item_name
          quantity
          price
          supplier
					supplier_name
					unit
        }
      }
    }
  }
`;