import {
  CREATE_COUPON,
  TOGGLE_COUPON_STATUS,
  GET_COUPONS,
  COUPONS_LOADING
} from "./types";
import { CREATE_COUPON_MUTATION } from "../graphql/mutation/coupons/createCoupon";
import { TOGGLE_COUPON_STATUS_MUTATION } from "../graphql/mutation/coupons/toggleCouponStatus";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";

export const createCoupon = ({ code, discount, discount_value }) => dispatch => {
  console.log(code, discount, discount_value)
  dispatch({ type: COUPONS_LOADING });
  client
    .mutate({
      variables: { code: code, discount: discount, discount_value: discount_value },
      mutation: CREATE_COUPON_MUTATION
    })
    .then(res => {
      dispatch({
        type: CREATE_COUPON,
        payload: res.data.createCoupon
      });
    })
    .catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getCoupons = () => dispatch => {
    dispatch({ type: COUPONS_LOADING });
    client
      .query({
        query: gql`
          query coupons {
            getCoupons {
              id
              code
							value_off
							pct_off
							redeemers
							is_active
            }
          }
        `
      })
      .then(res => {
        dispatch({ type: GET_COUPONS, payload: res.data.getCoupons });
      })
      .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

  export const toggleCouponStatus = couponId => dispatch => {
    client
      .mutate({
        variables: { couponId: couponId },
        mutation: TOGGLE_COUPON_STATUS_MUTATION
      })
      .then(res => {
        try {
          dispatch({
            type: TOGGLE_COUPON_STATUS,
            payload: res.data.toggleCouponStatus
          });
        } catch {
          dispatch(returnErrors(res.errors[0].message, null, "ERROR"));
        }
      })
      .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };