import React, { Component } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changeMarketlistName } from "../../../../actions/marketListActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as NeueBaustelleSvg } from "../../../../assets/NeueBaustelle.svg";

import Avatar from "@material-ui/core/Avatar";

import Swal from "sweetalert2";

import { Image } from "cloudinary-react";

class RenameModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { name } = this.state;

    Swal.fire({
      title: "Einkaufszettel umbenennen",
      text: `Sind Sie sicher, dass Sie den Einkaufszettel name in ${
        name
      } ändern möchten?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeMarketlistName( this.props.marketlist_id, name );
        this.toggle();
      }
    });
  };

  render() {
    return (
      <div>
        <h5 id="rename-modal" className="marketlist-name" onClick={this.toggle}>
          {this.props.marketlist_name ? this.props.marketlist_name : "None"}
          <FontAwesomeIcon
            icon={faPen}
            className="ml-1"
          />
        </h5>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Rename
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Neuer Einkaufszettel name"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Button
                  className="btn-secondary full-width"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Name aktualisieren
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

RenameModal.propTypes = {};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { changeMarketlistName }
)(withRouter(RenameModal));
