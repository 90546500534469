import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent,
  Row,
  Col
} from "reactstrap";

// import { getCart } from "../../../../../actions/cartActions";
// import { removeItemFromCart } from "../../../../../actions/cartActions";
import { getSingleSupplier } from "../../actions/supplierActions";

import FarmerItemsTab from "./tabs/FarmerItemsTab";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class FarmerPage extends Component {
  componentDidMount() {
    const supplierId = this.props.auth?.user?.farmer_account;
    this.props.getSingleSupplier(supplierId);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  getItemPrice = item => {
    var itemPrice = item.quantity * item.price_7_pct;
    itemPrice = itemPrice.toFixed(2);
    itemPrice = parseFloat(itemPrice);
    return itemPrice;
  };

  getOrderTotal = cart => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    return orderTotal;
  };

  removeCartItem = cartitemId => {
    this.props.removeItemFromCart(cartitemId);
    // this.props.removeItemFromCart(cartitemId);
  };

  render() {
    const { user } = this.props.auth;
		const items = this.props.supplier.stock;

    return (
      <div className="page" id="admin-page">
        <Row className="market-page mx-0">
          <Col className="p-0">
            <Container className="my-4">
              <Row>
                <Col>
                  <h4 className="page-title">Farmer</h4>  
                </Col>
              </Row>
            </Container>
            <Container>
              <Nav tabs className="">
                <NavItem className="mobile-right-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Items
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Orders
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3"
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Farmers
                  </NavLink>
                </NavItem>
                <NavItem className="mobile-left-margin">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4"
                    })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    Items
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {/* eslint-disable-next-line */}
                  {this.state.activeTab == 1 ? (
                    <FarmerItemsTab items={items}/>
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 2 ? (
                    <AdminOrdersTab/>
                  ) : null} */}
                </TabPane>
                <TabPane tabId="3">
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 3 ? <AdminFarmersTab /> : null} */}
                </TabPane>
                <TabPane tabId="4">
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 4 ? <AdminItemsTab /> : null} */}
                </TabPane>
              </TabContent>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

FarmerPage.propTypes = {
  getSingleSupplier: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  supplier: state.supplier,
	auth: state.auth
});

export default connect(
  mapStateToProps,
  { getSingleSupplier }
)(FarmerPage);