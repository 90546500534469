import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy
} from "@fortawesome/free-solid-svg-icons";

import { NavItem, NavLink, Nav, NavbarBrand, Button } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import history from "../../../helper/history";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logoTextTitle from "../../../assets/NewHelloFarmerLogo.svg";
import logoSmall from "../../../assets/NewHelloFarmerLogoSmall.png";
import premiumIconLong from "../../../assets/PremiumIconLong.png";
import premiumIcon from "../../../assets/PremiumIcon.png";

import { ReactComponent as DashboardSvg } from "../../../assets/DashboardIcon.svg";
import { ReactComponent as MarketSvg } from "../../../assets/MarketIcon.svg";
import { ReactComponent as OrdersSvg } from "../../../assets/OrdersIcon.svg";
import { ReactComponent as HelpSvg } from "../../../assets/HelpIcon.svg";
import { ReactComponent as FarmersSvg } from "../../../assets/FarmersIcon.svg";
import { ReactComponent as BaustellenSvg } from "../../../assets/Baustellen.svg";
import { ReactComponent as ArchiveSvg } from "../../../assets/Archiv.svg";
import { ReactComponent as PostfachSvg } from "../../../assets/Postfach.svg";
import { ReactComponent as NewPostfachSvg } from "../../../assets/NewPostfach.svg";
import { ReactComponent as StammdatenSvg } from "../../../assets/Stammdaten.svg";

import { logout } from "../../../actions/authActions";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "2"
    };
  }

  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  logout = () => {
    this.props.logout();
    history.push("/register");
  }

  render() {
    let sidebarNavItems;
    const isGuest = this.props.isGuest;

    if (this.props.isOpen) {
      sidebarNavItems = (
        <Nav vertical className="list-unstyled pb-3">
          {/* {this.props.membership.type ? 
            <NavItem>
              <img src={premiumIconLong} alt="Premium-icon-long" id="premium-icon-long" />
            </NavItem> : null
          } */}
          {isGuest ? 
            <NavItem>
              <NavLink
                className={classnames(
                  "sidebar-register",
                  "active",
                  {
                  "side-item": true,
                })}
                onClick={() => {
                  this.logout()
                }}
              >
                Zur Registrierung
              </NavLink>
            </NavItem> : null
          }
          {/* <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "1"
              })}
              tag={Link}
              to={"/"}
              onClick={() => {
                this.toggle("1");
              }}
            >
              <div className="sidebar-svg float-left">
                <DashboardSvg />
              </div>
              Home
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "2"
              })}
              tag={Link}
              to={"/market"}
              onClick={() => {
                this.toggle("2");
              }}
            >
              <div className="sidebar-svg float-left">
                <MarketSvg />
              </div>
              Markt
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "5"
              })}
              tag={Link}
              to={"/farmers"}
              onClick={() => {
                this.toggle("5");
              }}
            >
              <div className="sidebar-svg float-left">
                <FarmersSvg />
              </div>
              Farmer
            </NavLink>
          </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({
                  "side-item": true,
                  active: this.state.activeTab === "3"
                })}
                tag={Link}
                to={"/orders"}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                <div className="sidebar-svg float-left">
                  <OrdersSvg />
                </div>
                Bestellungen
              </NavLink>
            </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "4"
              })}
              tag={Link}
              to={"/help"}
              onClick={() => {
                this.toggle("4");
              }}
            >
              <div className="sidebar-svg float-left">
                <HelpSvg />
              </div>
              Hilfe
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "6"
              })}
              tag={Link}
              to={"/feedback"}
              onClick={() => {
                this.toggle("6");
              }}
            >
              <div className="sidebar-svg float-left">
                <HelpSvg />
              </div>
              Feedback
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "4"
              })}
              tag={Link}
              to={"/analytics"}
              onClick={() => {
                this.toggle("4");
              }}
            >
              Analysen
            </NavLink>
          </NavItem> */}
        </Nav>
      );
    } else {
      sidebarNavItems = (
        <Nav vertical className="list-unstyled pb-3">
          {/* {this.props.membership.type ?
            <NavItem>
              <img src={premiumIcon} alt="Premium-icon" id="premium-icon-short"/>
            </NavItem> : null
          } */}
          {/* <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "1"
              })}
              tag={Link}
              to={"/"}
              onClick={() => {
                this.toggle("1");
              }}
            >
              <div className="sidebar-svg">
                <DashboardSvg />
              </div>
            </NavLink>
          </NavItem> */}
          <NavItem className="mt-3">
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "2"
              })}
              tag={Link}
              to={"/market"}
              onClick={() => {
                this.toggle("2");
              }}
            >
              <div className="sidebar-svg">
                <MarketSvg />
              </div>
            </NavLink>
          </NavItem>
          {/* <NavItem className="mt-3">
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "5"
              })}
              tag={Link}
              to={"/farmers"}
              onClick={() => {
                this.toggle("5");
              }}
            >
              <div className="sidebar-svg">
                <FarmersSvg />
              </div>
            </NavLink>
          </NavItem> */}
            <NavItem className="mt-3">
              <NavLink
                className={classnames({
                  "side-item": true,
                  active: this.state.activeTab === "3",
                })}
                tag={Link}
                to={"/orders"}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                <div className="sidebar-svg">
                  <OrdersSvg />
                </div>
              </NavLink>
            </NavItem>
          <NavItem className="mt-3">
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "4"
              })}
              tag={Link}
              to={"/help"}
              onClick={() => {
                this.toggle("4");
              }}
            >
              <div className="sidebar-svg">
                <HelpSvg />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "6"
              })}
              tag={Link}
              to={"/feedback"}
              onClick={() => {
                this.toggle("6");
              }}
            >
              <div className="sidebar-svg">
                <HelpSvg />
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return (
      <div className={`sidebar ${this.props.isOpen ? "open" : "closed"}`}>
        <div className="sidebar-header">
          {/* <span color="info" style={{ color: "fff" }}>
        &times;
      </span> */}
          <NavbarBrand className="ml-3 mt-2" href="/">
            {this.props.isOpen ? (
              <img src={logoTextTitle} alt="Logo-long" className="Logo-long" />
            ) : (
              <img src={logoSmall} alt="Logo-small" className="Logo-small" />
            )}
          </NavbarBrand>
        </div>
        <div className="side-menu">{sidebarNavItems}</div>
      </div>
    );
  }
}

export default connect(
  null,
  { logout }
)(SideBar);
