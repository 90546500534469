import React, { Component, useState } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import { Container, ListGroup, ListGroupItem, Badge, Table, Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { Image } from "cloudinary-react";
import { connect } from "react-redux";

import { getAllOrders } from "../../../../../actions/orderActions";
import { getCart } from "../../../../../actions/cartActions";
import { addItemToCart } from "../../../../../actions/cartActions";
import { createMarketlistFromOrder } from "../../../../../actions/marketListActions";

import PropTypes from "prop-types";
import history from "../../../../../helper/history";

import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./invoices/Invoice";
import invoice from "./invoices/invoiceData";

class OrdersList extends Component {
  componentDidMount() {
    this.props.getAllOrders();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    selectedOrder: null
  };

  onDeleteClick = id => {
    this.props.deleteProject(id);
  };

  createMarketListFromOrder = (orderId) => {
    this.props.createMarketlistFromOrder(orderId);
    Swal.fire({
      text: this.props.t('shoppingListCreated'),
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  }

  addOrderItemsToCart = (orderItems, cart) => {
    var cartContainsOrderItem = null;
    orderItems.forEach(orderItem => {
      if (cart.items.some(item => item.item === orderItem.item)) {
        cartContainsOrderItem = true;
      }
    })
    if (cartContainsOrderItem) {
      Swal.fire({
        text: this.props.t('atLeastOneItemFromYourOrderIsAlreadyInTheShoppingCart'),
        icon: "error",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    } else {
      orderItems.forEach(item => {
        const newCartitem = {
          image: item.image,
          item: item.item,
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.price,
          price_7_pct: item.price_7_pct,
          supplier: item.supplier,
          supplier_name: item.supplier_name,
          unit: item.unit
        };

        this.props.addItemToCart(newCartitem);

      });
      Swal.fire({
        text: this.props.t('itemsPlacedInTheShoppingCart'),
        icon: "success",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    }
  }

  getShortOrderId = orderId => {
    var shortOrderId = ""
    shortOrderId = orderId.substring(0,6) + "..."
    return shortOrderId 
  }

  deliveryDateField = delivery_date => {
    if (delivery_date){
      if (delivery_date.charAt(4) == "-") {
        var shortOrderDate = delivery_date.split("T")[0]
        var splitOrderDate = shortOrderDate.split("-")
        const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
        return date
      } else {
        var splitDeliveryDate = delivery_date.split(' ')
        var day = splitDeliveryDate[2];
        var month = splitDeliveryDate[1];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months.indexOf(month);
        month += 1;
        var year = splitDeliveryDate[3];
        const date = day + '.' + ('0' + month).slice(-2) + '.' + year
        return date
      }
    }
  }

  orderDateField = orderDate => {
    if (orderDate){
      var shortOrderDate = orderDate.split("T")[0]
      var splitOrderDate = shortOrderDate.split("-")
      const date = splitOrderDate[2] + "." + splitOrderDate[1] + "." + splitOrderDate[0]
      return date
    }
  }

  statusField = status => {
    switch (status) {
      case 0:
        return <span className="order-status-yellow">{this.props.t('processing')}</span>;
      case 1:
        return <span className="order-status-red">{this.props.t('cancelled')}</span>;
      case 2:
        return <span>{this.props.t('delivering')}</span>;
      case 3:
        return <span>{this.props.t('undeliverable')}</span>;
      case 4:
        return <span className="order-status-green">{this.props.t('confirmed')}</span>;
    }
  };

  getOrderItemsPreviewImages = items => {
    let orderItemPreviewImages = [];
    
    for (let i = 0; i < 7; i++){
      if (items[i]){
        orderItemPreviewImages.push(items[i].image)
      }
    }

    return orderItemPreviewImages;
  }

  selectOrder = (order) => {
    console.log(order)
    this.setState({
      selectedOrder: order
    });
  }

  getSelectedOrderCard = () => {
    let order = this.state.selectedOrder;
    const { user } = this.props.auth;
    const { cart } = this.props.cart;

    return (
      <div id='selected-order-card'>
        <div style={{display: 'flex', flexDirection: 'row', border: '1px solid #DEDEDE', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.75rem',paddingRight: '0.75rem',  borderRadius: 8, marginBottom: '1.5rem'}}>
          <div>
            <p style={{fontSize: 26, fontWeight: 'bold', color: 'black', marginBottom: 0}}>
              {this.props.t('order')}
            </p>
            <p style={{fontSize: 18, color: '#858585', marginBottom: '0.4rem'}}>
              #{order.order_no}
            </p>
          </div>
          <p style={{marginLeft: 'auto', marginTop: 5}}>
            {this.statusField(order.status)}
          </p>
        </div>
        <div id='selected-order-items'>
        {order.items.map(item => {
          console.log(item)
          return (
            <Row style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
              <Col style={{maxWidth: '20%', paddingRight: 0}}>
                <Image publicId={item.image} />
              </Col>
              <Col style={{maxWidth: '60%', padding: 0}}>
                <p style={{fontSize: 16, fontWeight: 'bold', color: 'black'}}>{item.item_name}</p>
                <Row>
                  <Col>
                    <p className='label'>{this.props.t('unit')}: <span className='value'>{item.unit}</span></p>
                    <p className='label'>{this.props.t('orderQuantity')}: <span className='value'>{item.quantity_accepted ? item.quantity_accepted : 'N/A'}</span></p>
                  </Col>
                  <Col>
                    <p className='label'>{this.props.t('quantityReceived')}: <span className='value'>{item.quantity_received ? item.quantity_received : 'N/A'}</span></p>
                    <p className='label'>{this.props.t('price')}: <span className='value'>{this.props.t('currencySymbol')}{item.price.toFixed(2)}</span></p>
                  </Col>
                </Row>              
              </Col>
              <Col style={{maxWidth: '20%'}}>
                <p style={{textAlign: 'right', fontSize: 14, fontWeight: 'bold', color: '#C25982'}}>{this.props.t('currencySymbol')}{item.price.toFixed(2)}</p>
              </Col>
            </Row>
          )
        })}
        </div>
        {/* <div style={{marginBottom: '0.5rem'}}>
        <div className='label-value'>
          <p className='label'>
            Name
          </p>
          <p className='value'>
            {order.supplier.name}
          </p>
        </div>
        <div className='label-value'>
          <p className='label'>
            Packmass
          </p>
          <p className='value'>
            {order.supplier.name}
          </p>
        </div>
        <div className='label-value'>
          <p className='label'>
            Bestellmenge
          </p>
          <p className='value'>
            {order.supplier.name}
          </p>
        </div>
        <div className='label-value'>
          <p className='label'>
            Erhaltene Menge
          </p>
          <p className='value'>
            {order.supplier.name}
          </p>
        </div>
        <div className='label-value'>
          <p className='label'>
            Preis
          </p>
          <p className='value'>
            {order.supplier.name}
          </p>
        </div>
        </div> */}
        <div style={{marginBottom: '1rem'}}>
          <h3>
            {this.props.t('orderSummary')}
          </h3>
          <div className='label-value'>
          <p className='label'>
            {this.props.t('article')}
          </p>
          <p className='value' style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}{order.total_price ? order.total_price.toFixed(2) : `${this.props.t('currencySymbol')}0.00`}
          </p>
        </div>
        <div className='label-value' style={{borderBottom: '2px solid #A9CB59', paddingBottom: '1rem', marginBottom: '1rem'}}>
          <p className='label'>
            {this.props.t('delivery')}
          </p>
          <p className='value' style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}5
          </p>
        </div>
        <div className='label-value'>
          <h3>
            {this.props.t('orderSummary')}
          </h3>
          <h3 style={{marginLeft: 'auto'}}>
            {this.props.t('currencySymbol')}{order.total_price ? (order.total_price + 5).toFixed(2) : `${this.props.t('currencySymbol')}0.00`}
          </h3>
        </div>
        </div>
        <div style={{textAlign: 'center', marginBottom: '1rem'}}>
        <Button onClick={() => this.addOrderItemsToCart(order?.items, cart)}>
          {this.props.t('reorder')}
        </Button>
        <Button onClick={() => this.createMarketListFromOrder(order.id)} style={{backgroundColor: 'white', color: '#C25982', borderWidth: 2}}>
          {this.props.t('marketList')}
        </Button>
        </div>
        <div style={{borderBottom: '1px solid #CECECE', marginLeft: '-1rem', marginRight: '-1rem', marginBottom: '2rem'}}>
        </div>
        <div style={{textAlign: 'center'}}>
          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: '0.5rem'}}>{this.props.t('deliveryNote')}?</p>
          {/* <Button>
            Herunterladen
          </Button> */}
          <Button className="full-width download-invoice-btn download-lieferschein-btn mb-3">
            <PDFDownloadLink
              document={
                <Invoice
                  title="Lieferschein"
                  invoice={invoice}
                  order_no={order?.order_no}
                  order_date={
                    order?.date ? this.orderDateField(order.date) : "None"
                  }
                  order_price={order?.price?.toFixed(2)}
                  order_7_pct_tax={order?.seven_pct_tax?.toFixed(2)}
                  order_total={order?.total_price?.toFixed(2)}
                  customer_name={order?.delivery_address?.name}
                  customer_street_address={
                    order?.delivery_address?.street_address
                  }
                  customer_zip_code={
                    order?.delivery_address?.zip_code
                  }
                  customer_city={order?.delivery_address?.city}
                  customer_phone={""}
                  customer_email={user?.email}
                  items={order?.items}
                  discount={order?.discount}
                />
              }
              fileName="hellofarmer_lieferschein.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Laden......" : "Herunterladen"
              }
            </PDFDownloadLink>
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const orders = this.props.order.orders;
    const orderedOrders = [...orders].reverse()
    const id = "singleorder";
    const isArchived = this.props.isArchived;
    const page = this.props.page;
    const { loading } = this.props.order

    return (
      <LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
        >
          <Row>
            <Col>
          {orderedOrders.length ? (
              this.props.filter
              ? orderedOrders
                  .filter(order => order.status == this.props.filter)
                  .map(({ id, order_no, total_price, date, items, delivery_date, status }) => {
                    return (
                      <tr
                        className="orders-list-item"
                        onClick={() => history.push(`/orders/${id}`)}
                      >
                        <td className="pl-4">{order_no}</td>
                        {/* <td>{supplier ? supplier.name : "None"}</td> */}
                        <td>{this.orderDateField(date.split("T")[0])}</td>
                        <td className="hide-on-mobile">{items.length} {this.props.t('article').toLowerCase()}</td>
                        <td>{this.props.t('currencySymbol')}{(total_price + 5).toFixed(2)}</td>
                        <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                        <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      </tr>
                    );
                  })
              : orderedOrders.map(
                  (order) => {
                    return (
                      <div>
                      <div id='order-list-item-mobile' onClick={() => history.push(`/orders/${order.id}`)} className='order-list-item-container' style={{borderBottom: '1px solid #C25982', paddingBottom: '1rem', marginBottom: '1rem'}}>
                      <div onClick={() => this.selectOrder(order)} className={classnames(
													"order-list-item",
                  				{
                    				active: this.state.selectedOrder?.id === order?.id
                  				}
                				)} style={{padding: '0.75rem', borderRadius: 14}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {this.orderDateField(order.date)}
                          </p>
                          <p style={{fontSize: 12, fontWeight: '600', color: '#7C7C7C', marginLeft: 'auto', marginBottom: 0}}>
                            Status {this.statusField(order.status)}
                          </p>
                        </div>
                        {(order.items.length >= 6) ?
                        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem', justifyContent: 'space-between'}} id='order-items-preview-container-1'>
                          {this.getOrderItemsPreviewImages(order.items).map((image) => { 
                            return (
                              <Image publicId={image} />
                            )
                          })}  
                        </div>
                        : <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem'}} id='order-items-preview-container-2'>
                        {this.getOrderItemsPreviewImages(order.items).map((image) => { 
                          return (
                            <Image publicId={image} style={{marginRight: '1.25rem'}} />
                          )
                        })}  
                      </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {this.props.t('order')} <spam style={{color: '#858585'}}>{order.order_no}</spam>
                          </p>
                          <p style={{fontSize: 12, fontWeight: '600', color: '#858585', marginLeft: 'auto', marginBottom: 0}}>
                            {this.props.t('price')} <span style={{fontWeight: 'bold', color: 'black'}}>{this.props.t('currencySymbol')}{(order.total_price + 5).toFixed(2)}</span>
                          </p>
                        </div>
                      </div>
                      </div>
                      
                      <div id='order-list-item-desktop' className='order-list-item-container' style={{borderBottom: '1px solid #C25982', paddingBottom: '1rem', marginBottom: '1rem'}}>
                      <div onClick={() => this.selectOrder(order)} className={classnames(
													"order-list-item",
                  				{
                    				active: this.state.selectedOrder?.id === order?.id
                  				}
                				)} style={{padding: '0.75rem', borderRadius: 14}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {this.orderDateField(order.date)}
                          </p>
                          <p style={{fontSize: 12, fontWeight: '600', color: '#7C7C7C', marginLeft: 'auto', marginBottom: 0}}>
                            Status {this.statusField(order.status)}
                          </p>
                        </div>
                        {(order.items.length >= 6) ?
                        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem', justifyContent: 'space-between'}} id='order-items-preview-container-1'>
                          {this.getOrderItemsPreviewImages(order.items).map((image) => { 
                            return (
                              <Image publicId={image} />
                            )
                          })}  
                        </div>
                        : <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0.75rem', paddingBottom: '0.75rem'}} id='order-items-preview-container-2'>
                        {this.getOrderItemsPreviewImages(order.items).map((image) => { 
                          return (
                            <Image publicId={image} style={{marginRight: '1.25rem'}} />
                          )
                        })}  
                      </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <p style={{fontSize: 12, fontWeight: '600', color: 'black', marginBottom: 0}}>
                            {this.props.t('order')} <spam style={{color: '#858585'}}>{order.order_no}</spam>
                          </p>
                          <p style={{fontSize: 12, fontWeight: '600', color: '#858585', marginLeft: 'auto', marginBottom: 0}}>
                            {this.props.t('price')} <span style={{fontWeight: 'bold', color: 'black'}}>{this.props.t('currencySymbol')}{(order.total_price + 5).toFixed(2)}</span>
                          </p>
                        </div>
                      </div>
                      </div>
                      </div>
                      // <tr
                      //   className="orders-list-item dark-grey"
                      //   onClick={() => history.push(`/orders/${id}`)}
                      // >
                      //   <td className="pl-4">{order_no}</td>
                      //   {/* <td>{supplier ? supplier.name : "None"}</td> */}
                      //   <td>{this.orderDateField(date)}</td>
                      //   <td className="hide-on-mobile">{items.length} Artikel</td>
                      //   <td>{(total_price + 5).toFixed(2)}€</td>
                      //   <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                      //   <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      // </tr>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    {this.props.t('youDoNotHaveAnyOrdersAtTheMoment')}...
                  </p>
                )}
            </Col>
            <Col>
              {this.state.selectedOrder ? this.getSelectedOrderCard() : null}
            </Col>
          </Row>
      {/* <div className="project-list">
        <Table>
          <thead>
            <tr className="greyed-out">
              <th className="pl-4">Bestellung #</th>
              <th>Bestelldatum</th>
              <th className="hide-on-mobile">Anzahl der Artikel</th>
              <th>Preis</th>
              <th className="hide-on-mobile">Lieferdatum</th>
              <th className="pr-4 hide-on-mobile">Status</th>
            </tr>
          </thead>
          <tbody>
            {orderedOrders.length ? (
              this.props.filter
              ? orderedOrders
                  .filter(order => order.status == this.props.filter)
                  .map(({ id, order_no, total_price, date, items, delivery_date, status }) => {
                    return (
                      <tr
                        className="orders-list-item"
                        onClick={() => history.push(`/orders/${id}`)}
                      >
                        <td className="pl-4">{order_no}</td>
                        <td>{this.orderDateField(date.split("T")[0])}</td>
                        <td className="hide-on-mobile">{items.length} Artikel</td>
                        <td>{(total_price + 5).toFixed(2)}€</td>
                        <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                        <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      </tr>
                    );
                  })
              : orderedOrders.map(
                  ({ id, order_no, total_price, date, items, delivery_date, status }) => {
                    return (
                      <tr
                        className="orders-list-item dark-grey"
                        onClick={() => history.push(`/orders/${id}`)}
                      >
                        <td className="pl-4">{order_no}</td>
                        <td>{this.orderDateField(date)}</td>
                        <td className="hide-on-mobile">{items.length} Artikel</td>
                        <td>{(total_price + 5).toFixed(2)}€</td>
                        <td className="hide-on-mobile">{this.deliveryDateField(delivery_date)}</td>
                        <td className="pr-4 hide-on-mobile">{this.statusField(status)}</td>
                      </tr>
                    );
                  }
                )) : (
                  <p className="greyed-out pt-3 px-3">
                    Sie haben zur Zeit keine Bestellungen...
                  </p>
                )}
          </tbody>
        </Table>
      </div> */}
      </LoadingOverlay>
    );
  }
}

OrdersList.propTypes = {
  order: PropTypes.object.isRequired,
  isArchived: PropTypes.bool,
  getCart: PropTypes.func.isRequired,
  createMarketlistFromOrder: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  order: state.order,
  auth: state.auth,
  cart: state.cart
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllOrders, createMarketlistFromOrder, getCart, addItemToCart }
)(OrdersList));
