import gql from "graphql-tag";
// Login user

export const UPDATE_FARMER_ITEM_QUANTITY_MUTATION = gql`
  mutation updateFarmerItemQuantity($farmerId: String!, $stockItemId: String!, $value: Int!) {
    updateFarmerItemQuantity(farmerId: $farmerId, stockItemId: $stockItemId, value: $value) {
      id
      name
      email
      bio
      tax_id
      telephone
      avatar
      type
      farmer_picture
      website
      stock {
        ... on StockItem {
          item
          item_name
          price_per_unit
          price_per_unit_net
          price_per_unit_7_pct
          price_per_unit_19_pct
          unit
          amount
        }
      }
      farm_name
      year_founded
      location
      location_image
      location_coordinates {
        lat
        lng
      }
      certifications
      gallery
      articles
      address {
        street_address
        street_address_2
        zip_code
        city
        country
      }
    }
  }
`;
