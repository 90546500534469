import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import {
  Button,
  Label,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Container,
  Alert,
  ButtonGroup,
  Row,
  Col
} from "reactstrap";
// import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../../actions/authActions";
import { clearErrors } from "../../../actions/errorActions";
import logoTitle from "../../../assets/NewHelloFarmerLogo.svg";

import { ReactComponent as FieldCheckGray } from "../../../assets/FieldCheckGray.svg";
import { ReactComponent as FieldCheckGreen } from "../../../assets/FieldCheckGreen.svg";
import { ReactComponent as FieldCheckRed } from "../../../assets/FieldCheckRed.svg";
import { ReactComponent as UserIcon } from "../../../assets/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/PasswordIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/PhoneIcon.svg";
import { ReactComponent as CityIcon } from "../../../assets/CityIcon.svg";

import history from "../../../helper/history";

class RegisterPage extends Component {
  state = {
    modal: false,
    name: "",
    email: "",
    telephone: "",
    password: "",
    city: "",
    street_address: "",
    street_address_2 : "",
    zip_code : "",
    userType: 0,
    termsAndConditionsAgreed: false,
    receiveMarketingMaterialAgreed: false,
    msg: null,
  };

  static propTypes = {
    loading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === "REGISTER_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onTermsAndConditionsCheckboxToggle = () => {
    this.setState({
      termsAndConditionsAgreed: !this.state.termsAndConditionsAgreed
    });
  }

  oMarketingMaterialCheckboxToggle = () => {
    this.setState({
      receiveMarketingMaterialAgreed: !this.state.receiveMarketingMaterialAgreed
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const { name, email, telephone, password, city, userType, receiveMarketingMaterialAgreed, street_address, street_address_2, zip_code } = this.state;
    var zip_code_int = parseInt(zip_code)
    const coverage_zone = [10623,10557,10789,10787,10785,10117,10777,10779,10781,10783,10963,10969,10823,10825,12159,12157,12103,12101,12049,10961,10827,10829,10965]
    if (coverage_zone.includes(zip_code_int)){
      const user = {
        name,
        email: email.toLowerCase(),
        telephone,
        password,
        language: "Deutsch",
        region: "Europa",
        city,
        userType,
        sendMarketingMaterial: receiveMarketingMaterialAgreed,
        address: {
          name: name,
          street_address: street_address,
          street_address_2: street_address_2,
          zip_code: parseInt(zip_code),
          city: city,
          country: "Deutschland"
        }
      };
  
      // Attempt to login
  
      this.props.register(user);
      // history.push("/register-success")
    } else {
      Swal.fire({
        title: "Wir sind derzeit in Ihrem Ort nicht verfügbar, wir arbeiten daran",
        icon: "warning",
        confirmButtonColor: "#C25982",
        confirmButtonText: "Ok"
      });
    }
  };

  setUserType = selectedUserType => {
    if (selectedUserType != this.state.userType){
      this.setState({userType: selectedUserType})
    }
  }

  getFormCheckIcon = (field) => {
    switch (field){
      case 1:
        var re = /(?=.*[a-z])/ ;
        if (this.state.password == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.password)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 2:
        var re = /(?=.*[A-Z])/;
        if (this.state.password == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.password)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 3:
        var re = /^.{8,}/;
        if (this.state.password == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.password)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
      case 4:
        var re = /(.*\W.*)/;
        if (this.state.password == ""){
          return (
            <div>
              <FieldCheckGray className="float-left mr-2"/>
            </div>
          )
        } else if(re.test(this.state.password)){
          return (
            <div>
              <FieldCheckGreen className="float-left mr-2"/>
            </div>
          )
        } else {
          return (
            <div>
              <FieldCheckRed className="float-left mr-2"/>
            </div>
          )
        }
          
    }
    
  }

  isReadyForRegister = () => {
    var re1 = /(?=.*[a-z])/;
    var re2 = /(?=.*[A-Z])/;
    var re3 = /^.{8,}/;
    var re4 = /(.*\W.*)/;

    if (this.state.termsAndConditionsAgreed == false || this.state.name == "" || this.state.email == "" || this.state.telephone == "" || this.state.city == "" || !(re1.test(this.state.password)) || !(re2.test(this.state.password)) || !(re3.test(this.state.password)) || !(re4.test(this.state.password))){
      return false
    } else {
      return true
    }
  }

  render() {
    var isReadyForRegister = this.isReadyForRegister()
    const { loading } = this.props;

    return (
      <LoadingOverlay
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <div id="register-page" className="login auth-page">
        <Container className="login-page center">
          <div className="mb-4 mt-4">
            <a href="https://www.hellofarmer.de/">
              <img src={logoTitle} alt="Logo" />
            </a>  
          </div>

          <div className="panel">
            <h2 className="center">{this.props.t('register')}</h2>

            <Form className="py-4 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                {/* <ButtonGroup className="mb-3 full-width"> */}
                <div className='mb-3'>
                  <Button className={classnames(
                    "user-type-btn",
                      {
                        active: this.state.userType == 0
                      }
                    )} onClick={() => this.setUserType(0)} style={{width: '48%', marginRight: '4%'}}>{this.props.t('individual')}</Button>
                  <Button className={classnames(
                    "user-type-btn",
                      {
                        active: this.state.userType == 1
                      }
                    )} onClick={() => this.setUserType(1)} style={{width: '48%'}}>{this.props.t('company')}</Button>
                </div>
                {/* </ButtonGroup> */}
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={this.props.t('yourName')}
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={this.props.t('yourEmail')}
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PhoneIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="telephone"
                    id="telephone"
                    placeholder={this.props.t('yourPhoneNumber')}
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <CityIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="street_address"
                    id="craftsman"
                    className="mb-3"
                    placeholder={this.props.t('yourStreet')}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <CityIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="street_address_2"
                    id="craftsman"
                    className="mb-3"
                    placeholder={this.props.t('yourStreet2')}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <CityIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="zip_code"
                    id="craftsman"
                    className="mb-3"
                    placeholder={this.props.t('yourPostCode')}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <CityIcon className="input-icon"/>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder={this.props.t('yourCity')}
                    className="mb-3"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-input">
                  <PasswordIcon className="input-icon"/>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={this.props.t('yourPassword')}
                    className="mb-3"
                    onChange={this.onChange}
                />
                </div>
                <Row id="password-validation" className="px-2">
                  <Col>
                    <p className="form-check-field">{this.getFormCheckIcon(1)}<span className="disabled-text">{this.props.t('atLeast1LowerCaseLetter')}</span></p>
                    <p className="form-check-field">{this.getFormCheckIcon(2)}<span className="disabled-text">{this.props.t('atLeast1CapitalLetter')}</span></p>
                  </Col>
                  <Col>
                    <p className="form-check-field">{this.getFormCheckIcon(3)}<span className="disabled-text">{this.props.t('atLeast8Characters')}</span></p>
                    <p className="form-check-field">{this.getFormCheckIcon(4)}<span className="disabled-text">{this.props.t('atLeast1NumberOrSpecialCharacter')}</span></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup
                      className="register-checkbox"
                      check
                      inline
                    >
                      <Input type="checkbox" defaultChecked={this.state.termsAndConditionsAgreed} onChange={this.onTermsAndConditionsCheckboxToggle}/>
                      <Label className="greyed-out" check>
                        {this.props.t('iAgreeToThe')} <a href="https://www.hellofarmer.de/geschaftsbedingungen">{this.props.t('termsAndConditions')}</a>{this.props.t('zu')}.
                      </Label>
                    </FormGroup>
                    <FormGroup
                      className="register-checkbox"
                      check
                      inline
                    >
                      <Input type="checkbox" defaultChecked={this.state.receiveMarketingMaterialAgreed} onChange={this.oMarketingMaterialCheckboxToggle}/>
                      <Label className="greyed-out" check>
                        {this.props.t('iAgreeToReceiveOffersAndAdvertisingFromHellofarmer')}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  disabled={!isReadyForRegister}
                  className="authButtonPrimary"
                  style={{ marginTop: "2rem", height: 40, fontSize: 14 }}
                  block
                >
                  {this.props.t('register').toUpperCase()}
                </Button>
                <p className="center greyed-out" style={{fontSize: 14}}>{this.props.t('or')}</p>
                <p className="center mb-0">
                  <a className="center" href="/login" style={{fontSize: 14}}>
                    {this.props.t('signIn')}
                  </a>
                </p>
              </FormGroup>
            </Form>
            {/* <a className="signup-link" href="/login">
              <CardFooter>
                <span className="greyed-out">
                  Sie haben schon einen Account?{" "}
                </span>
                <span className="underline">Login</span>
              </CardFooter>
            </a> */}
          </div>
        </Container>
      </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default withTranslation()(connect(
  mapStateToProps,
  { register, clearErrors }
)(RegisterPage));

//FINAL VERSION:
// import React, { Component } from "react";

// // import RoleRegisterStep from "./RoleRegisterStep";
// import TierRegisterStep from "./TierRegisterStep";
// import UserInfoRegisterStep from "./UserInfoRegisterStep";
// import SummaryRegisterStep from "./SummaryRegisterStep";

// import { Container } from "reactstrap";

// class RegisterPage extends Component {
//   state = {
//     step: 1,

//     // STEP 1
//     role: "",

//     // STEP 2
//     tier: "",

//     // STEP 3
//     // User Info
//     name: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     //Company Info
//     companyName: "",
//     companyEmail: "",
//     companyAddress: "",
//     companyTel: "",

//     companyBic: "",
//     companyIban: "",

//     companyInstLocation: "",
//     companyRegistryNum: ""
//   };

//   nextStep = () => {
//     const { step } = this.state;

//     this.setState({
//       step: step + 1
//     });
//   };

//   prevStep = () => {
//     const { step } = this.state;

//     this.setState({
//       step: step - 1
//     });
//   };

//   handleRolePick = role => {
//     this.setState({ role: role });
//     this.nextStep();
//   };

//   handleTierPick = tier => {
//     this.setState({ tier: tier });
//     this.nextStep();
//   };

//   handleChange = input => e => {
//     this.setState({ [input]: e.target.value });
//   };

//   handleSignup = () => {
//     console.log(this.state);
//   };

//   showStep = () => {
//     const {
//       step,
//       role,
//       tier,
//       name,
//       email,
//       password,
//       companyName,
//       companyEmail,
//       companyAddress,
//       companyTel,
//       companyBic,
//       companyIban,
//       companyInstLocation,
//       companyRegistryNum
//     } = this.state;

//     if (step == 1) {
//       //   return <RoleRegisterStep handleRolePick = {this.handleRolePick} handleChange = {this.handleChange} nextStep = {this.nextStep} role = {role}/>
//       // } else if (step == 2){
//       return (
//         <TierRegisterStep
//           handleTierPick={this.handleTierPick}
//           handleChange={this.handleChange}
//           nextStep={this.nextStep}
//           prevStep={this.prevStep}
//           tier={tier}
//         />
//       );
//     } else if (step == 2) {
//       return (
//         <UserInfoRegisterStep
//           handleChange={this.handleChange}
//           nextStep={this.nextStep}
//           prevStep={this.prevStep}
//           name={name}
//           email={email}
//           password={password}
//           companyName={companyName}
//           companyEmail={companyEmail}
//           companyAddress={companyAddress}
//           companyTel={companyTel}
//           companyBic={companyBic}
//           companyIban={companyIban}
//           companyInstLocation={companyInstLocation}
//           companyRegistryNum={companyRegistryNum}
//         />
//       );
//     } else if (step == 3) {
//       return (
//         <SummaryRegisterStep
//           handleSignup={this.handleSignup}
//           prevStep={this.prevStep}
//           role={role}
//           tier={tier}
//           name={name}
//           email={email}
//           password={password}
//           companyName={companyName}
//           companyEmail={companyEmail}
//           companyAddress={companyAddress}
//           companyTel={companyTel}
//           companyBic={companyBic}
//           companyIban={companyIban}
//           companyInstLocation={companyInstLocation}
//           companyRegistryNum={companyRegistryNum}
//         />
//       );
//     }
//   };

//   render() {
//     const { step } = this.state;
//     return (
//       <>
//         <div id="register">
//           <Container className="register-page">
//             <div id="register-process-status">
//               <p>Step {step} of 3</p>
//             </div>
//             <div id="register-step-contents">{this.showStep()}</div>
//           </Container>
//         </div>
//       </>
//     );
//   }
// }

// export default RegisterPage;

//OLD VERSION:
// import React, { Component } from "react";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Input,
//   Container,
//   Alert
// } from "reactstrap";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import { register } from "../../actions/authActions";
// import { clearErrors } from "../../actions/errorActions";

// class RegisterPage extends Component {
//   state = {
//     modal: false,
//     name: "",
//     email: "",
//     password: "",
//     msg: null
//   };

//   static propTypes = {
//     isAuthenticated: PropTypes.bool,
//     error: PropTypes.object.isRequired,
//     register: PropTypes.func.isRequired,
//     clearErrors: PropTypes.func.isRequired
//   };

//   componentDidUpdate(prevProps) {
//     const { error, isAuthenticated } = this.props;
//     if (error !== prevProps.error) {
//       // Check for register error
//       if (error.id === "REGISTER_FAIL") {
//         this.setState({ msg: error.msg.msg });
//       } else {
//         this.setState({ msg: null });
//       }
//     }

//     // If authenticated close modal
//     if (this.state.modal) {
//       if (isAuthenticated) {
//         this.toggle();
//       }
//     }
//   }

//   toggle = () => {
//     // Clear errors
//     this.props.clearErrors();
//     this.setState({
//       modal: !this.state.modal
//     });
//   };

//   onChange = e => {
//     this.setState({ [e.target.name]: e.target.value });
//   };

//   onSubmit = e => {
//     e.preventDefault();

//     const { name, email, password } = this.state;

//     // Create a user object
//     const newUser = {
//       name,
//       email,
//       password
//     };

//     //Attempt to register
//     this.props.register(newUser);
//   };

//   render() {
//     return (
//       <div>
//         <Container className="login-page">
//           <div className="mb-5">
//             <img src={logoTitle} alt="Logo" />
//           </div>
//           {this.state.msg ? (
//             <Alert color="danger">{this.state.msg}</Alert>
//           ) : null}
//           <Form onSubmit={this.onSubmit}>
//             <FormGroup>
//               <Input
//                 type="text"
//                 name="name"
//                 id="name"
//                 placeholder="Name"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Input
//                 type="email"
//                 name="email"
//                 id="email"
//                 placeholder="Email"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Input
//                 type="password"
//                 name="password"
//                 id="password"
//                 placeholder="Password"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Button color="dark" style={{ marginTop: "2rem" }} block>
//                 Register
//               </Button>
//             </FormGroup>
//           </Form>
//         </Container>
//       </div>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
//   error: state.error
// });

// export default connect(
//   mapStateToProps,
//   { register, clearErrors }
// )(RegisterPage);
