import {
  ADD_DELIVERY_ADDRESS,
  CHANGE_DELIVERY_ADDRESS,
  DELETE_DELIVERY_ADDRESS,
  GET_DELIVERY_ADDRESSES
} from "../actions/types";

const initialState = {
  deliveryaddresses: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryaddresses: [...state.deliveryaddresses, action.payload],
        loading: false
      };
    case CHANGE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryaddresses: action.payload,
        // deliveryaddresses: [...state.deliveryaddresses],
        // user: action.payload,
        loading: false
      };
    case DELETE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryaddresses: action.payload,
        // deliveryaddresses: [...state.deliveryaddresses],
        // user: action.payload,
        loading: false
      };
    case GET_DELIVERY_ADDRESSES:
      return {
        ...state,
        deliveryaddresses: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
