import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import logo from "../../../../../../assets/NewHelloFarmerLogo.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column"
  },
  logo: {
    width: 100,
    marginRight: "auto"
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24
  },
  reportTitle: {
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "right",
    textTransform: "uppercase"
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 10,
    justifyContent: "flex-end"
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold"
  },
  label: {
    width: 80
  },
  headerContainer: {
    marginTop: 36
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica"
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  description: {
    width: "60%"
  },
  qty: {
    width: "10%"
  },
  rate: {
    width: "15%"
  },
  amount: {
    width: "15%"
  },
  footerrow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold"
  },
  footerdescription: {
    width: "85%",
    textAlign: "right",

    paddingRight: 8
  },
  footertotal: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8
  },
  footerContainer: {
    marginTop: 10,
    marginRight: "auto"
  },
  tytitleContainer: {
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: 10
  },
  tyreportTitle: {
    fontSize: 12,
    textAlign: "center"
  },
  datarow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold"
  },
  datadescription: {
    width: "60%",
    textAlign: "left",
    paddingLeft: 8
  },
  dataqty: {
    width: "10%",
    textAlign: "center",
    paddingRight: 8
  },
  datarate: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8
  },
  dataamount: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8
  },
  footerinforow: {
    borderTopColor: "black",
    borderTopWidth: 1,
    flexDirection: "row",
    marginBottom: 20
  }
});

const discountRow = (discount) => {
  if(discount != 0 && discount != null){
    return (
      <View style={styles.footerrow}>
        <Text style={styles.footerdescription}>- Gutschein</Text>
        <Text style={styles.footertotal}>{discount.toFixed(2)} EUR</Text>
      </View>
    )
  }
}

const deliveryNoteTitle = title => {
  if(title == "Lieferschein"){
    return (
      <Text style={styles.billTo}>Lieferung an:</Text>
    )
  }
}

const Invoice = ({
  title,
  order_no,
  order_date,
  order_price,
  order_7_pct_tax,
  order_total,
  customer_name,
  customer_street_address,
  customer_zip_code,
  customer_city,
  customer_phone,
  customer_email,
  items,
  discount
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <View style={styles.titleContainer}>
        <Text style={styles.reportTitle} t>
          {title ? title.toUpperCase() : "NONE"}
        </Text>
      </View>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Bestell-Nr.:</Text>
        <Text style={styles.invoiceDate}>{order_no ? order_no : "none"}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>Datum: </Text>
        <Text>{order_date ? order_date : "none"}</Text>
      </View>
      <View style={styles.headerContainer}>
        {deliveryNoteTitle(title)}
        <Text>{customer_name ? customer_name : "none"}</Text>
        <Text>
          {customer_street_address ? customer_street_address : "none"}
        </Text>
        <Text>
          {customer_zip_code ? customer_zip_code : "none"}{" "}
          {customer_city ? customer_city : "none"}
        </Text>
        <Text>
          Deutschland
        </Text>
        <Text>{customer_phone ? customer_phone : ""}</Text>
        {/* <Text>{customer_email ? customer_email : "none"}</Text> */}
      </View>
      <View style={styles.tableContainer}>
        <View style={styles.container}>
          <Text style={styles.description}>Artikel</Text>
          <Text style={styles.qty}>Menge</Text>
          <Text style={styles.amount}>Preis</Text>
        </View>
      </View>
      {items?.map(item => (
        <View style={styles.datarow}>
          <Text style={styles.datadescription}>{item?.item_name}</Text>
          <Text style={styles.dataqty}>{item?.quantity}</Text>
          <Text style={styles.dataamount}>{(item?.price * item?.quantity).toFixed(2)}</Text>
        </View>
      ))}
      <View style={styles.footerrow}>
        <Text style={styles.footerdescription}>Zwischensumme Netto</Text>
        <Text style={styles.footertotal}>
          {order_price ? order_price : "none"} EUR
        </Text>
      </View>
      <View style={styles.footerrow}>
        <Text style={styles.footerdescription}>+ Mehrwertsteuer 7%</Text>
        <Text style={styles.footertotal}>
          {order_7_pct_tax ? order_7_pct_tax : "none"} EUR
        </Text>
      </View>
      <View style={styles.footerrow}>
        <Text style={styles.footerdescription}>+ Mehrwertsteuer 19%</Text>
        <Text style={styles.footertotal}>0 EUR</Text>
      </View>
      {discountRow(discount)}
      <View style={styles.footerrow}>
        <Text style={styles.footerdescription}>Gesamtsumme Brutto</Text>
        <Text style={styles.footertotal}>
          {order_total ? order_total : "none"} EUR
        </Text>
      </View>
      <View style={styles.tytitleContainer}>
        {/* <Text style={styles.tyreportTitle}>Zahlbar sofort</Text> */}
      </View>
      <View style={styles.footerinforow}>
        <View style={styles.footerContainer}>
          <Text>Hellofarmer UG</Text>
          <Text>IBAN: DE14 1004 0000 0159 6410 00</Text>
          <Text>BIC: COBADEFFXXX</Text>
          <Text>Eingetragen im Amtsgericht Charlottenburg HRB 231619 B</Text>
        </View>
        <View style={styles.footerContainer}>
          <Text>HelloFarmer UG</Text>
          <Text>Burggrafenstr. 16</Text>
          <Text>10787 Berlin</Text>
          <Text>+49 176 82253349</Text>
          <Text>www.hellofarmer.de</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default Invoice;
