import React, { Component } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changeMarketlistName } from "../../../../../actions/marketListActions";
import { referAFarmer } from "../../../../../actions/supplierActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";

import Swal from "sweetalert2";

import { Image } from "cloudinary-react";

class ReferAFarmerModal extends Component {
  state = {
    modal: false,
    name: "",
		email: "",
		telephone: "",
		comments: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
		if (!this.state.email && !this.state.telephone){
			Swal.fire({
				title: "Bitte geben Sie zumindest eine Kontaktmöglichkeit an ",
				icon: "error",
				confirmButtonColor: "#C25982",
				confirmButtonText: "Ok"
			});
		} else {
			this.props.referAFarmer(this.state.name, this.state.email, this.state.telephone, this.state.comments);
    	this.toggle();
			Swal.fire({
      	title: "Anfrage erhalten",
      	icon: "success",
      	confirmButtonColor: "#C25982",
      	confirmButtonText: "Ok"
    	});
		}
  };

  render() {
    return (
      <div>
        <Button onClick={this.toggle} id="refer-a-farmer-btn">Empfehle oder werde ein Hellofarmer</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
						Empfehle oder werde ein Hellofarmer
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Farmer Name eingeben"
                  className="mb-3"
                  onChange={this.onChange}
                />
								<Label for="name">Email</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Farmer Email eingeben"
                  className="mb-3"
                  onChange={this.onChange}
                />
								<Label for="name">Rufnummer</Label>
                <Input
                  type="text"
                  name="telephone"
                  id="telephone"
                  placeholder="Farmer Rufnummer eingeben"
                  className="mb-3"
                  onChange={this.onChange}
                />
								<Label for="name">Kommentare</Label>
                <Input
                  type="textarea"
                  name="comments"
                  id="comments"
                  placeholder="Zusätzliche Kommentare eingeben"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Button
                  className="btn-secondary full-width"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Senden
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ReferAFarmerModal.propTypes = {};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { changeMarketlistName, referAFarmer }
)(withRouter(ReferAFarmerModal));