import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  Card,
  TabPane,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import ProfileTab from "../user/accountSettings/tabs/ProfileTab";

import OrdersTab from "./OrdersTab";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class OrdersPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      ordersFilter: null
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  render() {
    // const project = this.props.project;
    
    return (
      <div id="orders-page" className="page">
        <Container>
          {/* <Nav tabs className="project-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Alle
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Verarbeitung
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Abgebrochen
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Auslieferung
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Unzustellbar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                Bestätigt
              </NavLink>
            </NavItem> */}
          {/* </Nav> */}
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 1 ? <OrdersTab filter={null} /> : null}
            </TabPane>
            <TabPane tabId="2">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 2 ? <OrdersTab filter={"0"} /> : null}
            </TabPane>
            <TabPane tabId="3">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 3 ? <OrdersTab filter={"1"} /> : null}
            </TabPane>
            {/* <TabPane tabId="4">
              {this.state.activeTab == 4 ? <OrdersTab filter={"2"} /> : null}
            </TabPane>
            <TabPane tabId="5">
              {this.state.activeTab == 5 ? <OrdersTab filter={"3"} /> : null}
            </TabPane>
            <TabPane tabId="6">
              {this.state.activeTab == 6 ? <OrdersTab filter={"4"} /> : null}
            </TabPane> */}
          </TabContent>
        </Container>
      </div>
    );
  }
}

export default OrdersPage;
