import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

i18n
  .use(detector)
  .init({
  resources : {
    de : {
      translations : {
        signIn: "Anmelden",
        register: "Registrieren",
        or: "Oder",
        continueAsGuest: "Weiter als Gast",
        dontHaveAnAccountYet: "Noch kein Konto?",
        forgotYourPassword: "Passwort vergessen?",
        password: "Passwort",
        individual: "Individuell",
        company: "Unternehmen",
        yourName: "Name eingeben",
        yourEmail: "E-Mail eingeben",
        yourPhoneNumber: "Handynummer eingeben",
        yourStreet: "Straßenanschrift eingeben",
        yourStreet2: "Straßenanschrift 2 eingeben",
        yourPostCode: "Postleitzahl eingeben",
        yourCity: "Stadt eingeben",
        yourPassword: "Passwort eingeben",
        atLeast1LowerCaseLetter: "Mindestens 1 Kleinbuchstabe",
        atLeast1CapitalLetter: "Mindestens 1 Großbuchstabe",
        atLeast8Characters: "Mindestens 8 Zeichen insgesamt",
        atLeast1NumberOrSpecialCharacter: "Mindestens 1 Zahl oder Sonderzeichen (z. B. @#$)",
        iAgreeToThe: "Ich stimme den",
        termsAndConditions: "AGB's und Nutzungsbedingungen",
        zu: " zu",
        iAgreeToReceiveOffersAndAdvertisingFromHellofarmer: "Ich stimme zu, Angebote und Werbung von Hellofarmer zu erhalten.",
        forgotPasswordText: "Geben Sie unten Ihre registrierte E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten",
        send: "Senden",
        forgotPasswordConfirmationText: "E-Mail zum Zurücksetzen des Passworts gesendet",
        categories: "Kategorien",
        fruits: "Obst",
        vegetables: "Gemüse",
        bakedGoods: "Backwaren",
        drinks: "Getränke",
        coffee: "Kaffee",
        milk: "Milch",
        noodles: "Nudeln",
        eggs: "Eier",
        search: "Suchen",
        toRegister: "Zur Registrierung",
        support: "Support",
        adminDashboard: "Admin dashboard",
        settings: "Einstellungen",
        hello: "Hallo!",
        basket: "Warenkorb",
        emptyBasketMessage: "Bitte Artikel in den Warenkorb legen",
        checkAndSend: "Prüfen und senden",
        basketSubtotal: "Warenkorb Zwischensumme",
        goToCheckout: "Zur Kasse gehen",
        only: "Nur",
        moreToReachTheMinimumOrderValue: "mehr, um den Mindestbestellwert zu erreichen",
        supportPageText: "Brauchen sie Hilfe, oder haben sie Feedback für uns? Schicken sie uns eine Nachricht!",
        name: "Name",
        topic: "Thema",
        message: "Nachricht",
        inputTopic: "Thema eingeben",
        inputMessage: "Nachricht eingeben",
        incompleteFormText: "Bitte füllen Sie alle Felder aus",
        contactFormSendSuccessfullyText: "Ihre Nachricht wurde gesendet! Wir werden so schnell wie möglich antworten",
        editProfile: "Profil bearbeiten",
        save: "Speichern",
        phoneNumber: "Telefon",
        taxNumber: "Steuer Nr",
        vat: "USt-ID",
        currentPassword: "Aktuelles Passwort",
        newPassword: "Neues Passwort",
        confirmNewPassword: "Neues Passwort bestätigen",
        updatePassword: "Passwort aktualisieren",
        notifications: "Benachrichtingungen",
        orders: "Bestellungen",
        itemRequests: "Artikelanfragen",
        orderHistory: "Bestellverlauf",
        marketLists: "Einkaufszettle",
        logOut: "Ausloggen",
        changesWereSaved: "Änderungen wurden gespeichert",
        yes: "Ja",
        no: "Nein",
        toggleOrderUpdatesText: "Wollen Sie Bestell-Updates an/ab -schalten?",
        toggleItemRequestUpdatesText: "Wollen Sie Artikelanforderungen-Updates an/ab -schalten?",
        toggleEmailNotificationsText: "Wollen Sie Email Benachrichtigung an/ab -schalten?",
        vatChangeConfirmationText: "Sind Sie sicher, dass Sie Ihre USt.-IDnr. ändern möchten",
        taxNumberChangeConfirmationText: "Sind Sie sicher, dass Sie Ihre Steuernummer ändern möchten",
        phoneChangeConfirmationText: "Sind Sie sicher, dass Sie Ihre Telefonnummer ändern möchten?",
        emailChangeConfirmationText: "Sind Sie sicher, dass Sie Ihre E-Mail ändern möchten?",
        nameChangeConfirmationText: "Sind Sie sicher, dass Sie Ihren Namen ändern möchten?",
        languageChangeConfirmationText: "Sind Sie sicher, dass Sie Ihre Sprache ändern möchten?",
        passwordChangeConfirmationText: "Sind Sie sicher, dass Sie Ihr Passwort ändern möchten?",
        back: "Zurück",
        quantity: "Menge",
        pickAFarmer: "Farmer auswählen",
        productInformation: "Produktinformation",
        ingredientsAndAllergens: "Zutaten und Allergene",
        nutritionalValues: "Nährwerte",
        recommendedProducts: "Empfohlene Produkte",
        yourOrders: "Deine Bestellungen",
        shoppingListCreated: "Einkaufszettel erstellt",
        atLeastOneItemFromYourOrderIsAlreadyInTheShoppingCart: "Mindestens ein Artikel aus Ihrem Bestellung befindet sich bereits im Warenkorb",
        itemsPlacedInTheShoppingCart: "Artikeln in den Warenkorb abgelegt",
        processing: "Verarbeitung",
        cancelled: "Abgesagt",
        delivering: "Auslieferung",
        undeliverable: "Unzustellbar",
        confirmed: "Bestätigt",
        order: "Bestellung",
        unit: "Packmass",
        orderQuantity: "Bestellmenge",
        price: "Preis",
        quantityReceived: "Erhaltene Menge",
        orderSummary: "Bestellübersicht",
        article: "Artikel",
        delivery: "Lieferung",
        reorder: "Neu bestellen",
        marketList: "Einkaufszettle",
        deliveryNote: "Lieferschein",
        youDoNotHaveAnyOrdersAtTheMoment: "Sie haben zur Zeit keine Bestellungen",
        yourMarketLists: "Deine Einkaufszettel",
        youDontHaveAnyMarketListsAtTheMoment: "Sie haben zur Zeit keine Einkaufszettel",
        createMarketList: "Einkaufszettel hinzufügen",
        create: "Anlegen",
        cancel: "Abbrechen",
        addItem: "Artikel hinzufügen",
        backToMarket: "Zurück ins Markt",
        deliveryAddress: "Lieferadresse",
        change: "Ändern",
        none: "Keine",
        item: "Artikel",
        pickADate: "Datum auswählen",
        yourDeliveryInstructions: "Lieferanweisungen eingeben",
        deliveryInstructions: "Lieferanweisungen",
        chooseYourDeliveryDate: "Wähle dein Lieferdatum",
        chooseYourDeliveryWindow: "Wähle dein Lieferfenster",
        paymentDetails: "Zahlungsdetails",
        termsAndConditionsAgreement: "Mit Ihrer Bestellung erklären Sie sich mit den AGB von HelloFarmer UG einverstanden.",
        yourOrder: "Deine Bestellung",
        youHaveNoItemsInYourBasket: "Du hast zur Zeit keine Artikel im Warenkorb",
        coupon: "Gutschein",
        redeem: "Einlösen",
        delivery: "Lieferung",
        yourOrderHasBeenPlacedAndIsNowBeingProcessed: "Ihre Bestellung wurde aufgegeben und wird nun bearbeitet",
        areYouSureYouWantToRemoveThisItem: "Sind Sie sicher, dass Sie diesen Artikel entfernen möchten?",
        aMarketListHasBeenCreatedFromYourShoppingBasket: "Es wurde eine Einkaufszettel mit den Artikeln Ihres aktuellen Warenkorbs erstellt",
        hellofarmerPremiumDiscount: "Hellofarmer Premium-Rabatt",
        freeDelivery: "Kostenlose Lieferung",
        couponCode: "Gutschein-Code",
        invalid: "ungültig",
        redeemed: "eingelöst",
        isAlreadyRedeemed: "eingelöst",
        youHaveAlreadyThisCouponCode: "Du hast bereits den Gutschein-Code",
        nameOnCard: "Name auf Karte",
        pay: "KAUFEN",
        loading: "Lädt...",
        language: "Sprache",
        german: "Deutsch",
        spanish: "Spanisch",
        english: "Englisch",
        currencySymbol: "€"
      }
    },
    en : {
      translations : {
        signIn : "Sign In",
        register: "Register",
        or: "Or",
        continueAsGuest: "Continue as guest",
        dontHaveAnAccountYet: "Don't have an account yet?",
        forgotYourPassword: "Forgot your password?",
        password: "Password",
        individual: "Individual",
        company: "Company",
        yourName: "Your name",
        yourEmail: "Your email",
        yourPhoneNumber: "Your phone number",
        yourStreet: "Your street address",
        yourStreet2: "Your street address 2",
        yourPostCode: "Your post code",
        yourCity: "Your city",
        yourPassword: "Your password",
        atLeast1LowerCaseLetter: "At least 1 lower case letter",
        atLeast1CapitalLetter: "At least 1 capital letter",
        atLeast8Characters: "At least 8 characters",
        atLeast1NumberOrSpecialCharacter: "At least 1 number or special character (e.g. @#$)",
        iAgreeToThe: "I agree to the",
        termsAndConditions: "terms and conditions",
        zu: "",
        iAgreeToReceiveOffersAndAdvertisingFromHellofarmer: "I agree to receive offers and advertising from Hellofarmer.",
        forgotPasswordText: "Enter your registered email address below for password reset instructions",
        send: "Send",
        forgotPasswordConfirmationText: "Password reset email sent",
        categories: "Categories",
        fruits: "Fruits",
        vegetables: "Vegetables",
        bakedGoods: "Baked goods",
        drinks: "Drinks",
        coffee: "Coffee",
        milk: "Milk",
        noodles: "Noodles",
        eggs: "Eggs",
        search: "Search",
        toRegister: "Register",
        support: "Support",
        adminDashboard: "Admin dashboard",
        settings: "Settings",
        hello: "Hello!",
        basket: "Basket",
        emptyBasketMessage: "Please add an item to your basket",
        checkAndSend: "Check and send",
        basketSubtotal: "Basket subtotal",
        goToCheckout: "Go to checkout",
        only: "Only",
        moreToReachTheMinimumOrderValue: "more to reach the minimum order value",
        supportPageText: "Do you need help or have feedback for us? Send us a message!",
        name: "Name",
        topic: "Topic",
        message: "Message",
        inputTopic: "Topic",
        inputMessage: "Message",
        incompleteFormText: "Please fill in all the fields",
        contactFormSendSuccessfullyText: "Your message has been sent! We will reply as soon as possible",
        editProfile: "Edit profile",
        save: "Save",
        phoneNumber: "Phone number",
        taxNumber: "Tax number",
        vat: "VAT",
        currentPassword: "Current password",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        updatePassword: "Update password",
        notifications: "Notifications",
        orders: "Orders",
        itemRequests: "Item requests",
        orderHistory: "Order history",
        marketLists: "Market lists",
        logOut: "Log out",
        changesWereSaved: "Changes were saved",
        yes: "Yes",
        no: "No",
        toggleOrderUpdatesText: "Do you want to switch order updates on/off??",
        toggleItemRequestUpdatesText: "Do you want to switch item request updates on/off?",
        toggleEmailNotificationsText: "Do you want to turn email notification on/off?",
        vatChangeConfirmationText: "Are you sure you want to change your VAT registration number?",
        taxNumberChangeConfirmationText: "Are you sure you want to change your tax number",
        phoneChangeConfirmationText: "Are you sure you want to change your phone number?",
        emailChangeConfirmationText: "Are you sure you want to change your email?",
        nameChangeConfirmationText: "Are you sure you want to change your name?",
        languageChangeConfirmationText: "Are you sure you want to change your language?",
        passwordChangeConfirmationText: "Are you sure you want to change your password?",
        back: "Back",
        quantity: "Quantity",
        pickAFarmer: "Pick a farmer",
        productInformation: "Product information",
        ingredientsAndAllergens: "Ingredients and allergens",
        nutritionalValues: "Nutritional values",
        recommendedProducts: "Recommended products",
        yourOrders: "Your orders",
        shoppingListCreated: "Shopping list created",
        atLeastOneItemFromYourOrderIsAlreadyInTheShoppingCart: "At least one item from your order is already in the shopping cart",
        itemsPlacedInTheShoppingCart: "Items placed in the shopping cart",
        processing: "Processing",
        cancelled: "Cancelled",
        delivering: "Delivering",
        undeliverable: "Undeliverable",
        confirmed: "Confirmed",
        order: "Order",
        unit: "Unit",
        orderQuantity: "Quantity",
        price: "Price",
        quantityReceived: "Quantity received",
        orderSummary: "Order summary",
        article: "Article",
        delivery: "Delivery",
        reorder: "Reorder",
        marketList: "Market list",
        deliveryNote: "Delivery note",
        youDoNotHaveAnyOrdersAtTheMoment: "You do not have any orders at the moment",
        yourMarketLists: "Your market lists",
        youDontHaveAnyMarketListsAtTheMoment: "You don't have any market lists at the moment",
        createMarketList: "Create market list",
        create: "Create",
        cancel: "Cancel",
        addItem: "Add item",
        backToMarket: "Back to market",
        deliveryAddress: "Delivery address",
        change: "Change",
        none: "None",
        item: "Item",
        pickADate: "Pick a date",
        yourDeliveryInstructions: "Delivery instructions",
        deliveryInstructions: "Delivery instructions",
        chooseYourDeliveryDate: "Choose your delivery date",
        chooseYourDeliveryWindow: "Choose your delivery window",
        paymentDetails: "Payment details",
        termsAndConditionsAgreement: "With your order you agree to the terms and conditions of HelloFarmer UG.",
        yourOrder: "Your order",
        youHaveNoItemsInYourBasket: "You have no items in your basket",
        coupon: "Coupon",
        redeem: "Redeem",
        delivery: "Delivery",
        yourOrderHasBeenPlacedAndIsNowBeingProcessed: "Your order has been placed and is now being processed",
        areYouSureYouWantToRemoveThisItem: "Are you sure you want to remove this item?",
        aMarketListHasBeenCreatedFromYourShoppingBasket: "A shopping list has been created with the items in your current shopping basket",
        hellofarmerPremiumDiscount: "Hellofarmer Premium discount",
        freeDelivery: "Free delivery",
        couponCode: "Coupon code",
        invalid: "invalid",
        redeemed: "redeemed",
        isAlreadyRedeemed: "is already redeemed",
        youHaveAlreadyThisCouponCode: "Coupon code",
        nameOnCard: "Name on card",
        pay: "PAY",
        loading: "Loading...",
        language: "Language",
        german: "German",
        spanish: "Spanish",
        english: "English",
        currencySymbol: "€"
      }
    },
    es : {
      translations : {
        signIn: "Iniciar sesión",
        register: "Registrarse",
        or: "O",
        continueAsGuest: "Continuar como invitado",
        dontHaveAnAccountYet: "¿Todavía no tienes una cuenta?",
        forgotYourPassword: "¿Olvidaste tu contraseña?",
        password: "Contraseña",
        individual: "Individual",
        company: "Empresa",
        yourName: "Ingrese su nombre",
        yourEmail: "Ingrese su correo electrónico",
        yourPhoneNumber: "Ingrese su número de teléfono",
        yourStreet: "Ingrese su dirección",
        yourStreet2: "Ingrese la segunda línea de dirección",
        yourPostCode: "Ingrese su código postal",
        yourCity: "Ingrese su ciudad",
        yourPassword: "Ingrese su contraseña",
        atLeast1LowerCaseLetter: "Al menos 1 letra minúscula",
        atLeast1CapitalLetter: "Al menos 1 letra mayúscula",
        atLeast8Characters: "Al menos 8 caracteres en total",
        atLeast1NumberOrSpecialCharacter: "Al menos 1 número o carácter especial (por ejemplo, @#$)",
        iAgreeToThe: "Acepto los",
        termsAndConditions: "términos y condiciones",
        zu: " de",
        iAgreeToReceiveOffersAndAdvertisingFromHellofarmer: "Acepto recibir ofertas y publicidad de Hellofarmer.",
        forgotPasswordText: "Ingrese su dirección de correo electrónico registrada a continuación para recibir instrucciones para restablecer la contraseña.",
        send: "Enviar",
        forgotPasswordConfirmationText: "Correo electrónico enviado para restablecer la contraseña",
        categories: "Categorías",
        fruits: "Frutas",
        vegetables: "Verduras",
        bakedGoods: "Horneados",
        drinks: "Bebidas",
        coffee: "Café",
        milk: "Leche",
        noodles: "Fideos",
        eggs: "Huevos",
        search: "Buscar",
        toRegister: "Para registrarse",
        support: "Soporte",
        adminDashboard: "Panel de administración",
        settings: "Configuración",
        hello: "¡Hola!",
        basket: "Carrito",
        emptyBasketMessage: "Por favor, agregue artículos al carrito",
        checkAndSend: "Verificar y enviar",
        basketSubtotal: "Subtotal del carrito",
        goToCheckout: "Ir a la caja",
        only: "Solo",
        moreToReachTheMinimumOrderValue: "más para alcanzar el valor mínimo de pedido",
        supportPageText: "¿Necesitas ayuda o tienes comentarios para nosotros? ¡Envíanos un mensaje!",
        name: "Nombre",
        topic: "Tema",
        message: "Mensaje",
        inputTopic: "Ingrese el tema",
        inputMessage: "Ingrese el mensaje",
        incompleteFormText: "Por favor, complete todos los campos",
        contactFormSendSuccessfullyText: "¡Su mensaje ha sido enviado! Responderemos lo antes posible",
        editProfile: "Editar perfil",
        save: "Guardar",
        phoneNumber: "Número de teléfono",
        taxNumber: "Número de impuesto",
        vat: "IVA",
        currentPassword: "Contraseña actual",
        newPassword: "Nueva contraseña",
        confirmNewPassword: "Confirmar nueva contraseña",
        updatePassword: "Actualizar contraseña",
        notifications: "Notificaciones",
        orders: "Pedidos",
        itemRequests: "Solicitudes de artículos",
        orderHistory: "Historial de pedidos",
        marketLists: "Listas de compras",
        logOut: "Cerrar sesión",
        changesWereSaved: "Los cambios han sido guardados",
        yes: "Sí",
        no: "No",
        toggleOrderUpdatesText: "¿Desea activar/desactivar las actualizaciones de pedido?",
        toggleItemRequestUpdatesText: "¿Desea activar/desactivar las actualizaciones de solicitudes de artículos?",
        toggleEmailNotificationsText: "¿Desea activar/desactivar las notificaciones por correo electrónico?",
        vatChangeConfirmationText: "¿Está seguro de que desea cambiar su número de IVA?",
        taxNumberChangeConfirmationText: "¿Está seguro de que desea cambiar su número de impuesto?",
        phoneChangeConfirmationText: "¿Está seguro de que desea cambiar su número de teléfono?",
        emailChangeConfirmationText: "¿Está seguro de que desea cambiar su correo electrónico?",
        nameChangeConfirmationText: "¿Está seguro de que desea cambiar su nombre?",
        languageChangeConfirmationText: "¿Está seguro de que desea cambiar su idioma?",
        passwordChangeConfirmationText: "¿Está seguro de que desea cambiar su contraseña?",
        back: "Atrás",
        quantity: "Cantidad",
        pickAFarmer: "Elija un agricultor",
        productInformation: "Información del producto",
        ingredientsAndAllergens: "Ingredientes y alérgenos",
        nutritionalValues: "Valores nutricionales",
        recommendedProducts: "Productos recomendados",
        yourOrders: "Tus pedidos",
        shoppingListCreated: "Lista de compras creada",
        atLeastOneItemFromYourOrderIsAlreadyInTheShoppingCart: "Al menos un artículo de su pedido ya está en el carrito de compras",
        itemsPlacedInTheShoppingCart: "artículos colocados en el carrito de compras",
        processing: "Procesando",
        cancelled: "Cancelado",
        delivering: "Entregando",
        undeliverable: "No entregable",
        confirmed: "Confirmado",
        order: "Pedido",
        unit: "Unidad",
        orderQuantity: "Cantidad del pedido",
        price: "Precio",
        quantityReceived: "Cantidad recibida",
        orderSummary: "Resumen del pedido",
        article: "Artículo",
        delivery: "Entrega",
        reorder: "Volver a ordenar",
        marketList: "Lista de compras",
        deliveryNote: "Nota de entrega",
        youDoNotHaveAnyOrdersAtTheMoment: "No tienes ningún pedido en este momento",
        yourMarketLists: "Tus listas de compras",
        youDontHaveAnyMarketListsAtTheMoment: "No tienes ninguna lista de compras en este momento",
        createMarketList: "Crear lista de compras",
        create: "Crear",
        cancel: "Cancelar",
        addItem: "Agregar artículo",
        backToMarket: "Volver al mercado",
        deliveryAddress: "Dirección de entrega",
        change: "Cambiar",
        none: "Ninguno",
        item: "Artículo",
        pickADate: "Seleccionar fecha",
        yourDeliveryInstructions: "Ingrese sus instrucciones de entrega",
        deliveryInstructions: "Instrucciones de entrega",
        chooseYourDeliveryDate: "Elige tu fecha de entrega",
        chooseYourDeliveryWindow: "Elige tu ventana de entrega",
        paymentDetails: "Detalles de pago",
        termsAndConditionsAgreement: "Al realizar su pedido, acepta los términos y condiciones de HelloFarmer UG.",
        yourOrder: "Tu pedido",
        youHaveNoItemsInYourBasket: "No tienes artículos en tu carrito en este momento",
        coupon: "Cupón",
        redeem: "Canjear",
        delivery: "Entrega",
        yourOrderHasBeenPlacedAndIsNowBeingProcessed: "Tu pedido ha sido realizado y se está procesando",
        areYouSureYouWantToRemoveThisItem: "¿Estás seguro de que deseas eliminar este artículo?",
        aMarketListHasBeenCreatedFromYourShoppingBasket: "Se ha creado una lista de compras a partir de tu carrito actual",
        hellofarmerPremiumDiscount: "Descuento Hellofarmer Premium",
        freeDelivery: "Entrega gratuita",
        couponCode: "Código de cupón",
        invalid: "Inválido",
        redeemed: "Canjeado",
        isAlreadyRedeemed: "Ya canjeado",
        youHaveAlreadyThisCouponCode: "Ya tienes este código de cupón",
        nameOnCard: "Nombre en la tarjeta",
        pay: "PAGAR",
        loading: "Cargando...",
        language: "Idioma",
        german: "Alemán",
        spanish: "Español",
        english: "Inglés",
        currencySymbol: "$"
      }
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
})
export default i18n;