import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import classnames from "classnames";
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getAllItems } from "../../../../../actions/itemActions";

import Item from "./Item";

import ItemSupplierList from "../suppliers/ItemSupplierList";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

// import tomatoes from "../../../../../assets/tomatoes.png";

import Avatar from "@material-ui/core/Avatar";

class ItemsTab extends Component {
  state = {
    modal: false,
    name: "",
    email: "",
    filters: []
    // items_region_filter: null
  };

  componentDidMount() {
    this.props.getAllItems();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  setItemsRegionFilter = region => {
    this.setState({ items_region_filter: region });
  };

  recentItems = (items, userRecentItems) => {
    var recentItems = [];
    
    items.forEach(item => {
      if (userRecentItems?.includes(item.id)){
        recentItems.push(item)
      }
    })

    return recentItems
    // return(
    //   items
    //   .filter(item => item.is_recent == true)
    //   .map(item => {
    //     return (
    //       <Col className="item-list-item" sm={3}>
    //         <Item itemObject={item} />
    //       </Col>
    //     );
    //   })
    // )
  };

  returnItemIfInStock = (cart, item) => {
    var itemIsInStock = null;
    if(item){
    const itemSuppliers = item?.suppliers;
    if(itemSuppliers){
      itemSuppliers.forEach(supplier => {
        const supplierStock = supplier?.stock;
        const stockItem = supplierStock?.find(stockItem => stockItem.item == item.id)
        if (stockItem){
          if(stockItem.amount > 0){
            itemIsInStock = true
              // <Col className="item-list-item" sm={3}>
              //   <Item cart={cart} itemObject={item} />
              // </Col>
          } else {
            itemIsInStock = false
          }
        }
      })
    }
  }

  return itemIsInStock
  }

  toggleFilter = (filter) => {
    var currentFilters = this.state.filters;
    console.log(currentFilters)
    
    if(currentFilters.includes(filter)){
      const index = currentFilters.indexOf(filter);
      if (index > -1) {
        currentFilters.splice(index, 1);
      }
      this.setState({
        filters: currentFilters
      });
    } else {
      currentFilters.push(filter)
      this.setState({
        filters: currentFilters
      }); 
    }
  }

  getFilteredItems = (items, currentFilter) => {
    var currentFilters = this.state.filters;
    var filteredItems = [];

    items.map(item => {
      // if (currentFilters.some(v=> item.categories.indexOf(v) !== -1)){
      if (item.categories.includes(currentFilter)){
        filteredItems.push(item)
      }
    })

    return filteredItems
  }

  render() {
    const { user } = this.props.auth;
    const isGuest = user?.guest;
    const items = this.props.item.items;
    const { loading } = this.props.item
    const orderedItems = [...items].reverse();
    const userRecentItems = user.recent_items
    const recentItems = this.recentItems(items, userRecentItems)
    const aItems = this.props.items;
    const userRegion = this.props.userRegion;
    const cart = this.props.cart
    const filteredItems = this.getFilteredItems(orderedItems, this.props.filter)

    const filter = this.props.filter
    return (
      <div>
        <Container className="items-tab" style={{maxWidth: 'none'}}>        
        <LoadingOverlay
          active={loading}
          spinner
          text={this.props.t('loading')}
        >          
                <Row className="item-list-row">
                  {this.props.filter
                    ?
                      filteredItems.map(item => {
                      const itemIsInStock = this.returnItemIfInStock(cart, item)
                        return (
                          itemIsInStock ?
                            <Item isGuest={isGuest} cart={cart} itemObject={item} />
                          : null
                        );
                      })
                    : orderedItems.map(item => {
                      const itemIsInStock = this.returnItemIfInStock(cart, item)
                        return (
                          (itemIsInStock && item.is_active) ?
                            <Item isGuest={isGuest} cart={cart} itemObject={item} />
                          : null
                        );
                      })}
                </Row>
          </LoadingOverlay>
        </Container>
      </div>
    );
  }
}

ItemsTab.propTypes = {
  getAllItems: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  item: state.item
});

export default withTranslation()(connect(
  mapStateToProps,
  { getAllItems }
)(withRouter(ItemsTab)));
