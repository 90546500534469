import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import history from "../../../../helper/history";
import LoadingOverlay from "react-loading-overlay";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
  NavItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Image } from "cloudinary-react";

import Logout from "../../../authentication/Logout";

import { getUserFeed } from "../../../../actions/feedActions";

import { ReactComponent as NotificationBell } from "../../../../assets/NotificationBell.svg";
import { ReactComponent as NotificationsModalIcon } from "../../../../assets/NotificationsModalIcon.svg";
import { ReactComponent as NewNotificationIcon } from "../../../../assets/NewNotificationIcon.svg";

import { ReactComponent as SettingsIcon } from "../../../../assets/SettingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/SupportIcon.svg";

import Avatar from "@material-ui/core/Avatar";

import { Link } from "react-router-dom";

class SettingsModal extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getUserFeed();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  getDaysAgo = feedItemDate => {
    const millisecondsDiff = Date.now() - +new Date(feedItemDate);
    const daysDiff = millisecondsDiff / (60 * 60 * 24 * 1000);
    const days = Math.round(daysDiff);
    return days;
  };

  feedItem = item => {
    switch (item.type) {
      case "ORDER_CREATED":
        return (
          <Button className="notification card">
            <Row className="notification-content">
              <Col className="" sm={1}>
                <NewNotificationIcon />
              </Col>
              <Col className="ml-2 pr-5">
                <Row>
                  <p className="notification-text">
                    <b>Sie</b> haben eine Bestellung mit <b>{item.receiver} </b>
                    aufgegeben
                  </p>
                  <p className="greyed-out mb-0">
                    Vor {this.getDaysAgo(item.creation_date)} Tagen
                  </p>
                </Row>
              </Col>
            </Row>
          </Button>
        );
      default:
        break;
    }
  };

  render() {
    const { user } = this.props.auth;
    const { feed } = this.props.feed;
    const { loading } = this.props.feed;
    const orderedFeed = [...feed].reverse();
    const toggleModal = this.props.toggleModal;

    return (
      <div style={{height: '100%', width: '100%'}}>
        <NavLink onClick={this.toggle} href="#" style={{padding: 0, paddingTop: 2.5, height: '100%'}}>
          <SettingsIcon />
          <span className='mobile-navbar-item-label'>{this.props.t('settings')}</span>
        </NavLink>
        <Modal
          id="settings-modal"
          isOpen={this.state.modal}
          toggle={this.toggle}
					style={{maxWidth: 200}}
        >
          <ModalBody style={{padding: 8}}>
						<Row onClick={() => {
              history.push('/settings'); this.toggle()
              toggleModal();
              }} className='settings-modal-button' style={{marginBottom: 5}}>
							<Col className='icon-col' style={{maxWidth: '20%', textAlign: 'center', padding: 0}}>
								<SettingsIcon />
							</Col>
							<Col className='label-col' style={{maxWidth: '80%', textAlign: 'left', padding: 0}}>
								<p>{this.props.t('settings')}</p>
							</Col>
						</Row>
            {user?.admin &&
            <Row onClick={() => {
              history.push('/admin'); this.toggle()
              toggleModal();
              }} className='settings-modal-button' style={{marginBottom: 5}}>
							<Col className='icon-col' style={{maxWidth: '20%', textAlign: 'center', padding: 0}}>
								<SettingsIcon />
							</Col>
							<Col className='label-col' style={{maxWidth: '80%', textAlign: 'left', padding: 0}}>
								<p>{this.props.t('adminDashboard')}</p>
							</Col>
						</Row>
            }
						<Row onClick={() => {
              history.push('/help'); this.toggle()
              toggleModal();
              }} className='settings-modal-button'>
							<Col className='icon-col' style={{maxWidth: '20%', textAlign: 'center', padding: 0}}>
								<SupportIcon />
							</Col>
							<Col className='label-col' style={{maxWidth: '80%', textAlign: 'left', padding: 0}}>
								<p>{this.props.t('support')}</p>
							</Col>
						</Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

SettingsModal.propTypes = {
  feed: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  company: state.company,
  feed: state.feed,
  auth: state.auth
});

export default withTranslation()(connect(
  mapStateToProps,
  { getUserFeed }
)(SettingsModal));
