import React, { Component } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changeUserProfilePicture } from "../../../../../../../actions/userActions";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../../assets/NeueBaustelle.svg";
import { ReactComponent as DefaultProfilePic } from "../../../../../../../assets/NewDefaultProfilePic.svg";

import Avatar from "@material-ui/core/Avatar";

import Swal from "sweetalert2";

import { Image } from "cloudinary-react";

class UploadProfilePictureModal extends Component {
  state = {
    modal: false,
    imageUrl: null,
    imageAlt: null
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleProfilePictureChange = newProfilePictureUrl => {
    console.log(newProfilePictureUrl);
    Swal.fire({
      title: "Profilbild ändern",
      text: "Sind Sie sicher, dass Sie Ihr Profilbild ändern möchten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.changeUserProfilePicture(newProfilePictureUrl);
        this.toggle();
      }
    });
  };

  handleImageUpload = () => {
    console.log("HANDLE IMAGE UPLOAD");
    const { files } = document.querySelector('input[type="file"]');
    const formData = new FormData();
    formData.append("file", files[0]);
    
    formData.append("upload_preset", "yipxh7qq");
    const options = {
      method: "POST",
      body: formData
    };

    return fetch(
      "https://api.Cloudinary.com/v1_1/dqzo9yjly/image/upload",
      options
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          imageUrl: res.secure_url,
          imageAlt: `An image of ${res.original_filename}`
        });
        // handleProfilePictureChange(res.secure_url)
      })
      .catch(err => console.log(err));
  };

  render() {
    const { imageUrl, imageAlt } = this.state;

    return (
      <div>
        <Button className="full-width" onClick={this.toggle}>Profilbild ändern</Button>
        <Modal
          className="upload-profile-picture-modal"
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            Profilbild ändern
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="center">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={imageAlt}
                    className="displayed-image"
                  />
                ): 
                  <DefaultProfilePic className="displayed-image" />
                }
                <Row>
                  <Col className="left-side">
                    <form>
                      <div className="form-group">
                        <input type="file" id="file" className="inputfile" onChange={this.handleImageUpload}/>
                        <label for="file">Foto auswählen</label>
                      </div>
                    </form>
                  </Col>
                  {/* <Col className="right-side">
                    <Button className="full-width upload-picture-btn" onClick={this.handleImageUpload}>Foto hochladen</Button>
                  </Col> */}
                </Row>
                <p className="greyed-out">Wählen Sie ein Foto und drücken Sie foto hochladen</p>
              </Col>
              {/* <Col className="center center-vertically left-side">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={imageAlt}
                    className="displayed-image"
                  />
                ): 
                  <DefaultProfilePic className="displayed-image" />
                }
              </Col>
              <Col className="center right-side">
                <form>
                  <div className="form-group">
                    <input type="file" id="file" className="inputfile"/>
                    <label for="file">Foto auswählen</label>
                  </div>
                  <Button className="full-width" onClick={this.handleImageUpload}>Foto hochladen</Button>
                </form>
                <p className="greyed-out">Wählen Sie ein Foto und drücken Sie foto hochladen</p>
              </Col> */}
            </Row>
            <Button
              onClick={() => this.handleProfilePictureChange(imageUrl)}
              className="full-width btn-modal btn-upload-pp-modal"
              color="dark"
              style={{ marginTop: "2rem" }}
              block
              disabled={this.state.imageUrl == null}
            >
              Änderung bestätigen
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

UploadProfilePictureModal.propTypes = {
  changeUserProfilePicture: PropTypes.func.isRequired
};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { changeUserProfilePicture }
)(withRouter(UploadProfilePictureModal));
