import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay";
import {
  Container,
  Input,
  Row,
  Col,
  Button,
  Alert,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup
} from "reactstrap";
import { registerLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment-timezone";
import 'moment/locale/de';
import history from "../../../../../helper/history";
import classnames from "classnames";

import PaypalButton from "../payments/Paypal";
import StripeContainer from "../payments/StripeContainer";
import ChangeDeliveryAddressButton from "./ChangeDeliveryAddressButton";
import SubscribeToMembershipModal from "../membership/SubscribeToMembershipModal";
import { getDelivery_addresses } from "../../../../../actions/delivery_addressActions";
import { changeCartItemQuantity } from "../../../../../actions/cartActions";
import { getAllOrders } from "../../../../../actions/orderActions";
import Swal from "sweetalert2";
import { getMembership } from "../../../../../actions/membershipActions";
import { getCart } from "../../../../../actions/cartActions";
import { removeItemFromCart } from "../../../../../actions/cartActions";
import { createMarketlistFromCart } from "../../../../../actions/marketListActions";
import { createOrder } from "../../../../../actions/orderActions";
import { emptyCart } from "../../../../../actions/cartActions";
import { getCoupons } from "../../../../../actions/couponActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image } from "cloudinary-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ShoppingCartIcon } from "../../../../../assets/ShoppingCartIcon.svg";
import { ReactComponent as PlusIconSvg } from "../../../../../assets/PlusIcon.svg";
import { ReactComponent as MinusIconSvg } from "../../../../../assets/MinusIcon.svg";

class CheckoutPage extends Component {
  componentDidMount() {
    this.props.getCart();
    this.props.getDelivery_addresses();
    this.props.getMembership();
    this.props.getAllOrders();
    this.props.getCoupons();
    this.getSundays();

    var todaysMomentLocal = moment(new Date().toString())
    var todaysMomentCet = moment.tz(todaysMomentLocal, "Europe/Berlin");
    todaysMomentCet.locale('de');

    var tomorrowsMomentLocal = moment(new Date().toString()).add(1,'days');
    var tomorrowsMomentCet = moment.tz(tomorrowsMomentLocal, "Europe/Berlin");
    tomorrowsMomentCet.locale('de');

    var dayAfterTomorrowsMomentLocal = moment(new Date().toString()).add(2,'days');
    var dayAfterTomorrowsMomentCet = moment.tz(dayAfterTomorrowsMomentLocal, "Europe/Berlin");
    dayAfterTomorrowsMomentCet.locale('de');

    var twoDaysAfterTomorrowsMomentLocal = moment(new Date().toString()).add(3,'days');
    var twoDaysAfterTomorrowsMomentCet = moment.tz(twoDaysAfterTomorrowsMomentLocal, "Europe/Berlin");
    twoDaysAfterTomorrowsMomentCet.locale('de');

    var currentHour = todaysMomentCet.hour();
    var earliestDeliveryDate = this.getEarliestDeliveryDate(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 0)
    if (!this.state.deliveryDateByButton && !this.state.deliveryDateByCalendar){
      this.selectDeliveryDateByButton(earliestDeliveryDate);
    }
    if (!this.state.selectedDeliveryWindow){
      // var deliveryDateTime = earliestDeliveryDate.split(' ');
      // deliveryDateTime = deliveryDateTime[4]
      // deliveryDateTime = deliveryDateTime.split(':')
      // deliveryDateTime = deliveryDateTime[0]
      // if(deliveryDateTime)
      this.setState({ selectedDeliveryWindow: "9:00 - 10:00" });
    }
  }

  state = {
    msg: null,
    orderSuccess: null,
    deliveryDateByButton: null,
    deliveryDateByCalendar: null,
    startDate: null,
    selectedDeliveryWindow: null,
    deliveryInstructions: null,
    coupon_code: "",
    activeCouponCode: null,
    discount: 0.00
  };

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { order } = this.props;

    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "CREATE_ORDER_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    if (order !== prevProps.order) {
      if(order.new_order != null && Object.keys(order.new_order).length != 0){
        this.setState({ orderSuccess: "Bestellung aufgegeben" });
        this.props.emptyCart(this.props.cart.cart.id);
        history.push("/order-success");
        // Swal.fire({
        //   // title: "Bestellung aufgegeben",
        //   text: "Ihre Bestellung wurde aufgegeben und wird nun bearbeitet",
        //   icon: "success",
        //   confirmButtonColor: "#C25982",
        //   confirmButtonText: "Ok"
        // });
      } else {
        this.setState({ orderSuccess: null })
      }
    }
  }

  handleQuantityChange = (cartitemId, plusOrMinus, supplierId, cartItemId) => {
    if(plusOrMinus == "+") {
      this.props.changeCartItemQuantity(cartitemId, "+", supplierId, cartItemId);
    } else if (plusOrMinus = "-") {
      if (this.state.selectedQuantity != 1){
        this.props.changeCartItemQuantity(cartitemId, "-", supplierId, cartItemId);
      }
    }
  }

  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }
  endOfWeek(date) {
    let lastday = date.getDate() - (date.getDay() - 1) + 4;
    return new Date(date.setDate(lastday));
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired
  };

  calculateTotalBudgets = projects => {
    var totalBudgets = 0;
    projects.forEach(project => {
      totalBudgets += project.budget;
    });
    return totalBudgets;
  };

  // calculateTotalExpenses = projects => {
  //   var totalExpenses = 0;
  //   projects.forEach(project => {
  //     project.invoices.forEach(invoice => {
  //       totalExpenses += invoice.price
  //     })
  //   })
  //   return totalExpenses;
  // }

  aufgabenModuleItem = assignment => {
    return (
      <tr>
        <td>{assignment[0]}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  postfachModuleItem = notification => {
    return (
      <ListGroupItem>
        <p>{/* Notification */}</p>
      </ListGroupItem>
    );
  };

  getItemPrice = item => {
    var itemPrice = item.quantity * item.price_7_pct;
    itemPrice = itemPrice.toFixed(2);
    itemPrice = parseFloat(itemPrice);
    return itemPrice;
  };

  getOrderTotal = (cart) => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    // if (membership?.type) {
    //   orderTotal = orderTotal * 0.8;
    //   orderTotal = orderTotal.toFixed(2);
    //   orderTotal = parseFloat(orderTotal)
    // }
    return orderTotal;
  };

  placeOrder = () => {
    this.props.createOrder(this.props.cart.cart.id);
    Swal.fire({
      // title: "Bestellung aufgegeben",
      text: this.props.t('yourOrderHasBeenPlacedAndIsNowBeingProcessed'),
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  getSelectOptions = quantity => {
    var options = [];
    for (var i = 0; i < 10; i++) {
      if (i + 1 == quantity) {
        options.push(<option selected>{i + 1}</option>);
      } else {
        options.push(<option>{i + 1}</option>);
      }
    }
    return options;
  };

  activeDeliveryAddress = (delivery_addresses, user_delivery_address) => {
    var active_delivery_address = null;
    delivery_addresses.forEach(deliv_address => {
      if (deliv_address.is_active == true) {
        active_delivery_address = deliv_address;
      }
    });
    return active_delivery_address;
  };

  removeCartItem = cartitemId => {
    Swal.fire({
      text: this.props.t('yourOrderHasBeenPlacedAndIsNowBeingProcessed'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#C25982",
      cancelButtonColor: "#C25982",
      cancelButtonText: this.props.t('no'),
      confirmButtonText: this.props.t('yes')
    }).then(result => {
      if (result.value) {
        this.props.removeItemFromCart(cartitemId);
      }
    });
    // this.props.removeItemFromCart(cartitemId);
  };

  createMarketlistFromCart = () => {
    this.props.createMarketlistFromCart(this.props.cart.cart.id);
    Swal.fire({
      // title: "Einkaufszettel erstellt",
      text:
        this.props.t('aMarketListHasBeenCreatedFromYourShoppingBasket'),
      icon: "success",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  };

  isReadyForTransaction = (deliveryAddress, cart, deliveryDateByButton, deliveryDateByCalendar, itemsTotal) => {
    if ((itemsTotal < 15 || deliveryAddress == null || !(cart.items?.length) || (deliveryDateByButton == null && deliveryDateByCalendar == null))){
      return false
    } else {
      return true
    }
  }

  getEarliestDeliveryDate = (today, tomorrow, dayAfterTomorrow, twoDaysAfterTomorrow, currentHour, daysToAdd) => {
    if (daysToAdd == 0){
      if (currentHour < 16){
        return tomorrow.toString()
      } else {
        return dayAfterTomorrow.toString()
      }
    } else if (daysToAdd == 1){
      if (currentHour < 16){
        return dayAfterTomorrow.toString()
      } else {
        return twoDaysAfterTomorrow.toString()
      }
    }
  }

  getEarliestDeliveryDateText = (today, tomorrow, dayAfterTomorrow, twoDaysAfterTomorrow, currentHour, daysToAdd) => {
    var currentDate = today.format("LLLL").split(/\s+/).slice(0,3).join(" ");
    var tomorrowsDate = tomorrow.format("LLLL").split(/\s+/).slice(0,3).join(" ");
    var dayAfterTomorrowsDate = dayAfterTomorrow.format("LLLL").split(/\s+/).slice(0,3).join(" ");
    var twoDaysAfterTomorrowsDate = twoDaysAfterTomorrow.format("LLLL").split(/\s+/).slice(0,3).join(" ");
    if (daysToAdd == 0){
      if (currentHour < 16){
        return tomorrowsDate
      } else {
        return dayAfterTomorrowsDate
      }
    } else if (daysToAdd == 1){
      if (currentHour < 16){
        return dayAfterTomorrowsDate
      } else {
        return twoDaysAfterTomorrowsDate
      }
    }
  }

  selectDeliveryDateByButton = (deliveryDate) => {
    this.setState({ deliveryDateByButton: deliveryDate, deliveryDateByCalendar: null })
  }

  deliveryDateByCalendar = () => {
    this.setState({ deliveryDateByButton: null, deliveryDateByCalendar: true })
  }

  paypalContainer = (cart, deliveryPreferences, cartTotal, isReadyForTransaction) => {
    if(cart && deliveryPreferences.deliveryDate && cartTotal && deliveryPreferences.deliveryWindow){
      return (
        <div id="paypal-gateway-div" className={classnames(
          {
            disabled: isReadyForTransaction == false
          }
        )}>
          <PaypalButton cart={cart} deliveryPreferences={deliveryPreferences} cartTotal={cartTotal} type={"order"}/>
        </div>
      )
    }
  }

  // setDeliveryWindow = (window) => {
  //   this.setState({ selectedDeliveryWindow: window })
  // }

  ifMemberShowDiscount = (membership, cart, isFirstOrder) => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    var discount = (0.20 * orderTotal);
    discount = discount.toFixed(2);
    discount = parseFloat(discount);
    if (membership.type){
      return (
        <div>
          <p className="mb-0 dark-grey">
            {this.props.t('hellofarmerPremiumDiscount')} <span className="float-right">- {this.props.t('currencySymbol')}{discount.toFixed(2)}</span>
          </p>
        </div>
      )
    } else {
      return (
        <SubscribeToMembershipModal source="checkout" discount={discount} isFirstOrder={isFirstOrder}/>
        // <div>
        //   <p className="mb-0 dark-grey center">
        //     Du kannst <b>€{(discount + 5).toFixed(2)}</b> mit HelloFarmer Premium sparen. <a className="hellofarmer-premium-link" href="/">Klicke hier</a>
        //   </p>
        // </div>
      )
    }
  }

  getDateToCompare = (date) => {
    return date?.split(/\s+/).slice(0,4).join(" ");
  }

  ifFirstOrderDiscountDelivery = (orders, membership) => {
    if ((!orders.length > 0) && (!membership.type)){
      return (
        <div>
          <p className="mt-3 dark-grey">
            {this.props.t('freeDelivery')} <span className="float-right">- {this.props.t('currencySymbol')}5.00</span>
          </p>
        </div>
      )
    }
  }

  isFirstOrder = (orders) => {
    if (!orders.length > 0){
      return true
    } else {
      return false
    }
  }

  getSundays = () => {
    var d = new Date();
    var getTot = daysInMonth(d.getMonth(),d.getFullYear());
    var sun = new Array();

    for(var i=1;i<=getTot;i++){    //looping through days in month
      var newDate = new Date(d.getFullYear(),d.getMonth(),i)
      if(newDate.getDay()==0){   //if Sunday
        sun.push(newDate);
      }
    }
    
    function daysInMonth(month,year) {
      return new Date(year, month, 0).getDate();
    }

    return sun
  }

  handleDeliveryWindowChange = e => {
    const targetValue = e.target.value;
    this.setState({ selectedDeliveryWindow: targetValue });
  };

  applyCoupon = (orderTotal, userId) => {
    console.log(userId)
    const couponCode = this.state.coupon_code;
    const currentCouponCodes = this.props.coupon?.coupons
    // const currentCouponCodes = [{code: "WILLKOMMEN2022", value_off: 10, pct_off: null, redeemers: [], is_active: true}];

    if (currentCouponCodes){
      if (currentCouponCodes.some(e => e.code === couponCode)){
        var coupon = currentCouponCodes.filter(coupon => {
          return coupon.code === couponCode
        })

        coupon = coupon[0];

        if (!coupon.is_active) {
          this.setState({ coupon_code: "" });
          Swal.fire({
            text: `${this.props.t('couponCode')} ${couponCode} ${this.props.t('invalid')}`,
            icon: "error",
            confirmButtonColor: "#C25982",
            confirmButtonText: "Ok"
          });
        } else if(coupon.redeemers.indexOf(userId) > -1){
          this.setState({ coupon_code: "" });
          Swal.fire({
            text: `${this.props.t('youHaveAlreadyThisCouponCode')} ${couponCode} ${this.props.t('isAlreadyRedeemed')}`,
            icon: "error",
            confirmButtonColor: "#C25982",
            confirmButtonText: "Ok"
          });
        } else {
          var discount = 0
          const activeCouponValueOff = coupon.value_off;
          const activeCouponPctOff = coupon?.pct_off;
          if(activeCouponValueOff){
            discount = activeCouponValueOff;
          } else if (activeCouponPctOff){
            discount = orderTotal * `0.${activeCouponPctOff}`;
          }
    
          this.setState({ activeCouponCode: coupon, coupon_code: "", discount: discount });
          Swal.fire({
            text: `${this.props.t('couponCode')} ${couponCode} ${this.props.t('redeemed')}`,
            icon: "success",
            confirmButtonColor: "#C25982",
            confirmButtonText: "Ok"
          });
        }
      } else {
        this.setState({ coupon_code: "" });
        Swal.fire({
          text: `${this.props.t('couponCode')} ${couponCode} ${this.props.t('invalid')}`,
          icon: "error",
          confirmButtonColor: "#C25982",
          confirmButtonText: "Ok"
        });
      }
    }
  }

  render() {
    const { user } = this.props.auth;
    const { membership } = this.props;
    const orders = this.props.order.orders;
    const isFirstOrder = this.isFirstOrder(orders)
    const projects = [];
    const testProp = this.props.testProp;
    const cart = this.props.cart.cart;
    const delivery_addresses = this.props.delivery_address.deliveryaddresses;
    const active_delivery_address = this.activeDeliveryAddress(
      delivery_addresses,
      user.delivery_address
    );
    const { loading } = this.props.order;
    
    const deliveryPreferences = {
      deliveryDate: this.state.deliveryDateByButton ? this.state.deliveryDateByButton : this.state.startDate,
      deliveryWindow: this.state.selectedDeliveryWindow,
      deliveryInstructions: this.state.deliveryInstructions,
      deliveryAddress: {
        name: active_delivery_address?.name,
        street_address: active_delivery_address?.street_address,
        street_address_2: active_delivery_address?.street_address_2,
        zip_code: active_delivery_address?.zip_code,
        city: active_delivery_address?.city,
        country: active_delivery_address?.country
      }
    }

    registerLocale('de', de)
    
    const active_payment_method = "Test";
    var isReadyForTransaction = this.isReadyForTransaction(active_delivery_address, cart, this.state.deliveryDateByButton, this.state.deliveryDateByCalendar, this.getOrderTotal(cart))

    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 13);
    const aDayFromNow = new Date();
    aDayFromNow.setDate(aDayFromNow.getDate() + 1);

    var todaysMomentLocal = moment(new Date().toString())
    var todaysMomentCet = moment.tz(todaysMomentLocal, "Europe/Berlin");
    todaysMomentCet.locale('de');

    var currentHour = todaysMomentCet.hour()

    var daysToAdd = 1
    var testTomorrowsMomentLocal = moment(new Date().toString()).add(1,'days');
    var testTomorrowsMomentCet = moment.tz(testTomorrowsMomentLocal, "Europe/Berlin");
    testTomorrowsMomentCet.locale('de');
    var testForSundayString = testTomorrowsMomentCet.day();
    if (testForSundayString == 0){
      daysToAdd = 2
    }

    var daysToAdd2 = 0
    var testTwoDaysAfterTomorrowsMomentLocal = moment(new Date().toString()).add(3,'days');
    var testTwoDaysAfterTomorrowsMomentCet = moment.tz(testTwoDaysAfterTomorrowsMomentLocal, "Europe/Berlin");
    testTwoDaysAfterTomorrowsMomentCet.locale('de');
    var testForSundayString2 = testTwoDaysAfterTomorrowsMomentCet.day();
    if (testForSundayString2 == 0){
      daysToAdd2 = 1
    }

    var tomorrowsMomentLocal = moment(new Date().toString()).add(daysToAdd,'days');
    var tomorrowsMomentCet = moment.tz(tomorrowsMomentLocal, "Europe/Berlin");
    tomorrowsMomentCet.locale('de');

    var dayAfterTomorrowsMomentLocal = moment(new Date().toString()).add((daysToAdd + 1),'days');
    var dayAfterTomorrowsMomentCet = moment.tz(dayAfterTomorrowsMomentLocal, "Europe/Berlin");
    dayAfterTomorrowsMomentCet.locale('de');

    var twoDaysAfterTomorrowsMomentLocal = moment(new Date().toString()).add((daysToAdd + 2 + daysToAdd2),'days');
    var twoDaysAfterTomorrowsMomentCet = moment.tz(twoDaysAfterTomorrowsMomentLocal, "Europe/Berlin");
    twoDaysAfterTomorrowsMomentCet.locale('de');
    
    const discount = this.state.discount
    const orderTotal = (this.getOrderTotal(cart) + 5 - discount).toFixed(2)
    const activeCouponCode = this.state.activeCouponCode
    
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text={this.props.t('loading')}
      >
      <div className="checkout-page page">
        <Container>
          <Row style={{marginTop: '2.5%'}} className='back-button-row'>
            <Col>
              <FontAwesomeIcon
                onClick={() => history.push("/market")}
                icon={faChevronLeft}
                className="float-left mr-3 back-button"
                style={{height: 16}}
              />
              <p onClick={() => history.push("/market")} style={{marginTop: -2, fontSize: 14, fontWeight: '600', color: '#C25982'}}>{this.props.t('backToMarket')}</p>
            </Col>
          </Row>
          <Row className="mb-4 page-header-row">
            <Col>
              <h4 className="page-title">Details {this.props.t('and')} checkout</h4>
            </Col>
            {/* <Col>
              <HinzufügenModal projects={projects} />
            </Col> */}
          </Row>
          <Row>
            <Col className="checkout-left-block">
              <Row className="checkout-card delivery-address">
                <Col>
                <Row className="header">
                  <Col>
                    <h5>{this.props.t('deliveryAddress')}</h5>
                    {/* {this.state.deliveryDateByButton}
                    {this.state.startDate} */}
                  </Col>
                </Row>
                <Row className="body dark-grey">
                <Col>
                  {active_delivery_address ? (
                    <div className="active-delivery-address">
                      <p className="mb-1">{active_delivery_address?.name}</p>
                      <p className="mb-1">
                        {active_delivery_address?.street_address}
                      </p>
                      <p className="mb-1">{`${active_delivery_address?.city}, ${active_delivery_address?.country}, ${active_delivery_address?.zip_code}`}</p>
                    </div>
                  ) : (
                    <p>{this.props.t('none')}</p>
                  )}
                </Col>
                <Col sm={2}>
                  <ChangeDeliveryAddressButton
                    origin="checkout-page"
                    active_delivery_address={active_delivery_address?.id}
                  />
                </Col>
                </Row>
                </Col>
              </Row>
              
              <Row className="checkout-card">
                    <Col>
                    <Row className="header">
                      <Col>
                      <h5>{this.props.t('deliveryInstructions')}</h5>
                      </Col>
                    </Row>
                    <Row className="body dark-grey">
                    <Col>
                    <p className="center dark-grey mt-1 mb-2">{this.props.t('chooseYourDeliveryDate')}</p>
                    <Row>
                      <Col>
                        <Button className={classnames(
                          "delivery-date-btn", "mb-2",
                          {
                            active: this.getDateToCompare(this.state.deliveryDateByButton) === this.getDateToCompare(this.getEarliestDeliveryDate(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 0))
                          }
                        )} onClick={() => this.selectDeliveryDateByButton(this.getEarliestDeliveryDate(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 0))}>{this.getEarliestDeliveryDateText(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 0)}</Button>
                        <Button className={classnames(
                          "delivery-date-btn", "mb-2",
                          {
                            active: this.getDateToCompare(this.state.deliveryDateByButton) === this.getDateToCompare(this.getEarliestDeliveryDate(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 1))
                          }
                        )} onClick={() => this.selectDeliveryDateByButton(this.getEarliestDeliveryDate(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 1))}>{this.getEarliestDeliveryDateText(todaysMomentCet, tomorrowsMomentCet, dayAfterTomorrowsMomentCet, twoDaysAfterTomorrowsMomentCet, currentHour, 1)}</Button> 
                        <Button className={classnames(
                          "delivery-date-btn",
                          {
                            active: this.state.deliveryDateByCalendar != null
                          }
                        )} onClick={() => this.deliveryDateByCalendar()}>
                          <DatePicker
                            dateFormat="dd.MM.y"
                            locale={"de"}
                            placeholderText={this.props.t('pickADate')}
                            excludeDates={this.getSundays()}
                            selected={this.state.startDate}
                            minDate={aDayFromNow}
                            maxDate={twoWeeksFromNow}
                            onChange={this.handleDateChange}
                          />
                        </Button>
                      </Col>
                    </Row>
                    <p>
                    {/* {this.state.deliveryDateByButton}
                    {this.state.deliveryDateByCalendar ? this.state.startDate?.toString() : null} */}
                    </p>
                    <Row>
                      <Col>
                        <Form className="custom-form">
                          <FormGroup>
                            <Row form>
                              <Col>
                                <div className="linediv">
                                  <Input
                                    type="textarea"
                                    name="delivery_instructions"
                                    id="delivery_instructions"
                                    className=""
                                    placeholder={this.props.t('yourDeliveryInstructions')}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                    <Row className="delivery-window">
                      <Col>
                        <p className="center dark-grey mt-3 mb-2">{this.props.t('chooseYourDeliveryWindow')}</p>
                        <Input
                			    type="select"
                			    name="select"
											    className="mb-2"
                			    id="deliveryWindowSelect"
                			    onChange={this.handleDeliveryWindowChange}
              			    >
													<option value={"9:00 - 10:00"}>
            								9:00 - 10:00
          								</option>
                          <option value={"10:00 - 11:00"}>
            								10:00 - 11:00
          								</option>
                          <option value={"11:00 - 12:00"}>
            								11:00 - 12:00
          								</option>
                          <option value={"12:00 - 13:00"}>
            								12:00 - 13:00
          								</option>
                          <option value={"13:00 - 14:00"}>
            								13:00 - 14:00
          								</option>
                          <option value={"14:00 - 15:00"}>
            								14:00 - 15:00
          								</option>
                          <option value={"15:00 - 16:00"}>
            								15:00 - 16:00
          								</option>
                          <option value={"16:00 - 17:00"}>
            								16:00 - 17:00
          								</option>
                          <option value={"17:00 - 18:00"}>
            								17:00 - 18:00
          								</option>
                          <option value={"18:00 - 19:00"}>
            								18:00 - 19:00
          								</option>
                          <option value={"19:00 - 20:00"}>
                            19:00 - 20:00
          								</option>  
             		 		    </Input>
                        {/* <Button className={classnames(
                          "full-width","mb-2", "btn-white",
                          {
                            "active": this.state.selectedDeliveryWindow === "9:00 - 12:00"
                          }
                        )} onClick={() => this.setDeliveryWindow("9:00 - 12:00")}>9:00 - 12:00</Button>
                        <Button className={classnames(
                          "full-width","mb-2", "btn-white",
                          {
                            "active": this.state.selectedDeliveryWindow === "12:00 - 15:00"
                          }
                        )} onClick={() => this.setDeliveryWindow("12:00 - 15:00")}>12:00 - 15:00</Button>
                        <Button className={classnames(
                          "full-width","mb-2", "btn-white",
                          {
                            "active": this.state.selectedDeliveryWindow === "15:00 - 18:00"
                          }
                        )} onClick={() => this.setDeliveryWindow("15:00 - 18:00")}>15:00 - 18:00</Button>
                        <Button className={classnames(
                          "full-width","mb-2", "btn-white",
                          {
                            "active": this.state.selectedDeliveryWindow === "18:00 - 20:00"
                          }
                        )} onClick={() => this.setDeliveryWindow("18:00 - 20:00")}>18:00 - 20:00</Button> */}
                      </Col>
                    </Row>
                  </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="checkout-card hide-on-mobile">
                <Col>
                <Row className="header">
                  <Col>
                    <h5>{this.props.t('paymentDetails')}</h5>
                  </Col>
                </Row>
                <Row className="body dark-grey">
                <Col>
                  {this.state.msg ? (
                    <Alert color="danger">
                      {this.state.msg}
                    </Alert>
                  ) : null}
                  {this.state.orderSuccess ? (
                    <Alert color="success">
                      {this.state.orderSuccess}
                    </Alert>
                  ) : null}
                  {
                    (orderTotal > 5.00) ? (<StripeContainer transactionType={"order"} isActive={isReadyForTransaction} cart={this.props.cart.cart} deliveryPreferences={deliveryPreferences} cartTotal={(this.getOrderTotal(cart) + 5 - discount).toFixed(2)} couponId={this.state.activeCouponCode?.id}/>) : null
                  }
                  {
                    this.paypalContainer(this.props.cart.cart, deliveryPreferences, (this.getOrderTotal(cart) + 5 - discount).toFixed(2), isReadyForTransaction)
                  }
                  <p className="mt-2 center" style={{fontSize: 12, color: '#9A9A9A'}}>{this.props.t('termsAndConditionsAgreement')}</p>
                </Col>
                </Row>
                </Col>
              </Row>
            </Col>
            <Col className="checkout-right-block">
            <Row className="checkout-card cart-items">
                <Col>
                  <Row className="header">
                  <Col>
                    <h5>{this.props.t('yourOrder')}</h5>
                  </Col>
                  </Row>
                  <ListGroup>
                    {cart.items?.length ? (
                      cart.items?.map(item => {
                      return (
                        <ListGroupItem className="px-0 my-2 item">
                          <Row>
                          <Col className="item-quantity-selector-col hide-on-desktop">
                            <div className="item-quantity-selector">
                              <Button className="minus-btn" onClick={() => this.handleQuantityChange(item.id, "-", item.supplier, item.item)}><MinusIconSvg /></Button>
                              <p>{item.quantity}</p>
                              <Button className="plus-btn" onClick={() => this.handleQuantityChange(item.id, "+", item.supplier, item.item)}><PlusIconSvg /></Button>
                            </div>
                          </Col>
                          <Col sm={2} className="pr-0 item-image">   
                            <Image publicId={item.image} className="" />
                          </Col>
                          <Col className="item-info">
                            <Row>
                              <Col>
                                <span className="item-name">
                                  {item.item_name}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="item-farmer dark-grey">
                                  {item.supplier_name}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="item-price">
                                  {this.props.t('currencySymbol')}{item.price_7_pct.toFixed(2)}
                                </span>
                                <span className="item-unit">
                                  {item.unit}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="item-quantity hide-on-mobile">
                            {/* <span className="float-right"> */}
                              {/* <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                              >
                                {this.getSelectOptions(item.quantity)}
                              </Input> */}
                              <div className="item-quantity-selector">
                                <Button className="minus-btn" onClick={() => this.handleQuantityChange(item.id, "-", item.supplier, item.item)}><MinusIconSvg /></Button>
                                <p>{item.quantity}</p>
                                <Button className="plus-btn" onClick={() => this.handleQuantityChange(item.id, "+", item.supplier, item.item)}><PlusIconSvg /></Button>
                              </div>
                            {/* </span> */}
                          </Col>
                          <Col className="item-total-price">
                            <Row>
                              <Col>
                                <span className="ml-4 float-right">
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => this.removeCartItem(item.id)}
                                    className="remove-cart-item-btn"
                                  />
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="price float-right">
                                  {this.props.t('currencySymbol')}{this.getItemPrice(item).toFixed(2)}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          </Row>
                        </ListGroupItem>
                      );
                    })) : (
                      <p className="greyed-out mt-4 pb-2 center">
                        {this.props.t('youHaveNoItemsInYourBasket')}...
                      </p>
                    )}
                  </ListGroup>
                  <hr></hr>
                  <h5 className="mb-3" style={{fontSize: 18, fontWeight: '800'}}>{this.props.t('coupon')}</h5>
                  <Form className="custom-form">
                    <FormGroup>
                      <Row form>
                        <Col>
                          <div className="linediv">
                            <Input
                              type="text"
                              name="coupon_code"
                              id="coupon_code"
                              value={this.state.coupon_code}
                              className=""
                              placeholder="Code eingeben"
                              onChange={this.onChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <Button onClick={() => this.applyCoupon(this.getOrderTotal(cart), user.id)} className="full-width">
                            {this.props.t('redeem')}
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                  <hr></hr>
                  <h5 className="mb-3" style={{fontSize: 18, fontWeight: '800'}}>{this.props.t('orderSummary')}</h5>
                  <p style={{fontSize: 14, fontWeight: '700'}}>
                    {this.props.t('item')}{" "}
                    <span className="float-right">
                      {this.props.t('currencySymbol')}{this.getOrderTotal(cart).toFixed(2)}
                    </span>
                  </p>
                  {
                    activeCouponCode ? (
                      <p className="dark-grey">
                        {this.props.t('coupon')} {activeCouponCode.code} <span className="float-right">- {this.props.t('currencySymbol')}{discount.toFixed(2)}</span>
                      </p>
                    ) : null
                  }
                  <p style={{fontSize: 14, fontWeight: '700'}}>
                    {this.props.t('delivery')} <span className="float-right">{this.props.t('currencySymbol')}5.00</span>
                  </p>
                  {/* {this.ifFirstOrderDiscountDelivery(orders, membership)}
                  {this.ifMemberShowDiscount(membership, cart, isFirstOrder)} */}
                  <hr></hr>
                  <h5 className="mb-3" style={{fontSize: 18, fontWeight: '800'}}>
                    {this.props.t('orderSummary')}{" "}
                    <span className="price float-right"  style={{fontSize: 18, fontWeight: '800', color: 'black'}}>{this.props.t('currencySymbol')}{(this.getOrderTotal(cart) + 5 - discount).toFixed(2)}</span>
                  </h5>
                  {/* <Button
                    className="btn-checkout-market-list mt-4 mb-3"
                    onClick={() => this.createMarketlistFromCart()}
                  >
                    < ShoppingCartIcon className="mr-4 hide-on-mobile"/>Einkaufszettel aus dem Warenkorb erstellen
                  </Button> */}
                  {/* <CreateOrderButton isActive={isReadyForTransaction} cart={this.props.cart.cart} deliveryPreferences={deliveryPreferences}/> */}

                  {/* <Card className="order-total-secondary">
                    <Row>
                      <Col sm={3}>
                        <CreateOrderButton isActive={isReadyForTransaction} cart={this.props.cart.cart} deliveryPreferences={deliveryPreferences}/>
                        <Button onClick={() => this.placeOrder()}>
                          Jetzt bestellen
                        </Button>
                      </Col>
                      <Col>
                        <h5>
                          Auftragssumme:{" "}
                          <span className="price">
                            €{this.getOrderTotal(cart).toFixed(2)}
                          </span>
                        </h5>
                        <p className="greyed-out mb-0 disclaimer">
                          Mit Ihrer Bestellung erklären Sie sich mit den <a className="underline" href="/agb">AGB</a> von HelloFarmer UG einverstanden.
                        </p>
                      </Col>
                    </Row>
                  </Card> */}
                </Col>
              </Row>
              <Row className="checkout-card hide-on-desktop">
                <Col>
                <Row className="header">
                  <Col>
                    <h5>{this.props.t('paymentDetails')}</h5>
                  </Col>
                </Row>
                <Row className="body dark-grey">
                <Col>
                  {this.state.msg ? (
                    <Alert color="danger">
                      {this.state.msg}
                    </Alert>
                  ) : null}
                  {this.state.orderSuccess ? (
                    <Alert color="success">
                      {this.state.orderSuccess} 
                    </Alert>
                  ) : null}
                  {
                    (orderTotal > 5.00) ? (<StripeContainer transactionType={"order"} isActive={isReadyForTransaction} cart={this.props.cart.cart} deliveryPreferences={deliveryPreferences} cartTotal={(this.getOrderTotal(cart) + 5 - discount).toFixed(2)} couponId={this.state.activeCouponCode?.id}/>) : null
                  }
                  {
                    this.paypalContainer(this.props.cart.cart, deliveryPreferences, (this.getOrderTotal(cart) + 5 - discount).toFixed(2), isReadyForTransaction)
                  }
                  <p className="mt-2 dark-grey center order-disclaimer">{this.props.t('termsAndConditionsAgreement')}</p>
                </Col>
                </Row>
                </Col>
              </Row>
              {/* <Card className="order-total-primary">
                <Button onClick={() => this.placeOrder()} className="mb-2">
                  Jetzt bestellen
                </Button>
                <CreateOrderButton isActive={isReadyForTransaction} cart={this.props.cart.cart} deliveryPreferences={deliveryPreferences}/>
                <p className="text-center greyed-out mb-0 disclaimer">
                  Mit Ihrer Bestellung erklären Sie sich mit den <a className="underline" href="/agb">AGB</a> von HelloFarmer UG einverstanden.
                </p>
                <hr></hr>
                <h5>Bestellübersicht</h5>
                <p>
                  Artikel:{" "}
                  <span className="float-right">
                    €{this.getOrderTotal(cart).toFixed(2)}
                  </span>
                </p>
                <p className="mb-0">
                  Lieferung: <span className="float-right">€0,00</span>
                </p>
                <hr></hr>
                <h5>
                  Auftragssumme:{" "}
                  <span className="price">€{this.getOrderTotal(cart).toFixed(2)}</span>
                </h5>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
      </LoadingOverlay>
    );
  }
}

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// export default connect(
//   mapStateToProps,
//   null
// )(DashboardPage);

CheckoutPage.propTypes = {
  getMembership: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  createMarketlistFromCart: PropTypes.func.isRequired,
  changeCartItemQuantity: PropTypes.func.isRequired,
  emptyCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  delivery_address: PropTypes.object.isRequired,
  isArchived: PropTypes.bool,
  order: PropTypes.object.isRequired,
  coupon: PropTypes.object.isRequired,
  getCoupons: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  order: state.order,
  cart: state.cart,
  coupon: state.coupon,
  membership: state.membership,
  company: state.company,
  delivery_address: state.delivery_address,
  dashboard: state.dashboard
});

export default withTranslation()(connect(
  mapStateToProps,
  {
    getMembership,
    getCart,
    removeItemFromCart,
    createMarketlistFromCart,
    createOrder,
    emptyCart,
    getDelivery_addresses,
    changeCartItemQuantity,
    getAllOrders,
    getCoupons
  }
)(CheckoutPage));
