import gql from "graphql-tag";
// Invite supplier
export const CREATE_MARKET_LIST_FROM_CART_MUTATION = gql`
  mutation createMarketlistFromCart($cartId: String!) {
    createMarketlistFromCart(cartId: $cartId) {
      id
      name
      items {
        ... on Cartitem {
          item
          item_name
          quantity
          price
          price_7_pct
          supplier
          supplier_name
          unit
        }
      }
    }
  }
`;
