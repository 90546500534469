import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import Swal from "sweetalert2";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ListGroup,
	ListGroupItem,
  ModalBody,
  NavLink,
  ModalHeader,
  Label,
  Input,
  Nav,
  NavItem,
  Row,
  Col,
	Button
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { getCart } from "../../../../actions/cartActions";
import { removeItemFromCart } from "../../../../actions/cartActions";
import { changeCartItemQuantity } from "../../../../actions/cartActions";
import { Image } from "cloudinary-react";

import { Link } from "react-router-dom";

import { ReactComponent as CartIcon } from "../../../../assets/CartIcon.svg";
import { ReactComponent as CartIconActive } from "../../../../assets/CartIconActive.svg";
import { ReactComponent as CartModalIcon } from "../../../../assets/CartModalIcon.svg";
import { ReactComponent as PlusIconSvg } from "../../../../assets/PlusIcon.svg";
import { ReactComponent as MinusIconSvg } from "../../../../assets/MinusIcon.svg";

class CartModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: this.props.auth?.user?.region,
      selectedLanguage: this.props.auth?.user?.language
    };
  }

	componentDidMount() {
    this.props.getCart();
  }

  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  handleQuantityChange = (cartitemId, plusOrMinus, supplierId, cartItemId) => {
    if(plusOrMinus == "+") {
      this.props.changeCartItemQuantity(cartitemId, "+", supplierId, cartItemId);
    } else if (plusOrMinus = "-") {
      if (this.state.selectedQuantity != 1){
        this.props.changeCartItemQuantity(cartitemId, "-", supplierId, cartItemId);
      }
    }
  }

	getItemPrice = item => {
    var itemPrice = item.quantity * item.price_7_pct;
    itemPrice = itemPrice.toFixed(2);
    itemPrice = parseFloat(itemPrice);
    return itemPrice;
  };

	getOrderTotal = cart => {
    var orderTotal = 0;
    if (cart.items) {
      cart.items.forEach(item => {
        orderTotal += this.getItemPrice(item);
      });
    }
    orderTotal = orderTotal.toFixed(2);
    orderTotal = parseFloat(orderTotal);
    return orderTotal;
  };

	getSelectOptions = quantity => {
    var options = [];
    for (var i = 0; i < 10; i++) {
      if (i + 1 == quantity) {
        options.push(<option selected>{i + 1}</option>);
      } else {
        options.push(<option>{i + 1}</option>);
      }
    }
    return options;
  };

	removeCartItem = cartitemId => {
    this.props.removeItemFromCart(cartitemId);
    // this.props.removeItemFromCart(cartitemId);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  showCartTotalTooLowErrorMessage = () => {
    Swal.fire({
      title: "Bitte fülle alle Felder aus",
      icon: "warning",
      confirmButtonColor: "#C25982",
      confirmButtonText: "Ok"
    });
  }

  checkoutButton = (cart) => {
    var orderTotal = this.getOrderTotal(cart);
    var amountLeftFor15Euros = (15 - orderTotal)

    if (orderTotal < 15.00){
      return(
        <div>
          <p className="mb-0 center greyed-out"><b>{this.props.t('only')} {this.props.t('currencySymbol')}{amountLeftFor15Euros.toFixed(2)} {this.props.t('moreToReachTheMinimumOrderValue')}</b></p>
          <Button disabled={true} className="mt-3" onClick={() => {Swal.fire({
            title: `Der Mindestbestellwert beträgt ${this.props.t('currencySymbol')}15`,
            icon: "warning",
            confirmButtonColor: "#C25982",
            confirmButtonText: "Ok"
          })}}>
            {this.props.t('goToCheckout')}
          </Button>
        </div>
      )
    } else {
      return (
        <div>
        <Link
          exact
          to="/checkout"
        >
          <Button disabled={!cart.items?.length} className="mt-3" onClick={() => this.toggle()}>
            <Row style={{paddingLeft: 15, paddingRight: 15}}>
              <Col style={{padding: 0, textAlign: 'left'}}>
                <p style={{fontSize: 14, fontWeight: '500', marginBottom: 0, marginTop: 5}}>
                  {this.props.t('checkAndSend')}
                </p>
              </Col>
              <Col style={{padding: 0, textAlign: 'right'}}>
                <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 0}}>
                  {this.props.t('currencySymbol')}{this.getOrderTotal(cart).toFixed(2)}
                </p>
                <p style={{fontSize: 8, marginBottom: 0}}>
                  {this.props.t('basketSubtotal')}
                </p>
              </Col>
            </Row>
          </Button>
        </Link>
        </div>
      )
    }
  }

  render() {
    const { user } = this.props.auth;
		const cart = this.props.cart.cart;

    return (
      <div style={{height: '100%'}}>
        <div className="hide-on-mobile" style={{height: '100%'}}>
					{cart.items?.length ? (
              <NavLink className="navbar-cart-container" onClick={this.toggle} href="#" style={{padding: 0, height: '100%'}}>
                <Row style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 12, paddingLeft: 10, paddingRight: 10}}>
                  <Col className="pr-0" style={{padding: 0, marginRight: 5}}>
                    <CartIcon />
                  </Col>
                  <Col style={{padding: 0, marginRight: 20}}>
                    <p style={{fontSize: 14, fontWeight: 'bold'}}>
                      {this.props.t('basket')}
                    </p>
                  </Col>
                  <Col className="ml-1 pl-0" style={{padding: 0}}>
                    <p className="mb-0 cart-total" style={{fontSize: 14, fontWeight: 'bold'}}>{this.props.t('currencySymbol')}{this.getOrderTotal(cart).toFixed(2)}</p>
                  </Col>
                </Row>
              </NavLink>
            ) : (
              null
            )}
        </div>
        <div className="hide-on-desktop">
          <NavLink className="" onClick={this.toggle} href="#">
					  {cart.items?.length ? (
              <CartIconActive />
            ) : (
              null
            )}
          </NavLink>
        </div>
        <Modal className="cart-modal" size="md" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{this.props.t('basket')}</ModalHeader>
          <ModalBody>
						<ListGroup>
              {cart.items?.length ? (
								cart.items?.map(item => {
                return (
                  // <Link
                  //   className="market-list-item"
                  //   exact
                  //   to={`/items/${item.item}`}
                  // >
                  <div className="market-list-item" style={{boxShadow: 'none'}}>
                    <ListGroupItem className="justify-content-between" style={{backgroundColor: 'transparent'}}>
                      {/* <img src={tomatoes} /> */}
                      
												<Row>
                          <Col className="item-quantity-selector-col hide-on-desktop">
                            <div className="item-quantity-selector">
                              <Button className="minus-btn" onClick={() => this.handleQuantityChange(item.id, "-", item.supplier, item.item)}><MinusIconSvg /></Button>
                              <p>{item.quantity}</p>
                              <Button className="plus-btn" onClick={() => this.handleQuantityChange(item.id, "+", item.supplier, item.item)}><PlusIconSvg /></Button>
                            </div>
                          </Col>
													<Col sm={2} className="item-icon-col" style={{padding: 0}}>
                       	 		<Image publicId={item.image} />
													</Col>
													<Col className="item-info-col">
														<p className="item-name">{item.item_name}</p>
                            <p className="item-per-unit-price" style={{fontSize: 10}}><b style={{fontSize: 12}}>{this.props.t('currencySymbol')}{item.price_7_pct?.toFixed(2)}</b>{item.unit}</p>
                            <p className="item-farm" style={{fontSize: 12, color: '#9A9A9A'}}>{item.supplier_name}</p>
													</Col>
													<Col className="hide-on-mobile" sm={2} style={{padding: 0}}>
														{/* <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
															className="item-quantity"
                            >
                              {this.getSelectOptions(item.quantity)}
                            </Input> */}
                            <div className="item-quantity-selector">
                              <Button className="minus-btn" onClick={() => this.handleQuantityChange(item.id, "-", item.supplier, item.item)}><MinusIconSvg /></Button>
                              <p>{item.quantity}</p>
                              <Button className="plus-btn" onClick={() => this.handleQuantityChange(item.id, "+", item.supplier, item.item)}><PlusIconSvg /></Button>
                            </div>
													</Col>
													<Col sm={3}>
														{/* <Button onClick={() => this.removeCartItem(item.id)}> */}
                            <Row>
                              <Col>
                                <FontAwesomeIcon
                          			  icon={faTimes}
                          			  onClick={() => this.removeCartItem(item.id)}
                          			  className="remove-cart-item-btn float-right"
                        			  />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="item-price float-right">
                        			    {this.props.t('currencySymbol')}{this.getItemPrice(item).toFixed(2)}
                      			    </span>
                              </Col>
                            </Row>
													</Col>
												</Row>
                      
                    </ListGroupItem>
                  </div>
                  // </Link>
                );
              })) : (
                <div className="center">
                  <CartModalIcon className="mt-2"/>
								  <p className="greyed-out mt-2 center">
                    {this.props.t('emptyBasketMessage')}
              	  </p>
                </div>
							)}
            </ListGroup>
            {/* {cart.items?.length ? (
              <p className="align-right mt-3">
                Warenkorb Zwischensumme{" "}
                <p className="subtotal-price">
                  EUR {this.getOrderTotal(cart).toFixed(2)}
                </p>
              </p>
            ) : (
              null
            )} */}
            {this.checkoutButton(cart)}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

CartModal.propTypes = {
  getCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  changeCartItemQuantity: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
	cart: state.cart
});

export default withTranslation()(connect(
  mapStateToProps,
  { removeItemFromCart, changeCartItemQuantity, getCart }
)(CartModal));