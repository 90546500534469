import gql from "graphql-tag";

export const CREATE_ITEM_MUTATION = gql`
  mutation createItem($name: String, $region: String, $description: String, $image: String, $categories: [String], $supplier: String, $unit: String, $price_per_unit: Float, $tax_pct: Int, $amount: Int, $nutritional_facts: [String!], $item_cost: Float) {
    createItem(name: $name, region: $region, description: $description, image: $image, categories: $categories, supplier: $supplier, unit: $unit, price_per_unit: $price_per_unit, tax_pct: $tax_pct, amount: $amount, nutritional_facts: $nutritional_facts, item_cost: $item_cost) {
      id
      name
      description
      image
      region
      is_recent
      categories
      suppliers {
        ... on Supplier {
          id
          name
          stock {
            ... on StockItem {
              item
              price_per_unit
              price_per_unit_net
              price_per_unit_7_pct
              price_per_unit_19_pct
              unit
              amount
            }
          }
        }
      }
    }
  }
`;