import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import {
  Button,
  Card,
  CardFooter,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  NavLink,
  Alert
} from "reactstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sendPasswordResetEmail } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoTitle from "../../assets/NewHelloFarmerLogo.svg";

import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";

class ForgottenPasswordPage extends Component {
  state = {
    modal: false,
    email: "",
    msg: null,
    successMsg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    password_reset_email_sent: PropTypes.bool,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { password_reset_email_sent, error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "SEND_PASSWORD_RESET_EMAIL_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    if (password_reset_email_sent !== prevProps.password_reset_email_sent) {
      // Check for login error
      if (password_reset_email_sent == true) {
        this.setState({ successMsg: this.props.t('forgotPasswordConfirmationText'), email: "" });
      }
    }
    
    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { email } = this.state;

    // Attempt to login
    this.props.sendPasswordResetEmail(email);
  };

  render() {
    return (
      <div id="forgot-password-page" className="login auth-page">
        <Container className="login-page center">
          <div className="mb-4">
            <img src={logoTitle} alt="Logo" />
          </div>

          <div className="panel">
            <h2 className="center">{this.props.t('forgotYourPassword')}</h2>
            <Form className="pl-4 pr-4 pb-4 pt-2 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              {this.state.successMsg ? (
              <Alert color="success">{this.state.successMsg}</Alert>
              ) : null}
              <FormGroup>
                <div className="form-input">
                  <UserIcon className="input-icon"/>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="mb-3"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
                <p className="greyed-out" style={{fontSize: 12, marginBottom: '3rem'}}>
                  {this.props.t('forgotPasswordText')}
                </p>
                <Button
                  className="authButtonPrimary"
                  block
                  style={{height: 40, fontSize: 14}}
                >
                  {this.props.t('send').toUpperCase()}
                </Button>
              </FormGroup>
            </Form>
            {/* <a className="signup-link" href="/login">
              <CardFooter>
                <span className="greyed-out">Zurück zum </span>
                <span className="underline">Login</span>
              </CardFooter>
            </a> */}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  password_reset_email_sent: state.auth.password_reset_email_sent,
  error: state.error
});

export default withTranslation()(connect(
  mapStateToProps,
  { sendPasswordResetEmail, clearErrors }
)(ForgottenPasswordPage));
