import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SEND_PASSWORD_RESET_EMAIL,
  CHANGE_USER_PROFILE_PICTURE,
  CHANGE_USER_LANGUAGE,
  CHANGE_USER_NAME,
  CHANGE_USER_EMAIL,
  CHANGE_USER_PHONE,
  CHANGE_USER_TAX_ID,
  CHANGE_USER_TAX_NUMBER,
  CHANGE_PASSWORD,
  TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE,
  TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE,
  TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE,
  TOGGLE_ORDER_UPDATES_PREFERENCE,
  TOGGLE_SMS_NOTIFICATIONS_PREFERENCE,
  SEND_CONTACT_FORM,
  RESET_FORGOTTEN_PASSWORD,
  ADMIN_GET_ALL_USERS,
  GET_SINGLE_USER
} from "../actions/types";

import Cookies from "js-cookie";
import { AUTH_TOKEN } from "../helper/constants";

const initialState = {
  token: Cookies.get(AUTH_TOKEN),
  isAuthenticated: null,
  loading: false,
  user: null,
  users: [],
  password_reset_email_sent: false,
  password_change_successfull: false,
  contact_form_sent: {},
  singleUser: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
        password_reset_email_sent: false
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
    case LOGIN_SUCCESS:
      Cookies.set("token", action.payload.token, { expires: 7 });
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false
      };
    case REGISTER_SUCCESS:
      Cookies.set("token", action.payload.token, { expires: 7 });
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false,
        loading: false
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      Cookies.remove("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        loading: false
      };
    case SEND_PASSWORD_RESET_EMAIL:
      return {
        ...state,
        loading: false,
        password_reset_email_sent: true
      };
    case RESET_FORGOTTEN_PASSWORD:
      return {
        ...state,
        loading: false
      };
    case CHANGE_USER_PROFILE_PICTURE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_LANGUAGE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_NAME:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_EMAIL:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_PHONE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_TAX_ID:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_USER_TAX_NUMBER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        password_change_successfull: action.payload,
        loading: false
      };
    case TOGGLE_BROWSER_NOTIFICATIONS_PREFERENCE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
      case TOGGLE_EMAIL_NOTIFICATIONS_PREFERENCE:
        return {
          ...state,
          user: action.payload,
          loading: false
        };
        case TOGGLE_ITEM_REQUEST_UPDATES_PREFERENCE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
      case TOGGLE_ORDER_UPDATES_PREFERENCE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
      case TOGGLE_SMS_NOTIFICATIONS_PREFERENCE:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
      case SEND_CONTACT_FORM:
      return {
        ...state,
        contact_form_sent: action.payload,
        loading: false
      };
      case ADMIN_GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
      case GET_SINGLE_USER:
        return {
          ...state,
          singleUser: action.payload,
          loading: false
        };
      default:
        return state;
  }
}
