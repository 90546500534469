import axios from "axios";
import {
  GET_CART,
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  CHANGE_CART_ITEM_QUANTITY,
  EMPTY_CART,
} from "./types";
import { ADD_ITEM_TO_CART_MUTATION } from "../graphql/mutation/carts/addItemToCart";
import { REMOVE_ITEM_FROM_CART_MUTATION } from "../graphql/mutation/carts/removeItemFromCart";
import { CHANGE_CART_ITEM_QUANTITY_MUTATION } from "../graphql/mutation/carts/changeCartItemQuantity";
import { EMPTY_CART_MUTATION } from "../graphql/mutation/carts/emptyCart";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";

export const getCart = () => dispatch => {
  client
    .query({
      query: gql`
        query cart {
          getCart {
            id
            items {
              ... on Cartitem {
                id
                item
                item_name
                image
                quantity
                price
                price_7_pct
                supplier
                supplier_name
                unit
              }
            }
          }
        }
      `
    })
    .then(res => {
      dispatch({ type: GET_CART, payload: res.data.getCart });
    })
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addItemToCart = cartitem => (dispatch, getState) => {
  console.log(cartitem);
  client
    .mutate({
      variables: { cartitem: cartitem },
      mutation: ADD_ITEM_TO_CART_MUTATION
    })
    .then(res => {
      dispatch({
        type: ADD_ITEM_TO_CART,
        payload: res.data.addItemToCart
      })
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeItemFromCart = cartitemId => (dispatch, getState) => {
  client
    .mutate({
      variables: { cartitemId },
      mutation: REMOVE_ITEM_FROM_CART_MUTATION
    })
    .then(res => {
      console.log("REMOVING");
      dispatch({
        type: REMOVE_ITEM_FROM_CART,
        payload: res.data.removeItemFromCart
      });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changeCartItemQuantity = (cartitemId, plus_or_minus, supplierId, stockItemId) => (dispatch, getState) => {
  client
    .mutate({
      variables: { cartitemId, plus_or_minus, supplierId, stockItemId },
      mutation: CHANGE_CART_ITEM_QUANTITY_MUTATION
    })
    .then(res => {
      dispatch({
        type: CHANGE_CART_ITEM_QUANTITY,
        payload: res.data.changeCartItemQuantity
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const emptyCart = id => dispatch => {
  client
    .mutate({
      variables: { cartId: id },
      mutation: EMPTY_CART_MUTATION
    })
    .then(res => {
      dispatch({
        type: EMPTY_CART,
        payload: res.data.emptyCart
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};