import gql from "graphql-tag";
// Invite supplier
export const CREATE_MEMBERSHIP_MUTATION = gql`
  mutation createMembership($type: String!, $paymentMethodId: String!, $price: Float!, $durationMonths: Int!, $billingAddress: Delivery_addressInput!) {
    createMembership(type: $type, paymentMethodId: $paymentMethodId, price: $price, durationMonths: $durationMonths, billingAddress: $billingAddress) {
      id
			type
      billing_address {
        name
        street_address
        street_address
        zip_code
        city
        country
      }
			price
			date_started
			date_ending
      status
      duration_months
    }
  }
`;
