import {
  CREATE_MEMBERSHIP,
	GET_MEMBERSHIP,
  CREATE_MEMBERSHIP_FAIL,
  CREATE_MEMBERSHIP_SUCCESS
} from "../actions/types";

const initialState = {
  membership: {},
  new_membership: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_MEMBERSHIP:
      return {
        ...state,
        new_membership: action.payload,
        membership: action.payload,
        loading: false
      };
    case GET_MEMBERSHIP:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CREATE_MEMBERSHIP_FAIL:
      return {
        state
      }
    case CREATE_MEMBERSHIP_SUCCESS:
      return {
        ...state
      }
    default:
      return state;
  }
}