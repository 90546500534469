import React, { Component } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as StatusCircleGreen } from "../../../../../assets/StatusCircleGreen.svg";
import { ReactComponent as StatusCircleYellow } from "../../../../../assets/StatusCircleYellow.svg";
import { ReactComponent as StatusCircleRed } from "../../../../../assets/StatusCircleRed.svg";

import { getAllItemrequests } from "../../../../../actions/item_requestActions";
import { deleteItemrequest } from "../../../../../actions/item_requestActions";

import AddItemRequestButton from "./AddItemRequestButton";

class ItemRequestsTab extends Component {
  componentDidMount() {
    this.props.getAllItemrequests();
  }

  statusBadge = itemRequest => {
    switch(itemRequest.status){
      case 0:
        return(
          <StatusCircleYellow className="mr-3"/>
        );
      case 1:
        return(
          <StatusCircleGreen className="mr-3"/>
        );
      default:
        break;
    }
  }

  deleteItemrequest = (e, itemrequestId) => {
    e.preventDefault();
    this.props.deleteItemrequest(itemrequestId);
    // this.props.removeItemFromCart(cartitemId);
  };

  render() {
    const itemrequests = this.props.item_request.item_requests;
    const isGuest = this.props.isGuest
    return (
      <Container className="my-4 p-0">
        <Row>
          <Col>
            <ListGroup>
              <AddItemRequestButton isGuest={isGuest}/>
              <p>
                <span className="ml-2 mr-3"><StatusCircleGreen /> Approved</span>
                <span className="mr-3"><StatusCircleYellow /> Pending</span>
                <span><StatusCircleRed /> Declined</span>
              </p>
              {itemrequests.length ? (
                itemrequests.map(item_request => {
                return (
                  <div className="item-request-list-item">
                    {/* <Link
                      className="item-request-list-item"
                      exact
                      to={`/itemrequests/${item_request?.id}`}
                    > */}
                    <ListGroupItem className="justify-content-between">
                      {this.statusBadge(item_request)}
                      <span>
                        {item_request?.item_name}
                        {/* (x
                        {item_request?.item_quantity}) */}
                      </span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={(e) => this.deleteItemrequest(e, item_request.id)}
                        className="delete-itemrequest-btn float-right"
                      />
                    </ListGroupItem>
                    {/* </Link> */}
                  </div>
                );
              })) : (
                <p className="greyed-out">
                  Sie haben zur Zeit keine Produktanforderungen...
                </p>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

ItemRequestsTab.propTypes = {
  marketlist: PropTypes.object.isRequired,
  item_request: PropTypes.object.isRequired,
  deleteItemrequest: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  marketlist: state.marketlist,
  item_request: state.item_request,
});

export default connect(
  mapStateToProps,
  { deleteItemrequest, getAllItemrequests }
)(withRouter(ItemRequestsTab));
