import React, { useEffect, useRef, useState } from "react"
import { createOrder } from "../../../../../actions/orderActions";
import { emptyCart } from "../../../../../actions/cartActions";
import { createMembership } from "../../../../../actions/membershipActions";
import { useDispatch } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import { PAYPAL_CLIENT_ID } from "../../../../../helper/constants";

const PaypalButton = ({cart, deliveryPreferences, cartTotal, type}) => {
	const dispatch = useDispatch();
	const cartTotalConst = cartTotal;
	const typeConst = type;
	const deliveryDate = deliveryPreferences?.deliveryDate
	const deliveryWindow = deliveryPreferences?.deliveryWindow
	const deliveryInstructions = deliveryPreferences?.deliveryInstructions
	const deliveryAddress = deliveryPreferences?.deliveryAddress

	const getItemsArray = (cart => {
		var itemsArray = []
		if (cart.items){
			cart.items.forEach(cartItem => {
				const newPaypalItem = {
					name: cartItem.item_name,
					// sku: "xyz-2654",
					unit_amount: {
						currency_code: "EUR",
						value: cartItem.price_7_pct.toString()
					},
					quantity: cartItem.quantity.toString()
				}
				itemsArray.push(newPaypalItem)
			})
		}
		return itemsArray
	})

  return (
    <PayPalButton
		style = {{
			color:   'silver',
			shape:   'rect',
			label:   'paypal',
			height: 40,
			eligibilityStatus: 'ineligible'
		}}
    createOrder= {(data, actions, err) => {
			// console.log(cartTotalConst, "CART TOTAL")
			if (typeConst == "order"){
				return actions.order.create({
					intent: "CAPTURE",
					purchase_units: [
						{
							description: "HelloFarmer Bestellung",
							amount: {
								currency_code: "EUR",
								value: cartTotalConst,
								breakdown: {
									item_total: {
										currency_code: "EUR",
										value: cartTotalConst
									}
								}
							}
							// items: getItemsArray(cart)
						}
					]
				})
			} else if (typeConst == "membership"){
				return actions.order.create({
					intent: "CAPTURE",
					purchase_units: [
						{
							description: "HelloFarmer Premium",
							amount: {
								currency_code: "EUR",
								value: cartTotalConst,
								breakdown: {
									item_total: {
										currency_code: "EUR",
										value: cartTotalConst
									}
								}
							}
						}
					]
				})
			}
		}}
		onApprove = {async (data, actions) => {
			const order = await (actions.order.capture())
			if (typeConst == "order"){
				dispatch(createOrder(cart.id, "None", deliveryDate, deliveryWindow, deliveryInstructions, deliveryAddress))
				// dispatch(emptyCart(cart.id))
			} else if (typeConst == "membership"){
				dispatch(createMembership("Launch", "None", parseFloat(9.99), 3, deliveryPreferences.deliveryAddress))
			}
		}}
		onError = {(err) => {
			console.log({err})
		}}
    options={{
      clientId: process.env.PAYPAL_CLIENT_ID
    }}
  />
  );
}

export default PaypalButton;

// export default function Paypal({cart, deliveryPreferences, cartTotal}){
// 	const dispatch = useDispatch();
// 	const paypal = useRef();
// 	const cartTotalConst = cartTotal
// 	const deliveryDate = deliveryPreferences.deliveryDate
// 	const deliveryWindow = deliveryPreferences.deliveryWindow
// 	const deliveryInstructions = deliveryPreferences.deliveryInstructions
// 	const deliveryAddress = deliveryPreferences.deliveryAddress
	
	// const getItemsArray = (cart => {
	// 	var itemsArray = []
	// 	if (cart.items){
	// 		cart.items.forEach(cartItem => {
	// 			const newPaypalItem = {
	// 				name: cartItem.item_name,
	// 				// sku: "xyz-2654",
	// 				unit_amount: {
	// 					currency_code: "EUR",
	// 					value: cartItem.price_7_pct.toString()
	// 				},
	// 				quantity: cartItem.quantity.toString()
	// 			}
	// 			itemsArray.push(newPaypalItem)
	// 		})
	// 	}

	// 	return itemsArray
	// })

	// const handleSubmit = async (e) => {
	// 	e.preventDefault()
	// 	console.log(cart)
	// 	const {error, paymentMethod} = await stripe.createPaymentMethod({
	// 		type: "card",
	// 		card: elements.getElement(CardElement),
	// 		billing_details: billingDetails
	// 	})

	// 	if (error) {
  //    	console.log('[error]', error);
	// 		setErrorMessage({ message: error.message });
  //   } else {
	// 		dispatch(createOrder(cart.id, paymentMethod.id, deliveryPreferences.deliveryDate, deliveryPreferences.deliveryInstructions))
	// 		dispatch(emptyCart(cart.id))
  //   }
	// }

// 	useEffect(() => {
// 		let myButton = window.paypal.Buttons({
// 			style: {
// 				color:   'silver',
// 				shape:   'rect',
// 				label:   'paypal',
// 				height: 40,
// 				eligibilityStatus: 'ineligible'
// 			},
// 			createOrder: (data, actions, err) => {
// 				console.log(cartTotalConst, "CART TOTAL")
// 				return actions.order.create({
// 					intent: "CAPTURE",
// 					purchase_units: [
// 						{
// 							description: "HelloFarmer Bestellung",
// 							amount: {
// 								currency_code: "EUR",
//                 value: cartTotalConst,
//                 breakdown: {
//               		item_total: {
//                   	currency_code: "EUR",
//                   	value: cartTotalConst
//                 	}
//                 }
// 							}
// 							// items: getItemsArray(cart)
// 						}
// 					]
// 				})
// 			},
// 			onApprove: async (data, actions) => {
// 				const order = await (actions.order.capture())
// 				dispatch(createOrder(cart.id, "None", deliveryDate, deliveryWindow, deliveryInstructions, deliveryAddress))
// 				dispatch(emptyCart(cart.id))
// 			},
// 			onError: (err) => {
// 				console.log({err})
// 			}
// 		});
// 		myButton.render(paypal.current)
		
// 	}, [cartTotalConst, deliveryDate, deliveryInstructions, deliveryAddress])

//   return (
//     <div>
// 			<div ref={paypal}></div>
// 		</div>
//   )
// }