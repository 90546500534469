import gql from "graphql-tag";
// Login user
export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        admin
        guest
        farmer
        farmer_account
        name
        email
        role
        region
        language
        profile_picture
        feed {
          ... on FeedItem {
            id
            creator
            receiver
            type
            sourceId
            creation_date
          }
        }
        delivery_address
        telephone
        tax_number
        tax_id
        recent_items
        notification_preferences {
          order_updates
          item_request_updates
          email_notifications
          sms_notifications
          browser_notifications
        }
      }
    }
  }
`;
